import clsx from "clsx";
import { FormEvent, useState } from "react";

import "../fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface InputPasswordProps {
    label: string;
    value: string | undefined;
    placeholder?: string;
    required?: boolean;
    showRequired?: boolean;
    onChange: (e: FormEvent<HTMLInputElement>) => void;
}

export const InputPassword = ({
    label,
    value,
    placeholder = "...",
    onChange,
    showRequired = false,
    required = true,
}: InputPasswordProps) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <div className="flex flex-col font-medium relative">
            <span className="inline-flex justify-between">
                <label htmlFor={label} className="text-sm text-black">
                    {label}
                </label>
                {required && showRequired && (
                    <p className="text-red leading-3">Dit veld is verplicht</p>
                )}
            </span>
            <input
                type={showPassword ? "text" : "password"}
                id={label}
                value={value}
                autoComplete="new-password"
                className={clsx(
                    "border border-gray rounded px-4 py-2 text-sm transition-all focus:outline-none w-full focus:ring-2 hover:ring-primary-green hover:ring-1 focus:ring-primary-green",
                    showRequired && required && !value && "ring-1 ring-red"
                )}
                placeholder={placeholder}
                onChange={(e: FormEvent<HTMLInputElement>) => onChange(e)}
            />
            <div
                className="absolute right-3 bottom-2 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
            >
                {showPassword ? (
                    <FontAwesomeIcon color="#B7B7B7" icon="eye-slash" />
                ) : (
                    <FontAwesomeIcon color="#B7B7B7" icon="eye" />
                )}
            </div>
        </div>
    );
};
