import { API, Auth, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { Spinner } from "../../../components/Spinner";
import "../../../fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PersonalSettings } from "./pages/PersonalSettings";
import { CompanySettings } from "./pages/CompanySettings";
import { AccountSettings } from "./pages/AccountSettings";
import { Modal } from "../../../components/Modal";
import { useLocation } from "react-router-dom";
import { CpvModification } from "./pages/CpvModification";
import clsx from "clsx";
import { PaymentSettings } from "./pages/PaymentSettings";
import { FiUser } from "react-icons/fi";

interface UserInfoProps {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    stripeID: string;
    companyInfo: CompanyInfoProps;
    cpvs: any;
}

interface CompanyInfoProps {
    id: string;
    chamberOfCommerce: string;
    name: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
}

const Settings = () => {
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const urlPage = urlParams.get("page");
    const [navigator, setNavigator] = useState<string>(
        urlPage ? urlPage : "personalsettings"
    );
    const [UserInfo, setUserInfo] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [userActive, setUserActive] = useState<boolean>(false);

    useEffect(() => {
        document.title = "Instellingen | TenderTracer";
        fetchInfo();
    }, []);

    async function fetchInfo() {
        const { attributes } = await Auth.currentAuthenticatedUser();
        const InfoQuery = `query MyQuery {
            getUser(id: "${attributes.sub}") {
                firstname
                lastname
                email
                phone
                stripe_id
                active
                companies {
                    items {
                        company {
                            id
                            name
                            address
                            city
                            postalcode
                            country
                            active_subscription
                            chamber_of_commerce
                            cpv {
                                items {
                                    id
                                    cpvId
                                    cpv {
                                        id
                                        description
                                        code
                                        cpv_classes {
                                            items {
                                                cpv {
                                                id
                                                description
                                                code
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            aiCPV {
                                items {
                                    id
                                    cpvId
                                    cpv {
                                        id
                                        description
                                        code
                                    }
                                }
                            }
                        }
                    }
                }
            }
            }
`;
        const fetchCpvDivisions = `query MyQuery { listCpvDivisions { items { id description code cpv_groups { items { id description code cpv_classes { items { id description code } } } } } } }`;
        try {
            const fetchedUserInfo: any = await API.graphql(
                graphqlOperation(InfoQuery)
            );
            const fetchedCpvDivisions: any = await API.graphql(
                graphqlOperation(fetchCpvDivisions)
            );
            setUserActive(
                fetchedUserInfo.data.getUser.companies.items[0].company
                    .active_subscription
            );
            setUserInfo({
                firstname: fetchedUserInfo.data.getUser.firstname,
                lastname: fetchedUserInfo.data.getUser.lastname,
                email: attributes.email,
                phone: fetchedUserInfo.data.getUser.phone.slice(3),
                stripeID: fetchedUserInfo.data.getUser.stripe_id,
                companyInfo: {
                    id: fetchedUserInfo.data.getUser.companies.items[0].company
                        .id,
                    chamberOfCommerce:
                        fetchedUserInfo.data.getUser.companies.items[0].company
                            .chamber_of_commerce,
                    name: fetchedUserInfo.data.getUser.companies.items[0]
                        .company.name,
                    address:
                        fetchedUserInfo.data.getUser.companies.items[0].company
                            .address,
                    postalCode:
                        fetchedUserInfo.data.getUser.companies.items[0].company
                            .postalcode,
                    city: fetchedUserInfo.data.getUser.companies.items[0]
                        .company.city,
                    country:
                        fetchedUserInfo.data.getUser.companies.items[0].company
                            .country,
                },
                cpvs: {
                    cpvCodesUser:
                        fetchedUserInfo.data.getUser.companies.items[0].company,
                    allCpvs: fetchedCpvDivisions.data.listCpvDivisions.items,
                },
            });
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    return (
        <>
            {loading ? (
                <div className="w-full h-full flex items-center justify-center">
                    <Spinner />
                </div>
            ) : (
                <section className="h-full w-full overflow-y-hidden">
                    <div className="bg-white shadow border rounded-lg p-2 ml-5 mr-5 mb-3 mt-5">
                        <div className="flex items-center">
                            <FiUser className="text-2xl text-primary-blue" />
                            <p className="pl-2 font-bold text-lg">
                                Instellingen
                            </p>
                        </div>
                        {/* <div className="pb-2 pl-2 pr-2 flex w-[70vw]">
                            <p
                                className={clsx(
                                    "cursor-pointer",
                                    navigator === "personalsettings"
                                        ? "text-primary-pink"
                                        : "text-black"
                                )}
                                onClick={() => {
                                    setNavigator("personalsettings");
                                    window.history.replaceState(
                                        null,
                                        "",
                                        `/settings/?page=personalsettings`
                                    );
                                }}
                            >
                                Persoonsinstellingen
                            </p>
                            <p
                                className={clsx(
                                    "cursor-pointer pl-3",
                                    navigator === "companysettings"
                                        ? "text-primary-pink"
                                        : "text-black"
                                )}
                                onClick={() => {
                                    setNavigator("companysettings");
                                    window.history.replaceState(
                                        null,
                                        "",
                                        `/settings/?page=companysettings`
                                    );
                                }}
                            >
                                Bedrijfsinstellingen
                            </p>
                            <p
                                className={clsx(
                                    "cursor-pointer pl-3",
                                    navigator === "accountsettings"
                                        ? "text-primary-pink"
                                        : "text-black"
                                )}
                                onClick={() => {
                                    setNavigator("accountsettings");
                                    window.history.replaceState(
                                        null,
                                        "",
                                        `/settings/?page=accountsettings`
                                    );
                                }}
                            >
                                Accountsinstellingen
                            </p>
                            <div className="group relative flex justify-end">
                                <p
                                    className={clsx(
                                        "pl-3",
                                        navigator === "cpvsettings"
                                            ? "text-primary-pink cursor-pointer"
                                            : !userActive
                                            ? "opacity-50 cursor-not-allowed"
                                            : "cursor-pointer"
                                    )}
                                    onClick={() => {
                                        if (userActive) {
                                            setNavigator("cpvsettings");
                                            window.history.replaceState(
                                                null,
                                                "",
                                                `/settings/?page=cpvsettings`
                                            );
                                        }
                                    }}
                                >
                                    Sectoren
                                </p>
                                {!userActive && (
                                    <span className="absolute top-7 left-0 w-full justify-center items-center scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 flex">
                                        <FontAwesomeIcon
                                            className="mr-2 drop-shadow-xl animate-bounce"
                                            color="#ffd700"
                                            icon="crown"
                                        />
                                        <p className="font-bold">Premium</p>
                                    </span>
                                )}
                            </div>
                            <div className="group relative flex justify-end">
                                <p
                                    className={clsx(
                                        "pl-3",
                                        navigator === "paymentsettings"
                                            ? "text-primary-pink cursor-pointer"
                                            : !userActive
                                            ? "opacity-50 cursor-not-allowed"
                                            : "cursor-pointer"
                                    )}
                                    onClick={() => {
                                        if (userActive) {
                                            setNavigator("paymentsettings");
                                            window.history.replaceState(
                                                null,
                                                "",
                                                `/settings/?page=paymentsettings`
                                            );
                                        }
                                    }}
                                >
                                    Betalingsinstellingen & Facturen
                                </p>
                                {!userActive && (
                                    <span className="absolute top-7 left-0 w-full justify-center items-center scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 flex">
                                        <FontAwesomeIcon
                                            className="mr-2 drop-shadow-xl animate-bounce"
                                            color="#ffd700"
                                            icon="crown"
                                        />
                                        <p className="font-bold">Premium</p>
                                    </span>
                                )}
                            </div>
                        </div> */}
                    </div>
                    <section className="grid grid-cols-5 ml-5">
                        <section className="col-span-1">
                            <div className="bg-white w-full h-full border shadow rounded-lg">
                                <div className="p-5">
                                    <p
                                        className={clsx(
                                            "cursor-pointer",
                                            navigator === "personalsettings"
                                                ? "text-primary-pink"
                                                : "text-black"
                                        )}
                                        onClick={() => {
                                            setNavigator("personalsettings");
                                            window.history.replaceState(
                                                null,
                                                "",
                                                `/settings/?page=personalsettings`
                                            );
                                        }}
                                    >
                                        Persoonsinstellingen
                                    </p>
                                    <p
                                        className={clsx(
                                            "cursor-pointer pt-2",
                                            navigator === "companysettings"
                                                ? "text-primary-pink"
                                                : "text-black"
                                        )}
                                        onClick={() => {
                                            setNavigator("companysettings");
                                            window.history.replaceState(
                                                null,
                                                "",
                                                `/settings/?page=companysettings`
                                            );
                                        }}
                                    >
                                        Bedrijfsinstellingen
                                    </p>
                                    <p
                                        className={clsx(
                                            "cursor-pointer pt-2",
                                            navigator === "accountsettings"
                                                ? "text-primary-pink"
                                                : "text-black"
                                        )}
                                        onClick={() => {
                                            setNavigator("accountsettings");
                                            window.history.replaceState(
                                                null,
                                                "",
                                                `/settings/?page=accountsettings`
                                            );
                                        }}
                                    >
                                        Accountsinstellingen
                                    </p>
                                    <div className="group relative flex pt-2">
                                        <p
                                            className={clsx(
                                                "",
                                                navigator === "cpvsettings"
                                                    ? "text-primary-pink cursor-pointer"
                                                    : !userActive
                                                    ? "opacity-50 cursor-not-allowed"
                                                    : "cursor-pointer"
                                            )}
                                            onClick={() => {
                                                if (userActive) {
                                                    setNavigator("cpvsettings");
                                                    window.history.replaceState(
                                                        null,
                                                        "",
                                                        `/settings/?page=cpvsettings`
                                                    );
                                                }
                                            }}
                                        >
                                            Sectoren
                                        </p>
                                        {!userActive && (
                                            <span className="absolute top-7 left-0 w-full justify-center items-center scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 flex">
                                                <FontAwesomeIcon
                                                    className="mr-2 drop-shadow-xl animate-bounce"
                                                    color="#ffd700"
                                                    icon="crown"
                                                />
                                                <p className="font-bold">
                                                    Premium
                                                </p>
                                            </span>
                                        )}
                                    </div>
                                    <div className="group relative flex pt-2">
                                        <p
                                            className={clsx(
                                                "",
                                                navigator === "paymentsettings"
                                                    ? "text-primary-pink cursor-pointer"
                                                    : !userActive
                                                    ? "opacity-50 cursor-not-allowed"
                                                    : "cursor-pointer"
                                            )}
                                            onClick={() => {
                                                if (userActive) {
                                                    setNavigator(
                                                        "paymentsettings"
                                                    );
                                                    window.history.replaceState(
                                                        null,
                                                        "",
                                                        `/settings/?page=paymentsettings`
                                                    );
                                                }
                                            }}
                                        >
                                            Facturatie & Opzeggen
                                        </p>
                                        {!userActive && (
                                            <span className="absolute top-7 left-0 w-full justify-center items-center scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 flex">
                                                <FontAwesomeIcon
                                                    className="mr-2 drop-shadow-xl animate-bounce"
                                                    color="#ffd700"
                                                    icon="crown"
                                                />
                                                <p className="font-bold">
                                                    Premium
                                                </p>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            className="col-span-4 overflow-y-auto"
                            style={{ height: "calc(100vh - 120px)" }}
                        >
                            {navigator === "personalsettings" && (
                                <PersonalSettings
                                    UserInfo={UserInfo}
                                    reloadData={() => fetchInfo()}
                                />
                            )}
                            {navigator === "companysettings" && (
                                <CompanySettings
                                    chamberOfCommerce={
                                        UserInfo.companyInfo.chamberOfCommerce
                                    }
                                    name={UserInfo.companyInfo.name}
                                    address={UserInfo.companyInfo.address}
                                    postalCode={UserInfo.companyInfo.postalCode}
                                    city={UserInfo.companyInfo.city}
                                    country={UserInfo.companyInfo.country}
                                />
                            )}
                            {navigator === "accountsettings" && (
                                <AccountSettings />
                            )}
                            {navigator === "cpvsettings" && (
                                <CpvModification
                                    cpvs={UserInfo.cpvs}
                                    companyID={UserInfo.companyInfo.id}
                                />
                            )}
                            {navigator === "paymentsettings" && (
                                <PaymentSettings stripeID={UserInfo.stripeID} />
                            )}
                        </section>
                    </section>
                </section>
            )}
        </>
    );
};

export default Settings;
