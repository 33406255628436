import ReactGA from "react-ga4";

const TRACK_ID = "G-GWV4HY7HTQ";
ReactGA.initialize(TRACK_ID);

const useAnalyticsEventTracker = (
    category: string,
    action: string,
    label: string
) => {
    return ReactGA.event({ category, action, label });
};
export default useAnalyticsEventTracker;
