import { API, graphqlOperation, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import useAnalyticsEventTracker from "../../../tracker/EventTracker";
import BrancheTenders from "./components/BrancheTenders";
import CompanyTenders from "./components/CompanyTenders";
import FavoriteTenders from "./components/FavoriteTenders";
import { Spinner } from "../../../components/Spinner";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FiBriefcase } from "react-icons/fi";
import { sendGAEvent } from "../../../utils/analytics";

const RecommendedTenders = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [navigator, setNavigator] = useState<number>(1);
    const [userActive, setUserActive] = useState<boolean>(false);

    useEffect(() => {
        document.title = "Mijn aanbestedingen | TenderTracer";
        sendGAEvent('view_recommended_tenders', {
            user_active: userActive
        });
    }, [userActive]);

    useEffect(() => {
        EventTracker();
        fetchUserData();
    }, []);

    function EventTracker() {
        useAnalyticsEventTracker(
            "Mijn aanbestedingen",
            "Bekijkt Mijn aanbestedingen",
            "Mijn aanbestedingen"
        );
    }

    async function fetchUserData() {
        setLoading(true);
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });

        //prettier-ignore
        const UserQuery = `query MyQuery { getUser(id: "${attributes.sub}") { companies { items { company { active_subscription } } } } }`;

        try {
            const userData: any = await API.graphql(
                graphqlOperation(UserQuery)
            );
            const subscriptionActive: boolean =
                userData.data.getUser.companies.items[0].company
                    .active_subscription;
            setNavigator(subscriptionActive ? 1 : 2);
            setUserActive(subscriptionActive);
            setLoading(false);
            sendGAEvent('fetch_user_data', {
                success: true,
                subscription_active: subscriptionActive
        });
        } catch (error) {
            console.log(error);
            setLoading(false);
            sendGAEvent('fetch_user_data', {
                success: false,
                error_message: error
            });
        }
    }
    const handleNavigatorChange = (newNavigator: number) => {
        if (userActive || newNavigator === 2) {
            setNavigator(newNavigator);
            sendGAEvent('change_recommended_section', {
                section: newNavigator === 1 ? 'branch_specific' : 
                         newNavigator === 2 ? 'favorites' : 'company_specific'
            });
        }
    };

    return (
        <section className="h-full w-full flex flex-col overflow-y-auto overflow-x-hidden">
            {loading ? (
                <div className="w-full h-full flex items-center justify-center">
                    <Spinner infiniteLoop={false} />
                </div>
            ) : (
                <>
                    <div className="bg-white border shadow rounded-lg ml-5 mr-5 mb-2 mt-5">
                        <div className="flex items-center mx-2 mt-2">
                            <FiBriefcase className="text-2xl text-primary-blue" />
                            <p className="pl-2 font-bold text-lg">
                                Mijn aanbestedingen
                            </p>
                        </div>

                        <div className="pb-2 pl-2 pr-2 lg:flex">
                            <div className="group relative flex justify-begin">
                                <p
                                    className={clsx(
                                        navigator === 1
                                            ? "text-primary-pink cursor-pointer"
                                            : !userActive
                                            ? "opacity-50 cursor-not-allowed"
                                            : "cursor-pointer"
                                    )}
                                    onClick={() =>
                                        userActive ? handleNavigatorChange(1) : ""
                                    }
                                >
                                    Branchespecifieke aanbestedingen
                                </p>
                                {!userActive && (
                                    <span className="absolute top-7 left-0 w-full justify-center items-center scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 flex">
                                        <FontAwesomeIcon
                                            className="mr-3 drop-shadow-xl animate-bounce"
                                            color="#ffd700"
                                            icon="crown"
                                        />
                                        <p className="font-bold">Premium</p>
                                    </span>
                                )}
                            </div>
                            <div className="group relative flex justify-begin">
                                <p
                                    className={clsx(
                                        "lg:pl-5",
                                        navigator === 3
                                            ? "text-primary-pink cursor-pointer"
                                            : !userActive
                                            ? "opacity-50 cursor-not-allowed"
                                            : "cursor-pointer"
                                    )}
                                    onClick={() =>
                                        userActive ? handleNavigatorChange(3) : ""
                                    }
                                >
                                    Bedrijfsspecifieke aanbestedingen
                                </p>
                                {!userActive && (
                                    <span className="absolute top-7 left-0 w-full justify-center items-center scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 flex">
                                        <FontAwesomeIcon
                                            className="mr-3 drop-shadow-xl animate-bounce"
                                            color="#ffd700"
                                            icon="crown"
                                        />
                                        <p className="font-bold">Premium</p>
                                    </span>
                                )}
                            </div>
                            <p
                                className={clsx(
                                    "lg:pl-5 cursor-pointer",
                                    navigator === 2 && "text-primary-pink"
                                )}
                                onClick={() => {
                                    handleNavigatorChange(2);
                                }}
                            >
                                Mijn favorieten
                            </p>
                        </div>
                    </div>
                    <div className="flex lg:overflow-y-auto">
                        {navigator === 1 && <BrancheTenders />}
                        {navigator === 2 && <FavoriteTenders />}
                        {navigator === 3 && <CompanyTenders />}
                    </div>
                </>
            )}
        </section>
    );
};

export default RecommendedTenders;
