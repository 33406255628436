import { FormEvent, useEffect, useState } from "react";
import { CompanyInfo, Cpv, UserInfo, UserServiceConfiguration } from "AppTypes";
import Lottie from "lottie-react";
import errorAnimation from "../../lottie/94992-error-404.json";

import { Register } from "./components/Register";
import ChatBot from "../../components/help/ChatBot";

export const Onboarding = () => {
    const [userServiceConfiguration, setUserServiceConfiguration] =
        useState<UserServiceConfiguration>({
            userInfo: {
                id: "",
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                password: "",
                passwordValid: "",
                stripeId: "",
                subscription: "",
                subscriptionSummary: "",
                subscriptionMode: "",
            },
            companyInfo: {
                name: "",
                chamberOfCommerce: "",
                kvk: "",
                address: "",
                number: "",
                postalcode: "",
                town: "",
                country: "",
                invoiceName: "",
                invoiceAddress: "",
                invoicePostalcode: "",
                invoiceTown: "",
                invoiceCountry: "",
            },
            selectedPlan: null,
            cpvDivisions: [],
            cpvGroups: [],
            cpvCodes: [],
            cpvs: [],
            sbis: [],
        });
    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        document.title = "Account aanmaken | TenderTracer";
    }, []);

    const updateUserInfo = (userInfo: UserInfo) => {
        setUserServiceConfiguration({ ...userServiceConfiguration, userInfo });
    };

    const updateCompanyInfo = (companyInfo: CompanyInfo) => {
        setUserServiceConfiguration({
            ...userServiceConfiguration,
            companyInfo,
        });
    };

    const pagination = (pageAction: string) => {
        if (pageAction === "next") {
            if (page === 0) {
                setPage(page + 1);
            }
            if (page === 2 && userServiceConfiguration.cpvGroups.length > 0) {
                setPage(page + 1);
            }
        } else if (pageAction === "back") {
            setPage(page - 1);
        }
    };

    return (
        <>
            {/* <ChatBot type="onboarding" /> */}
            <section className="drop-shadow-2xl bg-white 2xl:max-w-[80%] 2xl:h-[80%] h-full w-full 2xl:m-auto 2xl:absolute top-0 left-0 right-0 2xl:bottom-[6%] 2xl:rounded-lg flex overflow-hidden">
                {page === 0 && (
                    <Register
                        userInfo={userServiceConfiguration.userInfo}
                        updateUserInfo={updateUserInfo}
                        setPage={pagination}
                        companyInfo={userServiceConfiguration.companyInfo}
                        updateCompanyInfo={updateCompanyInfo}
                    />
                )}
            </section>
        </>
    );
};
