import Lottie from "lottie-react";
import paymentAnimation from "../../../../lottie/37960-online-payment.json";
import { useEffect } from "react";
import { API_DEFAULT_HEADERS, API_DOMAIN } from "../../../../APIconfig";
import { Auth } from "aws-amplify";

interface PaymentSettingsProps {
    stripeID: string;
}

export const PaymentSettings = ({ stripeID }: PaymentSettingsProps) => {
    useEffect(() => {
        setTimeout(() => {
            fetchStripePortalLink();
        }, 3000);
    }, []);

    function fetchStripePortalLink() {
        Auth.currentSession().then((res) => {
            let accessToken = res.getIdToken();
            let jwt = accessToken.getJwtToken();

            const requestOptions: RequestInit = {
                method: "POST",
                headers: new Headers(API_DEFAULT_HEADERS(jwt)),
                body: JSON.stringify({
                    customerId: stripeID,
                }),
            };

            fetch(`${API_DOMAIN}/stripe/customerportal`, requestOptions)
                .then(async (response) => {
                    const jsonResponse = await response.json();
                    window.location.href = jsonResponse.body;
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }

    return (
        <div className="bg-white shadow border rounded-lg ml-5 mr-5 mb-5 mt-5 p-20 flex justify-center flex-col items-center">
            <div className="w-[300px]">
                <Lottie animationData={paymentAnimation} />
            </div>
            <p className="text-3xl pt-10">
                Je wordt doorgestuurd naar ons abonnement portaal...
            </p>
            <p className="pt-3">
                Wij werken samen met Stripe om de facturatie te vereenvoudigen.
            </p>
        </div>
    );
};
