import { useEffect } from "react";

import { Button } from "../../components/Button";
import useAnalyticsEventTracker from "../../tracker/EventTracker";

export const PaymentFailed = () => {
    useEffect(() => {
        document.title = "Betaling Mislukt | TenderTracer";
        EventTracker();
    }, []);

    function EventTracker() {
        useAnalyticsEventTracker(
            "Payment Failed",
            "Betaling mislukt",
            "Payment Failed"
        );
    }

    return (
        <section className="max-w-[80%] h-[80%] m-auto absolute top-0 left-0 right-0 bottom-[6%] rounded-lg drop-shadow-2xl">
            <div className="w-full h-full bg-light-blue rounded-lg relative">
                <div className="p-10">
                    <p className="text-7xl font-bold text-white">Mislukt!</p>
                    <p className="text-3xl text-white">
                        Er is wat mis gegaan bij de betaling of je hebt zelf het
                        betalingsproces gestopt.
                    </p>
                    <p className="text-xl text-white pt-10">
                        Druk op doorgaan om terug te gaan naar ons dashboard. Je
                        kunt daar inloggen om het proces opnieuw uit te voeren.
                    </p>
                </div>
                <div className="rounded-r rounded-br-lg absolute bottom-0 left-0 h-100 w-full flex flex-col justify-center">
                    <div className="flex">
                        <div className="bg-white rounded-bl-lg w-300 pl-5 pr-2 pb-7 pt-5">
                            <img src="/images/TenderTracer_logo.png" />
                        </div>
                        <div className="w-full bg-[length:1100px_550px] rounded-br-lg bg-[url('/public/images/onboarding_bg.webp')] relative" />
                        <div className="bg-white rounded-br-lg w-300 pr-10 pl-10 flex justify-center items-center">
                            <Button
                                type="ghost"
                                onClick={() => (window.location.href = "/")}
                            >
                                DOORGAAN
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
