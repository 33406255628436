declare global {
  interface Window {
    gtag: any;
  }
}

export const sendGAEvent = (eventName: string, eventParams: Record<string, any> = {}) => {
    if (window.gtag) {
      window.gtag('event', eventName, eventParams);
    }
  };