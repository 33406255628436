import { ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";

const options3: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: "top",
            display: false,
        },
        title: {
            display: false,
            text: "Aantal werken",
        },
    },
    scales: {
        x: {
            ticks: { display: false },
            grid: { display: false },
            borderColor: "transparant",
            borderWidth: 0,
        },
        y: {
            ticks: { display: false },
            grid: { display: false },
            borderColor: "transparant",
            borderWidth: 0,
        },
    },
};

export default function BarChart({ data }: any) {
    const dataWerken = {
        datasets: [
            {
                data: data,
                backgroundColor: "#284F82",
            },
        ],
    };
    return (
        <div className="relative w-full max-h-[100px] flex items-center justify-center">
            <Bar options={options3} data={dataWerken} />
        </div>
    );
}
