import { useEffect, useState } from "react";
import useAnalyticsEventTracker from "../../../tracker/EventTracker";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { TenderItem } from "../TenderItemOverview";
import { Spinner } from "../../../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SlFire } from "react-icons/sl";
import Tabs from "../../../components/Tabs";
import { useLocation } from "react-router-dom";
import { sendGAEvent } from "../../../utils/analytics";
import { send } from "@emailjs/browser";

// prettier-ignore
const TenderQuery = `query MyQuery { searchPhases(limit: 25, sort: {field: dispatch_notice_date, direction: desc}, filter: {or: [ {phase: {match: "Aankondiging opdracht"}}, {phase: {match: "Aankondiging van een van een opdracht"}}, {phase: {match: "Aankondiging van een opdracht"}}, {phase: {match: "Vooraankondiging"}} {phase: {match: "Marktconsultatie"}} ]}) { items { id title dispatch_notice_date date_receipt time_receipt procedure tender_id description tender_type country contracting_authority phase total_value contacts { items { contact { contracting_authority_id contact_type contractor_id } } } } } }`;

const FavoritesQuery = (userID: string) =>
    `query MyQuery { getUser(id: "${userID}") { companies { items { company { id saved_tenders { items { tender { id } } } } } } } }`;

const NewTenders = () => {
    const location = useLocation();
    const [tenders, setTenders] = useState<any[]>([]);
    const [favoriteTendersIDs, setFavoriteTendersIDs] = useState<any[]>([]);
    const [companyID, setCompanyID] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        document.title = "Nieuwste 25 aanbestedingen | TenderTracer";
        EventTracker();
        handleData();
    }, []);

    async function handleData() {
        try {
            const { attributes } = await Auth.currentAuthenticatedUser({
                bypassCache: true,
            });

            const favoritesData: any = await API.graphql(
                graphqlOperation(FavoritesQuery(attributes.sub))
            );

            //Favorites
            const favoriteTenders =
                favoritesData.data.getUser.companies.items[0].company
                    .saved_tenders.items;

            const favoriteTenderItems: any[] = [];

            favoriteTenders.map((tenders: any) =>
                favoriteTenderItems.push(tenders.tender.id)
            );

            setCompanyID(
                favoritesData.data.getUser.companies.items[0].company.id
            );

            setFavoriteTendersIDs(favoriteTenderItems);
            const tenderData: any = await API.graphql(
                graphqlOperation(TenderQuery)
            );

            setTenders(tenderData.data.searchPhases.items);
            setLoading(false);
            sendGAEvent('view_new_tenders', {
                tender_count: tenderData.data.searchPhases.items.length
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
            sendGAEvent('new_tenders_error', {
                error_message: error
            });
        }
    }

    function EventTracker() {
        useAnalyticsEventTracker(
            "Nieuwste 25 aanbestedingen",
            "Bekijkt Nieuwste 25 aanbestedingen",
            "Nieuwste 25 aanbestedingen"
        );
    }

    return (
        <section className="h-full w-full flex flex-col overflow-y-hidden overflow-x-hidden">
            <div className="bg-white rounded-lg border shadow ml-5 pt-2 pl-2 pr-2 mr-5 mb-2 mt-5">
                <div className="flex items-center">
                    <SlFire className="text-2xl text-primary-blue" />
                    <p className="font-bold text-lg pl-2">
                        Nieuwste 25 aanbestedingen
                    </p>
                </div>
                <p className="pb-2 pl-2 pr-2 lg:flex text-sm">
                    Hieronder zie je de nieuwste 25 aanbestedingen |
                    Marktconsultaties, aankondigingen en vooraankondigingen.
                </p>
            </div>
            <div className="flex lg:overflow-y-hidden">
                {loading ? (
                    <div className="w-full h-full flex items-center justify-center">
                        <Spinner infiniteLoop={false} />
                    </div>
                ) : tenders.length > 0 ? (
                    <div
                        className="overflow-y-auto overflow-x-hidden"
                        style={{
                            height: "calc(100vh - 90px)",
                        }}
                    >
                        {tenders.map((item: any, index: any) => (
                            <div key={index}>
                                <TenderItem
                                    item={item}
                                    index={index}
                                    style="recommended"
                                    favorite={favoriteTendersIDs.includes(
                                        item.tender_id
                                    )}
                                    companyID={companyID}
                                    state={{ from: location.pathname }}
                                />
                            </div>
                        ))}
                        <div className="flex w-full">
                            <div
                                className="w-full cursor-pointer hover:underline"
                                // onClick={() => fetchTenders(false)}
                            >
                                {tenders.length === 20 ? (
                                    loading ? (
                                        <div className="flex justify-center pb-10 items-center">
                                            <div>
                                                <Spinner />
                                            </div>
                                            <p>Laden...</p>
                                        </div>
                                    ) : (
                                        <div className="flex justify-center pb-10 items-center w-full">
                                            <p className="pr-3">
                                                <FontAwesomeIcon
                                                    className="text-primary-pink"
                                                    icon={"down-long"}
                                                />
                                            </p>
                                            <p>Meer laden</p>
                                        </div>
                                    )
                                ) : (
                                    <div className="flex justify-center pb-10 items-center w-full">
                                        <p>Je hebt het einde bereikt.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="w-full h-full flex items-center justify-center">
                        <p>Geen resultaten gevonden.</p>
                    </div>
                )}
            </div>
        </section>
    );
};

export default NewTenders;
