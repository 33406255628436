import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API, graphqlOperation } from "aws-amplify";
import clsx from "clsx";
import Moment from "moment";
import "moment/locale/nl";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaRegClock } from "react-icons/fa6";
import { FiBriefcase } from "react-icons/fi";
import { GrAlarm } from "react-icons/gr";
import { GrLocation } from "react-icons/gr";
import { GoGear } from "react-icons/go";
import { GoTrophy } from "react-icons/go";
import { AiOutlineEuro } from "react-icons/ai";
import { translateType } from "../tenders/TenderOverview";
import Tabs from "../../components/Tabs";
import Flag from "react-world-flags";
import { set } from "lodash";
import { sendGAEvent } from "../../utils/analytics";

Moment.locale("nl");

interface TenderItemProps {
    item: {
        id: string;
        title: string;
        title_eng: string;
        country: string;
        dispatch_notice_date: string;
        phase: string;
        date_receipt: string;
        time_receipt: string;
        description: string;
        procedure: string;
        contracting_authority: string;
        tender_type: string;
        tender_id: string;
        contacts: any;
        total_value: any;
        tsender: string;
        tsender_link: string;
        winner_name: string;
    };
    index: string;
    favorite?: boolean;
    companyID?: string;
    style?: "recommended" | "overview";
    showFavoriteIcon?: boolean;
    state: any;
}

export const TenderItem = ({
    item,
    index,
    favorite = false,
    showFavoriteIcon = true,
    companyID = "",
    style = "overview",
    state = {from: "null"}
}: TenderItemProps) => {
    const [favorited, setFavorite] = useState(favorite);
    const dispatch_notice_date = Moment(
        item.dispatch_notice_date?.toString()
    ).format("ll");

    //const getPhaseQuery = `query MyQuery { getPhase(id: "${}") { id title title_eng phase country ojs_number buyer_city time_receipt tender_type recurring procedure total_value eu_program_related dispatch_notice_date dispatch_invitations_date description date_receipt currency pdf pdf_eng tsender tsender_link contracting_authority contacts { items { contact { id name email phone fax contracting_authority_id contractor_id contact_type } } } object_descriptions { items { id phase_id title value description renewal_description main_site lot_number duration end_date duration criteria { items { name weight description} } } } phase cpv_codes { items { id cpv { id description } } } } }`;
    //prettier-ignore
    const getContractingAuthority = (id: string) => `query MyQuery { getContractingAuthority(id: "${id}") { name address postalcode town country URL} }`;
    //prettier-ignore
    const getContractor = (id: string) => `query getContractor { getContractor(id: "${id}") { name address postalcode town country URL} }`;
    const date_receipt = Moment(item.date_receipt?.toString()).format("ll");

    const createFavoriteQuery = `mutation MyMutation { createCompanyTender(input: {id: "${companyID}_${item.tender_id}", companyId: "${companyID}", tenderId: "${item.tender_id}"}) { id } }`;
    const deleteFavoriteQuery = `mutation MyMutation { deleteCompanyTender(input: {id: "${companyID}_${item.tender_id}"}) { id } }`;

    const location = useLocation();

    const Location = () => {
        const [town, setTown] = useState<string>("Onbekend");

        useEffect(() => {
            const fetchData = async () => {
                // console.log("item:", item);
                if (item.country === "NL" || item.country === null) {
                    for (const phaseContact of item.contacts.items) {
                        // console.log("phaseContact:", phaseContact);
                        if (
                            phaseContact.contact?.contact_type ===
                            "Contracting authority"
                        ) {
                            const contractingAuthorityId =
                                phaseContact.contact.contracting_authority_id;
                            if (contractingAuthorityId !== "0") {
                                // console.log("contractingAuthorityId:", contractingAuthorityId);
                                const contractingAuthorityData: any =
                                    await API.graphql(
                                        graphqlOperation(
                                            getContractingAuthority(
                                                contractingAuthorityId
                                            )
                                        )
                                    );

                                // console.log("contractingAuthorityData:", contractingAuthorityData);
                                // console.log("town:", contractingAuthorityData?.data?.getContractingAuthority?.town);

                                if (
                                    contractingAuthorityData?.data
                                        ?.getContractingAuthority?.town
                                ) {
                                    setTown(
                                        contractingAuthorityData.data
                                            .getContractingAuthority.town
                                    );
                                    break;
                                }
                            }
                        }
                    }
                } else if (item.country !== "NL" || item.country !== null) {
                    for (const phaseContact of item.contacts.items) {
                        // console.log("phaseContact:", phaseContact);
                        if (
                            phaseContact.contact?.contact_type ===
                            "contracting_authority"
                        ) {
                            const contractingAuthorityId =
                                phaseContact.contact.contracting_authority_id;
                            if (contractingAuthorityId !== "0") {
                                // console.log("contractingAuthorityId:", contractingAuthorityId);
                                const contractingAuthorityData: any =
                                    await API.graphql(
                                        graphqlOperation(
                                            getContractingAuthority(
                                                contractingAuthorityId
                                            )
                                        )
                                    );

                                // console.log("contractingAuthorityData:", contractingAuthorityData);
                                // console.log("town:", contractingAuthorityData?.data?.getContractingAuthority?.town);

                                if (
                                    contractingAuthorityData?.data
                                        ?.getContractingAuthority?.town
                                ) {
                                    setTown(
                                        contractingAuthorityData.data
                                            .getContractingAuthority.town
                                    );
                                    break;
                                }
                            }
                        }
                    }
                }
            };
            fetchData();
        }, []);
        return item.country === "NL" || item.country == null ? (
            town !== null ? (
                <p className="text-right">
                    {town.charAt(0).toLocaleUpperCase() + town.slice(1)}
                </p>
            ) : (
                <div className="text-right">
                    <svg
                        aria-hidden="true"
                        className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary-pink"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                        />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                        />
                    </svg>
                </div>
            )
        ) : (
            <div className="text-right flex items-center justify-end">
                <Flag code={item.country} height="10" width={25} />
                <span className="ml-2">{item.country}</span>
            </div>
        );
    };

    const Winner = () => {
        const [winner, setWinner] = useState<string>("Onbekend");

        useEffect(() => {
            const fetchData = async () => {
                // console.log("item:", item);
                if (item.winner_name) {
                    setWinner(item.winner_name);
                } else if (item.country === "NL" || item.country == null) {
                    const list: string[] = [];
                    for (const phase of item.contacts.items) {
                        if (
                            phase.contact.contact_type === "Contractor" &&
                            phase.contact.contractor_id !== "0"
                        ) {
                            const contractingAuthorityData: any =
                                await API.graphql(
                                    graphqlOperation(
                                        getContractor(
                                            phase.contact.contractor_id
                                        )
                                    )
                                );
                            list.push(
                                contractingAuthorityData.data.getContractor.name
                            );
                        }
                    }
                    if (list.length > 0) {
                        setWinner(list[0]);
                    }
                } else {
                    setWinner("Onbekend");
                }
            };
            fetchData();
        }, [item]);

        return winner !== "" ? (
            <p className="text-right truncate w-44">{winner}</p>
        ) : (
            <div className="text-right">
                <svg
                    aria-hidden="true"
                    className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary-pink"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                    />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                    />
                </svg>
            </div>
        );
    };

    async function Favoritizer() {
        try {
            if (favorited === false) {
                const id: any = await API.graphql(
                    graphqlOperation(createFavoriteQuery)
                );
                setFavorite(true);
                sendGAEvent('add_favorite', {
                    tender_id: item.tender_id,
                    tender_type: item.tender_type
                });
            } else {
                await API.graphql(graphqlOperation(deleteFavoriteQuery));
                setFavorite(false);
                sendGAEvent('remove_favorite', {
                    tender_id: item.tender_id,
                    tender_type: item.tender_type
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div
            key={index}
            className="rounded-lg bg-white ml-5 mr-5 mb-5 cursor-pointer border shadow hover:shadow-xl relative"
        >
            {showFavoriteIcon && (
                <div
                    className="absolute right-5 top-5"
                    onClick={() => Favoritizer()}
                >
                    <FontAwesomeIcon
                        className={clsx(
                            "text-2xl",
                            favorited
                                ? "text-primary-pink"
                                : "text-gray-300 hover:text-primary-pink"
                        )}
                        icon="heart"
                    />
                </div>
            )}
            <Link
                to={`/tender/${item.tender_id}/${item.id}`}
                state={{ from: state.pathname }}
                className={clsx(
                    "flex flex-col grid-cols-3 gap-4 p-4",
                    style === "overview" && "2xl:grid",
                    style === "recommended" && "xl:grid"
                )}
            >
                {/* Top */}
                <div className="col-span-2 flex flex-col">
                    <div className="text-primary-pink text-sm w-[95%]">
                        {item.phase ? <p>{item.phase}</p> : <p>Onbekend</p>}
                    </div>
                    <span className="font-bold text-lg">{item.title}</span>
                    <div className="pr-5 flex 2xl:border-b-0 pb-5 pt-2 2xl:pb-0">
                    <span className="opacity-60">
                        {item.description
                            ? item.description.substring(0, 250).replace(/[\[\]']+/g, "") + "..."
                            : "Geen beschrijving"}
                    </span>
                    </div>
                </div>
                {/* Bottom */}
                <div
                    className={clsx(
                        "w-full col-span-1 border-l-0 border-t p-4",
                        style === "overview" && "2xl:border-t-0 2xl:border-l",
                        style === "recommended" && "xl:border-t-0 xl:border-l"
                    )}
                >
                    <div className="flex flex-col">
                        <div className="flex items-center w-full text-primary-blue pb-3 pt-1 ">
                            <FiBriefcase className="text-lg" />
                            <p className="pl-1 text-sm truncate w-full">
                                {item.contracting_authority}
                            </p>
                        </div>
                        <div className="flex justify-between">
                            <div className="flex items-center">
                                <FaRegClock />
                                <span className="text-sm pl-1">
                                    Publicatiedatum:
                                </span>
                            </div>
                            <div className="text-right">
                                {item.dispatch_notice_date ? (
                                    <span>{dispatch_notice_date}</span>
                                ) : (
                                    <span>Onbekend</span>
                                )}
                            </div>
                        </div>
                        {item.date_receipt && (
                            <div className="flex justify-between">
                                <div className="flex items-center">
                                    <GrAlarm />
                                    <span className="text-sm pl-1">
                                        Sluitingsdatum:
                                    </span>
                                </div>
                                <div className="text-right">
                                    <p className="text-right">
                                        {date_receipt}, {item.time_receipt}
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className="flex justify-between">
                            <div className="flex items-center">
                                <GrLocation />
                                <span className="text-sm pl-1">Locatie:</span>
                            </div>
                            <div className="text-right">
                                <Location />
                            </div>
                        </div>
                        {item.total_value ? (
                            <div className="flex justify-between">
                                <div className="flex items-center">
                                    <AiOutlineEuro />
                                    <span className="text-sm pl-1">
                                        Waarde:
                                    </span>
                                </div>
                                <div className="text-right">
                                    {`€${item.total_value.toLocaleString(
                                        undefined,
                                        {
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                        ,-`}
                                </div>
                            </div>
                        ) : null}
                        <div className="flex justify-between">
                            <div className="flex items-center">
                                <GoGear />
                                <span className="text-sm pl-1">Type:</span>
                            </div>
                            <div className="text-right">
                                {item.tender_type ? (
                                    <p className="text-right">
                                        {translateType(
                                            item.tender_type,
                                            item.country
                                        )}
                                    </p>
                                ) : (
                                    <p className="text-right">Onbekend</p>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="flex items-center">
                                <span className="relative flex h-3 w-3 ml-[2px]">
                                    <span
                                        className={clsx(
                                            item.phase
                                                .toLowerCase()
                                                .includes("gegunde opdracht") || item.phase.toLowerCase().includes("wijziging")
                                                ? "bg-primary-pink"
                                                : item.phase
                                                      .toLowerCase()
                                                      .includes(
                                                          "vooraankondiging"
                                                      )
                                                ? "bg-[#FFBF00]"
                                                : "bg-primary-green",
                                            "animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
                                        )}
                                    />
                                    <span
                                        className={clsx(
                                            item.phase
                                                .toLowerCase()
                                                .includes("gegunde opdracht") || item.phase.toLowerCase().includes("wijziging")
                                                ? "bg-primary-pink"
                                                : item.phase
                                                      .toLowerCase()
                                                      .includes(
                                                          "vooraankondiging"
                                                      )
                                                ? "bg-[#FFBF00]"
                                                : "bg-primary-green",
                                            "relative inline-flex rounded-full h-3 w-3"
                                        )}
                                    ></span>
                                </span>
                                <span className="text-sm pl-[5px]">
                                    Status:
                                </span>
                            </div>
                            <div className="text-right">
                                {item.tender_type ? (
                                    <p className="text-right">
                                        {item.phase
                                            .toLowerCase()
                                            .includes("gegunde opdracht") || item.phase.toLowerCase().includes("wijziging")
                                            ? "Gesloten aanbesteding"
                                            : item.phase
                                                  .toLowerCase()
                                                  .includes("vooraankondiging")
                                            ? "Verwachte aanbesteding"
                                            : "Open aanbesteding"}
                                    </p>
                                ) : (
                                    <p className="text-right">Onbekend</p>
                                )}
                            </div>
                        </div>
                        {item.phase
                            .toLowerCase()
                            .includes("gegunde opdracht") && (
                            <div className="flex justify-between">
                                <div className="flex items-center">
                                    <GoTrophy />
                                    <span className="text-sm pl-1">
                                        Winnaar:
                                    </span>
                                </div>
                                <div className="text-right">
                                    <Winner />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Link>
        </div>
    );
};
