import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Spinner } from "../../../components/Spinner";
import clsx from "clsx";
import ReactCodeInput from "react-code-input";
import { API_DEFAULT_HEADERS, API_DOMAIN } from "../../../APIconfig";
import ChangePhone from "./changePhone";

const PhoneVerification = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isPinCodeValid, setIsPinCodeValid] = useState<boolean>(true);
    const [countdown, setCountdown] = useState<number>(30);
    const [page, setPage] = useState<number>(1);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        document.title = "Verificatie | TenderTracer";
    }, []);

    const resendVerificationCode = async () => {
        try {
            setLoading(true);
            const { attributes } = await Auth.currentAuthenticatedUser({
                bypassCache: true,
            });
    
            //console.log("User attributes for resend:", attributes);
    
            const session = await Auth.currentSession();
            const jwt = session.getIdToken().getJwtToken();
    
            const requestData = {
                id: attributes.sub,
            };
    
            // console.log("Resend verification request:", {
            //     url: `${API_DOMAIN}/sms/send`,
            //     data: requestData,
            //     headers: API_DEFAULT_HEADERS(jwt)
            // });
    
            const response = await fetch(`${API_DOMAIN}/sms/send`, {
                method: "POST",
                headers: API_DEFAULT_HEADERS(jwt),
                body: JSON.stringify(requestData)
            });
            
            const responseData = await response.json().catch(() => null);
    
            // console.log("Resend response:", {
            //     status: response.status,
            //     ok: response.ok,
            //     data: responseData
            // });
    
            if (response.ok) {
                setCountdown(30);
                setError("");
            } else {
                throw new Error(responseData?.error || "Failed to send verification code");
            }
        } catch (error: unknown) {
            console.error("Error sending verification code:", {
                error,
                message: error instanceof Error ? error.message : 'Unknown error'
            });
            setError("Er ging iets mis bij het versturen van de code. Probeer het opnieuw.");
        } finally {
            setLoading(false);
        }
    };

    async function signOut() {
        try {
            setLoading(true);
            await Auth.signOut();
            window.location.href = "/";
        } catch (error: unknown) {
            console.error("Error signing out:", {
                error,
                message: error instanceof Error ? error.message : 'Unknown error'
            });
            setError("Er ging iets mis bij het uitloggen. Probeer het opnieuw.");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [countdown]);
    async function confirmCode(code: string) {
        if (code.length === 6) {
            try {
                setLoading(true);
                setIsPinCodeValid(true);
                setError("");
    
                const { attributes } = await Auth.currentAuthenticatedUser({
                    bypassCache: true,
                });
    
                const session = await Auth.currentSession();
                const jwt = session.getIdToken().getJwtToken();
    
                const requestData = {
                    id: attributes.sub,
                    smscode: code
                };
    
                // console.log('Making verification request:', {
                //     url: `${API_DOMAIN}/ConfirmSMS`,
                //     headers: API_DEFAULT_HEADERS(jwt),
                //     body: requestData
                // });
    
                const response = await fetch(`${API_DOMAIN}/ConfirmSMS`, {
                    method: "POST",
                    headers: API_DEFAULT_HEADERS(jwt),
                    body: JSON.stringify({
                        body: JSON.stringify(requestData),
                        httpMethod: "POST"

                    })
                });
    
                let responseData = await response.json();
    
                // Parse de response data als het een string is
                if (typeof responseData === 'string') {
                    try {
                        responseData = JSON.parse(responseData);
                    } catch (e) {
                        // Als het geen JSON is, laat het dan zoals het is
                    }
                }
    
                // Check voor verschillende succesvolle response formaten
                if (response.ok && (responseData === "1" || responseData?.success || responseData?.statusCode === 200)) {
                    // console.log("Verification successful, redirecting...");
                    // Direct doorsturen naar dashboard
                    window.location.href = "https://dashboard.tendertracer.nl";
                    return; // Belangrijk: stop de functie hier
                } else {
                    // Alleen als het echt een foute code is
                    if (response.status === 400) {
                        setIsPinCodeValid(false);
                        setError("Code is onjuist. Probeer het nogmaals.");
                    } else {
                        throw new Error("Verification failed");
                    }
                }
            } catch (error: unknown) {
                console.error("Verification error:", {
                    error,
                    message: error instanceof Error ? error.message : 'Unknown error',
                    stack: error instanceof Error ? error.stack : undefined
                });
                setIsPinCodeValid(false);
                setError("Er ging iets mis bij de verificatie. Probeer het opnieuw.");
            } finally {
                setLoading(false);
            }
        }
    }

    return page === 1 ? (
        <section className="h-full w-full overflow-y-auto flex justify-center pt-[10%] relative bg-[#284F82] bg-center bg-cover bg-[url('/public/images/onboarding_bg.webp')]">
            <div className="ml-5 mr-5 mt-5 w-[500px]">
                <div className="bg-white rounded-lg mb-2 p-2 border shadow">
                    <div className="w-full bg-white rounded-lg py-2">
                        <img
                            className="w-[150px]"
                            src="/images/TenderTracer_logo.png"
                            alt="TenderTracer Logo"
                        />
                    </div>
                    <p className="font-bold pl-3 text-lg">
                        Telefoonnummer verifiëren
                    </p>
                    <p className="pl-3 text-sm">
                        We hebben een verificatiecode per SMS naar je
                        telefoonnummer gestuurd. Voer de code hieronder in om
                        verder te gaan.
                    </p>
                    <div className="w-full p-5 h-full rounded-lg bg-white lg:px-3 px-3 flex flex-col">
                        <div className="flex relative flex-col items-center justify-center">
                            {loading && (
                                <div className="absolute w-[500px] rounded-lg bg-opacity-80 h-full bg-white items-center justify-center flex z-10">
                                    <Spinner />
                                </div>
                            )}
                            {!isPinCodeValid && (
                                <p className="text-primary-pink mb-2">
                                    {error || "Code is onjuist. Probeer het nogmaals."}
                                </p>
                            )}
                            <ReactCodeInput
                                type="text"
                                fields={6}
                                isValid={isPinCodeValid}
                                onChange={confirmCode}
                                inputStyle={{
                                    width: "3em",
                                    height: "4em",
                                    margin: "0.5em",
                                    fontSize: "1em",
                                    borderRadius: "5px",
                                    border: "2px solid #284F82",
                                    textAlign: "center",
                                }}
                                name={"PhoneVerification"}
                                inputMode={"tel"}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-1 rounded-lg bg-white p-3 border text-center shadow">
                    <p
                        onClick={countdown === 0 ? resendVerificationCode : undefined}
                        className={clsx(
                            "text-sm",
                            countdown !== 0
                                ? "opacity-50 cursor-not-allowed"
                                : "text-primary-green cursor-pointer hover:underline"
                        )}
                    >
                        {`Verificatiecode opnieuw versturen ${
                            countdown !== 0 ? `(${countdown})` : ""
                        }`}
                    </p>
                </div>
                <div className="mt-1 rounded-lg bg-white p-3 text-center border shadow">
                    <p
                        onClick={() => setPage(2)}
                        className="text-sm text-primary-green cursor-pointer hover:underline"
                    >
                        Telefoonnummer wijzigen
                    </p>
                </div>
                <div className="mt-1 rounded-lg bg-white p-3 text-center border shadow">
                    <p
                        onClick={signOut}
                        className="text-sm text-primary-pink cursor-pointer hover:underline"
                    >
                        Uitloggen
                    </p>
                </div>
            </div>
        </section>
    ) : (
        <ChangePhone setPage={setPage} />
    );
};

export default PhoneVerification;

// import { Auth } from "aws-amplify";
// import { useEffect, useState } from "react";
// import { Spinner } from "../../../components/Spinner";
// import clsx from "clsx";
// import ReactCodeInput from "react-code-input";
// import { API_DEFAULT_HEADERS, API_DOMAIN } from "../../../APIconfig";
// import ChangePhone from "./changePhone";

// const PhoneVerification = () => {
//     const [loading, setLoading] = useState<boolean>(false);
//     const [isPinCodeValid, setIsPinCodeValid] = useState<boolean>(true);
//     const [countdown, setCountdown] = useState<number>(30);
//     const [page, setPage] = useState<number>(1);
//     const [error, setError] = useState<string>("");

//     useEffect(() => {
//         document.title = "Verificatie | TenderTracer";
//     }, []);

//     const resendVerificationCode = async () => {
//         try {
//             setLoading(true);
//             const { attributes } = await Auth.currentAuthenticatedUser({
//                 bypassCache: true,
//             });
//             console.log("User attributes for resend:", attributes);

//             const session = await Auth.currentSession();
//             const jwt = session.getIdToken().getJwtToken();

//             const raw = JSON.stringify({
//                 id: attributes.sub,
//             });

//             const requestOptions = {
//                 method: "POST",
//                 headers: new Headers(API_DEFAULT_HEADERS(jwt)),
//                 body: raw,
//                 redirect: "follow" as RequestRedirect,
//             };

//             const response = await fetch(`${API_DOMAIN}/sms/send`, requestOptions);
//             if (response.ok) {
//                 setCountdown(30);
//                 setError("");
//             } else {
//                 throw new Error("Failed to send verification code");
//             }
//         } catch (error) {
//             console.error("Error sending verification code:", error);
//             setError("Er ging iets mis bij het versturen van de code. Probeer het opnieuw.");
//         } finally {
//             setLoading(false);
//         }
//     };

//     async function signOut() {
//         try {
//             setLoading(true);
//             await Auth.signOut();
//             window.location.href = "/";
//         } catch (error) {
//             console.error("Error signing out:", error);
//             setError("Er ging iets mis bij het uitloggen. Probeer het opnieuw.");
//         } finally {
//             setLoading(false);
//         }
//     }

//     useEffect(() => {
//         let timer: NodeJS.Timeout;

//         if (countdown > 0) {
//             timer = setInterval(() => {
//                 setCountdown((prevCountdown) => prevCountdown - 1);
//             }, 1000);
//         }

//         return () => clearInterval(timer);
//     }, [countdown]);

//     async function confirmCode(code: string) {
//         if (code.length === 6) {
//             try {
//                 setLoading(true);
//                 setIsPinCodeValid(true);
//                 setError("");
    
//                 const { attributes } = await Auth.currentAuthenticatedUser({
//                     bypassCache: true,
//                 });
    
//                 const session = await Auth.currentSession();
//                 const jwt = session.getIdToken().getJwtToken();
    
//                 const response = await fetch(`${API_DOMAIN}/sms/confirm`, {
//                     method: "POST",
//                     headers: {
//                         ...API_DEFAULT_HEADERS(jwt),
//                         'Content-Type': 'application/json'
//                     },
//                     body: JSON.stringify({
//                         id: attributes.sub,
//                         smscode: code
//                     })
//                 });
                
//                 const data = await response.json();
//                 console.log('dit is de data: ', data);
//                 console.log('dit is de response: ', response);
                
//                 if (response.ok && data.success) {
//                     window.location.href = "https://dashboard.tendertracer.nl/";
//                 } else {
//                     setIsPinCodeValid(false);
//                     setError(data.error || "Verificatie mislukt. Probeer het opnieuw.");
//                 }
//             } catch (error) {
//                 console.error("Error during verification:", error);
//                 setIsPinCodeValid(false);
//                 setError("Er ging iets mis. Probeer het opnieuw.");
//             } finally {
//                 setLoading(false);
//             }
//         }
//     }

//     return page === 1 ? (
//         <section className="h-full w-full overflow-y-auto flex justify-center pt-[10%] relative bg-[#284F82] bg-center bg-cover bg-[url('/public/images/onboarding_bg.webp')]">
//             <div className="ml-5 mr-5 mt-5 w-[500px]">
//                 <div className="bg-white rounded-lg mb-2 p-2 border shadow">
//                     <div className="w-full bg-white rounded-lg py-2">
//                         <img
//                             className="w-[150px]"
//                             src="/images/TenderTracer_logo.png"
//                             alt="TenderTracer Logo"
//                         />
//                     </div>
//                     <p className="font-bold pl-3 text-lg">
//                         Telefoonnummer verifiëren
//                     </p>
//                     <p className="pl-3 text-sm">
//                         We hebben een verificatiecode per SMS naar je
//                         telefoonnummer gestuurd. Voer de code hieronder in om
//                         verder te gaan.
//                     </p>
//                     <div className="w-full p-5 h-full rounded-lg bg-white lg:px-3 px-3 flex flex-col">
//                         <div className="flex relative flex-col items-center justify-center">
//                             {loading && (
//                                 <div className="absolute w-[500px] rounded-lg bg-opacity-80 h-full bg-white items-center justify-center flex z-10">
//                                     <Spinner />
//                                 </div>
//                             )}
//                             {!isPinCodeValid && (
//                                 <p className="text-primary-pink mb-2">
//                                     {error || "Code is onjuist. Probeer het nogmaals."}
//                                 </p>
//                             )}
//                             <ReactCodeInput
//                                 type="text"
//                                 fields={6}
//                                 isValid={isPinCodeValid}
//                                 onChange={confirmCode}
//                                 inputStyle={{
//                                     width: "3em",
//                                     height: "4em",
//                                     margin: "0.5em",
//                                     fontSize: "1em",
//                                     borderRadius: "5px",
//                                     border: "2px solid #284F82",
//                                     textAlign: "center",
//                                 }}
//                                 name={"PhoneVerification"}
//                                 inputMode={"tel"}
//                             />
//                         </div>
//                     </div>
//                 </div>
//                 <div className="mt-1 rounded-lg bg-white p-3 border text-center shadow">
//                     <p
//                         onClick={countdown === 0 ? resendVerificationCode : undefined}
//                         className={clsx(
//                             "text-sm",
//                             countdown !== 0
//                                 ? "opacity-50 cursor-not-allowed"
//                                 : "text-primary-green cursor-pointer hover:underline"
//                         )}
//                     >
//                         {`Verificatiecode opnieuw versturen ${
//                             countdown !== 0 ? `(${countdown})` : ""
//                         }`}
//                     </p>
//                 </div>
//                 <div className="mt-1 rounded-lg bg-white p-3 text-center border shadow">
//                     <p
//                         onClick={() => setPage(2)}
//                         className="text-sm text-primary-green cursor-pointer hover:underline"
//                     >
//                         Telefoonnummer wijzigen
//                     </p>
//                 </div>
//                 <div className="mt-1 rounded-lg bg-white p-3 text-center border shadow">
//                     <p
//                         onClick={signOut}
//                         className="text-sm text-primary-pink cursor-pointer hover:underline"
//                     >
//                         Uitloggen
//                     </p>
//                 </div>
//             </div>
//         </section>
//     ) : (
//         <ChangePhone setPage={setPage} />
//     );
// };

// export default PhoneVerification;