export const csvString = `_key,emptyValues,value,emails
140,1,215000,1
142,1,215000,8
145,0,400000,2
146,1,215000,2
147,1,215000,1
150,1,17731505,62
153,0,660000,1
156,1,215000,2
157,0,2,4
158,1,138221057,74
159,1,215000,3
160,0,12,26
161,0,1200003,6
163,0,358331,8
167,1,791803,32
180,1,215004,31
181,1,7775475,49
182,1,4069696,24
184,1,545000,11
185,1,10813091,82
189,1,215000,4
190,1,215000,1
196,0,1789228,11
220,1,193130844,73
221,0,44695011,351
222,1,215000,2
224,1,215000,3
229,1,309710,10
240,0,0,31
241,1,4415002,23
243,1,215002,4
244,0,69307,5
249,1,215000,10
300,1,114687788,83
301,1,48239147,112
302,1,206054370,784
310,0,9868375,30
311,1,1247961,15
312,1,215001,11
313,0,23997886,7
314,0,1,2
315,0,2524576,41
316,1,345000,15
317,0,67480282,26
320,0,2,18
322,1,11362354,17
323,1,13767988,96
324,1,27761776,64
325,0,1115541,27
330,1,5215005,23
331,1,135788232,249
336,1,398002,36
337,1,878383,27
339,1,215000,4
340,0,10300009,61
341,1,117917076,679
342,1,11987607,50
343,0,1840143,12
344,1,215000,14
345,0,74404016,33
346,1,29362963,23
347,1,215000,7
349,0,744461888,232
350,1,1309680,40
351,1,11291291,65
352,0,8000002,13
353,1,215000,6
354,1,215000,5
355,0,254002,9
357,1,215000,18
358,1,215000,6
370,0,0,72
373,0,140000,1
374,0,3500001,18
375,1,18848384,156
378,0,2400000,4
380,1,1982135,181
381,0,2850000,16
382,0,6053904,13
383,1,815000,13
384,1,504989,52
385,1,695002,39
386,1,683224,10
387,1,5615002,7
388,0,2,2
389,0,140040,29
390,0,5859669,33
391,0,146730966,354
392,0,29320306,39
393,1,215000,1
395,0,500000,11
397,1,1815006,17
398,0,1640004,11
410,1,215000,1
411,1,215000,4
420,0,3600005,21
421,1,13632972,52
422,1,215001,4
424,1,2752708,24
425,1,2225001,22
426,1,615000,15
427,0,7000000,2
429,1,7938007,97
430,1,215000,1
431,0,1,3
432,1,215000,7
433,0,2267649,55
434,1,215000,1
438,1,215000,7
440,0,1565390,20
441,0,84341494,146
442,1,8417367,49
443,1,215000,1
444,1,3115002,29
445,1,1913003,30
446,1,5411329,32
448,1,215000,2
449,0,764146,5
450,1,8117721234,755
451,1,180547803,101
452,0,10854470349,1683
453,0,657542435,233
454,0,34475975,142
455,1,5382000,2
480,0,270190227,554
481,1,1413666,46
482,0,2500384,17
483,1,215002,8
484,0,4681267,53
485,0,49774110,23
486,1,4915002,14
487,0,1474500,5
488,0,6828580,30
489,1,535000,18
500,0,292599529,211
501,0,59508327,150
502,0,284208139,211
503,1,3358765,21
504,0,1963058,30
505,1,192362858,90
506,1,515000,4
507,0,233566101,190
508,1,4581109,60
510,0,2831094,17
511,0,7032730,16
512,0,4344002,26
513,0,645373,7
515,1,215000,3
516,1,215000,8
518,1,215000,1
519,0,1236762,5
550,0,11587543,21
551,0,48600002,21
552,1,215000,2
553,1,215000,2
554,0,1200002,8
555,0,53033070,93
559,0,720000,3
600,0,1350015,50
601,0,573140288,227
602,0,1722916,10
604,1,215000,6
606,0,114145869,18
630,0,800000,6
631,1,215000,6
635,1,3615003,26
637,0,141081318,84
640,1,215000,15
641,0,17670472,55
642,0,10374780514,174
650,0,1,14
651,1,952040,2
652,1,215000,3
653,0,180804,16
654,1,215019,22
655,0,329040,5
660,0,761898046,94
661,0,16657052,40
665,0,4157358590,1080
666,1,215000,1
667,0,1,1
700,0,464179,96
701,1,215004,37
702,0,1,1
703,1,2894444,50
710,0,76874173,509
712,1,14366007,129
713,1,1009908912,827
714,1,2226228,48
715,0,23685249,26
716,0,10342654,55
717,0,4440093,14
718,0,886740,7
719,1,1097004,30
720,1,2225448474,1051
721,1,215000,1
722,1,2168536560,422
723,1,251783783,84
724,1,4624731,65
725,1,30364718,59
726,0,4114663,14
727,0,10313516,44
728,1,215002,5
729,0,2305106,6
730,1,1214531104,152
731,1,42462137,150
732,0,8479729,56
733,0,3300000,27
750,0,2693713,130
751,1,48767521,71
752,0,40883722,99
753,0,500000,4
760,1,215000,14
762,1,215000,2
763,1,215000,2
764,1,215000,6
765,1,215000,5
766,1,215000,2
770,0,13883856,669
771,0,2959539,13
772,1,21890737,196
773,0,161865861,762
774,0,2575506,3
778,1,215002,3
790,1,2696599856,1193
791,1,15889788,63
792,0,42881487,333
793,0,439164200,244
794,1,81528160,101
795,0,28156463,105
796,1,13476594646,3280
797,0,189647019,144
798,0,5762746,83
799,1,384228889,259
800,1,401929716,194
801,1,2715462,474
802,0,2880002,7
803,1,488752,10
804,0,35328817,82
805,0,379486468,180
806,1,215000,3
850,0,21499942894,1303
851,0,133990102,470
852,0,399466,12
853,1,2970600173,523
900,0,3984740,118
904,1,48965986,182
905,1,242897454,470
906,0,53436533,150
907,0,5139398,130
909,0,136481770,549
920,1,491484,40
921,1,23311000,34
922,0,16,34
923,0,6016574,27
925,0,10316280,31
926,0,16764665,33
980,1,365008,60
981,1,215000,2
982,1,215000,1
983,1,80897589,121
985,1,215099,109
989,1,215000,1
091,0,11980305,132
093,1,23394506,115
090,1,11215001,44
033,0,2250000,5
031,0,5175962,42
034,1,5973316,137
032,1,215000,1
092,1,215000,5
030,1,215000,4`;
