import { ChartOptions } from "chart.js";
import { useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";

interface ProcedureChartProps {
    data: any;
    colorPalette: any;
}

export function WonTendersChart({ data, colorPalette }: ProcedureChartProps) {
    const [typeData, setTypeData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    function colorFromRaw(ctx: any) {
        const array: any = [];
        if (ctx.type !== "data") {
            return "transparent";
        }

        ctx.chart.data.datasets[0].tree.forEach((datastructure: any) => {
            array.push(datastructure.name);
        });

        const indexArray = array.indexOf(ctx.raw._data.name);

        const value = ctx.raw.v;
        let alpha = Math.log(value) / 10;
        return `${colorPalette[indexArray]}`;
    }

    const wonTendersChart = {
        datasets: [
            {
                tree: typeData,
                data: [],
                key: "Gewonnen",
                groups: ["name"],
                labels: {
                    display: true,
                    color: "white",
                },
                backgroundColor: (ctx: any) => colorFromRaw(ctx),
            },
        ],
    };

    const wonTendersOptions: ChartOptions = {
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
        responsive: true,
        layout: {
            padding: {
                top: 0,
                left: 10,
                right: 10,
                bottom: 0,
            },
        },
        maintainAspectRatio: false,
        indexAxis: "x",
    };

    useEffect(() => {
        handleData();
    }, []);

    async function handleData() {
        const output = Object.entries(
            data.reduce((prev: any, label: any) => {
                prev[label["Officiele benaming"]] = prev[
                    label["Officiele benaming"]
                ]
                    ? prev[label["Officiele benaming"]] + 1
                    : 1;
                return prev;
            }, {})
        )
            .map(([name, Gewonnen]) => ({ name, Gewonnen }))
            .sort((a: any, b: any) => b.count - a.count);

        const filteredOutput = output.filter(
            (element) => element.name !== "undefined"
        );
        setTypeData(filteredOutput.slice(0, 20));

        setLoading(false);
    }

    return (
        <Chart
            type="treemap"
            className="w-full h-full"
            data={wonTendersChart}
            options={wonTendersOptions}
        />
    );
}
