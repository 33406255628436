import { useState, useEffect } from "react";
import { Spinner } from "../../../components/Spinner";
import { InputPhone } from "../../../components/InputPhone";
import { Button } from "../../../components/Button";
import { API, Auth, graphqlOperation } from "aws-amplify";

interface PageProps {
    setPage: (e: number) => void;
}

const ChangePhone = ({ setPage }: PageProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [phone, setPhone] = useState<string>("");

    useEffect(() => {
        document.title = "Telefoonnummer Wijzigen | TenderTracer";
    }, []);

    async function handleData() {
        setLoading(true);
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });
        const updateUser = `mutation MyQuery { updateUser(input: {id: "${attributes.sub}", phone: "${phone}"}) { firstname }}`;
        try {
            await API.graphql(graphqlOperation(updateUser));
            setPage(1);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    async function signOut() {
        try {
            await Auth.signOut();
            window.location.href = "/";
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <section className="h-full w-full overflow-y-auto flex justify-center pt-[10%] relative bg-[#284F82] bg-center bg-cover bg-[url('/public/images/onboarding_bg.webp')]">
            <div className="ml-5 mr-5 mt-5 w-[500px]">
                <div className="bg-white rounded-lg mb-2 p-2 border shadow">
                    <div className="w-full bg-white rounded-lg py-2">
                        <img
                            className="w-[150px]"
                            src="/images/TenderTracer_logo.png"
                        />
                    </div>
                    <p className="font-bold pl-3 text-lg">
                        Telefoonnummer wijzigen
                    </p>
                    <div className="w-full p-5 h-full rounded-lg bg-white lg:px-3 px-3 flex flex-col ">
                        <div>
                            <InputPhone
                                label="Nieuwe Telefoonnummer"
                                height="100%"
                                required={false}
                                value={phone}
                                onChange={(e: string) => setPhone(e)}
                            />
                        </div>
                        <div className="mt-5 flex justify-end">
                            <Button
                                buttonType="submit"
                                type={loading ? "hidden" : "secondary"}
                                onClick={handleData}
                            >
                                {!loading ? (
                                    "WIJZIGEN"
                                ) : (
                                    <div className="flex items-center justify-end">
                                        <div className="pr-3">
                                            <p>Laden...</p>
                                        </div>
                                        <div>
                                            <Spinner />
                                        </div>
                                    </div>
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="mt-1 rounded-lg bg-white p-3 text-center border shadow">
                    <p
                        onClick={() => setPage(1)}
                        className="text-sm text-primary-green cursor-pointer hover:underline"
                    >
                        Terug naar verifiëren
                    </p>
                </div>
                <div className="mt-1 rounded-lg bg-white p-3 text-center border shadow">
                    <p
                        onClick={signOut}
                        className="text-sm text-primary-pink cursor-pointer hover:underline"
                    >
                        Uitloggen
                    </p>
                </div>
            </div>
        </section>
    );
};

export default ChangePhone;
