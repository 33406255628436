export const errorToast = {
    style: {
        border: "1px solid #ff4d72",
        padding: "16px",
        color: "#ff4d72",
    },
    iconTheme: {
        primary: "#ff4d72",
        secondary: "#FFFAEE",
    },
};
