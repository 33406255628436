import { Plan, PlanFeature } from "AppTypes";
import clsx from "clsx";
import { Button } from "../Button";
import "../../fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface PricingCardProps {
    id: string;
    title: string;
    subtitle: string;
    price: string;
    discount: string;
    period: "/maand" | "/jaar" | "/kwartaal" | "/14 dagen";
    features: PlanFeature[] | [];
    children?: React.ReactNode;
    buttonText: string;
    invoiceId: string;
    loading?: boolean;
    button: (id: string, title: string, price: string, type: string) => void;
}

export const PricingCard = ({
    id,
    title,
    subtitle,
    price,
    discount,
    period,
    features,
    children,
    buttonText,
    invoiceId,
    button,
    loading = false,
}: PricingCardProps) => {
    return (
        <div
            className={clsx(
                "flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 rounded bg-white border border-gray-100",
                title === "Proefperiode" ? "shadow-2xl" : "shadow"
            )}
        >
            <h3 className={clsx("mb-1 text-2xl font-semibold")}>{title}</h3>
            <p className="font-light-text-gray-500 sm:text-lg">{subtitle}</p>

            {discount === "" ? (
                <div className="flex justify-center items-baseline my-8">
                    <span className={clsx("mr-2 text-5xl font-extrabold")}>
                        {price}
                    </span>
                    <span className={clsx("text-gray-500")}>{period}</span>
                </div>
            ) : (
                <div className="flex justify-center items-center my-8">
                    <span className="mr-2 text-5xl font-extrabold">
                        {price}
                    </span>
                    <div className="flex flex-col text-left">
                        <span
                            style={{ color: "#ff4d72" }}
                            className="text-gray-500 line-through italic"
                        >
                            {discount}
                        </span>
                        <span className="text-gray-500">{period}</span>
                    </div>
                </div>
            )}
            <ul role="list" className="mb-8 space-y-4 text-left">
                {features.map((feature, index) => (
                    <li key={index} className="flex items-center space-x-3">
                        {feature.adventage ? (
                            <FontAwesomeIcon color={"#00aaaa"} icon="check" />
                        ) : (
                            <FontAwesomeIcon
                                className="pl-1"
                                color={"#ff4d72"}
                                icon="xmark"
                            />
                        )}
                        <span>{feature.name}</span>
                    </li>
                ))}
            </ul>
            {children}
            {/* {invoiceId !== "" && (
        <Button
          className="mb-2"
          type="secondary"
          onClick={() => button(invoiceId, title, price, "invoice")}
        >
          Betalen per factuur
        </Button>
      )} */}
            <Button
                // type={title === "Proefperiode" ? "secondary" : "ghost"}
                type="secondary"
                onClick={() => button(id, title, price, "payment")}
            >
                {buttonText}
            </Button>
        </div>
    );
};
