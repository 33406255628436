import { Button } from "./Button";
import "../fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

interface ModalProps {
    title: string;
    subtitle?: string;
    icon?: any;
    children: React.ReactNode;
    onClick: () => void;
}

export const Modal = ({
    title,
    subtitle,
    icon,
    children,
    onClick,
}: ModalProps) => {
    return (
        <div
            className={clsx(
                "flex overflow-x-hidden fixed inset-0 z-50 outline-none bg-[#00000094] focus:outline-none",
                title !== "Deelsectoren Wijzigen"
                    ? "overflow-y-auto"
                    : "overflow-y-hidden"
            )}
        >
            <div className="relative w-full my-6 mx-auto max-w-6xl">
                {/* Content */}
                <div
                    className={clsx(
                        "border border-gray rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none",
                        title === "Deelsectoren Wijzigen" && "h-[99%]"
                    )}
                >
                    {/* Header */}
                    <div className="p-5 border-b border-solid border-slate-200 rounded-t justify-between flex flex-row">
                        <div>
                            <h3 className="text-3xl font-semibold">
                                {icon && (
                                    <FontAwesomeIcon
                                        className={
                                            icon === "crown"
                                                ? "pr-3 animate-bounce"
                                                : "pr-3"
                                        }
                                        color={
                                            icon === "crown"
                                                ? "#ffd700"
                                                : "#284f82"
                                        }
                                        icon={icon}
                                    />
                                )}
                                {title}
                            </h3>
                            <p>{subtitle}</p>
                        </div>
                        {title === "Deelsectoren Wijzigen" ? (
                            <div
                                onClick={onClick}
                                className="cursor-pointer flex items-center border px-2 rounded-lg text-primary-green border-primary-green hover:text-primary-pink hover:border-primary-pink"
                            >
                                <FontAwesomeIcon
                                    className="text-2xl pr-2"
                                    icon="check"
                                />
                                <p className="pr-2">Opslaan en sluiten</p>
                            </div>
                        ) : (
                            <div onClick={onClick} className="cursor-pointer">
                                <FontAwesomeIcon
                                    className="text-4xl text-primary-blue hover:text-primary-pink pr-3"
                                    icon="xmark"
                                />
                            </div>
                        )}
                    </div>
                    {/* Body */}
                    {children}
                </div>
            </div>
        </div>
    );
};
