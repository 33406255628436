import "../../fontawesome";
import WelcomeOnboarding from "./pages/Welcome";
import { useState } from "react";
import BrancheOnboarding from "./pages/Branche";
import ResultsOnboarding from "./pages/Results";
import SuccessOnboarding from "./pages/Success";

interface ModalProps {
    onClose: () => void;
}

export const OnboardingModal = ({ onClose }: ModalProps) => {
    const [page, setPage] = useState<number>(1);
    const [keywords, setKeywords] = useState<string[]>([]);
    const [selection, setSelection] = useState<any[]>([]);
    return (
        <div
            className={
                "flex overflow-x-hidden fixed inset-0 z-50 outline-none bg-[#00000094] focus:outline-none"
            }
        >
            <div className="relative w-full my-6 mx-auto max-w-7xl">
                {/* Content */}
                <div className="rounded-lg shadow-xl bg-white relative flex flex-col w-full outline-none focus:outline-none">
                    {/* Header */}
                    <div className="w-full h-10 bg-[length:1100px_550px] rounded-t-lg bg-[url('/public/images/onboarding_bg.webp')]" />
                    <div className="bg-light-blue rounded--lg justify-between flex flex-row">
                        <div className="p-5">
                            <h3 className="text-3xl font-bold text-white">
                                Onboarding Premium
                            </h3>
                        </div>
                        <div className="bg-white flex items-center justify-center">
                            <img
                                src="/images/TenderTracer_logo.png"
                                alt="Tendertracer Logo"
                            />
                        </div>
                    </div>

                    {/* Body */}
                    {page === 1 && (
                        <WelcomeOnboarding
                            onClose={onClose}
                            onNext={() => setPage(2)}
                        />
                    )}
                    {page === 2 && (
                        <BrancheOnboarding
                            onClose={() => setPage(1)}
                            onNext={() => setPage(4)}
                            setKeywords={setKeywords}
                            keywords={keywords}
                        />
                    )}
                    {page === 4 && (
                        <ResultsOnboarding
                            onClose={() => setPage(2)}
                            onNext={() => setPage(5)}
                            keywords={keywords}
                            selection={selection}
                            setSelection={setSelection}
                        />
                    )}
                    {page === 5 && (
                        <SuccessOnboarding
                            onClose={onClose}
                            selection={selection}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
