import { RouteType } from "./config";

// IMPORT PAGES
import { Dashboard } from "../pages/dashboard";

// IMPORT ICONS
import { RiDashboard2Line } from "react-icons/ri";
import { SlFire } from "react-icons/sl";
import { FiBriefcase } from "react-icons/fi";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { RiBoxingLine } from "react-icons/ri";
import { IoMedicalOutline } from "react-icons/io5";

import TenderOverview from "../pages/tenders/TenderOverview";
import Settings from "../pages/profile/settings";
import Payment from "../pages/profile/phone";
import TenderDetails from "../pages/tenders/detail";
import RecommendedTenders from "../pages/tenders/recommended/Recommended";
import Help from "../pages/help/Help";
import Competitor from "../pages/competitor/Competitor";
import Premium from "../pages/profile/premium";
import NewTenders from "../pages/tenders/recommended/NewTenders";

const appRoutes: RouteType[] = [
    {
        index: true,
        path: "settings",
        element: <Settings />,
        state: "settings",
    },
    {
        index: true,
        path: "premium",
        element: <Premium />,
        state: "premium",
    },
    {
        index: true,
        mobile: true,
        path: "",
        element: <Dashboard />,
        state: "dashboard",
        sidebarProps: {
            displayText: "Dashboard",
            icon: <RiDashboard2Line />,
            premium: false,
        },
    },
    {
        index: true,
        mobile: true,
        path: "new-tenders",
        element: <NewTenders />,
        state: "new-tenders",
        sidebarProps: {
            displayText: "Nieuwste 25 Aanbestedingen",
            icon: <SlFire />,
            premium: false,
        },
    },
    {
        index: true,
        mobile: true,
        path: "recommended-tenders",
        element: <RecommendedTenders />,
        state: "recommended-tenders",
        sidebarProps: {
            displayText: "Mijn Aanbestedingen",
            icon: <FiBriefcase />,
            premium: false,
        },
    },
    {
        index: true,
        path: "all-tenders",
        element: <TenderOverview />,
        state: "tender-overview",
        sidebarProps: {
            displayText: "Alle Aanbestedingen",
            icon: <HiOutlineBuildingOffice />,
            premium: false,
        },
    },
    {
        index: true,
        path: "competitor",
        element: <Competitor />,
        state: "competitor",
        sidebarProps: {
            displayText: "Concurrentie Analyse",
            icon: <RiBoxingLine />,
            premium: false,
        },
    },
    {
        index: true,
        path: "help",
        element: <Help />,
        state: "help",
        sidebarProps: {
            displayText: "Hulp bij inschrijven",
            icon: <IoMedicalOutline />,
            premium: true,
        },
    },
    {
        path: "payment",
        element: <Payment />,
        state: "payment",
    },
    {
        path: "tender/:tenderid/:phaseid",
        element: <TenderDetails />,
        state: "tender-details",
    },
];

export default appRoutes;
