import clsx from "clsx";
import { FormEvent, useState } from "react";
import "../fontawesome";

interface InputButtonProps {
    label?: string;
    placeholder?: string;
    type?: string;
    string: string;
    searchString: (string: string) => void;
}

export const InputSearch = ({
    label = "",
    type = "text",
    placeholder = "...",
    string,
    searchString,
}: InputButtonProps) => {
    return (
        <div className="font-medium">
            <div className="relative">
                <input
                    type={type}
                    id={label}
                    value={string}
                    className="block w-full px-4 py-3 text-sm text-black bg-zinc-100 border border-gray rounded-lg focus:outline-none focus:ring-1 focus:ring-primary-pink"
                    placeholder={placeholder}
                    onChange={(e: FormEvent<HTMLInputElement>) =>
                        searchString(e.currentTarget.value.toString())
                    }
                />
            </div>
        </div>
    );
};
