import { API, Auth, graphqlOperation } from "aws-amplify";
import React, { FormEvent, useEffect, useState } from "react";
import { Spinner } from "../../components/Spinner";
import { Button } from "../../components/Button";
import { TenderItem } from "./TenderItemOverview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { InputSearchFilter } from "../../components/InputSearchFilter";
import useAnalyticsEventTracker from "../../tracker/EventTracker";
import clsx from "clsx";
import ReactSelect from "../../components/Selector";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { searchPhases } from "../../graphql/queries";
import Tabs from "../../components/Tabs";
import { RiFilterOffLine } from "react-icons/ri";
import { send } from "@emailjs/browser";
import { sendGAEvent } from "../../utils/analytics";

interface PhaseTranslations {
    [key: string]: string;
}

interface ProcedureTranslations {
    [key: string]: string;
}

interface TypeTranslations {
    [key: string]: string;
}

interface FilterState {
    keyword: string;
    publicationDate: any;
    closingDate: any;
    type: any;
    publication: any;
    internationalPublication: any;
    aiCpv: any;
    cpv: any;
}

const translatePhase = (phase: string): string => {
    const phaseTranslations: PhaseTranslations = {
        "pin-buyer":
            "Aankondiging van de bekendmaking van een vooraankondiging of een periodieke indicatieve aankondiging via een kopersprofiel",
        "pin-only":
            "Vooraankondiging of periodieke indicatieve aankondiging die alleen ter informatie wordt gebruikt",
        "pin-cfc-social":
            "Vooraankondiging of periodieke indicatieve aankondiging gebruikt als oproep tot mededinging – lichte regeling",
        "pin-cfc-standard":
            "Vooraankondiging of periodieke indicatieve aankondiging gebruikt als oproep tot mededinging – standaardregeling",
        "pin-rtl":
            "Vooraankondiging of periodieke indicatieve aankondiging gebruikt om termijnen voor de ontvangst van inschrijvingen in te korten",
        "pin-tran": "Vooraankondiging voor openbaar personenvervoer",
        "cn-standard":
            "Aankondiging van een opdracht of concessie – standaardregeling",
        "cn-social": "Aankondiging van een opdracht – lichte regeling",
        "cn-desg": "Aankondiging van een prijsvraag voor ontwerpen",
        "can-tran":
            "Aankondiging gegunde opdracht voor openbaar personenvervoer",
        "can-desg":
            "Aankondiging van de uitslag van een prijsvraag voor ontwerpen",
        "can-social":
            "Aankondiging van een gegunde opdracht of concessie – lichte regeling",
        "can-standard":
            "Aankondiging van een gegunde opdracht of concessie – standaardregeling",
        "can-modif": "Aankondiging van wijziging contract",
        "veat": "Aankondiging in geval van vrijwillige transparantie vooraf",
        "brin-eeig":
            "Aankondiging inzake een Europees economisch samenwerkingsverband",
        "brin-ecs":
            "Aankondiging inzake een Europese vennootschap / Europese coöperatieve vennootschap",
        "qu-sy": "Aankondiging inzake het bestaan van een erkenningsregeling",
        "subco": "Aankondiging van een opdracht in onderaanneming",
        "corr": "Aankondiging van een rectificatie",
    };
    return phaseTranslations[phase] || phase;
};

const translateProcedure = (procedure: string): string => {
    const procedureTranslations: ProcedureTranslations = {
        open: "Openbaar",
        restricted: "Niet-openbaar",
        "neg-w-call":
            "Onderhandelingsprocedure met voorafgaande oproep tot mededinging",
        "neg-wo-call":
            "Onderhandelingsprocedure zonder voorafgaande oproep tot mededinging",
        "comp-dial": "Concurrentiegerichte dialoog",
        "oth-mult": "Andere procedure in meerdere fasen",
        "oth-single": "Andere procedure in één fase",
        innovation: "Innovatiepartnerschap",
        "comp-tend": "Mededingingsprocedure",
        A: "Onderhandse gunning",
        "4": "Procedure gunning via onderhandelingen",
        E: "Procedure voor de gunning van een consessie",
        "6": "Versnelde procedure van gunning via onderhandelingen",
        "9": "Niet van toepassing",
        V: "Gunning van een opdracht zonder voorafgaande bekendmaking",
        F: "Gunning van een consessie zonder voorafgaande aankondiging van consessie",
        "8": "Andere",
        Z: "Niet gespecificeerd",
    };
    return procedureTranslations[procedure] || procedure;
};

const translateType = (type: string, country: string | null): string => {
    const typeTranslations: TypeTranslations = {
        services: "Diensten",
        works: "Werken",
        supplies: "Leveringen",
    };
    return country !== "NL" ? typeTranslations[type] || type : type;
};

interface TenderOverviewProps {}

const TenderOverview: React.FC<TenderOverviewProps> = () => {
    const location = useLocation();
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const navigate = useNavigate();

    const parseURLParams = () => {
        const urlParams = new URLSearchParams(location.search);
        const initialFilters: FilterState = {
            keyword: urlParams.get('keyword') || '',
            publicationDate: null,
            closingDate: null,
            type: null,
            publication: null,
            internationalPublication: null,
            cpv: null,
            aiCpv: null,
        };
    
        const parseJSONSafely = (param: string | null, defaultValue: any = null) => {
            if (!param) return defaultValue;
            try {
                return JSON.parse(param);
            } catch {
                return defaultValue;
            }
        };
    
        initialFilters.publicationDate = parseJSONSafely(urlParams.get('publicationDate'));
        initialFilters.closingDate = parseJSONSafely(urlParams.get('closingDate'));
        initialFilters.type = parseJSONSafely(urlParams.get('type'));
        initialFilters.publication = parseJSONSafely(urlParams.get('publication'));
        initialFilters.internationalPublication = parseJSONSafely(urlParams.get('internationalPublication'));
        initialFilters.cpv = parseJSONSafely(urlParams.get('cpv'));
        initialFilters.aiCpv = parseJSONSafely(urlParams.get('aicpv'));
    
        return initialFilters;
    };

    const [filters, setFilters] = useState<FilterState>(parseURLParams());
    const [activeFilters, setActiveFilters] = useState<FilterState>(parseURLParams());

    const handleFilterChange = (filterName: keyof FilterState, value: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value === null ? null : value,
        }));
    };

    const applyFilters = () => {
        setActiveFilters(filters);
        updateURLWithFilters(filters);
        fetchTenders(true, country, filters);
        sendGAEvent('search_tenders',{
            keyword: filters.keyword,
            publicationDate: filters.publicationDate?.value,
            closingDate: filters.closingDate?.value,
            type: filters.type?.value,
            publication: filters.publication?.value,
            internationalPublication: filters.internationalPublication?.value,
            cpv: filters.cpv?.value,
            aiCpv: filters.aiCpv?.value,
            country: country

        })
    
    
    };
    const handleClearFilters = () => {
        const emptyFilters: FilterState = {
            keyword: "",
            publicationDate: null,
            closingDate: null,
            type: null,
            publication: null,
            internationalPublication: null,
            cpv: "",
            aiCpv: "",
        };
        setFilters(emptyFilters);
        setActiveFilters(emptyFilters);
        updateURLWithFilters(emptyFilters);
        fetchTenders(true, country, emptyFilters);
    };

    const [filtersReset, setFiltersReset] = useState(false);
    const urlCountry = urlParams.get("country");
    const [tenders, setTenders] = useState<any[]>([]);
    const [favoriteIDs, setFavoriteIDs] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingKeyword, setLoadingKeyword] = useState<boolean>(false);
    const [nextToken, setNextToken] = useState<any>(null);
    const [companyID, setCompanyID] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [userActive, setUserActive] = useState<boolean>(false);
    const [country, setCountry] = useState<string>(urlCountry || "NL");

    const [publicationDate, setPublicationDate] = useState<any>(
        JSON.parse(urlParams.get("publicationDate") || "{}")
    );
    const [closingDate, setClosingDate] = useState<any>(
        JSON.parse(urlParams.get("closingDate") || "{}")
    );
    const [type, setType] = useState<any>(
        JSON.parse(urlParams.get("type") || "{}")
    );
    const [keyword, setKeyword] = useState<string>("");
    const [publication, setPublication] = useState<any>(
        JSON.parse(urlParams.get("publication") || "{}")
    );
    const [internationalPublication, setInternationalPublication] =
        useState<any>(
            JSON.parse(urlParams.get("internationalPublication") || "{}")
        );
    const [firstRender, setFirstRender] = useState<boolean>(true);
    const [aiCpvs, setAiCpvs] = useState<any[]>([]);
    const [aiCpv, setAiCpv] = useState<any>(
        JSON.parse(urlParams.get("aiCPV") || "{}")
    );
    const [cpv, setCpv] = useState<any>(
        JSON.parse(urlParams.get("cpv") || "{}")
    );
    const [cpvCodes, setCPVcodes] = useState<any[]>([]);
    const [contacts, setContacts] = useState<any[]>([]);
    const [publicationIdFilter, setPublicationIdFilter] =
        useState<string>("-2024");

    const updateURLWithFilters = (currentFilters: FilterState) => {
        const queryParams = new URLSearchParams();

        if (country) queryParams.set("country", country);
        if (currentFilters.publication) queryParams.set("publication", JSON.stringify(currentFilters.publication));
        if (currentFilters.internationalPublication) queryParams.set("internationalPublication", JSON.stringify(currentFilters.internationalPublication));
        if (currentFilters.keyword) queryParams.set("keyword", currentFilters.keyword);
        if (currentFilters.type) queryParams.set("type", JSON.stringify(currentFilters.type));
        if (currentFilters.publicationDate) queryParams.set("publicationDate", JSON.stringify(currentFilters.publicationDate));
        if (currentFilters.closingDate) queryParams.set("closingDate", JSON.stringify(currentFilters.closingDate));
        if (currentFilters.aiCpv) queryParams.set("aicpv", JSON.stringify(currentFilters.aiCpv));
        if (currentFilters.cpv) queryParams.set("cpv", JSON.stringify(currentFilters.cpv));

        navigate(`?${queryParams.toString()}`);
    };

    useEffect(() => {
        updateURLWithFilters(filters);
    }, [country])

    const handleCountryChange = async (newCountry: string) => {
        setLoading(true);
        setCountry(newCountry);

        // Fetch new data
        await fetchTenders(true, newCountry, activeFilters);
        setLoading(false);
    };

    useEffect(() => {
        document.title = "Alle aanbestedingen | TenderTracer";
        handleUserInfo();
        fetchTenders(true, country, activeFilters);
    }, []);

    const handleTenderClick = (tenderId: string, phaseId: string) => {
        console.log(location)
        
        sendGAEvent('view_tender_details', {
            tenderId: tenderId,
            phaseId: phaseId,
            country: country
        });
        navigate(
            `/tender/${encodeURIComponent(tenderId)}/${encodeURIComponent(
                phaseId
            )}`,
            { state: { from: location.pathname + location.search } }
        );
    };

    const fetchTenders = async (filter: boolean, countryUsed = country, currentFilters = activeFilters) => {
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });
        const query = `query MyQuery { getUser(id: "${attributes.sub}") { companies { items { company { active_subscription } } } } }`;
        const userData: any = await API.graphql(graphqlOperation(query));
        const userActiveness = userData.data.getUser.companies.items[0].company.active_subscription;
        
        if (filter) setLoading(true);
        let filterOptions: { [key: string]: any } = {};
    
        if (currentFilters.keyword && currentFilters.keyword.trim() !== "") {
            filterOptions.description = { match: currentFilters.keyword };
        }
    
        if (currentFilters.publicationDate?.value) {
            filterOptions.dispatch_notice_date = currentFilters.publicationDate.value;
        }
    
        if (currentFilters.closingDate?.value) {
            filterOptions.end_date = currentFilters.closingDate.value;
        }
    
        if (currentFilters.type?.value) {
            filterOptions.tender_type = {
                eq: countryUsed !== "NL" ? currentFilters.type.altValue : currentFilters.type.value,
            };
        }
    
        if (currentFilters.publication?.value && countryUsed === "NL") {
            filterOptions.phase = { matchPhrasePrefix: currentFilters.publication.value };
        }
    
        if (currentFilters.internationalPublication?.value && countryUsed !== "NL") {
            filterOptions.phase = { matchPhrasePrefix: currentFilters.internationalPublication.value };
        }
    
        if (currentFilters.cpv?.value || currentFilters.aiCpv?.value) {
            filterOptions.or = [
                { cpv_list: { wildcard: `*${currentFilters.cpv?.value || currentFilters.aiCpv?.value}*` } },
            ];
        }
    
        if (currentFilters.aiCpv?.value || currentFilters.cpv?.value) {
            filterOptions.or = [
                { cpv_list: { wildcard: `*${currentFilters.aiCpv?.value || currentFilters.cpv?.value}*` } },
            ];
        }
    
        if (userActiveness) {
            if (countryUsed === "NL") {
                filterOptions.country = { exists: false };
            } else if (countryUsed !== "") {
                filterOptions.country = { eq: countryUsed };
            }
        } else {
            filterOptions.country = { exists: false };
        }
    
        const limit = 50;
        const sort = {
            field: "dispatch_notice_date",
            direction: "desc",
        };
    
        try {
            const tenderData: any = await API.graphql(
                graphqlOperation(searchPhases, {
                    filter: filterOptions,
                    sort: sort,
                    limit: limit,
                    nextToken: filter ? null : nextToken,
                })
            );
            const tenderItems = filter
                ? tenderData.data.searchPhases.items
                : [...tenders, ...tenderData.data.searchPhases.items];
            const token = tenderData.data.searchPhases.nextToken;
            setNextToken(token);
            setTenders(tenderItems);
    
            setLoadingKeyword(false);
            setLoading(false);
        } catch (err: any) {
            console.log(err);
            const tenderItems = filter
                ? err.data?.searchPhases?.items || []
                : [...tenders, ...(err.data?.searchPhases?.items || [])];
            const token = err.data?.searchPhases?.nextToken;
            setNextToken(token);
            setTenders(tenderItems);
    
            setLoadingKeyword(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        EventTracker();
        handleUserInfo();
    }, []);

    useEffect(() => {
        if (filtersReset) {
            setFiltersReset(false);
        }
    }, [filtersReset]);

    function EventTracker() {
        useAnalyticsEventTracker(
            "Alle aanbestedingen",
            "Bekijkt Alle aanbestedingen",
            "Alle aanbestedingen"
        );
    }

    async function handleUserInfo() {
        const cpvCodes = require("../../data/cpvCodes.json");
        const cpvArray = cpvCodes["CPV codes"].map((cpv: any) => ({
            label: cpv.Omschrijving,
            value: cpv.CODE.includes("-") ? cpv.CODE.split("-")[0] : cpv.CODE,
        }));
        setCPVcodes(cpvArray);
        try {
            const { attributes } = await Auth.currentAuthenticatedUser({
                bypassCache: true,
            });
            const query = `query MyQuery { getUser(id: "${attributes.sub}") { active companies { items { company { id name active_subscription aiCPV { items { cpv { id code description } } } saved_tenders { items { tenderId } } } } } } }`;
            const userData: any = await API.graphql(graphqlOperation(query));

            setCompanyID(userData.data.getUser.companies.items[0].company.id);
            setCompanyName(
                userData.data.getUser.companies.items[0].company.name
            );
            setUserActive(
                userData.data.getUser.companies.items[0].company
                    .active_subscription
            );
            setAiCpvs(
                userData.data.getUser.companies.items[0].company.aiCPV.items
            );

            const favoriteItems: any[] = [];
            userData.data.getUser.companies.items[0].company.saved_tenders.items.forEach(
                (tender: any) => favoriteItems.push(tender.tenderId)
            );
            setFavoriteIDs(favoriteItems);
        } catch (userData: any) {
            setCompanyID(userData.data.getUser.companies.items[0].company.id);
            setAiCpvs(
                userData.data.getUser.companies.items[0].company.aiCPV.items
            );
            setUserActive(
                userData.data.getUser.companies.items[0].company
                    .active_subscription
            );

            const favoriteItems: any[] = [];
            userData.data.getUser.companies.items[0].company.saved_tenders.items.forEach(
                (tender: any) => favoriteItems.push(tender.tenderId)
            );
            setFavoriteIDs(favoriteItems);
        }
    }

    return (
        <section className="h-full w-[calc(100vw-240px)] overflow-hidden">
            <div className="flex bg-white border shadow rounded-lg mt-5 ml-5 mr-5 p-2 items-center">
                <HiOutlineBuildingOffice className="text-2xl text-primary-blue" />
                <p className="font-bold text-lg pl-2">Alle aanbestedingen</p>
                <Tabs
                    country={userActive ? country : "NL"}
                    setCountry={handleCountryChange}
                    userActive={userActive}
                    setPublicationIdFilter={setPublicationIdFilter}
                    resetFilters={handleClearFilters}
                />
            </div>
            <div className="flex">
                <div className="w-[400px] bg-white border shadow ml-5 mt-3 mb-2 p-8 hidden rounded-lg h-screen items-center flex-col lg:flex overflow-auto">
                    <div className="w-full justify-between items-center flex">
                        <p className="font-bold text-lg">Filters</p>
                        <div
                            className="flex space-x-1 hover:underline cursor-pointer opacity-50 hover:opacity-100"
                            onClick={handleClearFilters}
                        >
                            <RiFilterOffLine />
                            <p className="text-sm">Filters wissen</p>
                        </div>
                    </div>

                    <div className="pt-5 w-full border-b pb-5">
                        <InputSearchFilter
                            label="Zoeken op trefwoord"
                            placeholder="Voer hier je trefwoord in..."
                            value={filters.keyword}
                            //loading={loadingKeyword}
                            // onChange={(e: FormEvent<HTMLInputElement>) =>
                            //     setKeyword(e.currentTarget.value.toString())
                            // }
                            onChange={(e: React.FormEvent<HTMLInputElement>) => handleFilterChange('keyword', e.currentTarget.value)}

                        />
                    </div>
                    <div className="pt-3 text-sm text-black w-full">
                        <label>Publicatiedatum</label>
                        <ReactSelect
                            advancedLabel={false}
                            defaultValue={filters.publicationDate}
                            value={filters.publicationDate}
                            defaultLabel={
                                filters.publicationDate === null
                                    ? "Alle datums"
                                    : filters.publicationDate.label || "Alle datums"
                            }
                            data={[
                                { label: "Alle datums", value: null },
                                {
                                    label: "Vandaag",
                                    value: {
                                        gte: moment()
                                            .startOf("day")
                                            .toISOString(),
                                        lte: moment()
                                            .endOf("day")
                                            .toISOString(),
                                    },
                                },
                                {
                                    label: "Gisteren",
                                    value: {
                                        gte: moment()
                                            .subtract(1, "day")
                                            .startOf("day")
                                            .toISOString(),
                                        lte: moment()
                                            .subtract(1, "day")
                                            .endOf("day")
                                            .toISOString(),
                                    },
                                },
                                {
                                    label: "Afgelopen 7 dagen",
                                    value: {
                                        gte: moment()
                                            .subtract(6, "days")
                                            .startOf("day")
                                            .toISOString(),
                                        lte: moment()
                                            .endOf("day")
                                            .toISOString(),
                                    },
                                },
                                {
                                    label: "Afgelopen 30 dagen",
                                    value: {
                                        gte: moment()
                                            .subtract(29, "days")
                                            .startOf("day")
                                            .toISOString(),
                                        lte: moment()
                                            .endOf("day")
                                            .toISOString(),
                                    },
                                },
                            ]}
                            placeholder="Selecteer een publicatiedatum"
                            multi={false}
                            onChange={(event) => handleFilterChange('publicationDate', event)}
                        />
                    </div>
                    <div className="pt-2 text-sm text-black w-full border-b pb-5">
                        <label>Sluitingsdatum</label>
                        <ReactSelect
                            advancedLabel={false}
                            defaultValue={filters.closingDate}
                            value={filters.closingDate}
                            defaultLabel={
                                filters.closingDate === null
                                    ? "Alle datums"
                                    : filters.closingDate.label || "Alle datums"
                            }
                            data={[
                                { label: "Alle datums", value: null },
                                {
                                    label: "Vandaag",
                                    value: {
                                        gte: moment()
                                            .startOf("day")
                                            .toISOString(),
                                        lte: moment()
                                            .endOf("day")
                                            .toISOString(),
                                    },
                                },
                                {
                                    label: "Morgen",
                                    value: {
                                        gte: moment()
                                            .add(1, "day")
                                            .startOf("day")
                                            .toISOString(),
                                        lte: moment()
                                            .add(1, "day")
                                            .endOf("day")
                                            .toISOString(),
                                    },
                                },
                                {
                                    label: "Aankomende 7 dagen",
                                    value: {
                                        gte: moment()
                                            .startOf("day")
                                            .toISOString(),
                                        lte: moment()
                                            .add(6, "days")
                                            .endOf("day")
                                            .toISOString(),
                                    },
                                },
                                {
                                    label: "Aankomende 30 dagen",
                                    value: {
                                        gte: moment()
                                            .startOf("day")
                                            .toISOString(),
                                        lte: moment()
                                            .add(29, "days")
                                            .endOf("day")
                                            .toISOString(),
                                    },
                                },
                                {
                                    label: "Aankomende 60 dagen",
                                    value: {
                                        gte: moment()
                                            .startOf("day")
                                            .toISOString(),
                                        lte: moment()
                                            .add(59, "days")
                                            .endOf("day")
                                            .toISOString(),
                                    },
                                },
                            ]}
                            placeholder="Selecteer een sluitingsdatum"
                            multi={false}
                            //onChange={(event) => setClosingDate(event)}
                            onChange={(event) => handleFilterChange('closingDate', event)}
                        />
                    </div>
                    <div className="pt-3 text-sm text-black w-full">
                        <label>Type opdracht</label>
                        <ReactSelect
                            advancedLabel={false}
                            defaultValue={filters.type}
                            value={filters.type}
                            defaultLabel={filters.type ? "Alle types" : filters.type}
                            data={[
                                { label: "Alle types", value: "" },
                                {
                                    label: "Diensten",
                                    value: "Diensten",
                                    altValue: "services",
                                },
                                {
                                    label: "Leveringen",
                                    value: "Leveringen",
                                    altValue: "supplies",
                                },
                                {
                                    label: "Werken",
                                    value: "Werken",
                                    altValue: "works",
                                },
                            ]}
                            placeholder="Selecteer een type"
                            multi={false}
                            // onChange={(event) => {
                            //     if (country === "NL") {
                            //         setType(event);
                            //     } else {
                            //         setType(event);
                            //     }
                            // }}
                            onChange={(event) => handleFilterChange('type', event)}
                        />
                    </div>
                    {country === "NL" && (
                        <div className="pt-2 text-sm text-black w-full border-b pb-5">
                            <label>Publicatie Nederland</label>
                            <ReactSelect
                                advancedLabel={false}
                                defaultValue={filters.publication}
                                value={filters.publication}
                                defaultLabel={
                                    filters.publication === ""
                                        ? "Alle publicaties"
                                        : filters.publication
                                          
                                }                             
                                data={[
                                    { label: "Alle publicaties", value: "" },
                                    {
                                        label: "Marktconsultatie",
                                        value: "Marktconsultatie",
                                    },
                                    {
                                        label: "Vooraankondiging",
                                        value: "Vooraankondiging",
                                    },
                                    {
                                        label: "Aankondiging opdracht",
                                        value: "Aankondiging van een opdracht",
                                    },
                                    {
                                        label: "Aankondiging gegunde opdracht",
                                        value: "Aankondiging van een gegunde opdracht",
                                    },
                                    {
                                        label: "Aankondiging van een wijziging",
                                        value: "Aankondiging van een wijziging",
                                    },
                                    {
                                        label: "Rectificatie",
                                        value: "Rectificatie",
                                    },
                                ]}
                                placeholder="Selecteer een publicatie"
                                multi={false}
                                //onChange={(event) => setPublication(event)}
                                onChange={(event) => handleFilterChange('publication', event)}
                            />
                        </div>
                    )}

                    {country !== "NL" && (
                        <div className="pt-2 text-sm text-black w-full pb-5 group relative">
                            <label
                                className={clsx(
                                    !userActive &&
                                        "opacity-50 cursor-not-allowed"
                                )}
                            >
                                Publicatie internationaal
                            </label>
                            <ReactSelect
                                advancedLabel={false}
                                defaultValue={translatePhase(
                                    filters.internationalPublication
                                )}
                                value={filters.internationalPublication}
                                defaultLabel={
                                    filters.internationalPublication === ""
                                        ? "Alle publicaties"
                                        : translatePhase(
                                              filters.internationalPublication
                                          )
                                }
                                data={[
                                    { label: "Alle publicaties", value: "" },
                                    {
                                        label: "Aankondiging van de bekendmaking van een vooraankondiging of een periodieke indicatieve aankondiging via een kopersprofiel",
                                        value: "pin-buyer",
                                    },
                                    {
                                        label: "Vooraankondiging of periodieke indicatieve aankondiging die alleen ter informatie wordt gebruikt",
                                        value: "pin-only",
                                    },
                                    {
                                        label: "Vooraankondiging of periodieke indicatieve aankondiging gebruikt als oproep tot mededinging – lichte regeling",
                                        value: "pin-cfc-social",
                                    },
                                    {
                                        label: "Vooraankondiging of periodieke indicatieve aankondiging gebruikt als oproep tot mededinging – standaardregeling",
                                        value: "pin-cfc-standard",
                                    },
                                    {
                                        label: "Vooraankondiging of periodieke indicatieve aankondiging gebruikt om termijnen voor de ontvangst van inschrijvingen in te korten",
                                        value: "pin-rtl",
                                    },
                                    {
                                        label: "Vooraankondiging voor openbaar personenvervoer",
                                        value: "pin-tran",
                                    },
                                    {
                                        label: "Aankondiging van een opdracht of concessie – standaardregeling",
                                        value: "cn-standard",
                                    },
                                    {
                                        label: "Aankondiging van een opdracht – lichte regeling",
                                        value: "cn-social",
                                    },
                                    {
                                        label: "Aankondiging van een prijsvraag voor ontwerpen",
                                        value: "cn-desg",
                                    },
                                    {
                                        label: "Aankondiging gegunde opdracht voor openbaar personenvervoer",
                                        value: "can-tran",
                                    },
                                    {
                                        label: "Aankondiging van de uitslag van een prijsvraag voor ontwerpen",
                                        value: "can-desg",
                                    },
                                    {
                                        label: "Aankondiging van een gegunde opdracht of concessie – lichte regeling",
                                        value: "can-social",
                                    },
                                    {
                                        label: "Aankondiging van een gegunde opdracht of concessie – standaardregeling",
                                        value: "can-standard",
                                    },
                                    {
                                        label: "Aankondiging van wijziging contract",
                                        value: "can-modif",
                                    },
                                    {
                                        label: "Aankondiging in geval van vrijwillige transparantie vooraf",
                                        value: "veat",
                                    },
                                    {
                                        label: "Aankondiging inzake een Europees economisch samenwerkingsverband",
                                        value: "brin-eeig",
                                    },
                                    {
                                        label: "Aankondiging inzake een Europese vennootschap / Europese coöperatieve vennootschap",
                                        value: "brin-ecs",
                                    },
                                    {
                                        label: "Aankondiging inzake het bestaan van een erkenningsregeling",
                                        value: "qu-sy",
                                    },
                                    {
                                        label: "Aankondiging van een opdracht in onderaanneming",
                                        value: "subco",
                                    },
                                    { label: "Rectificatie", value: "corr" },
                                ]}
                                placeholder="Selecteer een publicatie"
                                multi={false}
                                // onChange={(event) =>
                                //     setInternationalPublication(event)
                                // }
                                onChange={(event) => handleFilterChange('internationalPublication', event)}
                                disabled={!userActive}
                            />
                            {!userActive && (
                                <span className="absolute top-30 left-0 w-full justify-center items-center scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 flex">
                                    <FontAwesomeIcon
                                        className="mr-3 drop-shadow-xl animate-bounce"
                                        color="#ffd700"
                                        icon="crown"
                                    />
                                    <p className="font-bold">Premium</p>
                                </span>
                            )}
                        </div>
                    )}

                    <div className="group relative pt-3 text-sm text-black w-full">
                        <label>CPV codes</label>
                        <ReactSelect
                            advancedLabel={true}
                            defaultValue={filters.cpv}
                            value={filters.cpv}
                            defaultLabel={filters.cpv === null ? "Alle CPV codes" : filters.cpv.label || "Alle CPV codes"}
                            data={cpvCodes}
                            placeholder="Vul hier een CPV code in"
                            multi={false}
                            onChange={(event) => handleFilterChange('cpv', event)}
                        />
                    </div>

                    <div
                        className="group relative pt-2 text-sm text-black w-full"
                        style={{ marginBottom: "30%" }}
                    >
                        <label
                            className={clsx(
                                !userActive && "opacity-50 cursor-not-allowed"
                            )}
                        >
                            Relevante CPV codes voor {companyName}
                        </label>
                        <ReactSelect
                            advancedLabel={true}
                            data={[
                                { label: "", value: "Alle CPV codes" },
                                ...aiCpvs.map(
                                    (Cpv) =>
                                        Cpv !== null && {
                                            label: Cpv.cpv.description,
                                            value: Cpv.cpv.code,
                                        }
                                ),
                            ]}
                            defaultValue={filters.aiCpv}
                            value={filters.aiCpv}
                            defaultLabel={
                                filters.aiCpv === null ? "Alle CPV codes" : filters.aiCpv.label || "Alle CPV codes"
                            }
                            placeholder="Vul hier een CPV code in"
                            multi={false}
                            // onChange={(event) =>
                            //     event.value === "Alle CPV codes"
                            //         ? setAiCpv("")
                            //         : setAiCpv(event)
                            // }
                            onChange={(event) => handleFilterChange('aiCpv', event)}
                            disabled={!userActive}
                        />
                        {!userActive && (
                            <span className="absolute top-30 left-0 w-full justify-center items-center scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 flex">
                                <FontAwesomeIcon
                                    className="mr-3 drop-shadow-xl animate-bounce"
                                    color="#ffd700"
                                    icon="crown"
                                />
                                <p className="font-bold">Premium</p>
                            </span>
                        )}
                    
                    <div className="flex justify-center w-full">
                    <Button
                        className="mt-5 w-full"
                        onClick={applyFilters}
                    >
                        Filters toepassen
                    </Button>
                </div>
                </div>
                </div>
                
                <div className="flex w-full h-full mt-3 w-[calc(100vw-600px)] mr-5">
                    {/* {loading && firstRender ? ( */}
                    {loading ? (
                        <div className="w-full h-full flex items-center justify-center">
                            <Spinner infiniteLoop={false} />
                        </div>
                    ) : tenders.length > 0 ? (
                        <div className="overflow-y-auto overflow-x-hidden h-[calc(100vh-90px)]">
                            {tenders.map((item, index) => (
                                <div
                                    key={index}
                                    onClick={() =>
                                        handleTenderClick(
                                            item.tender_id,
                                            item.id
                                        )
                                    }
                                >
                                    <TenderItem
                                        item={{
                                            ...item,
                                            tender_type: translateType(
                                                item.tender_type,
                                                item.country
                                            ),
                                            phase: translatePhase(item.phase),
                                        }}
                                        index={index.toString()}
                                        favorite={favoriteIDs.includes(
                                            item.tender_id
                                        )}
                                        companyID={companyID}
                                        state={{ from: location.pathname + location.search } }
                                    />
                                </div>
                            ))}
                            <div className="flex w-full">
                                <div
                                    className="w-full cursor-pointer hover:underline"
                                    onClick={() => fetchTenders(false)}
                                >
                                    {loading ? (
                                        <div className="flex justify-center pb-10 items-center">
                                            <div>
                                                <Spinner />
                                            </div>
                                            <p>Laden...</p>
                                        </div>
                                    ) : (
                                        <div className="flex justify-center pb-10 items-center w-full">
                                            <p className="pr-3">
                                                <FontAwesomeIcon
                                                    className="text-primary-pink"
                                                    icon={"down-long"}
                                                />
                                            </p>
                                            <p>Meer laden</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full h-full flex items-center justify-center">
                            <p>Geen resultaten gevonden.</p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export { translatePhase };
export { translateType };
export { translateProcedure };
export default TenderOverview;
