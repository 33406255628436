import { API, Auth, graphqlOperation } from "aws-amplify";
import { LoadingDocument } from "../../../../components/LoadingDocument";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "../../../../components/Spinner";
import { InputSearchFilter } from "../../../../components/InputSearchFilter";
import ReactSelect from "../../../../components/Selector";
import { translatePhase } from "../../TenderOverview";
import favoriteAnimation from "../../../../lottie/6531-favorite-icon.json";
import { FavoriteTenderItem } from "../../FavoriteTenderItem";
import { FormEvent } from "react";

export default function FavoriteTenders() {
    const [aiPhases, setAiPhases] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const urlPublication = urlParams.get("publication");
    const urlInternationalPublication = urlParams.get("internationalPublication");
    const urlType = urlParams.get("type");
    const urlKeyword = urlParams.get("keyword");
    const urlSort = urlParams.get("sort");
    const [favorites, setFavoriteTendersIDs] = useState<any[]>([]);
    const [loadingNext, setLoadingNext] = useState(false);
    const [firstRender, setFirstRender] = useState(true);
    const [loadingKeyword, setLoadingKeyword] = useState(false);
    const [companyID, setCompanyID] = useState<string>("");
    const [filteredAiPhases, setFilteredAiPhases] = useState<any[]>([]);
    const [publication, setPublication] = useState<string>(
        urlPublication ? urlPublication : ""
    );
    const [internationalPublication, setInternationalPublication] = useState<string>(
        urlInternationalPublication ? urlInternationalPublication : ""
    );
    const [country, setCountry] = useState<string>("NL");
    const [type, setType] = useState<string>(urlType ? urlType : "");
    const [nextToken, setNextToken] = useState<string>("");
    const [keyword, setKeyword] = useState<string>(
        urlKeyword ? urlKeyword : ""
    );
    const [sort, setSort] = useState<string>(urlSort ? urlSort : "desc");
    const [length, setLength] = useState<number>(0);

    useEffect(() => {

        let filtered = aiPhases;
        if (keyword !== "") {
            filtered = filtered.filter((item: any) =>
                item.tender.description.toLowerCase().includes(keyword.toLowerCase()));
        }
        if (publication !== "") {
            filtered = filtered.filter((item: any) => item.tender.current_phase === publication);
        }
        if (internationalPublication !== "") {
            filtered = filtered.filter((item: any) => item.tender.current_phase === internationalPublication);
        }
        if (type !== "") {
            filtered = filtered.filter((item: any) => item.tender.tender_type === type);
        }
        if (country !== "") {
            filtered = filtered.filter((item: any) => item.tender.country === country);
        }
        setFilteredAiPhases(filtered);


        //prettier-ignore
        window.history.replaceState(null, "", `?${publication !== "" ? `publication=${publication}&` : ""}${internationalPublication !== "" ? `internationalPublication=${internationalPublication}&` : ""}${country !== "" ? `country=${country}&` : ""}${type !== "" ? `type=${type}&` : ""}${sort !== "" ? `sort=${sort}&` : ""}${keyword !== "" ? `keyword=${keyword}&` : ""}`);
        if (firstRender) {
            fetchRelevantTenders("");
            setFirstRender(false);
        } else {
            setLoadingKeyword(true);
            const delayDebounceFn = setTimeout(() => {
                fetchRelevantTenders("");
                setLoadingKeyword(false);
            }, 1000);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [publication, internationalPublication, type, keyword, sort, country]);

    async function fetchRelevantTenders(token: string) {
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });

        //prettier-ignore
        const TenderQuery = `query MyQuery { getUser(id: "${attributes.sub}") { companies { items { company { id saved_tenders { items { tender { title procedure dispatch_notice_date description current_phase contracting_authority tender_type id phases { items { id cpv_list description date_receipt time_receipt } } } } } } } } } }`;

        try {
            const tenderData: any = await API.graphql(
                graphqlOperation(TenderQuery)
            );

            //Favorites
            let favoriteTenders =
                tenderData.data.getUser.companies.items[0].company.saved_tenders
                    .items;
            
            // const FilteredTenderQuery = `query MyQuery { searchPhases(sort: {field: dispatch_notice_date, direction: ${sort}}, filter: { ${keyword !== "" ? `description: {match: "${keyword}"}` : ""}${country !== "" && country !== "NL" ? `country: {eq: "${country}"}` : ""}${country === "NL" ? `country: {exists: false}` : ""}${publication !== "" ? `phase: {match: "${publication}"}` : ""}${internationalPublication !== "" ? `phase: {match: "${internationalPublication}"}` : ""}${type !== "" ? `tender_type: {eq: "${type}"}` : ""} tender_id: {in: [${favoriteTenders.map((item: any) => `"${item.tender.id}"`).join(", ")}]} }) { items { id title dispatch_notice_date date_receipt time_receipt procedure tender_id description tender_type contracting_authority phase total_value cpv_list tsender contacts { items { contact { contracting_authority_id contact_type contractor_id } } } } } }`;
                        
            // const filteredTenderData: any = await API.graphql(
            //     graphqlOperation(FilteredTenderQuery)
            // );
            
            // favoriteTenders = filteredTenderData.data.searchPhases.items;
            setAiPhases(favoriteTenders);
            setFavoriteTendersIDs(favoriteTenders.map((item: any) => item.tender.id));
            setFavoriteTendersIDs(favoriteTenders.map((item: any) => item.tender.id));
            setCompanyID(tenderData.data.getUser.companies.items[0].company.id);

            // ---------------------------------------------------------------------------
            setLoading(false);
        } catch (tenderData: any) {
            console.log(tenderData);
            //Favorites
            const favoriteTenders =
                tenderData.data.getUser.companies.items[0].company.saved_tenders
                    .items;
                    

            setAiPhases(favoriteTenders);
            setFavoriteTendersIDs(favoriteTenders.map((item: any) => item.tender.id));
            setCompanyID(tenderData.data.getUser.companies.items[0].company.id);

            // ---------------------------------------------------------------------------

            setLoading(false);
        }
    }

    return (
        <section className="h-full w-full flex flex-col overflow-y-hidden overflow-x-hidden">
            {/* <div className="h-10 w-full px-5 mt-1 mb-3 grid grid-cols-5 gap-4 z-10">
                <InputSearchFilter
                    placeholder="Voer hier je trefwoord in..."
                    value={keyword}
                    loading={loadingKeyword}
                    onChange={(e: FormEvent<HTMLInputElement>) =>
                        setKeyword(e.currentTarget.value.toString())
                    }
                />
                {(country == "NL" || country == "") && (
                <div className="w-full h-full">
                    {/* prettier-ignore */}
                    {/*
                    <ReactSelect
                        advancedLabel={false}
                        defaultValue={publication}
                        defaultLabel={publication === "" ? "Alle publicaties" : publication}
                        data={[
                            { label: "Alle publicaties", value: "" },
                            { label: "Marktconsultatie", value: "Marktconsultatie"},
                            { label: "Vooraankondiging", value: "Vooraankondiging" },
                            { label: "Aankondiging van een opdracht", value: "Aankondiging van een opdracht" },
                            { label: "Rectificatie", value: "Rectificatie" },
                            { label: "Aankondiging van een gegunde opdracht", value: "Aankondiging van een gegunde opdracht" },
                            { label: "Aankondiging van een wijziging", value: "Aankondiging van een wijziging" },
                        ]}
                        placeholder="Selecteer een publicatie"
                        multi={false}
                        onChange={(event: any) => setPublication(event.value)}
                    />
                </div>
                )}
                {(country != "NL" && country != "") && (
                <div className="w-full h-full">
                    {/* prettier-ignore */}
                    {/*
                    <ReactSelect
                        advancedLabel={false}
                        defaultValue={translatePhase(internationalPublication)}
                        defaultLabel={translatePhase(internationalPublication) === "" ? "Alle internationale publicaties" : translatePhase(internationalPublication)}
                        data={[
                            { label: "Alle internationale publicaties", value: "" },
                            { label: "Aankondiging van de bekendmaking van een vooraankondiging of een periodieke indicatieve aankondiging via een kopersprofiel", value: "pin-buyer" },
                            { label: "Vooraankondiging of periodieke indicatieve aankondiging die alleen ter informatie wordt gebruikt", value: "pin-only" },
                            { label: "Vooraankondiging of periodieke indicatieve aankondiging gebruikt als oproep tot mededinging – lichte regeling", value: "pin-cfc-social" },
                            { label: "Vooraankondiging of periodieke indicatieve aankondiging gebruikt als oproep tot mededinging – standaardregeling", value: "pin-cfc-standard" },
                            { label: "Vooraankondiging of periodieke indicatieve aankondiging gebruikt om termijnen voor de ontvangst van inschrijvingen in te korten", value: "pin-rtl" },
                            { label: "Vooraankondiging voor openbaar personenvervoer", value: "pin-tran" },
                            { label: "Aankondiging van een opdracht of concessie – standaardregeling", value: "cn-standard" },
                            { label: "Aankondiging van een opdracht – lichte regeling", value: "cn-social" },
                            { label: "Aankondiging van een prijsvraag voor ontwerpen", value: "cn-desg" },
                            { label: "Aankondiging gegunde opdracht voor openbaar personenvervoer", value: "can-tran" },
                            { label: "Aankondiging van de uitslag van een prijsvraag voor ontwerpen", value: "can-desg" },
                            { label: "Aankondiging van een gegunde opdracht of concessie – lichte regeling", value: "can-social" },
                            { label: "Aankondiging van een gegunde opdracht of concessie – standaardregeling", value: "can-standard" },
                            { label: "Aankondiging van wijziging contract", value: "can-modif" },
                            { label: "Aankondiging in geval van vrijwillige transparantie vooraf", value: "veat" },
                            { label: "Aankondiging inzake een Europees economisch samenwerkingsverband", value: "brin-eeig" },
                            { label: "Aankondiging inzake een Europese vennootschap / Europese coöperatieve vennootschap", value: "brin-ecs" },
                            { label: "Aankondiging inzake het bestaan van een erkenningsregeling", value: "qu-sy" },
                            { label: "Aankondiging van een opdracht in onderaanneming", value: "subco" },
                            { label: "Rectificatie", value: "corr" },
                        ]}
                        placeholder="Selecteer een internationale publicatie"
                        multi={false}
                        onChange={(event) => setInternationalPublication(event.value)}
                    />
                    </div>
                )}
                    {(country != "NL" && country != "") && (
                <div className="w-full h-full">
                    {/* prettier-ignore */}
                    {/*
                    <ReactSelect
                        advancedLabel={false}
                        defaultValue={type}
                        defaultLabel={type === "" ? "Alle types" : type}
                        data={[
                            { label: "Alle types", value: "" },
                            { label: "Diensten", value: "services"},
                            { label: "Leveringen", value: "supplies" },
                            { label: "Werken", value: "works" },
                        ]}
                        placeholder="Selecteer een type"
                        multi={false}
                        onChange={(event) => setType(event.value)}
                    />
                </div>
                    )}
                       {(country == "NL" || country == "") && (
                <div className="w-full h-full">
                    {/* prettier-ignore */}
                    {/*
                    <ReactSelect
                        advancedLabel={false}
                        defaultValue={type}
                        defaultLabel={type === "" ? "Alle types" : type}
                        data={[
                            { label: "Alle types", value: "" },
                            { label: "Diensten", value: "Diensten"},
                            { label: "Leveringen", value: "Leveringen" },
                            { label: "Werken", value: "Werken" },
                        ]}
                        placeholder="Selecteer een type"
                        multi={false}
                        onChange={(event) => setType(event.value)}
                    />
                </div>
                    )}

                <div className="w-full h-full">
                    {/* prettier-ignore */}
                    {/*
                    <ReactSelect
                        advancedLabel={false}
                        defaultValue={sort}
                        defaultLabel={sort === "desc" ? "Nieuwste publicaties eerst" : sort}
                        data={[
                            { label: "Nieuwste publicaties eerst", value: "desc" },
                            { label: "Oudste publicaties eerst", value: "asc"},
                        ]}
                        placeholder="Selecteer een type"
                        multi={false}
                        onChange={(event) => setSort(event.value)}
                    />
                </div>
                <div className="w-full h-full">
                    {/* prettier-ignore */}
                    {/*
                    <ReactSelect
                        advancedLabel={false}
                        defaultValue={country}
                        defaultLabel={"Nederland"}
                        data={[
                            { label: "Nederland", value: "NL"},
                            { label: "België", value: "BEL"},
                            { label: "Duitsland", value: "DEU" },
                            { label: "Frankrijk", value: "FRA" },
                            { label: "Spanje", value: "ESP" },

                        ]}
                        placeholder="Selecteer een land"
                        multi={false}
                        onChange={(event) => setCountry(event.value)}
                    />
                </div>
                
            </div> */}
            {loading ? (
                <div className="w-full h-full flex justify-center items-center">
                    <LoadingDocument infiniteLoop={false} />
                </div>
            ) : aiPhases.length > 0 ? (
                <div className="flex-col w-full overflow-y-auto h-full">
                    {aiPhases.map((item: any, index: any) => (
                        <div key={index}>
                            <FavoriteTenderItem
                                item={item}
                                index={index}
                                companyID={companyID}
                                favorite={true}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="flex flex-col items-center pt-10 w-full mt-5 h-full">
                    <Lottie
                        className="w-[400px]"
                        loop={false}
                        animationData={favoriteAnimation}
                    />
                    <div className="group relative flex h-full justify-center w-[600px]">
                        <p className="text-xs opacity-50 pb-1 text-center cursor-pointer hover:text-primary-pink">
                            Mijn Favorieten
                        </p>
                    </div>
                    <p className="col-span-2 text-center px-5 font-bold">
                        Verzamel en zie jouw favoriete aanbestedingen.
                    </p>
                    <p className="col-span-2 text-center px-5 text-sm w-[50%] opacity-80 font-lighter">
                        Klik op het grijze hartje wat op elke aanbestedingskaart
                        beschikbaar is om deze favoriet te maken. Al je
                        favoriete aanbestedingen zullen hier vertoont worden.
                    </p>
                </div>
            )}
        </section>
    );
}