/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getDashboardStatistics = /* GraphQL */ `query GetDashboardStatistics($id: ID!) {
  getDashboardStatistics(id: $id) {
    id
    name
    value
    type
    active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardStatisticsQueryVariables,
  APITypes.GetDashboardStatisticsQuery
>;
export const listDashboardStatistics = /* GraphQL */ `query ListDashboardStatistics(
  $filter: ModelDashboardStatisticsFilterInput
  $limit: Int
  $nextToken: String
) {
  listDashboardStatistics(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      value
      type
      active
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDashboardStatisticsQueryVariables,
  APITypes.ListDashboardStatisticsQuery
>;
export const searchDashboardStatistics = /* GraphQL */ `query SearchDashboardStatistics(
  $filter: SearchableDashboardStatisticsFilterInput
  $sort: [SearchableDashboardStatisticsSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableDashboardStatisticsAggregationInput]
) {
  searchDashboardStatistics(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      name
      value
      type
      active
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchDashboardStatisticsQueryVariables,
  APITypes.SearchDashboardStatisticsQuery
>;
export const getGeneralStatistics = /* GraphQL */ `query GetGeneralStatistics($id: ID!) {
  getGeneralStatistics(id: $id) {
    id
    totalTenders
    totalAwards
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGeneralStatisticsQueryVariables,
  APITypes.GetGeneralStatisticsQuery
>;
export const listGeneralStatistics = /* GraphQL */ `query ListGeneralStatistics(
  $filter: ModelGeneralStatisticsFilterInput
  $limit: Int
  $nextToken: String
) {
  listGeneralStatistics(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      totalTenders
      totalAwards
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGeneralStatisticsQueryVariables,
  APITypes.ListGeneralStatisticsQuery
>;
export const getDashboardFeatures = /* GraphQL */ `query GetDashboardFeatures($id: ID!) {
  getDashboardFeatures(id: $id) {
    id
    title
    description
    active
    coming_soon
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardFeaturesQueryVariables,
  APITypes.GetDashboardFeaturesQuery
>;
export const listDashboardFeatures = /* GraphQL */ `query ListDashboardFeatures(
  $filter: ModelDashboardFeaturesFilterInput
  $limit: Int
  $nextToken: String
) {
  listDashboardFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      active
      coming_soon
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDashboardFeaturesQueryVariables,
  APITypes.ListDashboardFeaturesQuery
>;
export const searchDashboardFeatures = /* GraphQL */ `query SearchDashboardFeatures(
  $filter: SearchableDashboardFeaturesFilterInput
  $sort: [SearchableDashboardFeaturesSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableDashboardFeaturesAggregationInput]
) {
  searchDashboardFeatures(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title
      description
      active
      coming_soon
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchDashboardFeaturesQueryVariables,
  APITypes.SearchDashboardFeaturesQuery
>;
export const getSbi = /* GraphQL */ `query GetSbi($id: ID!) {
  getSbi(id: $id) {
    id
    code
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSbiQueryVariables, APITypes.GetSbiQuery>;
export const listSbis = /* GraphQL */ `query ListSbis($filter: ModelSbiFilterInput, $limit: Int, $nextToken: String) {
  listSbis(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSbisQueryVariables, APITypes.ListSbisQuery>;
export const getCpvCode = /* GraphQL */ `query GetCpvCode($id: ID!) {
  getCpvCode(id: $id) {
    id
    code
    description
    cpv_category_id
    cpv {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCpvCodeQueryVariables,
  APITypes.GetCpvCodeQuery
>;
export const listCpvCodes = /* GraphQL */ `query ListCpvCodes(
  $filter: ModelCpvCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listCpvCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      description
      cpv_category_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCpvCodesQueryVariables,
  APITypes.ListCpvCodesQuery
>;
export const cpvCodesByCpv_category_id = /* GraphQL */ `query CpvCodesByCpv_category_id(
  $cpv_category_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCodesByCpv_category_id(
    cpv_category_id: $cpv_category_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      code
      description
      cpv_category_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCodesByCpv_category_idQueryVariables,
  APITypes.CpvCodesByCpv_category_idQuery
>;
export const getCpvCategory = /* GraphQL */ `query GetCpvCategory($id: ID!) {
  getCpvCategory(id: $id) {
    id
    code
    description
    cpv_class_id
    cpv_codes {
      nextToken
      __typename
    }
    cpv {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCpvCategoryQueryVariables,
  APITypes.GetCpvCategoryQuery
>;
export const listCpvCategories = /* GraphQL */ `query ListCpvCategories(
  $filter: ModelCpvCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCpvCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      description
      cpv_class_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCpvCategoriesQueryVariables,
  APITypes.ListCpvCategoriesQuery
>;
export const cpvCategoriesByCpv_class_id = /* GraphQL */ `query CpvCategoriesByCpv_class_id(
  $cpv_class_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCategoriesByCpv_class_id(
    cpv_class_id: $cpv_class_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      code
      description
      cpv_class_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCategoriesByCpv_class_idQueryVariables,
  APITypes.CpvCategoriesByCpv_class_idQuery
>;
export const getCpvClass = /* GraphQL */ `query GetCpvClass($id: ID!) {
  getCpvClass(id: $id) {
    id
    code
    description
    cpv_group_id
    cpv_categories {
      nextToken
      __typename
    }
    cpv {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCpvClassQueryVariables,
  APITypes.GetCpvClassQuery
>;
export const listCpvClasses = /* GraphQL */ `query ListCpvClasses(
  $filter: ModelCpvClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listCpvClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      description
      cpv_group_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCpvClassesQueryVariables,
  APITypes.ListCpvClassesQuery
>;
export const cpvClassesByCpv_group_id = /* GraphQL */ `query CpvClassesByCpv_group_id(
  $cpv_group_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvClassFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvClassesByCpv_group_id(
    cpv_group_id: $cpv_group_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      code
      description
      cpv_group_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvClassesByCpv_group_idQueryVariables,
  APITypes.CpvClassesByCpv_group_idQuery
>;
export const getCpvGroup = /* GraphQL */ `query GetCpvGroup($id: ID!) {
  getCpvGroup(id: $id) {
    id
    code
    description
    cpv_division_id
    cpv_classes {
      nextToken
      __typename
    }
    cpv {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCpvGroupQueryVariables,
  APITypes.GetCpvGroupQuery
>;
export const listCpvGroups = /* GraphQL */ `query ListCpvGroups(
  $filter: ModelCpvGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listCpvGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      description
      cpv_division_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCpvGroupsQueryVariables,
  APITypes.ListCpvGroupsQuery
>;
export const cpvGroupsByCpv_division_id = /* GraphQL */ `query CpvGroupsByCpv_division_id(
  $cpv_division_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvGroupsByCpv_division_id(
    cpv_division_id: $cpv_division_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      code
      description
      cpv_division_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvGroupsByCpv_division_idQueryVariables,
  APITypes.CpvGroupsByCpv_division_idQuery
>;
export const getCpvDivision = /* GraphQL */ `query GetCpvDivision($id: ID!) {
  getCpvDivision(id: $id) {
    id
    code
    description
    cpv_groups {
      nextToken
      __typename
    }
    cpv {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCpvDivisionQueryVariables,
  APITypes.GetCpvDivisionQuery
>;
export const listCpvDivisions = /* GraphQL */ `query ListCpvDivisions(
  $filter: ModelCpvDivisionFilterInput
  $limit: Int
  $nextToken: String
) {
  listCpvDivisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCpvDivisionsQueryVariables,
  APITypes.ListCpvDivisionsQuery
>;
export const getCpv = /* GraphQL */ `query GetCpv($id: ID!) {
  getCpv(id: $id) {
    id
    code
    description
    cpv_divisions {
      nextToken
      __typename
    }
    cpv_groups {
      nextToken
      __typename
    }
    cpv_classes {
      nextToken
      __typename
    }
    cpv_categories {
      nextToken
      __typename
    }
    cpv_codes {
      nextToken
      __typename
    }
    companies {
      nextToken
      __typename
    }
    phases {
      nextToken
      __typename
    }
    tenders {
      nextToken
      __typename
    }
    company {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCpvQueryVariables, APITypes.GetCpvQuery>;
export const listCpvs = /* GraphQL */ `query ListCpvs($filter: ModelCpvFilterInput, $limit: Int, $nextToken: String) {
  listCpvs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCpvsQueryVariables, APITypes.ListCpvsQuery>;
export const searchCpvs = /* GraphQL */ `query SearchCpvs(
  $filter: SearchableCpvFilterInput
  $sort: [SearchableCpvSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableCpvAggregationInput]
) {
  searchCpvs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCpvsQueryVariables,
  APITypes.SearchCpvsQuery
>;
export const getAward = /* GraphQL */ `query GetAward($id: ID!) {
  getAward(id: $id) {
    id
    objectDescriptionID
    conclusion_date
    contract_number
    lot_number
    total_value
    currency
    title
    awarded
    number_of_tenders_received
    contacts {
      nextToken
      __typename
    }
    phase_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAwardQueryVariables, APITypes.GetAwardQuery>;
export const listAwards = /* GraphQL */ `query ListAwards(
  $filter: ModelAwardFilterInput
  $limit: Int
  $nextToken: String
) {
  listAwards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      objectDescriptionID
      conclusion_date
      contract_number
      lot_number
      total_value
      currency
      title
      awarded
      number_of_tenders_received
      phase_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAwardsQueryVariables,
  APITypes.ListAwardsQuery
>;
export const awardsByObjectDescriptionID = /* GraphQL */ `query AwardsByObjectDescriptionID(
  $objectDescriptionID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAwardFilterInput
  $limit: Int
  $nextToken: String
) {
  awardsByObjectDescriptionID(
    objectDescriptionID: $objectDescriptionID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      objectDescriptionID
      conclusion_date
      contract_number
      lot_number
      total_value
      currency
      title
      awarded
      number_of_tenders_received
      phase_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AwardsByObjectDescriptionIDQueryVariables,
  APITypes.AwardsByObjectDescriptionIDQuery
>;
export const awardsByPhase_id = /* GraphQL */ `query AwardsByPhase_id(
  $phase_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAwardFilterInput
  $limit: Int
  $nextToken: String
) {
  awardsByPhase_id(
    phase_id: $phase_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      objectDescriptionID
      conclusion_date
      contract_number
      lot_number
      total_value
      currency
      title
      awarded
      number_of_tenders_received
      phase_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AwardsByPhase_idQueryVariables,
  APITypes.AwardsByPhase_idQuery
>;
export const getCriteria = /* GraphQL */ `query GetCriteria($id: ID!) {
  getCriteria(id: $id) {
    id
    name
    description
    weight
    object_description_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCriteriaQueryVariables,
  APITypes.GetCriteriaQuery
>;
export const listCriteria = /* GraphQL */ `query ListCriteria(
  $filter: ModelCriteriaFilterInput
  $limit: Int
  $nextToken: String
) {
  listCriteria(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      weight
      object_description_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCriteriaQueryVariables,
  APITypes.ListCriteriaQuery
>;
export const criteriaByObject_description_id = /* GraphQL */ `query CriteriaByObject_description_id(
  $object_description_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCriteriaFilterInput
  $limit: Int
  $nextToken: String
) {
  criteriaByObject_description_id(
    object_description_id: $object_description_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      weight
      object_description_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CriteriaByObject_description_idQueryVariables,
  APITypes.CriteriaByObject_description_idQuery
>;
export const getObjectDescription = /* GraphQL */ `query GetObjectDescription($id: ID!) {
  getObjectDescription(id: $id) {
    id
    description
    phase_id
    title
    lot_number
    main_site
    longitude
    latitude
    value
    currency
    renewal
    renewal_description
    duration_type
    duration
    start_date
    end_date
    additional_informatio
    criteria {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetObjectDescriptionQueryVariables,
  APITypes.GetObjectDescriptionQuery
>;
export const listObjectDescriptions = /* GraphQL */ `query ListObjectDescriptions(
  $filter: ModelObjectDescriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listObjectDescriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      description
      phase_id
      title
      lot_number
      main_site
      longitude
      latitude
      value
      currency
      renewal
      renewal_description
      duration_type
      duration
      start_date
      end_date
      additional_informatio
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListObjectDescriptionsQueryVariables,
  APITypes.ListObjectDescriptionsQuery
>;
export const objectDescriptionsByPhase_id = /* GraphQL */ `query ObjectDescriptionsByPhase_id(
  $phase_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelObjectDescriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  objectDescriptionsByPhase_id(
    phase_id: $phase_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      description
      phase_id
      title
      lot_number
      main_site
      longitude
      latitude
      value
      currency
      renewal
      renewal_description
      duration_type
      duration
      start_date
      end_date
      additional_informatio
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ObjectDescriptionsByPhase_idQueryVariables,
  APITypes.ObjectDescriptionsByPhase_idQuery
>;
export const getContractor = /* GraphQL */ `query GetContractor($id: ID!) {
  getContractor(id: $id) {
    id
    national_id
    country
    address
    town
    postalcode
    name
    doc_id
    URL
    longitude
    latitude
    contacts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContractorQueryVariables,
  APITypes.GetContractorQuery
>;
export const listContractors = /* GraphQL */ `query ListContractors(
  $filter: ModelContractorFilterInput
  $limit: Int
  $nextToken: String
) {
  listContractors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      national_id
      country
      address
      town
      postalcode
      name
      doc_id
      URL
      longitude
      latitude
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContractorsQueryVariables,
  APITypes.ListContractorsQuery
>;
export const getContractingAuthority = /* GraphQL */ `query GetContractingAuthority($id: ID!) {
  getContractingAuthority(id: $id) {
    id
    national_id
    country
    address
    town
    ca_activity
    doc_id
    URL
    tender_id
    postalcode
    name
    ca_type
    longitude
    latitude
    contacts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContractingAuthorityQueryVariables,
  APITypes.GetContractingAuthorityQuery
>;
export const listContractingAuthorities = /* GraphQL */ `query ListContractingAuthorities(
  $filter: ModelContractingAuthorityFilterInput
  $limit: Int
  $nextToken: String
) {
  listContractingAuthorities(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      national_id
      country
      address
      town
      ca_activity
      doc_id
      URL
      tender_id
      postalcode
      name
      ca_type
      longitude
      latitude
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContractingAuthoritiesQueryVariables,
  APITypes.ListContractingAuthoritiesQuery
>;
export const contractingAuthoritiesByTender_id = /* GraphQL */ `query ContractingAuthoritiesByTender_id(
  $tender_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContractingAuthorityFilterInput
  $limit: Int
  $nextToken: String
) {
  contractingAuthoritiesByTender_id(
    tender_id: $tender_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      national_id
      country
      address
      town
      ca_activity
      doc_id
      URL
      tender_id
      postalcode
      name
      ca_type
      longitude
      latitude
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContractingAuthoritiesByTender_idQueryVariables,
  APITypes.ContractingAuthoritiesByTender_idQuery
>;
export const getContact = /* GraphQL */ `query GetContact($id: ID!) {
  getContact(id: $id) {
    id
    contact_type
    phone
    email
    name
    fax
    contractor_id
    contracting_authority_id
    tenders {
      nextToken
      __typename
    }
    awards {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContactQueryVariables,
  APITypes.GetContactQuery
>;
export const listContacts = /* GraphQL */ `query ListContacts(
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      contact_type
      phone
      email
      name
      fax
      contractor_id
      contracting_authority_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactsQueryVariables,
  APITypes.ListContactsQuery
>;
export const contactsByContractor_id = /* GraphQL */ `query ContactsByContractor_id(
  $contractor_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  contactsByContractor_id(
    contractor_id: $contractor_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contact_type
      phone
      email
      name
      fax
      contractor_id
      contracting_authority_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContactsByContractor_idQueryVariables,
  APITypes.ContactsByContractor_idQuery
>;
export const contactsByContracting_authority_id = /* GraphQL */ `query ContactsByContracting_authority_id(
  $contracting_authority_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  contactsByContracting_authority_id(
    contracting_authority_id: $contracting_authority_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contact_type
      phone
      email
      name
      fax
      contractor_id
      contracting_authority_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContactsByContracting_authority_idQueryVariables,
  APITypes.ContactsByContracting_authority_idQuery
>;
export const getFile = /* GraphQL */ `query GetFile($id: ID!) {
  getFile(id: $id) {
    id
    bucket
    region
    key
    phase_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFileQueryVariables, APITypes.GetFileQuery>;
export const listFiles = /* GraphQL */ `query ListFiles(
  $filter: ModelFileFilterInput
  $limit: Int
  $nextToken: String
) {
  listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      bucket
      region
      key
      phase_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFilesQueryVariables, APITypes.ListFilesQuery>;
export const filesByPhase_id = /* GraphQL */ `query FilesByPhase_id(
  $phase_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFileFilterInput
  $limit: Int
  $nextToken: String
) {
  filesByPhase_id(
    phase_id: $phase_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      bucket
      region
      key
      phase_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FilesByPhase_idQueryVariables,
  APITypes.FilesByPhase_idQuery
>;
export const getPhase = /* GraphQL */ `query GetPhase($id: ID!) {
  getPhase(id: $id) {
    id
    phase
    title_eng
    country
    cpv_list
    tender_type
    contracting_authority
    dispatch_notice_date
    description
    procedure
    title
    ojs_number
    buyer_city
    email_send
    eu_program_related
    description_eng
    end_date
    start_date
    total_value
    currency
    pdf
    pdf_eng
    tsender
    tsender_link
    date_receipt
    time_receipt
    dispatch_invitations_date
    recurring
    tender_id
    companies {
      nextToken
      __typename
    }
    files {
      nextToken
      __typename
    }
    contacts {
      items{
        contact{
          id
          contact_type
          name 
          contractor_id
          contracting_authority_id
          email
          phone
          fax
        }
      }
      nextToken
      __typename
    }
    object_descriptions {
      nextToken
      __typename
    }
    awards {
      nextToken
      __typename
    }
    cpv_codes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPhaseQueryVariables, APITypes.GetPhaseQuery>;
export const listPhases = /* GraphQL */ `query ListPhases(
  $filter: ModelPhaseFilterInput
  $limit: Int
  $nextToken: String
) {
  listPhases(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      phase
      title_eng
      country
      cpv_list
      tender_type
      contracting_authority
      dispatch_notice_date
      description
      procedure
      title
      ojs_number
      buyer_city
      email_send
      eu_program_related
      description_eng
      end_date
      start_date
      total_value
      currency
      pdf
      pdf_eng
      tsender
      tsender_link
      date_receipt
      time_receipt
      dispatch_invitations_date
      recurring
      tender_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPhasesQueryVariables,
  APITypes.ListPhasesQuery
>;
export const phasesByTender_id = /* GraphQL */ `query PhasesByTender_id(
  $tender_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPhaseFilterInput
  $limit: Int
  $nextToken: String
) {
  phasesByTender_id(
    tender_id: $tender_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      phase
      title_eng
      country
      cpv_list
      tender_type
      contracting_authority
      dispatch_notice_date
      description
      procedure
      title
      ojs_number
      buyer_city
      email_send
      eu_program_related
      description_eng
      end_date
      start_date
      total_value
      currency
      pdf
      pdf_eng
      tsender
      tsender_link
      date_receipt
      time_receipt
      dispatch_invitations_date
      recurring
      tender_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PhasesByTender_idQueryVariables,
  APITypes.PhasesByTender_idQuery
>;
export const searchPhases = /* GraphQL */ `query SearchPhases(
  $filter: SearchablePhaseFilterInput
  $sort: [SearchablePhaseSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchablePhaseAggregationInput]
) {
  searchPhases(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      phase
      title_eng
      country
      cpv_list
      tender_type
      contracting_authority
      dispatch_notice_date
      description
      procedure
      title
      ojs_number
      buyer_city
      email_send
      eu_program_related
      description_eng
      end_date
      start_date
      total_value
      currency
      pdf
      pdf_eng
      tsender
      tsender_link
      date_receipt
      time_receipt
      dispatch_invitations_date
      recurring
      tender_id
      winner_name
      createdAt
      updatedAt
      __typename
      contacts {
        items {
          id
          contactId
          phaseId
          __typename
          contact{
            id
            contact_type
            name 
            contractor_id
            contracting_authority_id
            email
            phone
            fax
          }
        }
        nextToken
      }
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}` as GeneratedQuery<
  APITypes.SearchPhasesQueryVariables,
  APITypes.SearchPhasesQuery
>;
export const getTender = /* GraphQL */ `query GetTender($id: ID!) {
  getTender(id: $id) {
    id
    title_eng
    country
    tender_type
    current_phase
    contracting_authority
    dispatch_notice_date
    description
    procedure
    title
    companies {
      nextToken
      __typename
    }
    phases {
      nextToken
      __typename
    }
    cpv_codes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTenderQueryVariables, APITypes.GetTenderQuery>;
export const listTenders = /* GraphQL */ `query ListTenders(
  $filter: ModelTenderFilterInput
  $limit: Int
  $nextToken: String
) {
  listTenders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title_eng
      country
      tender_type
      current_phase
      contracting_authority
      dispatch_notice_date
      description
      procedure
      title
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTendersQueryVariables,
  APITypes.ListTendersQuery
>;
export const searchTenders = /* GraphQL */ `query SearchTenders(
  $filter: SearchableTenderFilterInput
  $sort: [SearchableTenderSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTenderAggregationInput]
) {
  searchTenders(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title_eng
      country
      tender_type
      current_phase
      contracting_authority
      dispatch_notice_date
      description
      procedure
      title
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTendersQueryVariables,
  APITypes.SearchTendersQuery
>;
export const getCompany = /* GraphQL */ `query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    name
    chamber_of_commerce
    address
    postalcode
    city
    country
    active
    active_subscription
    employees {
      nextToken
      __typename
    }
    saved_tenders {
      nextToken
      __typename
    }
    recommended_tenders {
      nextToken
      __typename
    }
    cpv {
      nextToken
      __typename
    }
    aiCPV {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyQueryVariables,
  APITypes.GetCompanyQuery
>;
export const listCompanies = /* GraphQL */ `query ListCompanies(
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      chamber_of_commerce
      address
      postalcode
      city
      country
      active
      active_subscription
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompaniesQueryVariables,
  APITypes.ListCompaniesQuery
>;
export const searchCompanies = /* GraphQL */ `query SearchCompanies(
  $filter: SearchableCompanyFilterInput
  $sort: [SearchableCompanySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableCompanyAggregationInput]
) {
  searchCompanies(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      name
      chamber_of_commerce
      address
      postalcode
      city
      country
      active
      active_subscription
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCompaniesQueryVariables,
  APITypes.SearchCompaniesQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    firstname
    lastname
    email
    phone
    smscode
    last_email_received
    last_login
    stripe_id
    trial
    active
    companies {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstname
      lastname
      email
      phone
      smscode
      last_email_received
      last_login
      stripe_id
      trial
      active
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const usersByStripe_id = /* GraphQL */ `query UsersByStripe_id(
  $stripe_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByStripe_id(
    stripe_id: $stripe_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstname
      lastname
      email
      phone
      smscode
      last_email_received
      last_login
      stripe_id
      trial
      active
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByStripe_idQueryVariables,
  APITypes.UsersByStripe_idQuery
>;
export const searchUsers = /* GraphQL */ `query SearchUsers(
  $filter: SearchableUserFilterInput
  $sort: [SearchableUserSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableUserAggregationInput]
) {
  searchUsers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      firstname
      lastname
      email
      phone
      smscode
      last_email_received
      last_login
      stripe_id
      trial
      active
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchUsersQueryVariables,
  APITypes.SearchUsersQuery
>;
export const getCpvCodeCode = /* GraphQL */ `query GetCpvCodeCode($id: ID!) {
  getCpvCodeCode(id: $id) {
    id
    cpvCodeId
    cpvId
    cpvCode {
      id
      code
      description
      cpv_category_id
      createdAt
      updatedAt
      __typename
    }
    cpv {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCpvCodeCodeQueryVariables,
  APITypes.GetCpvCodeCodeQuery
>;
export const listCpvCodeCodes = /* GraphQL */ `query ListCpvCodeCodes(
  $filter: ModelCpvCodeCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listCpvCodeCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cpvCodeId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCpvCodeCodesQueryVariables,
  APITypes.ListCpvCodeCodesQuery
>;
export const cpvCodeCodesByCpvCodeId = /* GraphQL */ `query CpvCodeCodesByCpvCodeId(
  $cpvCodeId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCodeCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCodeCodesByCpvCodeId(
    cpvCodeId: $cpvCodeId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvCodeId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCodeCodesByCpvCodeIdQueryVariables,
  APITypes.CpvCodeCodesByCpvCodeIdQuery
>;
export const cpvCodeCodesByCpvId = /* GraphQL */ `query CpvCodeCodesByCpvId(
  $cpvId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCodeCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCodeCodesByCpvId(
    cpvId: $cpvId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvCodeId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCodeCodesByCpvIdQueryVariables,
  APITypes.CpvCodeCodesByCpvIdQuery
>;
export const getCpvCodeCategory = /* GraphQL */ `query GetCpvCodeCategory($id: ID!) {
  getCpvCodeCategory(id: $id) {
    id
    cpvCategoryId
    cpvId
    cpvCategory {
      id
      code
      description
      cpv_class_id
      createdAt
      updatedAt
      __typename
    }
    cpv {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCpvCodeCategoryQueryVariables,
  APITypes.GetCpvCodeCategoryQuery
>;
export const listCpvCodeCategories = /* GraphQL */ `query ListCpvCodeCategories(
  $filter: ModelCpvCodeCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCpvCodeCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cpvCategoryId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCpvCodeCategoriesQueryVariables,
  APITypes.ListCpvCodeCategoriesQuery
>;
export const cpvCodeCategoriesByCpvCategoryId = /* GraphQL */ `query CpvCodeCategoriesByCpvCategoryId(
  $cpvCategoryId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCodeCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCodeCategoriesByCpvCategoryId(
    cpvCategoryId: $cpvCategoryId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvCategoryId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCodeCategoriesByCpvCategoryIdQueryVariables,
  APITypes.CpvCodeCategoriesByCpvCategoryIdQuery
>;
export const cpvCodeCategoriesByCpvId = /* GraphQL */ `query CpvCodeCategoriesByCpvId(
  $cpvId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCodeCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCodeCategoriesByCpvId(
    cpvId: $cpvId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvCategoryId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCodeCategoriesByCpvIdQueryVariables,
  APITypes.CpvCodeCategoriesByCpvIdQuery
>;
export const getCpvCodeClass = /* GraphQL */ `query GetCpvCodeClass($id: ID!) {
  getCpvCodeClass(id: $id) {
    id
    cpvClassId
    cpvId
    cpvClass {
      id
      code
      description
      cpv_group_id
      createdAt
      updatedAt
      __typename
    }
    cpv {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCpvCodeClassQueryVariables,
  APITypes.GetCpvCodeClassQuery
>;
export const listCpvCodeClasses = /* GraphQL */ `query ListCpvCodeClasses(
  $filter: ModelCpvCodeClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listCpvCodeClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cpvClassId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCpvCodeClassesQueryVariables,
  APITypes.ListCpvCodeClassesQuery
>;
export const cpvCodeClassesByCpvClassId = /* GraphQL */ `query CpvCodeClassesByCpvClassId(
  $cpvClassId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCodeClassFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCodeClassesByCpvClassId(
    cpvClassId: $cpvClassId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvClassId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCodeClassesByCpvClassIdQueryVariables,
  APITypes.CpvCodeClassesByCpvClassIdQuery
>;
export const cpvCodeClassesByCpvId = /* GraphQL */ `query CpvCodeClassesByCpvId(
  $cpvId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCodeClassFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCodeClassesByCpvId(
    cpvId: $cpvId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvClassId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCodeClassesByCpvIdQueryVariables,
  APITypes.CpvCodeClassesByCpvIdQuery
>;
export const getCpvCodeGroup = /* GraphQL */ `query GetCpvCodeGroup($id: ID!) {
  getCpvCodeGroup(id: $id) {
    id
    cpvGroupId
    cpvId
    cpvGroup {
      id
      code
      description
      cpv_division_id
      createdAt
      updatedAt
      __typename
    }
    cpv {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCpvCodeGroupQueryVariables,
  APITypes.GetCpvCodeGroupQuery
>;
export const listCpvCodeGroups = /* GraphQL */ `query ListCpvCodeGroups(
  $filter: ModelCpvCodeGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listCpvCodeGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cpvGroupId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCpvCodeGroupsQueryVariables,
  APITypes.ListCpvCodeGroupsQuery
>;
export const cpvCodeGroupsByCpvGroupId = /* GraphQL */ `query CpvCodeGroupsByCpvGroupId(
  $cpvGroupId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCodeGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCodeGroupsByCpvGroupId(
    cpvGroupId: $cpvGroupId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvGroupId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCodeGroupsByCpvGroupIdQueryVariables,
  APITypes.CpvCodeGroupsByCpvGroupIdQuery
>;
export const cpvCodeGroupsByCpvId = /* GraphQL */ `query CpvCodeGroupsByCpvId(
  $cpvId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCodeGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCodeGroupsByCpvId(
    cpvId: $cpvId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvGroupId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCodeGroupsByCpvIdQueryVariables,
  APITypes.CpvCodeGroupsByCpvIdQuery
>;
export const getCpvCodeDivision = /* GraphQL */ `query GetCpvCodeDivision($id: ID!) {
  getCpvCodeDivision(id: $id) {
    id
    cpvDivisionId
    cpvId
    cpvDivision {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    cpv {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCpvCodeDivisionQueryVariables,
  APITypes.GetCpvCodeDivisionQuery
>;
export const listCpvCodeDivisions = /* GraphQL */ `query ListCpvCodeDivisions(
  $filter: ModelCpvCodeDivisionFilterInput
  $limit: Int
  $nextToken: String
) {
  listCpvCodeDivisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cpvDivisionId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCpvCodeDivisionsQueryVariables,
  APITypes.ListCpvCodeDivisionsQuery
>;
export const cpvCodeDivisionsByCpvDivisionId = /* GraphQL */ `query CpvCodeDivisionsByCpvDivisionId(
  $cpvDivisionId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCodeDivisionFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCodeDivisionsByCpvDivisionId(
    cpvDivisionId: $cpvDivisionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvDivisionId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCodeDivisionsByCpvDivisionIdQueryVariables,
  APITypes.CpvCodeDivisionsByCpvDivisionIdQuery
>;
export const cpvCodeDivisionsByCpvId = /* GraphQL */ `query CpvCodeDivisionsByCpvId(
  $cpvId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCodeDivisionFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCodeDivisionsByCpvId(
    cpvId: $cpvId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvDivisionId
      cpvId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCodeDivisionsByCpvIdQueryVariables,
  APITypes.CpvCodeDivisionsByCpvIdQuery
>;
export const getCpvCompany = /* GraphQL */ `query GetCpvCompany($id: ID!) {
  getCpvCompany(id: $id) {
    id
    cpvId
    companyId
    cpv {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    company {
      id
      name
      chamber_of_commerce
      address
      postalcode
      city
      country
      active
      active_subscription
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCpvCompanyQueryVariables,
  APITypes.GetCpvCompanyQuery
>;
export const listCpvCompanies = /* GraphQL */ `query ListCpvCompanies(
  $filter: ModelCpvCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCpvCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cpvId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCpvCompaniesQueryVariables,
  APITypes.ListCpvCompaniesQuery
>;
export const cpvCompaniesByCpvId = /* GraphQL */ `query CpvCompaniesByCpvId(
  $cpvId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCompaniesByCpvId(
    cpvId: $cpvId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCompaniesByCpvIdQueryVariables,
  APITypes.CpvCompaniesByCpvIdQuery
>;
export const cpvCompaniesByCompanyId = /* GraphQL */ `query CpvCompaniesByCompanyId(
  $companyId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCpvCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  cpvCompaniesByCompanyId(
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CpvCompaniesByCompanyIdQueryVariables,
  APITypes.CpvCompaniesByCompanyIdQuery
>;
export const getPhaseCpvCode = /* GraphQL */ `query GetPhaseCpvCode($id: ID!) {
  getPhaseCpvCode(id: $id) {
    id
    cpvId
    phaseId
    cpv {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    phase {
      id
      phase
      title_eng
      country
      cpv_list
      tender_type
      contracting_authority
      dispatch_notice_date
      description
      procedure
      title
      ojs_number
      buyer_city
      email_send
      eu_program_related
      description_eng
      start_date
      end_date
      total_value
      currency
      pdf
      pdf_eng
      tsender
      tsender_link
      date_receipt
      time_receipt
      dispatch_invitations_date
      recurring
      tender_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPhaseCpvCodeQueryVariables,
  APITypes.GetPhaseCpvCodeQuery
>;
export const listPhaseCpvCodes = /* GraphQL */ `query ListPhaseCpvCodes(
  $filter: ModelPhaseCpvCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listPhaseCpvCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cpvId
      phaseId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPhaseCpvCodesQueryVariables,
  APITypes.ListPhaseCpvCodesQuery
>;
export const phaseCpvCodesByCpvId = /* GraphQL */ `query PhaseCpvCodesByCpvId(
  $cpvId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPhaseCpvCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  phaseCpvCodesByCpvId(
    cpvId: $cpvId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvId
      phaseId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PhaseCpvCodesByCpvIdQueryVariables,
  APITypes.PhaseCpvCodesByCpvIdQuery
>;
export const phaseCpvCodesByPhaseId = /* GraphQL */ `query PhaseCpvCodesByPhaseId(
  $phaseId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPhaseCpvCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  phaseCpvCodesByPhaseId(
    phaseId: $phaseId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvId
      phaseId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PhaseCpvCodesByPhaseIdQueryVariables,
  APITypes.PhaseCpvCodesByPhaseIdQuery
>;
export const getTenderCpvCode = /* GraphQL */ `query GetTenderCpvCode($id: ID!) {
  getTenderCpvCode(id: $id) {
    id
    cpvId
    tenderId
    cpv {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    tender {
      id
      title_eng
      country
      tender_type
      current_phase
      contracting_authority
      dispatch_notice_date
      description
      procedure
      title
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTenderCpvCodeQueryVariables,
  APITypes.GetTenderCpvCodeQuery
>;
export const listTenderCpvCodes = /* GraphQL */ `query ListTenderCpvCodes(
  $filter: ModelTenderCpvCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listTenderCpvCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cpvId
      tenderId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTenderCpvCodesQueryVariables,
  APITypes.ListTenderCpvCodesQuery
>;
export const tenderCpvCodesByCpvId = /* GraphQL */ `query TenderCpvCodesByCpvId(
  $cpvId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTenderCpvCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  tenderCpvCodesByCpvId(
    cpvId: $cpvId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvId
      tenderId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TenderCpvCodesByCpvIdQueryVariables,
  APITypes.TenderCpvCodesByCpvIdQuery
>;
export const tenderCpvCodesByTenderId = /* GraphQL */ `query TenderCpvCodesByTenderId(
  $tenderId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTenderCpvCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  tenderCpvCodesByTenderId(
    tenderId: $tenderId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvId
      tenderId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TenderCpvCodesByTenderIdQueryVariables,
  APITypes.TenderCpvCodesByTenderIdQuery
>;
export const getAiCpvs = /* GraphQL */ `query GetAiCpvs($id: ID!) {
  getAiCpvs(id: $id) {
    id
    cpvId
    companyId
    cpv {
      id
      code
      description
      createdAt
      updatedAt
      __typename
    }
    company {
      id
      name
      chamber_of_commerce
      address
      postalcode
      city
      country
      active
      active_subscription
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAiCpvsQueryVariables, APITypes.GetAiCpvsQuery>;
export const listAiCpvs = /* GraphQL */ `query ListAiCpvs(
  $filter: ModelAiCpvsFilterInput
  $limit: Int
  $nextToken: String
) {
  listAiCpvs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cpvId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAiCpvsQueryVariables,
  APITypes.ListAiCpvsQuery
>;
export const aiCpvsByCpvId = /* GraphQL */ `query AiCpvsByCpvId(
  $cpvId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAiCpvsFilterInput
  $limit: Int
  $nextToken: String
) {
  aiCpvsByCpvId(
    cpvId: $cpvId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AiCpvsByCpvIdQueryVariables,
  APITypes.AiCpvsByCpvIdQuery
>;
export const aiCpvsByCompanyId = /* GraphQL */ `query AiCpvsByCompanyId(
  $companyId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAiCpvsFilterInput
  $limit: Int
  $nextToken: String
) {
  aiCpvsByCompanyId(
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cpvId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AiCpvsByCompanyIdQueryVariables,
  APITypes.AiCpvsByCompanyIdQuery
>;
export const getContactAward = /* GraphQL */ `query GetContactAward($id: ID!) {
  getContactAward(id: $id) {
    id
    awardId
    contactId
    award {
      id
      objectDescriptionID
      conclusion_date
      contract_number
      lot_number
      total_value
      currency
      title
      awarded
      number_of_tenders_received
      phase_id
      createdAt
      updatedAt
      __typename
    }
    contact {
      id
      contact_type
      phone
      email
      name
      fax
      contractor_id
      contracting_authority_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContactAwardQueryVariables,
  APITypes.GetContactAwardQuery
>;
export const listContactAwards = /* GraphQL */ `query ListContactAwards(
  $filter: ModelContactAwardFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactAwards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      awardId
      contactId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactAwardsQueryVariables,
  APITypes.ListContactAwardsQuery
>;
export const contactAwardsByAwardId = /* GraphQL */ `query ContactAwardsByAwardId(
  $awardId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContactAwardFilterInput
  $limit: Int
  $nextToken: String
) {
  contactAwardsByAwardId(
    awardId: $awardId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      awardId
      contactId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContactAwardsByAwardIdQueryVariables,
  APITypes.ContactAwardsByAwardIdQuery
>;
export const contactAwardsByContactId = /* GraphQL */ `query ContactAwardsByContactId(
  $contactId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContactAwardFilterInput
  $limit: Int
  $nextToken: String
) {
  contactAwardsByContactId(
    contactId: $contactId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      awardId
      contactId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContactAwardsByContactIdQueryVariables,
  APITypes.ContactAwardsByContactIdQuery
>;
export const getContactPhase = /* GraphQL */ `query GetContactPhase($id: ID!) {
  getContactPhase(id: $id) {
    id
    contactId
    phaseId
    contact {
      id
      contact_type
      phone
      email
      name
      fax
      contractor_id
      contracting_authority_id
      createdAt
      updatedAt
      __typename
    }
    phase {
      id
      phase
      title_eng
      country
      cpv_list
      tender_type
      contracting_authority
      dispatch_notice_date
      description
      procedure
      title
      ojs_number
      buyer_city
      email_send
      eu_program_related
      description_eng
      start_date
      end_date
      total_value
      currency
      pdf
      pdf_eng
      tsender
      tsender_link
      date_receipt
      time_receipt
      dispatch_invitations_date
      recurring
      tender_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContactPhaseQueryVariables,
  APITypes.GetContactPhaseQuery
>;
export const listContactPhases = /* GraphQL */ `query ListContactPhases(
  $filter: ModelContactPhaseFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactPhases(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      contactId
      phaseId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactPhasesQueryVariables,
  APITypes.ListContactPhasesQuery
>;
export const contactPhasesByContactId = /* GraphQL */ `query ContactPhasesByContactId(
  $contactId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContactPhaseFilterInput
  $limit: Int
  $nextToken: String
) {
  contactPhasesByContactId(
    contactId: $contactId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contactId
      phaseId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContactPhasesByContactIdQueryVariables,
  APITypes.ContactPhasesByContactIdQuery
>;
export const contactPhasesByPhaseId = /* GraphQL */ `query ContactPhasesByPhaseId(
  $phaseId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContactPhaseFilterInput
  $limit: Int
  $nextToken: String
) {
  contactPhasesByPhaseId(
    phaseId: $phaseId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contactId
      phaseId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContactPhasesByPhaseIdQueryVariables,
  APITypes.ContactPhasesByPhaseIdQuery
>;
export const getCompanyPhase = /* GraphQL */ `query GetCompanyPhase($id: ID!) {
  getCompanyPhase(id: $id) {
    id
    phaseId
    companyId
    phase {
      id
      phase
      title_eng
      country
      cpv_list
      tender_type
      contracting_authority
      dispatch_notice_date
      description
      procedure
      title
      ojs_number
      buyer_city
      email_send
      eu_program_related
      description_eng
      start_date
      end_date
      total_value
      currency
      pdf
      pdf_eng
      tsender
      tsender_link
      date_receipt
      time_receipt
      dispatch_invitations_date
      recurring
      tender_id
      createdAt
      updatedAt
      __typename
    }
    company {
      id
      name
      chamber_of_commerce
      address
      postalcode
      city
      country
      active
      active_subscription
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyPhaseQueryVariables,
  APITypes.GetCompanyPhaseQuery
>;
export const listCompanyPhases = /* GraphQL */ `query ListCompanyPhases(
  $filter: ModelCompanyPhaseFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanyPhases(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      phaseId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyPhasesQueryVariables,
  APITypes.ListCompanyPhasesQuery
>;
export const companyPhasesByPhaseId = /* GraphQL */ `query CompanyPhasesByPhaseId(
  $phaseId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyPhaseFilterInput
  $limit: Int
  $nextToken: String
) {
  companyPhasesByPhaseId(
    phaseId: $phaseId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      phaseId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyPhasesByPhaseIdQueryVariables,
  APITypes.CompanyPhasesByPhaseIdQuery
>;
export const companyPhasesByCompanyId = /* GraphQL */ `query CompanyPhasesByCompanyId(
  $companyId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyPhaseFilterInput
  $limit: Int
  $nextToken: String
) {
  companyPhasesByCompanyId(
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      phaseId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyPhasesByCompanyIdQueryVariables,
  APITypes.CompanyPhasesByCompanyIdQuery
>;
export const getCompanyTender = /* GraphQL */ `query GetCompanyTender($id: ID!) {
  getCompanyTender(id: $id) {
    id
    tenderId
    companyId
    tender {
      id
      title_eng
      country
      tender_type
      current_phase
      contracting_authority
      dispatch_notice_date
      description
      procedure
      title
      createdAt
      updatedAt
      __typename
    }
    company {
      id
      name
      chamber_of_commerce
      address
      postalcode
      city
      country
      active
      active_subscription
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyTenderQueryVariables,
  APITypes.GetCompanyTenderQuery
>;
export const listCompanyTenders = /* GraphQL */ `query ListCompanyTenders(
  $filter: ModelCompanyTenderFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanyTenders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenderId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyTendersQueryVariables,
  APITypes.ListCompanyTendersQuery
>;
export const companyTendersByTenderId = /* GraphQL */ `query CompanyTendersByTenderId(
  $tenderId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyTenderFilterInput
  $limit: Int
  $nextToken: String
) {
  companyTendersByTenderId(
    tenderId: $tenderId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenderId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyTendersByTenderIdQueryVariables,
  APITypes.CompanyTendersByTenderIdQuery
>;
export const companyTendersByCompanyId = /* GraphQL */ `query CompanyTendersByCompanyId(
  $companyId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyTenderFilterInput
  $limit: Int
  $nextToken: String
) {
  companyTendersByCompanyId(
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenderId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyTendersByCompanyIdQueryVariables,
  APITypes.CompanyTendersByCompanyIdQuery
>;
export const getCompanyUser = /* GraphQL */ `query GetCompanyUser($id: ID!) {
  getCompanyUser(id: $id) {
    id
    companyId
    userId
    company {
      id
      name
      chamber_of_commerce
      address
      postalcode
      city
      country
      active
      active_subscription
      createdAt
      updatedAt
      owner
      __typename
    }
    user {
      id
      firstname
      lastname
      email
      phone
      smscode
      last_email_received
      last_login
      stripe_id
      trial
      active
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyUserQueryVariables,
  APITypes.GetCompanyUserQuery
>;
export const listCompanyUsers = /* GraphQL */ `query ListCompanyUsers(
  $filter: ModelCompanyUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanyUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      companyId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyUsersQueryVariables,
  APITypes.ListCompanyUsersQuery
>;
export const companyUsersByCompanyId = /* GraphQL */ `query CompanyUsersByCompanyId(
  $companyId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyUserFilterInput
  $limit: Int
  $nextToken: String
) {
  companyUsersByCompanyId(
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyUsersByCompanyIdQueryVariables,
  APITypes.CompanyUsersByCompanyIdQuery
>;
export const companyUsersByUserId = /* GraphQL */ `query CompanyUsersByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyUserFilterInput
  $limit: Int
  $nextToken: String
) {
  companyUsersByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyUsersByUserIdQueryVariables,
  APITypes.CompanyUsersByUserIdQuery
>;
