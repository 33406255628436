import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }: any) => (
    <div className="overflow-hidden pb-[56.25%] relative h-0">
        <iframe
            className="left-0 top-0 h-full w-full absolute rounded-lg"
            width="653"
            height="280"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
