import { RouteType } from "../../routes/config";
import "../../fontawesome";
import { Link } from "react-router-dom";
import { useState } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
    item: RouteType;
    toggleModal: () => void;
    path: string;
    setPath: (e: string) => void;
    subscription: boolean;
};

const SidebarItem = ({ item, subscription, path, setPath }: Props) => {
    return item.sidebarProps ? (
        <Link
            className={clsx(
                item.sidebarProps.premium && !subscription && "opacity-50",
                "grid grid-flow-col auto-cols-max gap-2 p-3 cursor-pointer group relative hover:text-primary-pink",
                path === item.path && "border-l-2 border-primary-pink"
            )}
            to={
                item.sidebarProps.premium && !subscription
                    ? "/premium"
                    : item.path
            }
            onClick={() => setPath(item.path)}
        >
            {item.sidebarProps.icon && (
                <div
                    className={clsx(
                        "flex items-center text-primary-blue text-xl",
                        path === item.path && "!text-primary-pink"
                    )}
                >
                    {item.sidebarProps.icon}
                </div>
            )}
            <span
                className={clsx(
                    path === item.path && "text-primary-pink",
                    "text-sm"
                )}
            >
                {item.sidebarProps.displayText}
            </span>
            {item.sidebarProps.premium && !subscription && (
                <span className="absolute w-full h-full justify-center items-center scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 flex">
                    <FontAwesomeIcon
                        className="mr-3 drop-shadow-xl animate-bounce"
                        color="#ffd700"
                        icon="crown"
                    />
                    <p className="font-bold">Premium</p>
                </span>
            )}
        </Link>
    ) : null;
};

export default SidebarItem;
