import { Button } from "../../Button";
import Lottie from "lottie-react";
import thumbsUpAnimation from "../../../lottie/Animation - 1701770150315.json";
import { useState } from "react";

interface Props {
    onClose: () => void;
    selection: any[];
}

export default function SuccessOnboarding({ onClose, selection }: Props) {
    return (
        <div className="flex pl-14 h-[100%]">
            <div className="w-[100%] h-[620px] bg-top bg-left flex items-end justify-end bg-cover bg-[url('/public/images/onboarding-roadmap-2.gif')]"></div>
            <div className="h-[100%] items-end justify-center flex flex-col">
                <Lottie
                    loop={false}
                    animationData={thumbsUpAnimation}
                    className="w-80"
                />
                <div className="flex p-10 mt-40">
                    <Button onClick={onClose} type="pink">
                        Afronden
                    </Button>
                </div>
            </div>
        </div>
    );
}
