import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface BlogPostsProps {
    posts: any[];
}

const BlogPosts = ({ posts }: BlogPostsProps) => {
    const getRandomPosts = (count: number) => {
        const shuffledPosts = posts.sort(() => 0.5 - Math.random());
        return shuffledPosts.slice(0, count);
    };
    const renderPosts = () => {
        const randomPosts = getRandomPosts(5);
        return (
            <div className="mt-2">
                {randomPosts.map((post, index) => (
                    <div
                        key={post.id}
                        className={`bg-white flex rounded-lg post p-2 cursor-pointer hover:shadow hover:underline ${
                            index !== randomPosts.length - 1 ? "mb-1" : ""
                        }`}
                        onClick={() =>
                            window.open(post.guid.rendered, "_blank")
                        }
                    >
                        <FontAwesomeIcon
                            className="text-[15px] text-primary-blue p-1"
                            icon={["far", "lightbulb"]}
                        />
                        <div className="w-full">
                            <h3
                                className="text-sm font-bold truncate text-center flex items-center"
                                dangerouslySetInnerHTML={{
                                    __html: `${post.title.rendered}`,
                                }}
                            />
                            <div className="flex text-xs w-[93%]">
                                <p
                                    className="truncate"
                                    dangerouslySetInnerHTML={{
                                        __html: `${post.excerpt.rendered}`,
                                    }}
                                />
                                <p>...</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };
    return <div className="blog-posts">{renderPosts()}</div>;
};

export default BlogPosts;
