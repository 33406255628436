import { useEffect, useState } from "react";

interface ProcedureChartProps {
    data: any;
    colorPalette: any;
}

export function CPVChart({ data, colorPalette }: ProcedureChartProps) {
    const [typeData, setTypeData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        handleData();
    }, []);

    async function handleData() {
        const output = Object.entries(
            data.reduce((prev: any, label: any) => {
                prev[label["Hoofd cpv definitie omschrijving"]] = prev[
                    label["Hoofd cpv definitie omschrijving"]
                ]
                    ? prev[label["Hoofd cpv definitie omschrijving"]] + 1
                    : 1;
                return prev;
            }, {})
        )
            .map(([name, Aanbestedingen]) => ({ name, Aanbestedingen }))
            .sort((a: any, b: any) => b.Aanbestedingen - a.Aanbestedingen);

        const filteredOutput = output.filter(
            (element) => element.name !== "undefined"
        );

        setTypeData(filteredOutput.slice(0, 5));

        setLoading(false);
    }

    return (
        <div className="flex-col items-center justify-items overflow-y-auto h-[250px] w-full">
            <div className="grid grid-cols-4 px-3">
                <p className="col-span-3 pl-2">CPV omschrijving</p>
                <p className="text-center">Aantal</p>
            </div>
            {typeData.map((cpv: any, index: any) => (
                <div
                    key={index}
                    className="border-b p-3 grid grid-cols-4 pl-5 items-center justify-items"
                    style={{ borderColor: colorPalette[index] }}
                >
                    <p className="text-sm col-span-3">{cpv.name}</p>
                    <p className="text-lg text-center">{cpv.Aanbestedingen}</p>
                </div>
            ))}
        </div>
    );
}
