// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// interface Props {
//     header?: boolean;
// }

// const PremiumComp = ({ header = true }: Props) => {
//     return (
//         <div className="w-full h-full shadow-md rounded-lg overflow-hidden">
//             <div className="overflow-x-hidden">
//                 <table className="w-full table-auto bg-white">
//                     {header && (
//                         <thead>
//                             <tr className="bg-primary-blue text-white ">
//                                 <th className="py-2 px-4 text-left rounded-tl-lg"></th>
//                                 <th className="py-2 px-4 text-center">
//                                     Gratis
//                                 </th>
//                                 <th className="py-2 px-4 text-center">
//                                     Premium
//                                 </th>
//                             </tr>
//                         </thead>
//                     )}
//                     <tbody>
//                         <tr className="border-b bg-gray-100">
//                             <td className="py-2 px-4">
//                                 Volledig toegang tot het Tendertracer dashboard
//                             </td>
//                             <td className="py-2 px-4 text-center">
//                                 <FontAwesomeIcon
//                                     icon={"circle-check"}
//                                     className="text-primary-blue"
//                                 />
//                             </td>
//                             <td className="py-2 px-4 text-center">
//                                 <FontAwesomeIcon
//                                     icon={"circle-check"}
//                                     className="text-primary-blue"
//                                 />
//                             </td>
//                         </tr>
//                         <tr className="border-b">
//                             <td className="py-2 px-4">
//                                 Inzicht in alle (Europeese) aanbestedingen
//                             </td>
//                             <td className="py-2 px-4 text-center">
//                                 <FontAwesomeIcon
//                                     icon={"circle-check"}
//                                     className="text-primary-blue"
//                                 />
//                             </td>
//                             <td className="py-2 px-4 text-center">
//                                 <FontAwesomeIcon
//                                     icon={"circle-check"}
//                                     className="text-primary-blue"
//                                 />
//                             </td>
//                         </tr>
//                         <tr className="border-b bg-gray-100">
//                             <td className="py-2 px-4">
//                                 2x per week een signaleringsmail
//                             </td>
//                             <td className="py-2 px-4 text-center">
//                                 <FontAwesomeIcon
//                                     icon={"circle-check"}
//                                     className="text-primary-blue"
//                                 />
//                             </td>
//                             <td className="py-2 px-4 text-center">
//                                 <FontAwesomeIcon
//                                     icon={"circle-check"}
//                                     className="text-primary-blue"
//                                 />
//                             </td>
//                         </tr>
//                         <tr className="border-b">
//                             <td className="py-2 px-4">
//                                 Basis concurrentieanalyse
//                             </td>
//                             <td className="py-2 px-4 text-center">
//                                 <FontAwesomeIcon
//                                     icon={"circle-check"}
//                                     className="text-primary-blue"
//                                 />
//                             </td>
//                             <td className="py-2 px-4 text-center">
//                                 <FontAwesomeIcon
//                                     icon={"circle-check"}
//                                     className="text-primary-blue"
//                                 />
//                             </td>
//                         </tr>
//                         <tr className="border-b bg-gray-100">
//                             <td className="py-2 px-4">
//                                 Aanbestedingen gefilterd naar bedrijfs- en
//                                 Branchespecifieke
//                             </td>
//                             <td className="py-2 px-4 text-center"></td>
//                             <td className="py-2 px-4 text-center">
//                                 <FontAwesomeIcon
//                                     icon={"circle-check"}
//                                     className="text-primary-pink"
//                                 />
//                             </td>
//                         </tr>
//                         <tr className="border-b">
//                             <td className="py-2 px-4">
//                                 Uitgebreide concurrentieanalyse
//                             </td>
//                             <td className="py-2 px-4 text-center"></td>
//                             <td className="py-2 px-4 text-center">
//                                 <FontAwesomeIcon
//                                     icon={"circle-check"}
//                                     className="text-primary-pink"
//                                 />
//                             </td>
//                         </tr>
//                         <tr className="border-b bg-gray-100">
//                             <td className="py-2 px-4">
//                                 Opmaat ingericht account
//                             </td>
//                             <td className="py-2 px-4 text-center"></td>
//                             <td className="py-2 px-4 text-center">
//                                 <FontAwesomeIcon
//                                     icon={"circle-check"}
//                                     className="text-primary-pink"
//                                 />
//                             </td>
//                         </tr>
//                         <tr className="border-b">
//                             <td className="py-2 px-4">
//                                 Bedrijfsspecifieke emails
//                             </td>
//                             <td className="py-2 px-4 text-center"></td>
//                             <td className="py-2 px-4 text-center">
//                                 <FontAwesomeIcon
//                                     icon={"circle-check"}
//                                     className="text-primary-pink"
//                                 />
//                             </td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     );
// };

// export default PremiumComp;
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    header?: boolean;
}

const PremiumComp = ({ header = true }: Props) => {
    return (
        <div className="w-full shadow-md rounded-lg overflow-hidden">
            <div className="overflow-x-hidden">
                <table className="w-full table-auto bg-white">
                    {header && (
                        <thead>
                            <tr className="bg-primary-blue text-white ">
                                <th className="py-2 px-4 text-left rounded-tl-lg"></th>
                                <th className="py-2 px-4 text-center">
                                    Gratis
                                </th>
                                <th className="py-2 px-4 text-center">
                                    Premium
                                </th>
                            </tr>
                        </thead>
                    )}
                    <tbody>
                        <tr className="border-b bg-gray-100">
                            <td className="py-2 px-4">
                                Volledig toegang tot het Tendertracer dashboard
                            </td>
                            <td className="py-2 px-4 text-center">
                                <FontAwesomeIcon
                                    icon={"circle-check"}
                                    className="text-primary-blue"
                                />
                            </td>
                            <td className="py-2 px-4 text-center">
                                <FontAwesomeIcon
                                    icon={"circle-check"}
                                    className="text-primary-blue"
                                />
                            </td>
                        </tr>
                        <tr className="border-b">
                            <td className="py-2 px-4">
                                Inzicht in alle (Europese) aanbestedingen
                            </td>
                            <td className="py-2 px-4 text-center">
                                <FontAwesomeIcon
                                    icon={"circle-check"}
                                    className="text-primary-blue"
                                />
                            </td>
                            <td className="py-2 px-4 text-center">
                                <FontAwesomeIcon
                                    icon={"circle-check"}
                                    className="text-primary-blue"
                                />
                            </td>
                        </tr>
                        <tr className="border-b bg-gray-100">
                            <td className="py-2 px-4">
                                Signaleringsmail
                            </td>
                            <td className="py-2 px-4 text-center">
                                2x per week
                            </td>
                            <td className="py-2 px-4 text-center">
                                2x per week
                            </td>
                        </tr>
                        <tr className="border-b">
                            <td className="py-2 px-4">
                                Concurrentieanalyse
                            </td>
                            <td className="py-2 px-4 text-center">
                                Basis
                            </td>
                            <td className="py-2 px-4 text-center">
                                Uitgebreid
                            </td>
                        </tr>
                        <tr className="border-b bg-gray-100">
                            <td className="py-2 px-4">
                                Voor u geselecteerde aanbestedingen
                            </td>
                            <td className="py-2 px-4 text-center"></td>
                            <td className="py-2 px-4 text-center">
                                <FontAwesomeIcon
                                    icon={"circle-check"}
                                    className="text-primary-pink"
                                />
                            </td>
                        </tr>
                        <tr className="border-b">
                            <td className="py-2 px-4">
                                Internationale aanbestedingen
                            </td>
                            <td className="py-2 px-4 text-center"></td>
                            <td className="py-2 px-4 text-center">
                                <FontAwesomeIcon
                                    icon={"circle-check"}
                                    className="text-primary-pink"
                                />
                            </td>
                        </tr>
                        <tr className="border-b bg-gray-100">
                            <td className="py-2 px-4">
                                Hulp bij inschrijven
                            </td>
                            <td className="py-2 px-4 text-center"></td>
                            <td className="py-2 px-4 text-center">
                                <FontAwesomeIcon
                                    icon={"circle-check"}
                                    className="text-primary-pink"
                                />
                            </td>
                        </tr>
                        <tr className="border-b">
                            <td className="py-2 px-4">
                                Door AI gestuurde CPV-codes
                            </td>
                            <td className="py-2 px-4 text-center"></td>
                            <td className="py-2 px-4 text-center">
                                <FontAwesomeIcon
                                    icon={"circle-check"}
                                    className="text-primary-pink"
                                />
                            </td>
                        </tr>
                        <tr className="border-b bg-gray-100">
                            <td className="py-2 px-4">
                                Gepersonaliseerde e-mailmeldingen
                            </td>
                            <td className="py-2 px-4 text-center"></td>
                            <td className="py-2 px-4 text-center">
                                <FontAwesomeIcon
                                    icon={"circle-check"}
                                    className="text-primary-pink"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PremiumComp;
