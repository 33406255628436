import { FormEvent, useEffect, useState } from "react";
import Select from "react-select";

interface KvkInputProps {
    onClick: (comp: any) => void;
}

const CustomOption = ({ innerProps, data }: any) => {
    return (
        <>
            {data.type !== "rechtspersoon" && (
                <div
                    className="p-3 hover:bg-primary-pink hover:bg-opacity-20 hover:cursor-pointer"
                    {...innerProps}
                >
                    <div className="text-sm">
                        <strong>{data?.naam}</strong>
                    </div>
                    <div className="opacity-50 text-xs flex">
                        <p className="w-40">{data?.adres?.binnenlandsAdres?.plaats?.toUpperCase()}</p>
                        <p>KVK: {data?.kvkNummer}</p>
                    </div>
                </div>
            )}
        </>
    );
};

const LoadingMessage = (props: any) => {
    return (
        <div className="p-3 flex items-center justify-center" {...props}>
            Aan het laden...
        </div>
    );
};

const NoOptionsMessage = (props: any) => {
    return (
        <div className="p-3 flex items-center justify-center" {...props}>
            Start met typen om te zoeken
        </div>
    );
};

export const KvkInput = ({ onClick }: KvkInputProps) => {
    const [inputText, setinputText] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [kvkData, setKvkData] = useState<any[]>([]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(async () => {
            await fetchSearchKvk();
            setLoading(false);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [inputText]);

    const fetchSearchKvk = async () => {
        var requestOptions: any = {
            method: "GET",
            redirect: "follow",
        };

        if (inputText !== "") {
            await fetch(
                `https://o7yeoyy2qzrq664fktfp2uyg6m0ilgvj.lambda-url.eu-west-3.on.aws/?key=${inputText.replace(
                    /\s/g,
                    "%20"
                )}`,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    const formattedData = result["resultaten"].map(
                        (item: any) => ({
                            value: item.kvkNummer,
                            label: item.naam,
                            ...item,
                        })
                    );
                    setKvkData(formattedData);
                })
                .catch((e) => console.log(e));
        }
    };

    return (
        <Select
            className="text-sm"
            options={kvkData}
            isLoading={loading}
            placeholder="Vul hier je bedrijfsnaam of kvk-nummer in"
            components={{
                Option: CustomOption,
                LoadingMessage: LoadingMessage,
                NoOptionsMessage: NoOptionsMessage,
            }}
            onInputChange={(value, actionMeta) => {
                if (actionMeta.action === "input-change") {
                    setinputText(value);
                }
            }}
            onChange={(comp: any) => onClick(comp)}
            styles={{
                control: (provided:any, state:any) => ({
                    ...provided,
                    borderColor: state.isFocused
                        ? "#00aaaa"
                        : provided.borderColor,
                    boxShadow: state.isFocused ? "0 0 0 1px #00aaaa" : "blue",
                    "&:hover": {
                        borderColor: state.isFocused ? "#00aaaa" : "#00aaaa",
                    },
                }),
            }}
        />
    );
};
