import { API, Auth, graphqlOperation } from "aws-amplify";
import { LoadingDocument } from "../../../../components/LoadingDocument";
import { TenderItem } from "../../TenderItemOverview";
import { useLocation } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "../../../../components/Spinner";
import { InputSearchFilter } from "../../../../components/InputSearchFilter";
import ReactSelect from "../../../../components/Selector";
import { translatePhase } from "../../TenderOverview";
import { sendGAEvent } from "../../../../utils/analytics";
import { send } from "@emailjs/browser";


export default function CompanyTenders() {
    const location = useLocation();
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const urlPublication = urlParams.get("publication");
    const urlInternationalPublication = urlParams.get("internationalPublication");
    const urlType = urlParams.get("type");
    const urlKeyword = urlParams.get("keyword");
    const urlSort = urlParams.get("sort");
    const [aiPhases, setAiPhases] = useState<any[]>([]);
    const [aiCPVcodes, setAiCPVcodes] = useState<any[]>([]);
    const [favorites, setFavoriteTendersIDs] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [loadingNext, setLoadingNext] = useState(false);
    const [firstRender, setFirstRender] = useState(true);
    const [loadingKeyword, setLoadingKeyword] = useState(false);
    const [country, setCountry] = useState<string>("NL");
    const resetFilters = () =>{
        setPublication("");
        setInternationalPublication("");
        setType("");
        setSort("desc");
        setKeyword("");
    };
    const [companyID, setCompanyID] = useState<string>("");
    const [publication, setPublication] = useState<string>(
        urlPublication ? urlPublication : ""
    );
    const [internationalPublication, setInternationalPublication] = useState<string>(
        urlInternationalPublication ? urlInternationalPublication : ""
    );
    const [type, setType] = useState<string>(urlType ? urlType : "");
    const [nextToken, setNextToken] = useState<string>("");
    const [keyword, setKeyword] = useState<string>(
        urlKeyword ? urlKeyword : ""
    );
    const [sort, setSort] = useState<string>(urlSort ? urlSort : "desc");
    const [length, setLength] = useState<number>(0);


    useEffect(() => {
        sendGAEvent('view_company_tenders', {
            country: country,
            publication: publication || internationalPublication,
            type: type,
            sort: sort
        });
    }, []);

    useEffect(() => {
        //prettier-ignore
        window.history.replaceState(null, "", `?${publication !== "" ? `publication=${publication}&` : ""}${internationalPublication !== "" ? `internationalPublication=${internationalPublication}&` : ""}${country !== "" ? `country=${country}&` : ""}${type !== "" ? `type=${type}&` : ""}${sort !== "" ? `sort=${sort}&` : ""}${keyword !== "" ? `keyword=${keyword}&` : ""}`);
        if (firstRender) {
            fetchRelevantTenders("");
            setFirstRender(false);
        } else {
            setLoadingKeyword(true);
            const delayDebounceFn = setTimeout(() => {
                fetchRelevantTenders("");
                setLoadingKeyword(false);
            }, 1000);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [publication, internationalPublication, type, keyword, sort, country]);


    useEffect(() => {
        resetFilters();
    }, [country]);

    // useEffect(() => {
    //     window.history.replaceState(null, "", `?`);
    // }, []);

    async function fetchRelevantTenders(token: string) {
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });

        setLoadingNext(true);

        //prettier-ignore
        const TenderQuery = `query MyQuery { getUser(id: "${attributes.sub}") { active companies { items { company { id aiCPV { items { cpv { code description } } } saved_tenders { items { tender { id country } } } } } } } }`;

        try {
            const tenderData: any = await API.graphql(
                graphqlOperation(TenderQuery)
            );

            //Favorites
            const favoriteTenders =
                tenderData.data.getUser.companies.items[0].company.saved_tenders
                    .items;

            const favoriteTenderItems: any[] = [];

            favoriteTenders.map((tenders: any) =>
                favoriteTenderItems.push(tenders.tender.id)
            );

            setFavoriteTendersIDs(favoriteTenderItems);
            // ---------------------------------------------------------------------------

            //AI CPVs

            const aiCPVcodes: any = { or: [] };

            const aiCPVs =
                tenderData.data.getUser.companies.items[0].company.aiCPV.items;

            await aiCPVs.map((ai: any) =>
                aiCPVcodes.or.push({
                    cpv_list: { wildcard: `*${ai.cpv.code}*` },
                })
            );

            if (aiCPVcodes.or.length > 0) {
                try {
                    //prettier-ignore
                    const AiTenderQuery = `query MyQuery { searchPhases(sort: {field: dispatch_notice_date, direction: ${sort}}, limit: 20, ${ token !== "" ? `nextToken: "${token}",` : "" } filter: { ${ keyword !== "" ? `description: {match: "${keyword}"}` : "" } ${country !== "" && country !== "NL" ? `country: {eq: "${country}"}` : ""}
                    ${country === "NL" ? `country: {exists: false}` : ""} ${ publication !== "" ? `phase: {matchPhrase: "${publication}"}` : "" }${internationalPublication !== "" ? `phase: {matchPhrase: "${internationalPublication}"}` : ""} ${ type !== "" ? `tender_type: {eq: "${type}"}` : "" } ${JSON.stringify(aiCPVcodes).replace(/"([^"]+)":/g, "$1:").substring(1).slice(0, -1)}}) { items { id title dispatch_notice_date date_receipt time_receipt procedure tender_id country winner_name description tender_type contracting_authority phase total_value cpv_list tsender contacts { items { contact { contracting_authority_id contact_type contractor_id } } } } nextToken } }`;

                    const aiTenderData: any = await API.graphql(
                        graphqlOperation(AiTenderQuery)
                    );
                    if (token === "") {
                        setAiPhases(aiTenderData.data.searchPhases.items);
                        setNextToken(aiTenderData.data.searchPhases.nextToken);
                        setLength(aiTenderData.data.searchPhases.items.length);
                    } else {
                        setAiPhases([
                            ...aiPhases,
                            ...aiTenderData.data.searchPhases.items,
                        ]);
                        setNextToken(aiTenderData.data.searchPhases.nextToken);
                        setLength(aiTenderData.data.searchPhases.items.length);
                    }
                } catch (aiTenderData: any) {
                    if (token === "") {
                        setAiPhases(aiTenderData.data.searchPhases.items);
                        setNextToken(aiTenderData.data.searchPhases.nextToken);
                        setLength(aiTenderData.data.searchPhases.items.length);
                    } else {
                        setAiPhases([
                            ...aiPhases,
                            ...aiTenderData.data.searchPhases.items,
                        ]);
                        setNextToken(aiTenderData.data.searchPhases.nextToken);
                        setLength(aiTenderData.data.searchPhases.items.length);
                    }
                }
            }

            setAiCPVcodes(aiCPVcodes.or);

            // ---------------------------------------------------------------------------

            setCompanyID(tenderData.data.getUser.companies.items[0].company.id);
            setLoading(false);
            setLoadingNext(false);

            sendGAEvent('fetch_relevant_tenders', {
                success: true,
                tender_count: aiPhases.length,
                country: country,
                publication: publication || internationalPublication,
                type: type,
                sort: sort
            });
        } catch (tenderData: any) {
            //Favorites
            const favoriteTenders =
                tenderData.data.getUser.companies.items[0].company.saved_tenders
                    .items;

            const favoriteTenderItems: any[] = [];

            favoriteTenders.map((tenders: any) =>
                favoriteTenderItems.push(tenders.tender.id)
            );

            setFavoriteTendersIDs(favoriteTenderItems);
            // ---------------------------------------------------------------------------

            //AI CPVs

            const aiCPVcodes: any = { or: [] };

            const aiCPVs =
                tenderData.data.getUser.companies.items[0].company.aiCPV.items;

            await aiCPVs.map((ai: any) =>
                aiCPVcodes.or.push({
                    cpv_list: { wildcard: `*${ai.cpv.code}*` },
                })
            );

            if (aiCPVcodes.or.length > 0) {
                try {
                    //prettier-ignore
                    const AiTenderQuery = `query MyQuery { searchPhases(sort: {field: dispatch_notice_date, direction: ${sort}}, limit: 20, ${token !== "" ? `nextToken: "${token}",` : ""} filter: { ${keyword !== "" ? `description: {match: "${keyword}"}` : ""} ${publication !== "" ? `phase: {match: "${publication}"}` : ""}${internationalPublication !== "" ? `phase: {match: "${internationalPublication}"}` : ""}${type !== "" ? `tender_type: {eq: "${type}"}` : ""} ${JSON.stringify(aiCPVcodes).replace(/"([^"]+)":/g, '$1:').substring(1).slice(0, -1)}}) { items { id title dispatch_notice_date date_receipt time_receipt procedure tender_id description tender_type contracting_authority phase total_value cpv_list tsender contacts { items { contact { contracting_authority_id contact_type contractor_id } } } } nextToken } }`;

                    const aiTenderData: any = await API.graphql(
                        graphqlOperation(AiTenderQuery)
                    );
                    if (token === "") {
                        setAiPhases(aiTenderData.data.searchPhases.items);
                        setNextToken(aiTenderData.data.searchPhases.nextToken);
                        setLength(aiTenderData.data.searchPhases.items.length);
                    } else {
                        setAiPhases([
                            ...aiPhases,
                            ...aiTenderData.data.searchPhases.items,
                        ]);
                        setNextToken(aiTenderData.data.searchPhases.nextToken);
                        setLength(aiTenderData.data.searchPhases.items.length);
                    }
                } catch (aiTenderData: any) {
                    if (token === "") {
                        setAiPhases(aiTenderData.data.searchPhases.items);
                        setNextToken(aiTenderData.data.searchPhases.nextToken);
                        setLength(aiTenderData.data.searchPhases.items.length);
                    } else {
                        setAiPhases([
                            ...aiPhases,
                            ...aiTenderData.data.searchPhases.items,
                        ]);
                        setNextToken(aiTenderData.data.searchPhases.nextToken);
                        setLength(aiTenderData.data.searchPhases.items.length);
                    }
                }
            }

            setAiCPVcodes(aiCPVcodes.or);

            // ---------------------------------------------------------------------------

            setCompanyID(tenderData.data.getUser.companies.items[0].company.id);
            setLoading(false);
            setLoadingNext(false);
        }
    }

    return (
        <section className="h-full w-full flex flex-col overflow-y-hidden overflow-x-hidden">
            <div className="h-10 w-full px-5 mt-1 mb-3 grid grid-cols-5 z-10 gap-4">
                <InputSearchFilter
                    placeholder="Voer hier je trefwoord in..."
                    value={keyword}
                    loading={loadingKeyword}
                    onChange={(e: FormEvent<HTMLInputElement>) => {
                        const newKeyword = e.currentTarget.value.toString();
                        console.log('Keyword changed:', newKeyword);
                        setKeyword(newKeyword);
                        sendGAEvent('company_tenders_search', {
                            keyword: newKeyword
                        });
                        
                    }}
                />
                {(country =="NL" || country == "") && (
                <div className="w-full h-full">
                    {/* prettier-ignore */}
                    <ReactSelect
                        key={`nl-publication-${country}`}
                        advancedLabel={false}
                        defaultValue={publication}
                        defaultLabel={publication === "" ? "Alle publicaties" : publication}
                        data={[
                            { label: "Alle publicaties", value: "" },
                            { label: "Marktconsultatie", value: "Marktconsultatie"},
                            { label: "Vooraankondiging", value: "Vooraankondiging" },
                            { label: "Aankondiging van een opdracht", value: "Aankondiging van een opdracht" },
                            { label: "Rectificatie", value: "Rectificatie" },
                            { label: "Aankondiging van een gegunde opdracht", value: "Aankondiging van een gegunde opdracht" },
                            { label: "Aankondiging van een wijziging", value: "Aankondiging van een wijziging" },
                        ]}
                        placeholder="Selecteer een publicatie"
                        multi={false}
                        onChange={(event: any) => {
                            setPublication(event.value);
                            sendGAEvent('company_tenders_filter_change', {
                                filter_type: "publication",
                                filter_value: event.value
                        });
                        }}
                    />
                </div>
                )}
                {(country !== "NL" && country !== "") && (
                <div className="w-full h-full">
                    {/* prettier-ignore */}
                    <ReactSelect
                        key={`int-publication-${country}`}
                        advancedLabel={false}
                        defaultValue={translatePhase(internationalPublication)}
                        defaultLabel={translatePhase(internationalPublication) === "" ? "Alle internationale publicaties" : translatePhase(internationalPublication)}
                        data={[
                            { label: "Alle internationale publicaties", value: "" },
                            { label: "Aankondiging van de bekendmaking van een vooraankondiging of een periodieke indicatieve aankondiging via een kopersprofiel", value: "pin-buyer" },
                            { label: "Vooraankondiging of periodieke indicatieve aankondiging die alleen ter informatie wordt gebruikt", value: "pin-only" },
                            { label: "Vooraankondiging of periodieke indicatieve aankondiging gebruikt als oproep tot mededinging – lichte regeling", value: "pin-cfc-social" },
                            { label: "Vooraankondiging of periodieke indicatieve aankondiging gebruikt als oproep tot mededinging – standaardregeling", value: "pin-cfc-standard" },
                            { label: "Vooraankondiging of periodieke indicatieve aankondiging gebruikt om termijnen voor de ontvangst van inschrijvingen in te korten", value: "pin-rtl" },
                            { label: "Vooraankondiging voor openbaar personenvervoer", value: "pin-tran" },
                            { label: "Aankondiging van een opdracht of concessie – standaardregeling", value: "cn-standard" },
                            { label: "Aankondiging van een opdracht – lichte regeling", value: "cn-social" },
                            { label: "Aankondiging van een prijsvraag voor ontwerpen", value: "cn-desg" },
                            { label: "Aankondiging gegunde opdracht voor openbaar personenvervoer", value: "can-tran" },
                            { label: "Aankondiging van de uitslag van een prijsvraag voor ontwerpen", value: "can-desg" },
                            { label: "Aankondiging van een gegunde opdracht of concessie – lichte regeling", value: "can-social" },
                            { label: "Aankondiging van een gegunde opdracht of concessie – standaardregeling", value: "can-standard" },
                            { label: "Aankondiging van wijziging contract", value: "can-modif" },
                            { label: "Aankondiging in geval van vrijwillige transparantie vooraf", value: "veat" },
                            { label: "Aankondiging inzake een Europees economisch samenwerkingsverband", value: "brin-eeig" },
                            { label: "Aankondiging inzake een Europese vennootschap / Europese coöperatieve vennootschap", value: "brin-ecs" },
                            { label: "Aankondiging inzake het bestaan van een erkenningsregeling", value: "qu-sy" },
                            { label: "Aankondiging van een opdracht in onderaanneming", value: "subco" },
                            { label: "Rectificatie", value: "corr" },
                        ]}
                        placeholder="Selecteer een internationale publicatie"
                        multi={false}
                        onChange={(event) => {
                            setInternationalPublication(event.value)
                            sendGAEvent('company_tenders_filter_change', {
                                filter_type: "publication_international",
                                filter_value: event.value
                        });
                    }}
                    />
                </div>
                )}

                {(country =="NL" || country == "") && (
                <div className="w-full h-full">
                    {/* prettier-ignore */}
                    <ReactSelect
                        key={`nl-type-${country}`}
                        advancedLabel={false}
                        defaultValue={type}
                        defaultLabel={type === "" ? "Alle types" : type}
                        data={[
                            { label: "Alle types", value: "" },
                            { label: "Diensten", value: "Diensten"},
                            { label: "Leveringen", value: "Leveringen" },
                            { label: "Werken", value: "Werken" },
                        ]}
                        placeholder="Selecteer een type"
                        multi={false}
                        onChange={(event) => {
                            setType(event.value);
                            sendGAEvent('company_tenders_filter_change', {
                                filter_type: "type",
                                filter_value: event.value
                            });
                        }}
                    />
                </div>
                )}
                {(country !== "NL" && country !== "") && (
                <div className="w-full h-full">
                    {/* prettier-ignore */}
                    <ReactSelect
                        key={`int-type-${country}`}
                        advancedLabel={false}
                        defaultValue={type}
                        defaultLabel={type === "" ? "Alle types" : type}
                        data={[
                            { label: "Alle types", value: "" },
                            { label: "Diensten", value: "services"},
                            { label: "Leveringen", value: "supplies" },
                            { label: "Werken", value: "works" },
                        ]}
                        placeholder="Selecteer een type"
                        multi={false}
                        onChange={(event) => {
                            setType(event.value);
                            sendGAEvent('company_tenders_filter_change', {
                                filter_type: "type_international",
                                filter_value: event.value
                            });
                        }}
                    />
                </div>
                )}
                <div className="w-full h-full">
                    {/* prettier-ignore */}
                    <ReactSelect
                        key={`sort-${country}`}
                        advancedLabel={false}
                        defaultValue={sort}
                        defaultLabel={sort === "desc" ? "Nieuwste publicaties eerst" : sort}
                        data={[
                            { label: "Nieuwste publicaties eerst", value: "desc" },
                            { label: "Oudste publicaties eerst", value: "asc"},
                        ]}
                        placeholder="Selecteer een type"
                        multi={false}
                        onChange={(event) => {
                            setSort(event.value);
                            sendGAEvent('company_tenders_filter_change', {
                                filter_type: "sort",
                                filter_value: event.value
                            });
                        }}
                    />
                </div>
                <div className="w-full h-full">
                    {/* prettier-ignore */}
                    <ReactSelect
                        advancedLabel={false}
                        defaultValue={country}
                        defaultLabel={"Nederland"}
                        data={[
                            { label: "Nederland", value: "NL"},
                            { label: "België", value: "BEL"},
                            { label: "Duitsland", value: "DEU" },
                            { label: "Frankrijk", value: "FRA" },
                            { label: "Spanje", value: "ESP" },

                        ]}
                        placeholder="Selecteer een land"
                        multi={false}
                        onChange={(event) => {
                            setCountry(event.value);
                            sendGAEvent('company_tenders_filter_change', {
                                filter_type: "country",
                                filter_value: event.value
                            });
                            resetFilters();
                            // Reset de standaardwaarden van de keuzefilters
                            if (event.value === "NL" || event.value === "") {
                                setPublication("");
                            } else {
                                setInternationalPublication("");
                            }
                            setType("");
                            setSort("desc");
                            
                        }}
                    />
                </div>
            </div>
            <>
                {loading ? (
                    <div className="w-full h-full flex justify-center items-center">
                        <LoadingDocument infiniteLoop={false} />
                    </div>
                ) : (
                    <>
                        {aiCPVcodes.length === 0 ? (
                            <div className="flex justify-center pb-10 mt-5 items-center w-full">
                                <p>
                                    Geen aanbestedingen beschikbaar in ons
                                    database. Neem contact met ons op als je dit
                                    probleem vaker tegenkomt.
                                </p>
                            </div>
                        ) : (
                            <div className="flex-col w-full overflow-y-auto h-full">
                                {aiPhases.map((item: any, index: any) => (
                                    <div key={index}>
                                        <TenderItem
                                            item={{
                                                ...item,
                                                phase: translatePhase(item.phase),}}
                                            index={index}
                                            style="recommended"
                                            companyID={companyID}
                                            showFavoriteIcon={false}
                                            // favorite={favorites.includes(
                                            //     item.tender_id
                                            // )}
                                            state={{ from: location.pathname }}
                                        />
                                    </div>
                                ))}
                                <div className="flex w-full">
                                    <div
                                        className="w-full cursor-pointer hover:underline"
                                        onClick={() => {
                                            fetchRelevantTenders(nextToken);
                                            sendGAEvent('load_more_company_tenders', {
                                                current_count: aiPhases.length
                                            });
                                        }}
                                    >
                                        {length === 20 ? (
                                            loadingNext ? (
                                                <div className="flex justify-center pb-10 items-center">
                                                    <div>
                                                        <Spinner />
                                                    </div>
                                                    <p>Laden...</p>
                                                </div>
                                            ) : (
                                                <div className="flex justify-center pb-10 items-center w-full">
                                                    <p className="pr-3">
                                                        <FontAwesomeIcon
                                                            className="text-primary-pink"
                                                            icon={"down-long"}
                                                        />
                                                    </p>
                                                    <p>Meer laden</p>
                                                </div>
                                            )
                                        ) : (
                                            <div className="flex justify-center pb-10 items-center w-full">
                                                <p>
                                                    Je hebt het einde bereikt.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </>
        </section>
    );
}
