import { FormEvent, useEffect, useState } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Link } from "react-router-dom";

import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import { Spinner } from "../../../components/Spinner";
import { useLocation } from "react-router-dom";
import { InputPassword } from "../../../components/InputPassword";
import useAnalyticsEventTracker from "../../../tracker/EventTracker";
import toast from "react-hot-toast";
import { errorToast } from "../../../lib/Toaster";

export const LoginComp = () => {
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const urlFrom = urlParams.get("from");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState<any>([]);
    const [confirmation, setConfirmation] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);

    useEffect(() => {
        EventTracker();
    }, []);

    function EventTracker() {
        useAnalyticsEventTracker("Login", "Bekijkt Login", "Login");
    }

    function EventTrackerLogin(label: string) {
        useAnalyticsEventTracker("Login", label, "Login");
    }

    async function SignIn() {
        setLoading(true);
        try {
            await Auth.signIn(email, password);

            const user = await Auth.currentAuthenticatedUser();
            const userId = user.attributes.sub; 
            const getUserQuery = `query MyQuery {
                getUser(id: "${userId}") {
                    preferredTenderCountry
                }
            }`;

            const fetchedUserInfo: any = await API.graphql(
                graphqlOperation(getUserQuery)
            );
            var preferredTenderCountry = fetchedUserInfo.data.getUser.preferredTenderCountry;
            if (preferredTenderCountry) {
                await Auth.signOut();
            toast.error("You are trying to log in with an international account, you will be redirected to TenderTracer EU.", 
                { duration: 3000 }
            );
            
            // Wacht even zodat de toast zichtbaar is en de signOut voltooid is
            setTimeout(() => {
                window.location.href = "https://dashboard.tendertracer.eu/"; //hier komt uiteindelijk de url van de EU site
            }, 3000);
            return;
            }
            EventTrackerLogin("Login gelukt");
            window.location.href = urlFrom ? urlFrom : "/";
            setLoading(false);
        } catch (error: any) {
            EventTrackerLogin("Login mislukt");
            if (error.toString().includes("UserNotConfirmedException")) {
                setConfirmation(true);
            } else if (error.toString().includes("InvalidParameterException")) {
                setErrorMessage(["Vul alle velden in."]);
                toast.error(error.toString(), errorToast);
            } else if (error.toString().includes("UserNotFoundException")) {
                setErrorMessage([
                    "Er bestaat geen account met dit emailadres.",
                ]);
                toast.error(
                    "Er bestaat geen account met dit emailadres.",
                    errorToast
                );
            } else if (error.toString().includes("NotAuthorizedException")) {
                setErrorMessage(["Foutief wachtwoord."]);
                toast.error("Foutief wachtwoord.", errorToast);
            } else if (error.toString().includes("AuthError")) {
                setErrorMessage(["Vul alle velden in."]);
                toast.error(error.toString(), errorToast);
            } else {
                setErrorMessage([error.toString()]);
                toast.error(error.toString(), errorToast);
            }
            setLoading(false);
        }
    }

    return (
        <div className="ml-5 mr-5 mt-5 w-[500px]">
            <div className="bg-white rounded-lg p-2 border shadow">
                <div className="w-full bg-white rounded-lg py-2">
                    <img
                        className="w-[150px]"
                        src="/images/TenderTracer_logo.png"
                    />
                </div>
                <p className="font-bold pl-3 text-lg">Aanmelden</p>
                <form
                    className="flex flex-col p-3"
                    onSubmit={(event) => event.preventDefault()}
                >
                    <Input
                        label="Emailadres"
                        value={email}
                        required={false}
                        type="email"
                        autofill="on"
                        placeholder="john@tendertracer.nl"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            setEmail(e.currentTarget.value.toString())
                        }
                    />

                    <div className="pt-3">
                        <InputPassword
                            label="Wachtwoord"
                            placeholder="Voer hier je wachtwoord in"
                            value={password}
                            onChange={(e: FormEvent<HTMLInputElement>) =>
                                setPassword(e.currentTarget.value.toString())
                            }
                        />
                    </div>
                    <div className="mt-5 flex justify-end">
                        <Button
                            buttonType="submit"
                            type={loading ? "hidden" : "secondary"}
                            onClick={() => SignIn()}
                        >
                            {!loading ? (
                                "INLOGGEN"
                            ) : (
                                <div className="flex items-center justify-end">
                                    <div className="pr-3">
                                        <p>Laden...</p>
                                    </div>
                                    <div>
                                        <Spinner />
                                    </div>
                                </div>
                            )}
                        </Button>
                    </div>
                </form>
            </div>
            <div className="bg-white border shadow p-3 mt-1 rounded-lg text-center">
                <Link
                    className="cursor-pointer text-primary-green hover:underline"
                    to={"/forgot_password"}
                >
                    Wachtwoord vergeten?
                </Link>
            </div>
            <div className="bg-white border shadow p-3 mt-1 rounded-lg text-center">
                <p
                    className="cursor-pointer text-primary-green hover:underline"
                    onClick={() => (window.location.href = "/register")}
                >
                    Geen account?
                </p>
            </div>
            <div className="bg-white border shadow p-3 mt-1 rounded-lg text-center">
                <p
                    className="cursor-pointer text-primary-green hover:underline"
                    onClick={() =>
                        (window.location.href = "https://tendertracer.nl")
                    }
                >
                    Meer informatie? Ga naar onze website...
                </p>
            </div>
        </div>
    );
};
