// TrialExpirationChecker.tsx
import { useEffect, useState } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';

const checkPremiumQuery = (userID: string) => `
  query MyQuery {
    getUser(id: "${userID}") {
      trialDateEnd
    }
  }
`;

export const TrialExpirationChecker: React.FC = () => {
  const [trialEndDate, setTrialEndDate] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkTrialStatus = async () => {
      try {
        const { attributes } = await Auth.currentAuthenticatedUser();
        const userData: any = await API.graphql(
          graphqlOperation(checkPremiumQuery(attributes.sub))
        );
        const trialDateEnd = userData.data.getUser.trialDateEnd;
        setTrialEndDate(trialDateEnd);
      } catch (error) {
        console.error('Error checking trial status:', error);
      }
    };

    checkTrialStatus();
  }, []);

  useEffect(() => {
    if (!trialEndDate) return;

    const checkExpiration = () => {
      const now = new Date();
      const trialEnd = new Date(trialEndDate);
      if (now >= trialEnd && !localStorage.getItem('trialExpiredHandled')) {
        console.log('Trial has expired. Redirecting to dashboard and refreshing.');
        localStorage.setItem('trialExpiredHandled', 'true');
        if (location.pathname !== '/') {
          navigate('/', { state: { trialJustExpired: true } });
        } else {
          window.location.reload();
        }
      }
    };

    checkExpiration(); // Check immediately
    const intervalId = setInterval(checkExpiration, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, [trialEndDate, navigate, location]);

  useEffect(() => {
    if (location.pathname === '/' && (location.state as any)?.trialJustExpired) {
      // Clear the state to prevent future refreshes
      window.history.replaceState({}, document.title, window.location.pathname);
      // Perform the refresh
      window.location.reload();
    }
  }, [location]);

  return null;
};
// TrialExpirationChecker.tsx
// // TrialExpirationChecker.tsx
// import { useEffect, useState } from 'react';
// import { API, Auth, graphqlOperation } from 'aws-amplify';
// import { useNavigate, useLocation } from 'react-router-dom';

// const checkPremiumQuery = (userID: string) => `
//   query MyQuery {
//     getUser(id: "${userID}") {
//       trialDateEnd
//     }
//   }
// `;

// export const TrialExpirationChecker: React.FC = () => {
//   const [trialEndDate, setTrialEndDate] = useState<string | null>(null);
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     const checkTrialStatus = async () => {
//       try {
//         const { attributes } = await Auth.currentAuthenticatedUser();
//         const userData: any = await API.graphql(
//           graphqlOperation(checkPremiumQuery(attributes.sub))
//         );
//         const trialDateEnd = userData.data.getUser.trialDateEnd;
//         setTrialEndDate(trialDateEnd);
//       } catch (error) {
//         console.error('Error checking trial status:', error);
//       }
//     };

//     checkTrialStatus();
//   }, []);

//   useEffect(() => {
//     if (!trialEndDate) return;

//     const checkExpiration = () => {
//       const now = new Date();
//       const trialEnd = new Date(trialEndDate);
//       if (now >= trialEnd) {
//         console.log('Trial has expired. Redirecting to dashboard and refreshing.');
//         if (location.pathname !== '/') {
//           navigate('/', { state: { trialJustExpired: true } });
//         } else {
//           localStorage.setItem('needsDashboardRefresh', 'true');
//           window.location.reload();
//         }
//       }
//     };

//     const intervalId = setInterval(checkExpiration, 60000); // Check every minute

//     return () => clearInterval(intervalId);
//   }, [trialEndDate, navigate, location]);

//   useEffect(() => {
//     const needsDashboardRefresh = localStorage.getItem('needsDashboardRefresh');
//     if (location.pathname === '/' && (location.state as any)?.trialJustExpired) {
//       localStorage.setItem('needsDashboardRefresh', 'true');
//       window.location.reload();
//     } else if (needsDashboardRefresh === 'true') {
//       localStorage.removeItem('needsDashboardRefresh');
//       window.location.reload();
//     }
//   }, [location]);

//   return null;
// };