import { ChartOptions } from "chart.js";
import { Doughnut } from "react-chartjs-2";

const doughnutLabelsLine: any = {
    id: "doughnutLabelsLine",
    afterDraw(chart: any, args: any, options: any) {
        //prettier-ignore
        const { ctx, data, chartArea: { top, bottom, left, right, width, height } } = chart;

        data.datasets[0].data.forEach((datapoint: any, index: any) => {
            //prettier-ignore
            const { x, y } = chart.getDatasetMeta(0).data[index].tooltipPosition();

            const halfWidth = width / 2;
            const halfHeight = height / 2;

            const xLine = x >= halfWidth ? x + 5 : x - 5;
            const yLine = y >= halfHeight ? y + 5 : y - 5;
            const extraLine = x >= halfWidth ? 15 : -15;

            if (datapoint >= 150) {
                ctx.beginPath();
                ctx.moveTo(x, y);
                ctx.lineTo(xLine, yLine);
                ctx.lineTo(xLine + extraLine, yLine);
                ctx.strokeStyle = "black";
                ctx.stroke();

                const textWidth = ctx.measureText(
                    chart.data.labels[index].width
                );
                const textXPosition = x >= halfWidth ? "left" : "right";
                const plusFivePx = x >= halfWidth ? 5 : -5;
                ctx.textAlign = textXPosition;
                ctx.font = "10px";
                ctx.textBaseline = "middle";
                ctx.fillText(
                    chart.data.labels[index].length > 14
                        ? chart.data.labels[index].substring(0, 11) + "..."
                        : chart.data.labels[index],
                    xLine + extraLine + plusFivePx,
                    yLine
                );
            }
        });
    },
};

const colorPalette = [
    "#284F82",
    "#FF4D72",
    "#00aaaa",
    "#F2C80F",
    "#FD625E",
    "#5F6B6D",
    "#FE9666",
];

const donOptions: ChartOptions<"doughnut"> = {
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
        padding: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
    },
};

export default function DoughnutChart({ data }: any) {
    const typeChart = {
        labels: data.map((item: any) => {
            return item.name;
        }),
        datasets: [
            {
                data: data.map((item: any) => {
                    return item.value;
                }),
                backgroundColor: colorPalette,
                borderColor: colorPalette,
                borderWidth: 1,
                cutout: "60%",
            },
        ],
    };

    return (
        <div className="relative w-full max-h-[80px] flex items-center justify-center">
            <Doughnut
                data={typeChart}
                options={donOptions}
                plugins={[doughnutLabelsLine]}
            />
        </div>
    );
}
