import { API, Auth, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button } from "../../../components/Button";
import Moment from "moment";
import { translatePhase } from "../TenderOverview";
import { translateType } from "../TenderOverview";
import { translateProcedure } from "../TenderOverview";
import "moment/locale/nl";
import { DetailCategoryObjects } from "../../../components/DetailCategoryObjects";
import "../../../fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PremiumModal from "../../../components/pricing/PremiumModal";
import { Modal } from "../../../components/Modal";
import Lottie from "lottie-react";
import supportAnimation from "../../../lottie/74498-call-center-support-lottie-animation.json";
import clsx from "clsx";
import { LoadingDocument } from "../../../components/LoadingDocument";
import useAnalyticsEventTracker from "../../../tracker/EventTracker";
import { FiBriefcase } from "react-icons/fi";
import { SlLocationPin } from "react-icons/sl";
import { AiOutlineEuro } from "react-icons/ai";
import { LiaTrophySolid } from "react-icons/lia";
import { FaCodeBranch } from "react-icons/fa6";
import { MdChecklist } from "react-icons/md";
import { MdHistory } from "react-icons/md";
import { LuContact } from "react-icons/lu";
import { GoAlert } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import Tabs from "../../../components/Tabs";
import { encode } from "punycode";
import { ARROW_DOWN } from "@aws-amplify/ui-react/dist/types/primitives/shared/constants";
import { BiFontSize } from "react-icons/bi";

Moment.locale("nl");
const StructuredDescription = ({ description }: { description: string }) => {
    const [paragraphs, setParagraphs] = useState<string[]>([]);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        const splitIntoParagraphs = (text: string): string[] => {
            if (!text) return ["Geen beschrijving beschikbaar."];
            
            const result: string[] = [];
            const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
            let paragraph = "";
            let sentenceCount = 0;

            sentences.forEach((sentence) => {
                paragraph += sentence.trim() + " ";
                sentenceCount++;

                if (sentenceCount === 3 || sentence === sentences[sentences.length - 1]) {
                    result.push(paragraph.trim());
                    paragraph = "";
                    sentenceCount = 0;
                }
            });

            // If there's an incomplete paragraph, add it
            if (paragraph) {
                result.push(paragraph.trim());
            }

            return result.length ? result : ["No description available."];
        };

        setParagraphs(splitIntoParagraphs(description));
    }, [description]);

    return (
        <div>
            {paragraphs
                .slice(0, showAll ? paragraphs.length : 4)
                .map((paragraph, index) => (
                    <p key={index} className="mb-4">
                        {paragraph}
                    </p>
                ))}
            {paragraphs.length > 4 && !showAll && (
                <Button
                    type="secondary"
                    onClick={() => setShowAll(true)}
                    className="mt-4"
                >
                    Lees meer
                </Button>
            )}
        </div>
    );
};

const TenderDetails = () => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState<number>(1);
    const [companyID, setCompanyID] = useState("");
    const [stripeId, setStripeId] = useState<string>("");
    const [favorite, setFavorite] = useState(false);
    const [showPayment, setShowPayment] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [phase, setPhase] = useState<any>([]);
    const [tenders, setTenders] = useState<any>([]);
    const [descriptionList, setDescriptionList] = useState<any>([]);
    const [categoryDetail, setCategoryDetail] = useState<any>([]);
    const [contractingAuthority, setContractingAuthority] = useState<any>([]);
    const [contractorsList, setContractorsList] = useState<any>([]);
    const [documents, setDocuments] = useState<any>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [tsender, setTsender] = useState<string>("");

    const handleGoBack = () => {
        console.log(location);
        if (location.state && location.state.from) {
            if (location.state.from.includes("/all-tenders")) {
                console.log(location.state.from);
                navigate(location.state.from, { replace: true });
            } else if (location.state.from.includes("/recommended-tenders")) {
                console.log(location.state.from);
                navigate(location.state.from, { replace: true });
            } else {
                navigate(-1);
            }
        } else {
            navigate(-1);
        }
    };

    const contractors: any[] = [];
    //prettier-ignore
    const getPhaseQuery = `query MyQuery { getPhase(id: "${params.phaseid}") { id title title_eng phase country ojs_number buyer_city time_receipt tender_type recurring procedure start_date end_date completion_date total_value eu_program_related dispatch_notice_date dispatch_invitations_date description date_receipt currency pdf pdf_eng tsender tsender_link contracting_authority contacts { items { contact { id name email phone fax contracting_authority_id contractor_id contact_type } } } object_descriptions { items { id phase_id title value description renewal_description main_site lot_number duration end_date duration criteria { items { name weight description} } } } phase cpv_codes { items { id cpv { id description } } } } }`;

    const getContractingAuthority = (id: string) =>
        `query MyQuery { getContractingAuthority(id: "${id}") { name national_id postalcode town country ca_activity ca_type address URL } }`;
    //prettier-ignore
    const getContractor = (id: string) => `query getContractor { getContractor(id: "${id}") { id name national_id postalcode town country address URL } }`;
    //prettier-ignore
    const getTenderQuery = `query MyQuery { getTender(id: "${params.tenderid}") { phases { items { id phase dispatch_notice_date tender_id } } } }`;
    //prettier-ignore
    const checkForFavorite = (userID: string) => `query MyQuery { getUser(id: "${userID}") { trial stripe_id companies { items { company { id saved_tenders { items { tender { id } id } } } } } } }`;
    //prettier-ignore
    const createFavoriteQuery = `mutation MyMutation { createCompanyTender(input: {id: "${companyID}_${params.tenderid}", companyId: "${companyID}", tenderId: "${params.tenderid}"}) { id } }`;
    //prettier-ignore
    const deleteFavoriteQuery = `mutation MyMutation { deleteCompanyTender(input: {id: "${companyID}_${params.tenderid}"}) { id } }`;

    async function Favoritizer() {
        try {
            if (favorite === false) {
                await API.graphql(graphqlOperation(createFavoriteQuery));
                setFavorite(true);
            } else {
                await API.graphql(graphqlOperation(deleteFavoriteQuery));
                setFavorite(false);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const CategoryDetailItems = (item: any) => [
        {
            name: "Publicatiedatum",
            data: Moment(item.dispatch_notice_date).format("LL"),
            line: false,
        },
        {
            name: "Aanvang opdracht",
            data: (() => {
                if (item.country === "NL" || item.country === null) {
                    return item.start_date
                        ? Moment(item.start_date).format("LL")
                        : "Onbekend";
                } else if (item.country) {
                    return item.start_date
                        ? Moment(item.start_date).format("LL")
                        : "Onbekend";
                } else {
                    return "Onbekend";
                }
            })(),
            line: true,
        },
        {
            name: "Sluitingsdatum",
            data: (() => {
                if (item.country === "NL" || item.country === null) {
                    return item.end_date
                        ? Moment(item.end_date).format("LL")
                        : "Onbekend";
                } else if (item.country) {
                    return item.end_date
                        ? Moment(item.end_date).format("LL")
                        : "Onbekend";
                } else {
                    return "Onbekend";
                }
            })(),
            line: true,
        },
        {
            name: "Voltooiïng opdracht",
            data: (() => {
                if (item.country === "NL" || item.country === null) {
                    return item.completion_date
                        ? Moment(item.completion_date).format("LL")
                        : "Onbekend";
                } else if (item.country) {
                    return item.completion_date
                        ? Moment(item.completion_date).format("LL")
                        : "Onbekend";
                } else {
                    return "Onbekend";
                }
            })(),
            line: true,
        },
        {
            name: "Type opdracht",
            data: item.tender_type
                ? translateType(item.tender_type, item.country)
                : "Onbekend",
        },
        {
            name: "Procedure",
            data:
                !item.procedure || item.procedure === "-"
                    ? "Onbekend"
                    : translateProcedure(item.procedure),
        },
        {
            name: "Publicatie type",
            data: item.phase ? translatePhase(item.phase) : "Onbekend",
        },
        {
            name: "Land",
            data: item.country ? item.country : "NLD",
        },

        {
            name: "Valuta",
            data: item.currency ? item.currency : "Onbekend",
        },
        {
            name: "OJS nummer",
            data: item.ojs_number ? item.ojs_number : "Onbekend",
        },
        {
            name: "Aanbestedende dienst",
            data: item.contracting_authority
                ? item.contracting_authority
                : "Onbekend",
        },
        {
            name: "Totaal waarde",
            data: item.total_value
                ? `€${item.total_value.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                  })} `
                : "Onbekend",
        },

        // {
        //     name: "Site",
        //     data: item.tsender_link ? <a href={item.tsender_link} style={{textDecoration: 'underline'}}>{item.tsender_link}</a> : "Onbekend",
        // },
    ];

    async function fetchTender() {
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });
        try {
            const phaseData: any = await API.graphql(
                graphqlOperation(getPhaseQuery)
            );

            const phase = phaseData.data.getPhase;
            const phaseCurrent = phaseData.data.getPhase.phase;
            const tenderData: any = await API.graphql(
                graphqlOperation(getTenderQuery)
            );
            await fetch(
                `https://3wizisksgkxwgdbev6p3znuzwu0dalqq.lambda-url.eu-central-1.on.aws/?id=${phase.id}`,
                {
                    method: "GET",
                }
            )
                .then((response) => response.json())
                .then((result) => setDocuments(result.documenten));

            let contractingAuthorityData: any;
            let contractorData: any;

            phase.contacts.items.map(async (item: any, index: any) => {
                if (phase.country !== null) {
                    if (item.contact.contact_type === "contracting_authority") {
                        if (item.contact.contracting_authority_id !== "0") {
                            contractingAuthorityData = await API.graphql(
                                graphqlOperation(
                                    getContractingAuthority(
                                        item.contact.contracting_authority_id
                                    )
                                )
                            );
                            setContractingAuthority(
                                contractingAuthorityData.data
                                    .getContractingAuthority
                            );
                        }
                    } else {
                        if (item.contact.contractor_id !== "0") {
                            contractorData = await API.graphql(
                                graphqlOperation(
                                    getContractor(item.contact.contractor_id)
                                )
                            );
                            contractors.push(contractorData.data.getContractor);
                            const uniqueContractors = contractors.filter(
                                (val: any, index: any, array: any) =>
                                    array
                                        .map((obj: { id: any }) => obj.id)
                                        .indexOf(val.id) === index
                            );
                            setContractorsList(uniqueContractors);
                        }
                    }
                } else if (phase.country == null || phase.country == "NL") {
                    if (item.contact.contact_type === "Contracting authority") {
                        if (item.contact.contracting_authority_id !== "0") {
                            contractingAuthorityData = await API.graphql(
                                graphqlOperation(
                                    getContractingAuthority(
                                        item.contact.contracting_authority_id
                                    )
                                )
                            );
                            setContractingAuthority(
                                contractingAuthorityData.data
                                    .getContractingAuthority
                            );
                        }
                    } else {
                        if (item.contact.contractor_id !== "0") {
                            contractorData = await API.graphql(
                                graphqlOperation(
                                    getContractor(item.contact.contractor_id)
                                )
                            );
                            contractors.push(contractorData.data.getContractor);
                            const uniqueContractors = contractors.filter(
                                (val: any, index: any, array: any) =>
                                    array
                                        .map((obj: { id: any }) => obj.id)
                                        .indexOf(val.id) === index
                            );
                            setContractorsList(uniqueContractors);
                        }
                    }
                }
            });

            const tender = tenderData.data.getTender;
            setTenders(tender);
            setCategoryDetail(CategoryDetailItems(phase));
            setPhase(phase);

            // if (phaseData.data.getPhase.description) {
            //     const descrText =
            //         await phaseData.data.getPhase.description;
            //     setDescriptionList(descrText);
            // } else {
            //     setDescriptionList([
            //         "Er is geen beschrijving meegegeven met deze publicatie.",
            //     ]);
            // }

            const favoriteData: any = await API.graphql(
                graphqlOperation(checkForFavorite(attributes.sub))
            );
            const exists =
                await favoriteData.data.getUser.companies.items[0].company.saved_tenders.items.find(
                    (tender: any) => tender.tender.id.includes(params.tenderid)
                );
            setFavorite(exists ? true : false);
            setStripeId(favoriteData.data.getUser.stripe_id);

            setCompanyID(
                favoriteData.data.getUser.companies.items[0].company.id
            );

            document.title = `${phase.title} | TenderTracer`;

            setLoading(false);
        } catch (phaseData: any) {
            const phase = phaseData?.data?.getPhase;
            const tenderData: any = await API.graphql(
                graphqlOperation(getTenderQuery)
            );

            await fetch(
                `https://cqmuygwbvweb2ytru74jwduuye0qkclo.lambda-url.eu-west-3.on.aws/?id=${phase.id}`,
                {
                    method: "GET",
                }
            )
                .then((response) => response.json())
                .then((result) => setDocuments(result.documenten));

            let contractingAuthorityData: any;
            let contractorData: any;

            phase.contacts.items.map(async (item: any, index: any) => {
                if (item) {
                    if (item.contact.contact_type === "contracting_authority") {
                        if (item.contact.contracting_authority_id !== "0") {
                            contractingAuthorityData = await API.graphql(
                                graphqlOperation(
                                    getContractingAuthority(
                                        item.contact.contracting_authority_id
                                    )
                                )
                            );
                            setContractingAuthority(
                                contractingAuthorityData.data
                                    .getContractingAuthority
                            );
                        }
                    } else {
                        if (item.contact.contractor_id !== "0") {
                            contractorData = await API.graphql(
                                graphqlOperation(
                                    getContractor(item.contact.contractor_id)
                                )
                            );
                            contractors.push(contractorData.data.getContractor);
                            const uniqueContractors = contractors.filter(
                                (val: any, index: any, array: any) =>
                                    array
                                        .map((obj: { id: any }) => obj.id)
                                        .indexOf(val.id) === index
                            );
                            setContractorsList(uniqueContractors);
                        }
                    }
                }
            });

            const tender = tenderData.data.getTender;
            setTenders(tender);
            setCategoryDetail(CategoryDetailItems(phase));
            setPhase(phase);

            // if (phaseData.data.getPhase.description) {
            //     const descrText =
            //         await phaseData.data.getPhase.description;
            //     setDescriptionList(descrText);
            // } else {
            //     setDescriptionList([
            //         "Er is geen beschrijving meegegeven met deze publicatie.",
            //     ]);
            // }

            const favoriteData: any = await API.graphql(
                graphqlOperation(checkForFavorite(attributes.sub))
            );
            const exists =
                await favoriteData.data.getUser.companies.items[0].company.saved_tenders.items.find(
                    (tender: any) => tender.tender.id.includes(params.tenderid)
                );
            setFavorite(exists ? true : false);
            setStripeId(favoriteData.data.getUser.stripe_id);

            setCompanyID(
                favoriteData.data.getUser.companies.items[0].company.id
            );

            if (phase.country == null) {
                setTsender(
                    `https://www.tenderned.nl/aankondigingen/overzicht/${phase.id}/details`
                );
            } else {
                setTsender(phase.tsender_link);
            }

            document.title = `${phase.title} | TenderTracer`;

            setLoading(false);
        }
    }

    useEffect(() => {
        fetchTender();
    }, []);

    useEffect(() => {
        EventTracker();
    }, []);

    function EventTracker() {
        useAnalyticsEventTracker(
            "Aanbesteding",
            `Bekijkt ${phase.title}`,
            "Aanbesteding"
        );
    }

    return (
        <>
            {loading ? (
                <div className="w-full h-full flex items-center justify-center">
                    <LoadingDocument infiniteLoop={false} />
                </div>
            ) : (
                <section className="w-full h-full overflow-y-auto">
                    <div className="lg:grid grid-cols-3 flex flex-col">
                        <div className="col-span-2 grid grid-cols-2 gap-3 pr-3 h-screen">
                            <div className="col-span-2 pr-5 bg-white border shadow mt-3 ml-3 rounded-lg p-5">
                                <Button
                                    type={"pink"}
                                    className="items-center text-base justify-center hidden lg:flex float-right ml-1"
                                    onClick={handleGoBack}
                                >
                                    <p>&larr; Terug naar zoekresultaten</p>
                                </Button>
                                {/* <br/><br/> */}
                                <div>
                                    <p className="text-lg lg:text-2xl font-bold">
                                        {phase.title}
                                    </p>
                                </div>

                                <div className="flex space-x-5 w-full mt-2">
                                    <div className="flex bg-white items-center">
                                        <FiBriefcase />
                                        <p className="pl-1">
                                            {phase.contracting_authority}
                                        </p>
                                    </div>
                                    <div className="flex bg-white items-center">
                                        <span className="relative flex h-3 w-3">
                                            <span
                                                className={clsx(
                                                    phase.phase
                                                        .toLowerCase()
                                                        .includes(
                                                            "gegunde opdracht"
                                                        ) ||
                                                        phase.phase
                                                            .toLowerCase()
                                                            .includes("can")
                                                        ? "bg-primary-pink"
                                                        : phase.phase
                                                              .toLowerCase()
                                                              .includes(
                                                                  "vooraankondiging"
                                                              ) ||
                                                          phase.phase
                                                              .toLowerCase()
                                                              .includes("pin")
                                                        ? "bg-[#FFBF00]"
                                                        : "bg-primary-green",
                                                    "animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
                                                )}
                                            />
                                            <span
                                                className={clsx(
                                                    phase.phase
                                                        .toLowerCase()
                                                        .includes(
                                                            "gegunde opdracht"
                                                        ) ||
                                                        phase.phase
                                                            .toLowerCase()
                                                            .includes("can")
                                                        ? "bg-primary-pink"
                                                        : phase.phase
                                                              .toLowerCase()
                                                              .includes(
                                                                  "vooraankondiging"
                                                              ) ||
                                                          phase.phase
                                                              .toLowerCase()
                                                              .includes("pin")
                                                        ? "bg-[#FFBF00]"
                                                        : "bg-primary-green",
                                                    "relative inline-flex rounded-full h-3 w-3"
                                                )}
                                            ></span>
                                        </span>
                                        <p className="pl-2">
                                            {phase.phase
                                                .toLowerCase()
                                                .includes("gegunde opdracht") ||
                                            phase.phase
                                                .toLowerCase()
                                                .includes("can")
                                                ? "Gesloten aanbesteding"
                                                : phase.phase
                                                      .toLowerCase()
                                                      .includes(
                                                          "vooraankondiging"
                                                      ) ||
                                                  phase.phase
                                                      .toLowerCase()
                                                      .includes("pin")
                                                ? "Verwachte aanbesteding"
                                                : "Open aanbesteding"}
                                        </p>
                                    </div>
                                    <div className="flex bg-white items-center">
                                        <SlLocationPin />
                                        <p className="pl-1">
                                            {contractingAuthority.town !==
                                            undefined
                                                ? contractingAuthority.town
                                                : phase.buyer_city}
                                        </p>
                                    </div>
                                    <div className="flex bg-white items-center">
                                        <AiOutlineEuro />
                                        <p className="pl-2">
                                            {phase.total_value
                                                ? `${phase?.total_value?.toLocaleString(
                                                      undefined,
                                                      {
                                                          maximumFractionDigits: 2,
                                                      }
                                                  )},-`
                                                : "Zie document"}
                                        </p>
                                    </div>
                                </div>

                                <div className="flex pt-5 pb-2 border-b">
                                    <p
                                        className={clsx(
                                            "font-light hover:text-primary-pink cursor-pointer",
                                            page === 1 && "text-primary-pink"
                                        )}
                                        onClick={() => setPage(1)}
                                    >
                                        Beschrijving
                                    </p>
                                    <p
                                        className={clsx(
                                            "pl-5 font-light hover:text-primary-pink cursor-pointer",
                                            page === 2 && "text-primary-pink"
                                        )}
                                        onClick={() => setPage(2)}
                                    >
                                        Documenten
                                    </p>
                                </div>
                                {page === 1 && (
                                    <div className="pt-3">
                                        {/* {descriptionList.map(
                                            (item: any, index: any) => (
                                                <p key={index}>
                                                    {item.includes("null") ? (
                                                        <br />
                                                    ) : (
                                                        item.replace(
                                                            /[\[\]']+/g,
                                                            ""
                                                        )
                                                    )}
                                                </p>
                                            )
                                        )} */}
                                        <p>
                                            <StructuredDescription
                                                description={phase.description || ""}
                                            />
                                        </p>
                                    </div>
                                )}
                                {/* {page === 2 && (
                                    <div className="lg:grid grid-cols-2 gap-3 mt-3">
                                        {documents.length > 0 ? (
                                            documents.map(
                                                (document: any, index: any) => (
                                                    <div
                                                        key={index}
                                                        data-te-toggle="tooltip"
                                                        title={`${document.documentNaam}.${document.typeDocument.code}`}
                                                        className="border hover:shadow hover:underline cursor-pointer p-3 rounded-lg flex items-center"
                                                        onClick={() =>
                                                            window.open(
                                                                `https://www.tenderned.nl/${document.links.download.href}`
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            className={clsx(
                                                                "text-2xl",
                                                                document
                                                                    .typeDocument
                                                                    .code ===
                                                                    "pdf"
                                                                    ? "text-primary-pink"
                                                                    : document
                                                                          .typeDocument
                                                                          .code ===
                                                                      "xlsx"
                                                                    ? "text-primary-green"
                                                                    : document
                                                                          .typeDocument
                                                                          .code ===
                                                                      "docx"
                                                                    ? "text-primary-blue"
                                                                    : document
                                                                          .typeDocument
                                                                          .code ===
                                                                      "doc"
                                                                    ? "text-primary-blue"
                                                                    : document
                                                                          .typeDocument
                                                                          .code ===
                                                                      "xls"
                                                                    ? "text-primary-green"
                                                                    : document
                                                                          .typeDocument
                                                                          .code ===
                                                                      "csv"
                                                                    ? "text-primary-green"
                                                                    : "text-black"
                                                            )}
                                                            icon={
                                                                document
                                                                    .typeDocument
                                                                    .code ===
                                                                "pdf"
                                                                    ? "file-pdf"
                                                                    : document
                                                                          .typeDocument
                                                                          .code ===
                                                                      "xlsx"
                                                                    ? "file-excel"
                                                                    : document
                                                                          .typeDocument
                                                                          .code ===
                                                                      "docx"
                                                                    ? "file-word"
                                                                    : document
                                                                          .typeDocument
                                                                          .code ===
                                                                      "doc"
                                                                    ? "file-word"
                                                                    : document
                                                                          .typeDocument
                                                                          .code ===
                                                                      "xls"
                                                                    ? "file-excel"
                                                                    : document
                                                                          .typeDocument
                                                                          .code ===
                                                                      "zip"
                                                                    ? "file-zipper"
                                                                    : document
                                                                          .typeDocument
                                                                          .code ===
                                                                      "rar"
                                                                    ? "file-zipper"
                                                                    : document
                                                                          .typeDocument
                                                                          .code ===
                                                                      "xml"
                                                                    ? "file-lines"
                                                                    : document
                                                                          .typeDocument
                                                                          .code ===
                                                                      "csv"
                                                                    ? "file-csv"
                                                                    : "file"
                                                            }
                                                        />
                                                        <p className="pl-3 w-full truncate">
                                                            {
                                                                document.documentNaam
                                                            }
                                                        </p>
                                                    </div>
                                                )
                                            )
                                        ) : (
                                            <p>
                                                Er zijn geen documenten met deze
                                                publicatie vrijgegeven.
                                            </p>
                                        )}
                                    </div>
                                )} 
                                
                                
                                
                                */}
                                {page === 2 && (
                                    <div className="lg:grid grid-cols-2 gap-3 mt-3">
                                        {phase.country == null ? (
                                            <>
                                                {documents.length > 0 ? (
                                                    documents.map(
                                                        (
                                                            document: any,
                                                            index: any
                                                        ) => (
                                                            <div
                                                                key={index}
                                                                data-te-toggle="tooltip"
                                                                title={`${document.documentNaam}.${document.typeDocument.code}`}
                                                                className="border hover:shadow hover:underline cursor-pointer p-3 rounded-lg flex items-center"
                                                                onClick={() =>
                                                                    window.open(
                                                                        `https://www.tenderned.nl/${document.links.download.href}`
                                                                    )
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    className={clsx(
                                                                        "text-2xl",
                                                                        document
                                                                            .typeDocument
                                                                            .code ===
                                                                            "pdf"
                                                                            ? "text-primary-pink"
                                                                            : document
                                                                                  .typeDocument
                                                                                  .code ===
                                                                              "xlsx"
                                                                            ? "text-primary-green"
                                                                            : document
                                                                                  .typeDocument
                                                                                  .code ===
                                                                              "docx"
                                                                            ? "text-primary-blue"
                                                                            : document
                                                                                  .typeDocument
                                                                                  .code ===
                                                                              "doc"
                                                                            ? "text-primary-blue"
                                                                            : document
                                                                                  .typeDocument
                                                                                  .code ===
                                                                              "xls"
                                                                            ? "text-primary-green"
                                                                            : document
                                                                                  .typeDocument
                                                                                  .code ===
                                                                              "csv"
                                                                            ? "text-primary-green"
                                                                            : "text-black"
                                                                    )}
                                                                    icon={
                                                                        document
                                                                            .typeDocument
                                                                            .code ===
                                                                        "pdf"
                                                                            ? "file-pdf"
                                                                            : document
                                                                                  .typeDocument
                                                                                  .code ===
                                                                              "xlsx"
                                                                            ? "file-excel"
                                                                            : document
                                                                                  .typeDocument
                                                                                  .code ===
                                                                              "docx"
                                                                            ? "file-word"
                                                                            : document
                                                                                  .typeDocument
                                                                                  .code ===
                                                                              "doc"
                                                                            ? "file-word"
                                                                            : document
                                                                                  .typeDocument
                                                                                  .code ===
                                                                              "xls"
                                                                            ? "file-excel"
                                                                            : document
                                                                                  .typeDocument
                                                                                  .code ===
                                                                              "zip"
                                                                            ? "file-zipper"
                                                                            : document
                                                                                  .typeDocument
                                                                                  .code ===
                                                                              "rar"
                                                                            ? "file-zipper"
                                                                            : document
                                                                                  .typeDocument
                                                                                  .code ===
                                                                              "xml"
                                                                            ? "file-lines"
                                                                            : document
                                                                                  .typeDocument
                                                                                  .code ===
                                                                              "csv"
                                                                            ? "file-csv"
                                                                            : "file"
                                                                    }
                                                                />
                                                                <p className="pl-3 w-full truncate">
                                                                    {
                                                                        document.documentNaam
                                                                    }
                                                                </p>
                                                            </div>
                                                        )
                                                    )
                                                ) : (
                                                    <p>
                                                        Er zijn geen documenten
                                                        met deze publicatie
                                                        vrijgegeven.
                                                    </p>
                                                )}
                                            </>
                                        ) : (
                                            <div className="flex space-x-4">
                                                {phase.pdf && (
                                                    <div
                                                        className="border hover:shadow hover:underline cursor-pointer p-3 rounded-lg items-center flex flex-col w-full"
                                                        onClick={() =>
                                                            window.open(
                                                                phase.pdf
                                                            )
                                                        }
                                                    >
                                                        <p className="pl-3 w-full truncate">
                                                            <FontAwesomeIcon
                                                                className="text-2xl text-primary-pink"
                                                                icon="file-pdf"
                                                            />
                                                            <span>
                                                                &nbsp;&nbsp;
                                                            </span>
                                                            Origineel PDF
                                                        </p>
                                                    </div>
                                                )}
                                                {phase.pdf_eng && (
                                                    <div
                                                        className="border hover:shadow hover:underline cursor-pointer p-3 rounded-lg flex flex-col items-center w-full"
                                                        onClick={() =>
                                                            window.open(
                                                                phase.pdf_eng
                                                            )
                                                        }
                                                    >
                                                        <p className="pl-3 w-full truncate">
                                                            <FontAwesomeIcon
                                                                className="text-2xl text-primary-pink"
                                                                icon="file-pdf"
                                                            />
                                                            <span>
                                                                &nbsp;&nbsp;
                                                            </span>
                                                            English PDF
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="bg-white ml-3 rounded-lg border shadow p-5 lg:mt-0">
                                <div className="flex pb-3 font-bold items-center">
                                    <FiBriefcase className="text-xl" />
                                    <p className="pl-1 text-lg">
                                        Aanbestedende dienst
                                    </p>
                                </div>
                                <DetailCategoryObjects
                                    name={"Officiële benaming"}
                                    data={phase.contracting_authority}
                                    line={false}
                                />
                                <DetailCategoryObjects
                                    name={"Postadres"}
                                    data={contractingAuthority.address}
                                    line={true}
                                />
                                <DetailCategoryObjects
                                    name={"Postcode & Plaats"}
                                    data={`${
                                        contractingAuthority.postalcode &&
                                        phase.buyer_city
                                            ? `${contractingAuthority.postalcode} ${phase.buyer_city}`
                                            : ""
                                    }`}
                                    line={true}
                                />
                                <DetailCategoryObjects
                                    name={"Land"}
                                    data={contractingAuthority.country}
                                    line={true}
                                />
                                <DetailCategoryObjects
                                    name={"Internetadres(sen)"}
                                    data={contractingAuthority.URL}
                                    line={true}
                                    link={true}
                                />
                            </div>
                            <div className="bg-white rounded-lg shadow border p-5 lg:mt-0">
                                {phase.phase.includes("gegunde opdracht") ? (
                                    <>
                                        <div className="flex items-center text-lg font-bold pb-1">
                                            <LiaTrophySolid className="text-xl" />
                                            <p className="pl-1">{`Contractant${
                                                contractorsList.length > 1
                                                    ? "en"
                                                    : ""
                                            }`}</p>
                                        </div>
                                        {contractorsList.length !== 0 ? (
                                            contractorsList.map(
                                                (item: any, index: any) => (
                                                    <div
                                                        style={{
                                                            borderBottomWidth:
                                                                index === 0
                                                                    ? 1
                                                                    : 1,
                                                        }}
                                                        className="pb-2 pt-2"
                                                        key={index}
                                                    >
                                                        <p className="font-bold">
                                                            {item.name}
                                                        </p>
                                                        <p className="text-sm opacity-80">
                                                            {item.address}
                                                        </p>
                                                        <p className="text-sm opacity-80">
                                                            {item.postalcode &&
                                                                `${item.postalcode}, `}
                                                            {item.town}
                                                        </p>
                                                    </div>
                                                )
                                            )
                                        ) : (
                                            <div className="border-primary-pink border p-5 mt-3 rounded-lg flex space-x-3 items-center">
                                                <GoAlert className="text-primary-pink text-xl" />
                                                <p className="text-sm text-primary-pink">
                                                    Dit is een vroegtijdige
                                                    beëindiging.
                                                </p>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className="flex items-center text-lg font-bold pb-1">
                                            <LuContact className="text-xl" />
                                            <p className="pl-1">
                                                {phase.contacts.items.length > 1
                                                    ? "Contactpersonen"
                                                    : "Contactpersoon"}
                                            </p>
                                        </div>
                                        {phase.contacts.items
                                            .filter(
                                                (
                                                    value: any,
                                                    index: number,
                                                    self: any
                                                ) =>
                                                    self.findIndex(
                                                        (item: any) =>
                                                            item.contact
                                                                .email ===
                                                            value.contact.email
                                                    ) === index
                                            )
                                            .map((item: any, index: number) => (
                                                <div
                                                    style={{
                                                        borderBottomWidth:
                                                            index === 0 ? 1 : 1,
                                                    }}
                                                    className="pb-2"
                                                    key={index}
                                                >
                                                    <p className="font-bold">
                                                        {item?.contact?.name}
                                                    </p>
                                                    <p className="text-sm">
                                                        {item?.contact?.email}
                                                    </p>
                                                    <p className="text-sm">
                                                        {item?.contact?.phone}
                                                    </p>
                                                </div>
                                            ))}
                                    </>
                                )}
                            </div>
                            <div className="bg-white border shadow rounded-lg ml-3 p-5">
                                <div className="flex pb-3 font-bold items-center">
                                    <FaCodeBranch className="text-cl" />
                                    <p className="pl-1 text-lg">CPV codes</p>
                                </div>
                                {phase.cpv_codes.items.map(
                                    (item: any, index: any) => (
                                        <div key={index}>
                                            <DetailCategoryObjects
                                                name={"CPV code"}
                                                data={`${item.cpv.id} - ${item.cpv.description}`}
                                                line={
                                                    index === 0
                                                        ? false
                                                        : item.line
                                                }
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                            <div className="bg-white rounded-lg p-5 mt-5 shadow border lg:mt-0">
                                <div className="flex items-center text-lg font-bold pb-1">
                                    <MdChecklist className="text-xl" />
                                    <p className="pl-1">Gunningscriteria</p>
                                </div>
                                {phase.object_descriptions.items[0]?.criteria
                                    .items.length > 0 ? (
                                    phase.object_descriptions.items[0]?.criteria.items.map(
                                        (item: any, index: any) => (
                                            <div key={index}>
                                                <DetailCategoryObjects
                                                    name={item.name}
                                                    data={`Weight: ${
                                                        item.weight
                                                    } 
                                                    Description: ${
                                                        item.description
                                                            ? item.description
                                                            : "Onbekend"
                                                    }`}
                                                    line={
                                                        index === 0
                                                            ? false
                                                            : item.line
                                                    }
                                                />
                                            </div>
                                        )
                                    )
                                ) : (
                                    <p className="mt-1 text-sm">
                                        Geen criteria gevonden
                                    </p>
                                )}
                            </div>
                            <div className="pb-10 col-span-2 ml-3">
                                <div className="bg-white shadow border rounded-lg p-5">
                                    <div className="flex items-center text-lg font-bold pb-1">
                                        <MdHistory className="text-xl" />
                                        <p className="pl-1">
                                            Publicatie verleden
                                        </p>
                                    </div>

                                    {tenders.phases.items.length > 1 ? (
                                        tenders.phases.items.map(
                                            (item: any, index: any) => (
                                                <>
                                                    {item.id !==
                                                        params.phaseid && (
                                                        <div
                                                            key={index}
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                (window.location.href = `/tender/${item.tender_id}/${item.id}`)
                                                            }
                                                        >
                                                            <DetailCategoryObjects
                                                                name={Moment(
                                                                    item.dispatch_notice_date
                                                                ).format("LL")}
                                                                data={translatePhase(
                                                                    item.phase
                                                                )}
                                                                line={
                                                                    index === 0
                                                                        ? false
                                                                        : item.line
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )
                                        )
                                    ) : (
                                        <p className="mt-1 text-sm">
                                            Geen relevante publicaties gevonden
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="w-full h-full lg:mt-0 mt-[510px]">
                            <div className="bg-white border rounded-lg shadow p-5 my-3 lg:ml-0 ml-3 mr-3">
                                <div
                                    className={clsx(
                                        "pb-5 grid-cols-2 gap-2",
                                        !phase.phase.includes(
                                            "gegunde opdracht"
                                        ) &&
                                            !phase.phase
                                                .toLowerCase()
                                                .includes("can") &&
                                            "lg:grid"
                                    )}
                                >
                                    <Button
                                        className="w-full"
                                        type={favorite ? "ghost" : "primary"}
                                        onClick={Favoritizer}
                                    >
                                        <FontAwesomeIcon
                                            className="pr-3"
                                            color="#ff4d72"
                                            icon="heart"
                                        />
                                        {favorite ? "Verwijderen" : "Favoriet"}
                                    </Button>
                                    {!phase.phase.includes(
                                        "gegunde opdracht"
                                    ) &&
                                        !phase.phase
                                            .toLowerCase()
                                            .includes("can") && (
                                            <Button
                                                type={"secondary"}
                                                className="items-center justify-center hidden lg:flex"
                                                onClick={() => {
                                                    if (phase.country == null) {
                                                        setShowPopup(true);
                                                    } else {
                                                        //window.open(tsender);
                                                        setShowPopup(true);
                                                    }
                                                }}
                                                // onClick={() => setShowPopup(true)}
                                            >
                                                + Inschrijven
                                            </Button>
                                        )}
                                </div>

                                {categoryDetail.map((item: any, index: any) => (
                                    <div key={index}>
                                        <DetailCategoryObjects
                                            name={item.name}
                                            data={item.data}
                                            line={item.line}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {showPayment ? (
                        <PremiumModal
                            stripeId={stripeId}
                            closeModal={() => setShowPayment(false)}
                        />
                    ) : null}
                    {showPopup ? (
                        <Modal
                            title="👋 We sturen je door naar de inschrijfpagina..."
                            onClick={() => setShowPopup(false)}
                        >
                            <div className="p-5">
                                {phase.country === "NL" ||
                                phase.country == null ? (
                                    <p>
                                        TenderNed is het enige overheidsplatform
                                        waar het mogelijk is om je in te
                                        schrijven op alle overheidsopdrachten en
                                        aanbestedingen binnen de Nederlandse
                                        markt. Inschrijven verloopt altijd via
                                        TenderNed.
                                    </p>
                                ) : (
                                    <p>
                                        Klik op de knop hieronder om naar de
                                        inschrijfwebsite te gaan.
                                    </p>
                                )}
                                <div className="grid grid-cols-2 items-center">
                                    <Lottie
                                        loop={false}
                                        animationData={supportAnimation}
                                    />
                                    <div className="">
                                        <p className="pb-5">
                                            Nog wat tips & tricks om je verder
                                            te helpen:
                                        </p>
                                        <p
                                            className="border p-3 italic cursor-pointer text-primary-green font-bold hover:underline"
                                            onClick={() => window.open("/help")}
                                        >
                                            Hulp bij inschrijven?
                                        </p>
                                        <p
                                            className="border p-3 italic cursor-pointer text-primary-green font-bold hover:underline"
                                            onClick={() =>
                                                window.open(
                                                    "https://tendertracer.nl/aanbesteden"
                                                )
                                            }
                                        >
                                            Wat is aanbesteden?
                                        </p>
                                        <p
                                            className="border p-3 italic cursor-pointer text-primary-green font-bold hover:underline"
                                            onClick={() =>
                                                window.open(
                                                    "https://tendertracer.nl/mkbers-en-aanbestedingen-zo-maak-je-kans-op-succes/"
                                                )
                                            }
                                        >
                                            Hoe behaal ik succes?
                                        </p>
                                        <p
                                            className="border p-3 italic cursor-pointer text-primary-green font-bold hover:underline"
                                            onClick={() =>
                                                window.open(
                                                    "https://tendertracer.nl/drempelwaarden-in-het-aanbesteden-wat-zijn-ze-en-waarom-zijn-ze-belangrijk/"
                                                )
                                            }
                                        >
                                            Wat zijn de drempelwaarden van
                                            aanbesteden en waarom zijn ze
                                            belangrijk?
                                        </p>
                                    </div>
                                </div>
                                <Button
                                    type="secondary"
                                    className="w-full mt-10 animate-bounce"
                                    onClick={() => {
                                        if (phase.country == null) {
                                            window.open(
                                                `https://www.tenderned.nl/aankondigingen/overzicht/${phase.id}/details`
                                            );
                                        } else if (
                                            phase.country != null &&
                                            phase.tsender_link != null
                                        ) {
                                            window.open(phase.tsender_link);
                                        } else {
                                            setShowPopup(false);
                                            alert(
                                                "Er is iets misgegaan, excuses voor het ongemak. Probeer het later opnieuw."
                                            );
                                        }
                                    }}
                                >
                                    {phase.country === "NL" ||
                                    phase.country == null ? (
                                        <p>
                                            Ik ben klaar om in te schrijven!
                                            Naar TenderNed gaan...
                                        </p>
                                    ) : (
                                        <p>
                                            Ik ben klaar om in te schrijven!
                                            Naar de inschrijfpagina gaan...
                                        </p>
                                    )}
                                </Button>
                            </div>
                        </Modal>
                    ) : null}
                </section>
            )}
        </>
    );
};

export default TenderDetails;
