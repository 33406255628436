import clsx from "clsx";

interface CardProps {
    name: string;
    data: any;
    line?: boolean;
    selected?: boolean;
    link?: boolean;
}

export const DetailCategoryObjects = ({
    name,
    data,
    line = true,
    selected = false,
    link = false,
}: CardProps) => {
    return (
        <div
            style={{
                borderTopWidth: line ? 1 : 0,
            }}
            className={clsx("pb-3 pt-2")}
        >
            <div className={clsx("flex font-light text-sm opacity-80")}>
                <p className={clsx(selected && "underline")}>{name}</p>
                {selected ? (
                    <div className="flex items-center justify-center">
                        <p className="text-xs"> - Huidige selectie</p>
                    </div>
                ) : null}
            </div>
            <p
                className={clsx(
                    "text-ellipsis overflow-hidden",
                    selected && "underline",
                    link && "hover:cursor-pointer hover:underline"
                )}
                onClick={() => (link ? window.open(data) : "")}
            >
                {data}
            </p>
        </div>
    );
};
