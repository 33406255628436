import clsx from "clsx";
import { FormEvent } from "react";

interface InputProps {
    label: string;
    value: string | undefined;
    placeholder?: string;
    type?: string;
    required?: boolean;
    showRequired?: boolean;
    readonly?: boolean;
    autofill?: string;
    onChange: (e: FormEvent<HTMLInputElement>) => void;
}

export const Input = ({
    label,
    value,
    type = "text",
    placeholder = "...",
    onChange,
    showRequired = false,
    required = true,
    readonly = false,
    autofill = "new-password",
}: InputProps) => {
    return (
        <div className="flex flex-col font-medium">
            <span className="inline-flex justify-between">
                <label htmlFor={label} className="text-sm text-black flex">
                    {label}
                    {required && <p className="text-primary-pink">*</p>}
                </label>
                {required && showRequired && (
                    <p className="text-red leading-3">Dit veld is verplicht</p>
                )}
            </span>
            <input
                type={type}
                id={label}
                value={value}
                autoComplete={autofill}
                readOnly={readonly}
                className={clsx(
                    "border border-gray rounded px-4 py-2 text-sm transition-all focus:outline-none focus:ring-2 hover:ring-primary-green hover:ring-1 focus:ring-primary-green",
                    showRequired && required && !value && "ring-1 ring-red"
                )}
                placeholder={placeholder}
                style={{
                    color: readonly ? "#a9a9a9" : "#000",
                }}
                title={readonly ? "Je kunt je KvK nummer niet wijzigen." : ""}
                onChange={(e: FormEvent<HTMLInputElement>) => onChange(e)}
            />
        </div>
    );
};
