import { API, graphqlOperation, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Spinner } from "../../../components/Spinner";
import PremiumComp from "../../../components/pricing/PremiumComp";
import { Button } from "../../../components/Button";
import PremiumModal from "../../../components/pricing/PremiumModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faSearch, 
    faGlobe, 
    faChartLine, 
    faHandsHelping, 
    faRobot, 
    faBell 
} from "@fortawesome/free-solid-svg-icons";

const Premium = () => {
    const [userInfo, setUserInfo] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);
    const fetchUserQuery = (userID: any) => `
        query MyQuery { getUser(id: "${userID}") { id stripe_id firstname lastname phone companies { items { company { id active_subscription address country postalcode } } } } }`;

    async function fetchUser() {
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });
        try {
            const userData: any = await API.graphql(
                graphqlOperation(fetchUserQuery(attributes.sub))
            );
            const userInfo = await userData.data.getUser;
            setUserInfo(userInfo);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        document.title = "Premium | TenderTracer";
        fetchUser();
    }, []);

    const premiumFeatures = [
        { icon: faSearch, text: "Voor u geselecteerde aanbestedingen" },
        { icon: faGlobe, text: "Internationale aanbestedingen" },
        { icon: faChartLine, text: "Uitgebreide concurrentie analyse" },
        { icon: faHandsHelping, text: "Hulp bij inschrijven" },
        { icon: faRobot, text: "Door AI gestuurde CPV-codes" },
        { icon: faBell, text: "Gepersonaliseerde e-mailmeldingen" },
    ];
    return (
        <section className="h-full w-full overflow-y-auto relative">
            {loading && (
                <div className="absolute h-screen w-full bg-black bg-opacity-30 flex flex-col items-center justify-center">
                    <Spinner />
                    <p className="pt-3">Betaling aanmaken...</p>
                </div>
            )}

            <div className="ml-5 mr-5 mt-5">
                <div className="bg-white rounded-lg mb-5">
                    <p className="p-2 font-bold text-lg">TenderTracer Premium</p>
                </div>
                <div className="grid grid-cols-2 gap-5 bg-white rounded-lg p-6 w-full">
                    <div className="w-full">
                        <h2 className="text-2xl font-bold mb-4">Ontdek de kracht van TenderTracer Premium</h2>
                        <p className="mb-4 text-sm opacity-80">
                            Met trots introduceren we TenderTracer Premium, de sleutel tot een wereld vol nieuwe mogelijkheden in de wereld van aanbestedingen. Als lid van TenderTracer Premium krijg je toegang tot baanbrekende functionaliteiten die je helpen de voorhoede van aanbestedingen te bereiken!
                        </p>
                        <h3 className="text-xl font-semibold mb-2">Belangrijkste voordelen:</h3>
                        <ul className="list-none space-y-2 mb-4">
                            {premiumFeatures.map((feature, index) => (
                                <li key={index} className="flex items-center text-sm opacity-80">
                                    <FontAwesomeIcon icon={feature.icon} className="text-primary-pink mr-2" />
                                    <span>{feature.text}</span>
                                </li>
                            ))}
                        </ul>
                        <p className="text-sm opacity-80 mb-4">
                            Ontvang een zorgvuldig samengesteld aanbod van aanbestedingen, perfect afgestemd op jouw unieke zakelijke behoeften. Ons geavanceerde AI-systeem leert van jouw voorkeuren en interesses, waardoor je altijd de meest relevante aanbestedingen ziet en nooit meer een kans mist! Met onze gepersonaliseerde e-mailmeldingen blijf je dagelijks op de hoogte van nieuwe en opkomende aanbestedingen, zowel nationaal als internationaal.
                        </p>
                        <p className="text-sm opacity-80">
                            TenderTracer Premium biedt niet alleen inzicht, maar ook hulp bij het inschrijven op aanbestedingen. Onze uitgebreide concurrentieanalyse geeft je de informatie die je nodig hebt om strategische beslissingen te nemen en je kansen op succes te maximaliseren. Met door AI gestuurde CPV-codes zorgen we ervoor dat je altijd de meest relevante projecten vindt.
                        </p>
                        <p className="text-sm opacity-80 mt-4">
                            Met TenderTracer Premium open je de deur naar een efficiëntere en doelgerichtere manier van aanbestedingen traceren. Maak het verschil in jouw branche en blijf je concurrenten altijd een stap voor. Upgrade nu naar TenderTracer Premium en ervaar hoe wij de wereld van aanbestedingen revolutionair veranderen.
                        </p>
                    </div>
                    <div>
                        <PremiumComp />
                    </div>
                </div>
                <div className="bg-white rounded-lg mt-5 flex p-6 justify-between items-center">
                    <p className="font-bold text-xl">
                        Klaar om uw aanbestedingsstrategie naar het volgende niveau te tillen?
                    </p>
                    <div className="flex items-center">
                        <p className="pr-3 font-bold">€35,-/maand</p>
                        <Button type="pink" onClick={() => setModal(true)}>
                            Upgrade nu
                        </Button>
                    </div>
                </div>
            </div>
            {modal && (
                <PremiumModal
                    stripeId={userInfo.stripe_id}
                    closeModal={() => setModal(false)}
                />
            )}
        </section>
    );
};


//     return (
//         <section className="h-full w-full overflow-y-auto relative">
//             {loading && (
//                 <div className="absolute h-screen w-full bg-black bg-opacity-30 flex flex-col items-center justify-center">
//                     <Spinner />
//                     <p className="pt-3">Betaling aanmaken...</p>
//                 </div>
//             )}

//             <div className="ml-5 mr-5 mt-5">
//                 <div className="bg-white rounded-lg mb-5">
//                     <p className="p-2 font-bold text-lg">
//                         Tendertracer Premium
//                     </p>
//                 </div>
//                 <div className="grid grid-cols-2 gap-5 bg-white rounded-lg p-6 w-full">
//                     <div className="w-full">
//                         <p className="col-span-2 text-begin font-bold">
//                             Leuk dat je geinteresseerd bent in Tendertracer
//                             Premium
//                         </p>
//                         <p className="col-span-2 text-begin pt-5 text-sm opacity-80 font-lighter">
//                             Met trots introduceren we TenderTracer Premium, de
//                             sleutel tot een wereld vol nieuwe mogelijkheden. Als
//                             lid van TenderTracer Premium krijg je toegang tot
//                             baanbrekende functionaliteiten die je helpen de
//                             voorhoede van aanbestedingen te bereiken!
//                         </p>
//                         <p className="col-span-2 text-begin pt-5 text-sm opacity-80 font-lighter">
//                             Ontvang een zorgvuldig samengesteld aanbod van
//                             aanbestedingen, perfect afgestemd op jouw unieke
//                             zakelijke behoeften. Ons geavanceerde systeem leert
//                             van jouw voorkeuren en interesses, waardoor je
//                             altijd de meest relevante aanbestedingen ziet en mis
//                             nooit meer een kans! Met onze gepersonaliseerde
//                             e-mailmeldingen blijf je op de hoogte van nieuwe en
//                             opkomende aanbestedingen. Onze notificaties zorgen
//                             ervoor dat je altijd een stap voor bent, direct
//                             vanuit je mailbox.
//                         </p>
//                         <p className="col-span-2 text-begin pt-5 text-sm opacity-80 font-lighter">
//                             Met TenderTracer Premium open je de deur naar een
//                             efficiëntere en doelgerichtere manier van
//                             aanbestedingen traceren. Maak het verschil in jouw
//                             branche en blijf je concurrenten altijd een stap
//                             voor. Upgrade nu naar TenderTracer Premium en ervaar
//                             hoe wij de wereld van aanbestedingen revolutionair
//                             veranderen.
//                         </p>
//                     </div>
//                     <div>
//                         <PremiumComp />
//                     </div>
//                 </div>
//                 <div className="bg-white rounded-lg mt-5 flex p-6 justify-between">
//                     <p className="p-2 font-bold text-xl">
//                         Waar wacht je nog op?
//                     </p>
//                     <div className="flex items-center">
//                         <p className="pr-3">35,-/maand</p>
//                         <Button type="pink" onClick={() => setModal(true)}>
//                             Upgrade nu
//                         </Button>
//                     </div>
//                 </div>
//             </div>
//             {modal && (
//                 <PremiumModal
//                     stripeId={userInfo.stripe_id}
//                     closeModal={() => setModal(false)}
//                 />
//             )}
//         </section>
//     );
// };

export default Premium;
