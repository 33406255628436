import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

interface InputPhoneProps {
    label: string;
    required?: boolean;
    showRequired?: boolean;
    value: string;
    height?: string;
    onChange: (e: any) => void;
}

export const InputPhone = ({
    label,
    onChange,
    showRequired = false,
    required = false,
    height = "65%",
    value,
}: InputPhoneProps) => {
    return (
        <div className="flex flex-col font-medium">
            <span className="inline-flex justify-between">
                <label htmlFor={label} className="text-sm text-black flex">
                    {label}
                    {required && <p className="text-primary-pink">*</p>}
                </label>
                {required && showRequired && (
                    <p className="text-red leading-3">Dit veld is verplicht</p>
                )}
            </span>
            <PhoneInput
                inputStyle={{
                    width: "100%",
                    maxHeight: "50%",
                    border: "1",
                    borderColor: "#E2E3E8",
                    fontSize: "14px",
                    lineHeight: "20px",
                    boxShadow: "none",
                }}
                buttonStyle={{ height: height }}
                masks={{ nl: ". .. .. .. .." }}
                country={"nl"}
                onlyCountries={["nl", "be"]}
                localization={{ nl: "Nederland", be: "België" }}
                value={value}
                onChange={(phone) => onChange(phone)}
            />
        </div>
    );
};
