import { Auth } from "aws-amplify";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import "../../fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useState } from "react";
import { FiUser } from "react-icons/fi";
import { IoIosLogOut } from "react-icons/io";

interface sidebarProps {
    showPayment: () => void;
    active: boolean;
    subscription: boolean;
}

export default function Sidebar({
    showPayment,
    active,
    subscription,
}: sidebarProps) {
    const [path, setPath] = useState("");
    const navigate = useNavigate();

    async function signOut() {
        try {
            await Auth.signOut();
            window.location.href = "/";
        } catch (err) {
            console.log(err);
        }
    }

    return active ? (
        <div className="h-full bg-white w-[255px] rounded-r-lg drop-shadow-lg relative">
            <div className="w-full bg-white border-l-2 border-l-primary-pink rounded-tr-lg py-2 pr-2 border-b">
                <img
                    className="w-[250px] cursor-pointer"
                    onClick={() => navigate("/")}
                    src="/images/TenderTracer_logo.png"
                />
            </div>
            {active && (
                <ul className="grid py-3 border-b">
                    {appRoutes.map((route: any, index: any) =>
                        route.sidebarProps ? (
                            <SidebarItem
                                item={route}
                                key={index}
                                toggleModal={showPayment}
                                path={path}
                                subscription={subscription}
                                setPath={(e: string) => setPath(e)}
                            />
                        ) : null
                    )}
                </ul>
            )}

            {active && !subscription && (
                <ul
                    className={clsx(
                        "pt-5 flex flex-col border-b items-center cursor-pointer",
                        path === appRoutes[1].path
                            ? "text-white bg-primary-pink"
                            : "hover:bg-primary-pink text-primary-pink hover:text-white"
                    )}
                >
                    <Link
                        className={"pb-5 w-full pl-3 flex hover:drop-shadow-xl"}
                        to={appRoutes[1].path}
                        onClick={() => setPath("premium")}
                    >
                        <div className="pr-[5%] flex items-center">
                            <FontAwesomeIcon
                                className=" animate-bounce"
                                icon="crown"
                            />
                        </div>
                        <span className="text-sm">Stap over naar Premium</span>
                    </Link>
                </ul>
            )}

            <ul className="grid py-3">
                {active && (
                    <Link
                        className={clsx(
                            "grid grid-flow-col auto-cols-max gap-2 p-3 cursor-pointer hover:text-primary-pink",
                            path === appRoutes[0].path &&
                                "border-l-2 border-primary-pink"
                        )}
                        to={appRoutes[0].path}
                        onClick={() => setPath(appRoutes[0].path)}
                    >
                        <div
                            className={clsx(
                                "flex items-center text-primary-blue text-xl",
                                path === appRoutes[0].path &&
                                    "!text-primary-pink"
                            )}
                        >
                            <FiUser />
                        </div>
                        <span
                            className={clsx(
                                path === appRoutes[0].path &&
                                    "text-primary-pink",
                                "text-sm"
                            )}
                        >
                            Instellingen
                        </span>
                    </Link>
                )}
                {!active && (
                    <li
                        className={
                            "grid grid-flow-col auto-cols-max gap-2 p-3 cursor-pointer hover:text-primary-pink"
                        }
                        onClick={signOut}
                    >
                        <div className="flex items-center text-primary-blue text-xl">
                            <IoIosLogOut />
                        </div>
                        <span className="text-sm">
                            Telefoonnummer aanpassen
                        </span>
                    </li>
                )}
                <li
                    className={
                        "grid grid-flow-col auto-cols-max gap-2 p-3 cursor-pointer hover:text-primary-pink"
                    }
                    onClick={signOut}
                >
                    <div className="flex items-center text-primary-blue text-xl">
                        <IoIosLogOut />
                    </div>
                    <span className="text-sm">Uitloggen</span>
                </li>
            </ul>
        </div>
    ) : null;
}
