import { FormEvent, useEffect, useState } from "react";
import { Button } from "../../../../components/Button";
import { Modal } from "../../../../components/Modal";
import "../../../../fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API, graphqlOperation } from "aws-amplify";
import { SelectionSelectorItem } from "../../../../components/SelectionSelectorItem";
import { Spinner } from "../../../../components/Spinner";
import { SelectedSelectionSelectorItem } from "../../../../components/SelectedSelectionSelectorItem";

interface CpvModification {
    cpvs: Cpv;
    companyID: string;
}

interface Cpv {
    cpvCodesUser: any;
    allCpvs: any[];
}

interface CpvCode {
    CODE: string;
    Omschrijving: string;
    markedForDeletion: boolean;
}

export const CpvModification = ({ cpvs, companyID }: CpvModification) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCpvDivisions, setSelectedCpvDivisions] = useState<any>([]);
    const [brancheCpvs, setBrancheCpvs] = useState<any>([]);
    const [companyCpvs, setCompanyCpvs] = useState<any>([]);
    const [customCpvs, setCustomCpvs] = useState<CpvCode[]>([]);
    const [page, setPage] = useState<number>(1);
    const [keyword, setKeyword] = useState<string>("");
    const [filteredCpvs, setFilteredCpvs] = useState<CpvCode[]>([]);
    const [allCpvs, setAllCpvs] = useState<CpvCode[]>([]);
    const [tempCustomCpvs, setTempCustomCpvs] = useState<CpvCode[]>([]);

    useEffect(() => {
        getCpvGroups();
        loadAllCpvCodes();
    }, []);

    useEffect(() => {
        if (showModal) {
            setTempCustomCpvs(customCpvs.map((cpv) => ({...cpv, markedForDeletion: false})));
        }
    }, [showModal]);

    useEffect(() => {
        handleSearch();
    }, [keyword]);

    const loadAllCpvCodes = () => {
        const cpvCodes = require("../../../../data/cpvCodes.json");
        const validCpvs = cpvCodes["CPV codes"].filter((cpv: CpvCode) => 
            cpv && typeof cpv === 'object' && 'CODE' in cpv && 'Omschrijving' in cpv
        );
        setAllCpvs(validCpvs);
    }

    const handleSearch = () => {
        const filtered = allCpvs.filter((cpv: CpvCode) => {
            const code = cpv.CODE?.toLowerCase() || '';
            const description = cpv.Omschrijving?.toLowerCase() || '';
            const searchTerm = keyword.toLowerCase();
            return code.includes(searchTerm) || description.includes(searchTerm);
        });
        setFilteredCpvs(filtered);
    };

    function handleOnClick(item: CpvCode) {
        setTempCustomCpvs((prevCpvs: CpvCode[]) => {
            const existingIndex = prevCpvs.findIndex(cpv => cpv.CODE === item.CODE);
            if (existingIndex !== -1) {
                // Toggle de markedForDeletion status als de CPV al bestaat
                return prevCpvs.map(cpv => 
                    cpv.CODE === item.CODE 
                        ? {...cpv, markedForDeletion: !cpv.markedForDeletion} 
                        : cpv
                );
            } else {
                // Voeg de nieuwe CPV toe als deze nog niet bestaat
                return [...prevCpvs, {...item, markedForDeletion: false}];
            }
        });
    }

    function handleSelectionModification(item: any) {
        if (selectedCpvDivisions.includes(item)) {
            const newListDivisions = selectedCpvDivisions.filter(
                (cpvDivision: any) => cpvDivision.code !== item.code
            );
            const newListGroups = brancheCpvs.filter(
                (cpvGroup: any) =>
                    cpvGroup.code.slice(0, 2) !== item.code.slice(0, 2)
            );
            const newListClass = companyCpvs.filter(
                (cpvClass: any) =>
                    cpvClass.code.slice(0, 2) !== item.code.slice(0, 2)
            );
            setSelectedCpvDivisions(newListDivisions);
            setBrancheCpvs(newListGroups);
            setCompanyCpvs(newListClass);
        } else {
            if (selectedCpvDivisions.length < 5) {
                setSelectedCpvDivisions([...selectedCpvDivisions, item]);
            }
        }
    }

    function TreeMap(cpvDivision: any, index: any) {
        const [showChildren, setShowChildren] = useState<boolean>(true);

        function TreeMapItem(cpvGroup: any, index: any) {
            const [checked, setChecked] = useState<boolean>(
                brancheCpvs.some(
                    (cpvBranche: any) => cpvBranche.code === cpvGroup.item.code
                )
            );

            function HandleClick() {
                if (
                    brancheCpvs.some(
                        (cpvBranche: any) =>
                            cpvBranche.code === cpvGroup.item.code
                    )
                ) {
                    const newList = brancheCpvs.filter(
                        (cpvBranche: any) =>
                            cpvBranche.code !== cpvGroup.item.code
                    );
                    setBrancheCpvs(newList);
                    setChecked(false);
                } else {
                    setBrancheCpvs([...brancheCpvs, cpvGroup.item]);
                    setChecked(true);
                }
            }

            return (
                <div
                    className="p-3 border-l border-opacity-20 flex items-center border-b ml-5 border-primary-blue cursor-pointer"
                    onClick={() => HandleClick()}
                    key={index}
                >
                    <div className="w-9 flex items-center">
                        <input
                            className="w-5 h-5"
                            type="checkbox"
                            checked={checked}
                            onChange={() => ""}
                        />
                    </div>
                    <p>{cpvGroup.item.description}</p>
                </div>
            );
        }

        return (
            <div key={index}>
                <div
                    className="p-3 border-y border-primary-blue flex cursor-pointer"
                    onClick={() => setShowChildren(!showChildren)}
                >
                    <div onClick={() => ""} className="pr-3">
                        <FontAwesomeIcon
                            color="#284F82"
                            icon={
                                showChildren ? "chevron-down" : "chevron-right"
                            }
                        />
                    </div>
                    <p className="text-primary-blue font-bold">
                        {cpvDivision.data.description}
                    </p>
                </div>
                {showChildren &&
                    cpvDivision.data.cpv_groups.items.map(
                        (cpvGroup: any, index: any) => (
                            <div key={index}>
                                <TreeMapItem item={cpvGroup} index={index} />
                            </div>
                        )
                    )}
            </div>
        );
    }

    function TreeMapCompany(cpvGroup: any, index: any) {
        const [showChildren, setShowChildren] = useState<boolean>(true);

        function TreeMapItem(cpvClass: any, index: any) {
            const [checked, setChecked] = useState<boolean>(
                companyCpvs.some(
                    (cpvCompany: any) => cpvCompany.code === cpvClass.item.code
                )
            );

            function HandleClick() {
                if (checked) {
                    setCompanyCpvs((prevCpvs:any) => prevCpvs.filter((cpv: any) => cpv.code !== cpvClass.item.code));
                } else {
                    setCompanyCpvs((prevCpvs:any) => [...prevCpvs, cpvClass.item]);
                }
                setChecked(!checked);
            }

            return (
                <div
                    className="p-3 border-l border-opacity-20 flex items-center border-b ml-5 border-primary-blue cursor-pointer"
                    onClick={() => HandleClick()}
                    key={index}
                >
                    <div className="w-9 flex items-center">
                        <input
                            className="w-5 h-5"
                            type="checkbox"
                            checked={checked}
                            onChange={() => {}}
                        />
                    </div>
                    <p>{cpvClass.item.description}</p>
                </div>
            );
        }

        return (
            <div key={index}>
                <div
                    className="p-3 border-y border-primary-blue flex cursor-pointer"
                    onClick={() => setShowChildren(!showChildren)}
                >
                    {cpvGroup.data.cpv_classes.items.length > 0 ? (
                        <div onClick={() => ""} style={{ paddingRight: "1%" }}>
                            <FontAwesomeIcon
                                color="#284F82"
                                icon={
                                    showChildren
                                        ? "chevron-down"
                                        : "chevron-right"
                                }
                            />
                        </div>
                    ) : (
                        <div className="w-9 flex items-center">
                            <input
                                className="w-5 h-5"
                                type="checkbox"
                                checked={true}
                                disabled
                            />
                        </div>
                    )}
                    <p className="text-primary-blue font-bold">
                        {cpvGroup.data.description}
                    </p>
                </div>
                {showChildren &&
                    cpvGroup.data.cpv_classes.items.map(
                        (cpvClass: any, index: any) => (
                            <div key={index}>
                                <TreeMapItem item={cpvClass} index={index} />
                            </div>
                        )
                    )}
            </div>
        );
    }

    async function getCpvGroups() {
        var cpvGroupIds: string[] = [];
        var cpvDivisionIds: string[] = [];
        await Promise.all(
            cpvs.cpvCodesUser.cpv.items.map((cpvGroup: any) => {
                const cpvDivision = cpvs.allCpvs.find(
                    (cpvDivision: any) =>
                        cpvDivision.id === cpvGroup.cpvId.slice(0, 2)
                );
                const cpvDivGroup = cpvDivision.cpv_groups.items.find(
                    (cpvDivGroup: any) =>
                        cpvDivGroup.id === cpvGroup.cpvId.slice(0, 3)
                );
                cpvGroupIds.push(cpvDivGroup);
                if (!cpvDivisionIds.includes(cpvDivision)) {
                    cpvDivisionIds.push(cpvDivision);
                }
            })
        );
        var cpvClassIds: any[] = [];
        await Promise.all(
            cpvs.cpvCodesUser.aiCPV.items.map((cpvClass: any) =>
                cpvClassIds.push(cpvClass.cpv)
            )
        );
        var cpvCustomIds: CpvCode[] = [];
        await Promise.all(
            cpvs.cpvCodesUser.aiCPV.items.map((cpvClass: any) => {
                if (
                    !cpvGroupIds.some(
                        (cpvGroup: any) =>
                            cpvGroup.code.slice(0, 3) ===
                            cpvClass.cpv.code.slice(0, 3)
                    )
                ) {
                    cpvCustomIds.push({
                        CODE: cpvClass.cpv.code,
                        Omschrijving: cpvClass.cpv.description,
                        markedForDeletion: false,
                    });
                }
            })
        );
        setBrancheCpvs(cpvGroupIds);
        setCompanyCpvs(cpvClassIds);
        setCustomCpvs(cpvCustomIds);
        setSelectedCpvDivisions(cpvDivisionIds);
    }

    async function handleData() {
        setLoading(true);
    
        console.log("Current companyCpvs:", companyCpvs);
        console.log("Current customCpvs:", customCpvs);

        // Filter alleen de niet-gemarkeerde items uit tempCustomCpvs
        const updatedCustomCpvs = tempCustomCpvs.filter((cpv: CpvCode) => !cpv.markedForDeletion);
    
        // Update de lokale state
        setCustomCpvs(updatedCustomCpvs);

        // Update companyCpvs door items te verwijderen die gemarkeerd zijn voor verwijdering
        const updatedCompanyCpvs = companyCpvs.filter((cpv: any) => 
            !tempCustomCpvs.some((tempCpv: CpvCode) => 
                tempCpv.CODE === cpv.code && tempCpv.markedForDeletion
            )
        );
        setCompanyCpvs(updatedCompanyCpvs);

        // Verwijder alleen de bestaande bedrijfsspecifieke items
        await deleteBranchItems();
        await deleteCompanyItems();

        // Creëer nieuwe items alleen voor de bijgewerkte bedrijfsspecifieke lijsten
        await createBranchItems();
        await createCompanyItems(updatedCompanyCpvs, updatedCustomCpvs);

        window.location.reload();

        setLoading(false);
        setShowModal(false);
        getCpvGroups();
    }

    async function deleteCompanyItems() {
        const companyQuery = `query MyQuery { aiCpvsByCompanyId(companyId: "${companyID}") { items { id } } }`;
        const deleteCompanyQuery = (id: string) =>
            `mutation MyMutation { deleteAiCpvs(input: {id: "${id}"}) { id } }`;
    
        try {
            const fetchedCompanyCpvs: any = await API.graphql(
                graphqlOperation(companyQuery)
            );
    
            await Promise.all(
                fetchedCompanyCpvs.data.aiCpvsByCompanyId.items.map(
                    async (cpv: any) => {
                        await API.graphql(
                            graphqlOperation(deleteCompanyQuery(cpv.id))
                        );
                    }
                )
            );
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    
    async function createCompanyItems(updatedCompanyCpvs: any[], updatedCustomCpvs: CpvCode[]) {
        const createCompanyQuery = (cpv: string) => `mutation MyMutation { createAiCpvs(input: {id: "${companyID.concat(cpv)}", cpvId: "${cpv}", companyId: "${companyID}"}) { id } }`;
    
        try {
            // Creëer items voor de bijgewerkte companyCpvs
            await Promise.all(
                updatedCompanyCpvs.map(async (cpv: any) => {
                    await API.graphql(
                        graphqlOperation(createCompanyQuery(cpv.code))
                    );
                })
            );
    
            // Creëer items voor de bijgewerkte customCpvs
            await Promise.all(
                updatedCustomCpvs.map(async (cpv: CpvCode) => {
                    if (!updatedCompanyCpvs.some((companyCpv: any) => companyCpv.code === cpv.CODE)) {
                        await API.graphql(
                            graphqlOperation(createCompanyQuery(cpv.CODE.split("-")[0]))
                        );
                    }
                })
            );
    
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function deleteBranchItems() {
        const brancheQuery = `query MyQuery { cpvCompaniesByCompanyId(companyId: "${companyID}") { items { id } } }`;
        const deleteBrancheQuery = (id: string) =>
            `mutation MyMutation { deleteCpvCompany(input: {id: "${id}"}) { id } }`;
    
        try {
            const fetchedBrancheCpvs: any = await API.graphql(
                graphqlOperation(brancheQuery)
            );
    
            await Promise.all(
                fetchedBrancheCpvs.data.cpvCompaniesByCompanyId.items.map(
                    async (cpv: any) => {
                        await API.graphql(
                            graphqlOperation(deleteBrancheQuery(cpv.id))
                        );
                    }
                )
            );
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function createBranchItems() {
        const createBrancheQuery = (cpv: string) => `mutation CreateCpvCompany { createCpvCompany(input: {id: "${companyID.concat(cpv)}", cpvId: "${cpv}", companyId: "${companyID}"}) { id } }`;
    
        try {
            await Promise.all(
                brancheCpvs.map(async (cpv: any) => {
                    await API.graphql(
                        graphqlOperation(createBrancheQuery(cpv.code))
                    );
                })
            );
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <div className="overflow-y-hidden grid grid-cols-3 gap-5 mx-5">
            <div className="bg-white shadow border rounded-lg p-5 row-span-4">
                <div className="flex justify-between items-center">
                    <h1 className="font-bold text-xl text-primary-blue">
                        Jouw brancheselectie:
                    </h1>
                </div>
                <div className="pt-5 pb-10">
                    {cpvs.cpvCodesUser.cpv.items.map((cpv: any, index: any) => (
                        <div
                            key={index}
                            className="border-b border-primary-blue p-4 flex justify-between items-center"
                        >
                            <p>{cpv.cpv.description}</p>
                            <div className="group relative flex justify-end">
                                <FontAwesomeIcon
                                    className="opacity-30"
                                    icon={["far", "circle-question"]}
                                />
                                <span className="absolute top-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                                    {cpv.cpvId}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="bg-white shadow border rounded-lg p-5 row-span-4">
                <div className="flex justify-between items-center">
                    <h1 className="font-bold text-xl text-primary-blue">
                        Jouw bedrijfsselectie:
                    </h1>
                </div>
                <div className="pt-5 pb-10">
                    {cpvs.cpvCodesUser.aiCPV.items.map(
                        (cpv: any, index: any) => (
                            <div
                                key={index}
                                className="border-b border-primary-blue p-4 flex justify-between items-center"
                            >
                                <p>{cpv.cpv.description}</p>
                                <div className="group relative flex justify-end">
                                    <FontAwesomeIcon
                                        className="opacity-30"
                                        icon={["far", "circle-question"]}
                                    />
                                    <span className="absolute top-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                                        {cpv.cpvId}
                                    </span>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
            <div className="">
                <div className="bg-white shadow border rounded-lg p-10 h-[120px]">
                    <div className="flex justify-between items-center">
                        <h1 className="font-bold text-xl">
                            Selectie wijzigen?
                        </h1>
                        <Button
                            type="secondary"
                            onClick={() => setShowModal(true)}
                        >
                            +/- Wijzigen
                        </Button>
                    </div>
                </div>
            </div>
            {showModal ? (
                <Modal
                    title={
                        page === 1
                            ? "Sectoren"
                            : page === 2
                            ? "Branchespecifiek"
                            : "Bedrijfsspecifiek"
                    }
                    icon="pencil"
                    subtitle={
                        page === 1
                            ? "Laten we starten met het wijzigen van sectoren waarin je bedrijf actief is. Klik op de pijl om de sector (maximaal 5) te kiezen. Alle sectoren bepaald? Klik dan op volgende."
                            : page === 2
                            ? "We wijzigen eerst jouw branchespecifieke sectoren. Zo weten we in welke branche je zit en voorkomen we dat je een aanbesteding mist wanneer de overheid verkeerd codeert. Door een vinkje bij te zetten kun je, jouw selectie uitbreiden."
                            : "Nu wijzigen we wat echt specifiek is voor jouw bedrijf. We bepalen dus jouw bedrijfsselectie. Door een vinkje bij te zetten kun je, jouw selectie uitbreiden. Ook kan je handmatig CPV-codes invoeren als je zelf al op de hoogte bent van je codes."
                    }
                    onClick={() => setShowModal(false)}
                >
                    {page === 1 && (
                        <div className="flex flex-col items-center w-full justify-center mt-5">
                            <div className="lg:flex w-[90%] h-[600px] border-primary-blue rounded-lg">
                                <div className="lg:w-1/2 w-full border rounded-l-lg lg:h-full h-[500px] border-r border-primary-blue relative">
                                    <div className="w-full h-[60px] bg-light-blue flex items-center pl-5 rounded-tl-lg border-r border-white">
                                        <p className="text-white font-bold">
                                            Sectoren
                                        </p>
                                    </div>
                                    <div className="absolute lg:h-[90%] h-[80%] w-full overflow-y-auto overflow-x-hidden">
                                        {cpvs.allCpvs.length > 0 ? (
                                            cpvs.allCpvs.map(
                                                (
                                                    cpvDivision: any,
                                                    index: any
                                                ) => (
                                                    <div key={index}>
                                                        {!selectedCpvDivisions.some(
                                                            (e: any) =>
                                                                e.id ===
                                                                cpvDivision.id
                                                        ) && (
                                                            <SelectionSelectorItem
                                                                name={
                                                                    cpvDivision.description
                                                                }
                                                                onClick={() =>
                                                                    handleSelectionModification(
                                                                        cpvDivision
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                )
                                            )
                                        ) : (
                                            <div className="flex justify-center items-center h-full">
                                                <Spinner />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="lg:w-1/2 lg:mt-0 mt-5 w-full lg:h-full h-[500px] border rounded-r-lg border-primary-blue">
                                    <div className="w-full h-[60px] bg-primary-blue rounded-tr-lg flex items-center pl-5 border-tr-lg">
                                        <p className="text-white font-bold">
                                            Uw selectie (
                                            {selectedCpvDivisions.length}
                                            /5)
                                        </p>
                                    </div>
                                    <div
                                        className="w-full overflow-y-auto overflow-x-auto"
                                        style={{ height: "calc(100vh - 60px)" }}
                                    >
                                        {selectedCpvDivisions.map(
                                            (cpvDivision: any, index: any) => (
                                                <div key={index}>
                                                    <SelectedSelectionSelectorItem
                                                        name={
                                                            cpvDivision.description
                                                        }
                                                        onClick={() =>
                                                            handleSelectionModification(
                                                                cpvDivision
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {page === 2 && (
                        <div className="flex flex-col items-center w-full justify-center mt-5">
                            <div className="flex w-[90%] lg:h-[70%] h-[500px] border border-primary-blue rounded-lg">
                                <div className="w-full h-full">
                                    <div className="w-full h-[60px] bg-light-blue flex items-center pl-5 rounded-t-lg">
                                        <p className="text-white font-bold">
                                            Sectoren
                                        </p>
                                    </div>
                                    <div className="lg:h-[90%] h-[80%] w-full overflow-y-auto overflow-x-hidden">
                                        {selectedCpvDivisions.map(
                                            (cpvDivision: any, index: any) => (
                                                <div key={index}>
                                                    <TreeMap
                                                        data={cpvDivision}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {page === 3 && (
                        <div className="flex flex-col items-center w-full justify-center mt-5">
                            <div className="w-[90%] lg:h-[70%] h-[500px] border border-primary-blue rounded-lg">
                                <div className="w-full h-full">
                                    <div className="w-full h-[60px] bg-light-blue flex items-center pl-5 rounded-t-lg">
                                        <p className="text-white font-bold">
                                            Sectoren
                                        </p>
                                    </div>
                                    <div className="lg:h-[90%] h-[80%] w-full overflow-y-auto overflow-x-hidden">
                                        {brancheCpvs.map(
                                            (cpvGroup: any, index: any) => (
                                                <div key={index}>
                                                    <TreeMapCompany
                                                        data={cpvGroup}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="w-[90%] text-left rounded-lg">
                                <div className="h-[60px] flex items-center pl-5 bg-light-blue rounded-t-lg mt-5 border border-primary-blue">
                                    <p className="px-1 text-white">
                                        Zelf interessegebieden, deelsectoren of
                                        CPV-codes zoeken en toevoegen.
                                    </p>
                                </div>
                                <div className="w-full border-b border-x border-primary-blue rounded-b-lg">
                                    <div className="p-3">
                                        <input
                                            type="text"
                                            placeholder="Voer hier je interessegebied, deelsector of CPV-code in..."
                                            value={keyword}
                                            onChange={(e) => setKeyword(e.target.value)}
                                            className="w-full p-2 border rounded"
                                        />
                                    </div>
                                    <div className="border-y max-h-60 overflow-y-auto">
                                        {(keyword ? filteredCpvs : tempCustomCpvs).map((cpvCode: CpvCode, index: number) => {
                                            const isSelected = tempCustomCpvs.some((tempCpv) => tempCpv.CODE === cpvCode.CODE && !tempCpv.markedForDeletion);
                                            return (
                                                <div key={index} onClick={() => handleOnClick(cpvCode)} className={`cursor-pointer hover:bg-gray-100 ${isSelected ? '' : 'opacity-50'}`}>
                                                    <div className="p-3 flex items-center">
                                                        <input
                                                            type="checkbox"
                                                            checked={isSelected}
                                                            readOnly
                                                            className="mr-2"
                                                        />
                                                        <span className="text-primary-pink">{cpvCode.CODE || 'N/A'}</span>
                                                        <span className="ml-2">- {cpvCode.Omschrijving || 'Geen omschrijving'}</span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="mt-5 mb-10 w-full flex items-center justify-center">
                        <div className="flex justify-between w-[90%]">
                            {loading ? (
                                <div className="w-full flex justify-end">
                                    <Button
                                        className="mt-5"
                                        buttonType="submit"
                                        type="hidden"
                                    >
                                        <div className="flex items-center justify-end">
                                            <div className="pr-2">
                                                <p>Laden...</p>
                                            </div>
                                            <div>
                                                <Spinner />
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                            ) : (
                                <>
                                    <Button
                                        type="ghost"
                                        onClick={() => setPage(page - 1)}
                                    >
                                        Vorige
                                    </Button>
                                    {(selectedCpvDivisions.length === 0 &&
                                        page === 1) ||
                                    (brancheCpvs.length === 0 && page === 2) ? (
                                        <Button type="hidden">
                                            Selecteer minimaal 1 sector om
                                            verder te gaan
                                        </Button>
                                    ) : (
                                        <Button
                                            type="secondary"
                                            onClick={() =>
                                                page === 3
                                                    ? handleData()
                                                    : setPage(page + 1)
                                            }
                                        >
                                            {page === 3
                                                ? "Opslaan en afsluiten"
                                                : "Volgende"}
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </Modal>
            ) : null}
        </div>
    );
};