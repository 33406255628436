import clsx from "clsx";
import Moment from "moment";

interface Props {
    updates: any[];
}

export default function Updates({ updates }: Props) {
    return (
        <>
            {updates.map((update, index) => (
                <div
                    key={index}
                    className={clsx(
                        "bg-white rounded-lg p-3 mb-2",
                        update.coming_soon &&
                            "italic text-gray-500 bg-opacity-50",
                        update.active && "bold bg-primary-pink text-white"
                    )}
                >
                    <p className="font-bold border-b pb-1">{update.title}</p>
                    <p className="italic text-sm pt-1">{update.description}</p>
                    <p className="italic text-xs pt-1">
                        {Moment(update.createdAt).format("LL")}
                    </p>
                </div>
            ))}
        </>
    );
}
