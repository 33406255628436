import { ChartOptions } from "chart.js";
import { useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import { Spinner } from "../../../components/Spinner";

interface ProcedureChartProps {
    data: any;
}

export function TendersRegionChart({ data }: ProcedureChartProps) {
    const [typeData, setTypeData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const averageChart = {
        datasets: [
            {
                data: typeData,
                label: "Aantal aanbestedingen",
                backgroundColor: "#00aaaa",
                order: 2,
            },
        ],
    };

    const mixedOptions: ChartOptions = {
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: false,
            },
        },
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 0,
                left: 10,
                right: 10,
                bottom: 0,
            },
        },
    };

    useEffect(() => {
        handleData();
    }, []);

    async function handleData() {
        const output = Object.entries(
            data.reduce((prev: any, label: any) => {
                prev[label["AD plaats"]] = prev[label["AD plaats"]]
                    ? prev[label["AD plaats"]] + 1
                    : 1;
                return prev;
            }, {})
        )
            .map(([y, x]) => ({ y, x }))
            .sort((a: any, b: any) => b.x - a.x);

        const filteredOutput = output.filter((element) => element.x !== "NaN");

        setTypeData(filteredOutput.slice(0, 5));

        setLoading(false);
    }

    return loading ? (
        <Spinner />
    ) : (
        <Chart
            type="bar"
            className="w-full"
            data={averageChart}
            options={mixedOptions}
        />
    );
}
