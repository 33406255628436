import { useEffect } from "react";
import { LoginComp } from "./components/Login";

const Login = () => {
    useEffect(() => {
        document.title = "Inloggen | TenderTracer";
    }, []);

    return (
        <section className="h-screen w-screen overflow-y-auto flex justify-center pt-[10%] relative bg-[#284F82] bg-center bg-cover bg-[url('/public/images/onboarding_bg.webp')]">
            <LoginComp />
        </section>
    );
};

export default Login;
