import { FormEvent, useState, useEffect } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { Spinner } from "../../../components/Spinner";
import useAnalyticsEventTracker from "../../../tracker/EventTracker";
import toast from "react-hot-toast";
import { errorToast } from "../../../lib/Toaster";

export const ForgotPasswordComp = () => {
    const [email, setEmail] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [password1, setPassword1] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<any>([]);
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        EventTracker();
    }, []);

    function EventTracker() {
        useAnalyticsEventTracker(
            "Forgot Password",
            "Bekijkt Wachtwoord Vergeten",
            "Wachtwoord vergeten"
        );
    }

    async function handleReset() {
        setLoading(true);
        var checkup = Validator();
        if (checkup && page === 1) {
            try {
                await Auth.forgotPassword(email);
                setPage(page + 1);
            } catch (error) {
                setErrorMessage([`Error: ${error}`]);
                toast.error(`Error: ${error}.`, errorToast);
                setLoading(false);
            }
        }
        if (checkup && page === 3) {
            try {
                await Auth.forgotPasswordSubmit(email, code, password1);
                toast.error(
                    "Je wachtwoord is gewijzigd. Je kunt nu inloggen met je nieuwe wachtwoord.",
                    errorToast
                );
                navigate("/login");
            } catch (error) {
                setErrorMessage([`Error: ${error}`]);
                toast.error(`Error: ${error}.`, errorToast);
            }
        }
        if (checkup && page === 2) {
            setPage(page + 1);
        }
        setLoading(false);
    }

    function Validator() {
        var errors = [];
        if (page === 1) {
            if (email === "") {
                errors.push("Vul alle velden in.");
                toast.error("Vul alle velden in.", errorToast);
            }
            if (!email.includes("@") && !email.includes(".")) {
                errors.push("Vul een geldig emailadres in.");
                toast.error("Vul een geldig emailadres in.", errorToast);
            }
        }
        if (page === 2) {
            if (code === "") {
                errors.push("Vul alle velden in.");
                toast.error("Vul alle velden in.", errorToast);
            }
        }
        if (page === 3) {
            if (password1 !== password2) {
                errors.push("Je wachtwoorden komen niet met elkaar overeen.");
                toast.error(
                    "Je wachtwoorden komen niet met elkaar overeen.",
                    errorToast
                );
            }
            if (password1.length < 6 || password1.length > 99) {
                errors.push(
                    "Je wachtwoord moet bestaan uit minimaal 6 en maximaal 99 karakters."
                );
                toast.error(
                    "Je wachtwoord moet bestaan uit minimaal 6 en maximaal 00 karakters.",
                    errorToast
                );
            }
            if (!/[A-Z]/.test(password1)) {
                errors.push(
                    "Je wachtwoord moet minimaal 1 hoofdletter bevatten."
                );
                toast.error(
                    "Je wachtwoord moet minimaal 1 hoofdletter bevatten.",
                    errorToast
                );
            }
            if (!/[a-z]/.test(password1)) {
                errors.push(
                    "Je wachtwoord moet minimaal 1 kleine letter bevatten."
                );
                toast.error(
                    "Je wachtwoord moet minimaal 1 kleine letter bevatten.",
                    errorToast
                );
            }
            if (!/[0-9]/.test(password1)) {
                errors.push("Je wachtwoord moet minimaal 1 cijfer bevatten.");
                toast.error(
                    "Je wachtwoord moet minimaal 1 cijfer bevatten.",
                    errorToast
                );
            }
            if (!/[!@#$%^&*]/.test(password1)) {
                errors.push("Je wachtwoord moet minimaal 1 symbool bevatten.");
                toast.error(
                    "Je wachtwoord moet minimaal 1 symbool bevatten.",
                    errorToast
                );
            }
        }

        if (errors.length > 0) {
            setErrorMessage(errors);
            return false;
        } else {
            setErrorMessage([]);
            return true;
        }
    }

    return (
        <div className="ml-5 mr-5 mt-5 w-[500px]">
            <div className="bg-white rounded-lg mb-2 p-2 border shadow">
                <div className="w-full bg-white rounded-lg py-2">
                    <img
                        className="w-[150px]"
                        src="/images/TenderTracer_logo.png"
                    />
                </div>
                <p className="font-bold pl-3 text-lg">Wachtwoord vergeten</p>
                <p className="px-3 text-sm">
                    Voer het emailadres in die bij ons bekend staat en druk op
                    versturen. Je ontvangt vervolgens per mail een 6 cijferig
                    code. Voer deze bij de verificatiecode in en pas je
                    wachtwoord aan.
                </p>
                <form
                    className="flex flex-col p-3"
                    onSubmit={(event) => event.preventDefault()}
                >
                    {page === 1 && (
                        <Input
                            label="Emailadres"
                            value={email}
                            required={false}
                            type="email"
                            autofill="on"
                            placeholder="john@tendertracer.nl"
                            onChange={(e: FormEvent<HTMLInputElement>) =>
                                setEmail(e.currentTarget.value.toString())
                            }
                        />
                    )}
                    {page === 2 && (
                        <Input
                            label="Verificatiecode"
                            value={code}
                            required={false}
                            type="number"
                            placeholder="------"
                            onChange={(e: FormEvent<HTMLInputElement>) =>
                                setCode(e.currentTarget.value.toString())
                            }
                        />
                    )}
                    {page === 3 && (
                        <div className="space-y-2">
                            <Input
                                label="Nieuw wachtwoord"
                                value={password1}
                                required={false}
                                type="password"
                                placeholder="******"
                                onChange={(e: FormEvent<HTMLInputElement>) =>
                                    setPassword1(
                                        e.currentTarget.value.toString()
                                    )
                                }
                            />
                            <Input
                                label="Herhaal wachtwoord"
                                value={password2}
                                required={false}
                                type="password"
                                placeholder="******"
                                onChange={(e: FormEvent<HTMLInputElement>) =>
                                    setPassword2(
                                        e.currentTarget.value.toString()
                                    )
                                }
                            />
                        </div>
                    )}
                    <div className="flex justify-end mt-5 w-full">
                        <Button
                            type={!loading ? "secondary" : "hidden"}
                            onClick={() =>
                                !loading ? handleReset() : undefined
                            }
                        >
                            {!loading ? (
                                "VOLGENDE"
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <div style={{ paddingRight: "15%" }}>
                                        <p>Laden...</p>
                                    </div>
                                    <div>
                                        <Spinner />
                                    </div>
                                </div>
                            )}
                        </Button>
                    </div>
                </form>
            </div>
            <div className="bg-white border shadow p-3 rounded-lg text-center">
                <Link
                    className="cursor-pointer text-primary-pink hover:underline"
                    to={"/login"}
                >
                    Terug naar inloggen
                </Link>
            </div>
        </div>
    );
};
