import { API, Auth, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import aiSpinner from "../../../lottie/Animation - 1709894762114.json";
import { csvString } from "../../../data/cpvGroep2022data";
import { readString } from "react-papaparse";
import { API_DEFAULT_HEADERS, API_DOMAIN } from "../../../APIconfig";
import Lottie from "lottie-react";

interface Props {
    onClose: () => void;
    onNext: () => void;
    keywords: string[];
    selection: any[];
    setSelection: (selection: any) => void;
}

export default function ResultsOnboarding({
    onClose,
    onNext,
    keywords,
    selection,
    setSelection,
}: Props) {
    const [loadingPhase, setLoadingPhase] = useState<number>(1);
    const [response, setResponse] = useState<any[]>([]);
    const [summaryItemsValue, setSummaryItemsValue] = useState<number>(0);
    const [summaryItemsEmails, setSummaryItemsEmails] = useState<number>(0);
    const [allSummaryItems, setAllSummaryItems] = useState<any[]>([]);

    useEffect(() => {
        readString(csvString, {
            worker: true,
            header: true,
            complete: (results) => {
                setAllSummaryItems(results.data);
            },
        });
    }, []);

    useEffect(() => {
        if (loadingPhase === 1) getCPVsFromAI();
        if (loadingPhase === 2) calculateSummary();
    }, [loadingPhase]);

    async function calculateSummary() {
        var totalValues: any[] = [0];
        var totalEmails: any[] = [0];
        allSummaryItems.forEach((item: any) => {
            if (
                selection.some(
                    (e) => item._key === e.cpvcode.toString().substring(0, 3)
                )
            ) {
                if (Number(item.value)) {
                    totalValues[0] =
                        Number(totalValues[0]) + Number(item.value);
                }
                if (Number(item.emails)) {
                    totalEmails[0] =
                        Number(totalEmails[0]) + Number(item.emails);
                }
            }
        });
        setSummaryItemsValue(totalValues[0]);
        setSummaryItemsEmails(totalEmails[0]);
        setTimeout(function () {
            linkCPVtoCompany();
        }, 5000);
    }

    async function linkCPVtoCompany() {
        const { attributes } = await Auth.currentAuthenticatedUser();
        const InfoQuery = `query MyQuery { getUser(id: "${attributes.sub}") { companies { items { company { id } } } } }`;
        const fetchedUserInfo: any = await API.graphql(
            graphqlOperation(InfoQuery)
        );
        const companyID: string =
            fetchedUserInfo.data.getUser.companies.items[0].company.id;

        Auth.currentSession().then((res) => {
            let accessToken = res.getIdToken();
            let jwt = accessToken.getJwtToken();

            var requestOptions: any = {
                method: "POST",
                headers: new Headers(API_DEFAULT_HEADERS(jwt)),
                body: JSON.stringify({
                    userID: attributes.sub,
                    companyID: companyID,
                    cpvs: selection,
                }),
            };

            fetch(`${API_DOMAIN}/user/cpvlink`, requestOptions)
                .then(async (response) => {
                    onNext();
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }

    async function getCPVsFromAI() {
        try {
            // prettier-ignore
            const parsedData: any[] = [];
            const cpvCodes = await require("../../../data/cpvCodes.json");
            Auth.currentSession().then((res) => {
                let accessToken = res.getIdToken();
                let jwt = accessToken.getJwtToken();

                const requestOptions: RequestInit = {
                    method: "POST",
                    headers: new Headers(API_DEFAULT_HEADERS(jwt)),
                    body: JSON.stringify({
                        keywords: `${keywords.map((item: any) => `${item},`)}`,
                    }),
                };

                fetch(`${API_DOMAIN}/ai/cpvgenerator`, requestOptions)
                    .then(async (response: any) => {
                        const jsonResponse = await response.json();
                        const jsonBody = await JSON.parse(
                            JSON.parse(jsonResponse.body)
                        );
                        const AIdata = await jsonBody.cpvcodes.map(
                            (cpvCode: any) => {
                                const formattedCpv = cpvCode.includes("-")
                                    ? cpvCode.split("-")[0]
                                    : cpvCode;
                                if (
                                    !parsedData.find(
                                        (data) => data.cpvcode === formattedCpv
                                    )
                                ) {
                                    if (
                                        cpvCodes["CPV codes"].find((cpv: any) =>
                                            cpv.CODE.includes(formattedCpv)
                                        )
                                    ) {
                                        parsedData.push({
                                            cpvcode: formattedCpv,
                                            omschrijving: cpvCodes[
                                                "CPV codes"
                                            ].find((cpv: any) =>
                                                cpv.CODE.includes(formattedCpv)
                                            )?.Omschrijving,
                                        });
                                    }
                                }
                            }
                        );
                        const blacklist = [
                            "van",
                            "de",
                            "het",
                            "bouw",
                            "bouwen",
                            "software",
                        ];
                        const fileData = keywords.map(async (text: any) => {
                            const keyword = text.split(" ");
                            await keyword.every(async (keyword: string) => {
                                if (!blacklist.includes(keyword)) {
                                    try {
                                        await cpvCodes["CPV codes"]
                                            .filter((cpv: any) =>
                                                cpv.Omschrijving.toString()
                                                    .toLowerCase()
                                                    .includes(
                                                        keyword.toLowerCase()
                                                    )
                                            )
                                            .map((item: any) => {
                                                if (
                                                    !parsedData.find(
                                                        (data) =>
                                                            data.cpvcode ===
                                                            item.CODE.split(
                                                                "-"
                                                            )[0]
                                                    )
                                                ) {
                                                    parsedData.push({
                                                        cpvcode:
                                                            item.CODE.split(
                                                                "-"
                                                            )[0],
                                                        omschrijving:
                                                            item.Omschrijving,
                                                    });
                                                }
                                            });
                                    } catch (error) {}
                                }
                            });
                        });
                        setResponse(parsedData);
                        setSelection(parsedData);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        } catch (error) {
            console.log(error);
        } finally {
            setTimeout(function () {
                setLoadingPhase(2);
            }, 5000);
        }
        // console.log(jsonResponse);
    }

    return (
        <div className="p-10 flex flex-col items-center justify-center">
            <Lottie animationData={aiSpinner} className="w-80" />
            {loadingPhase === 1 && (
                <>
                    <p className="pt-5">We zetten een voorspelling in elkaar</p>
                    <p className="pb-10 text-primary-pink">
                        Dit kan even duren...
                    </p>
                </>
            )}
            {loadingPhase === 2 && (
                <div className="group relative col-span-1 flex flex-col items-center w-full h-full mt-5">
                    <p className="pb-3">
                        Aan de hand van jouw trefwoorden verwachten we de
                        volgende
                        <b> potentiële business</b>
                    </p>
                    <p className="text-4xl font-bold ">
                        {summaryItemsEmails.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })}
                    </p>
                    <p className="pb-5">aanbestedingen per jaar</p>
                    <p className="pt-5 border-t">met een waarde van</p>
                    <p className="text-4xl font-bold">
                        €
                        {summaryItemsValue.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })}
                        ,-
                    </p>
                    <p className="pb-10 mt-5 text-primary-pink">
                        We koppelen de voorspelling aan je account...
                    </p>
                </div>
            )}
        </div>
    );
}
