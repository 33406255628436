import { useEffect, useState } from "react";
import { Spinner } from "../../components/Spinner";
import { InputSearch } from "../../components/InputSearch";
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    LineController,
} from "chart.js";
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
import { Link } from "react-router-dom";
import { TypeChart } from "./components/TypeChart";
import { ProcedureChart } from "./components/ProcedureChart";
import { ContractorChart } from "./components/ContractorChart";
import { WonTendersChart } from "./components/WonTendersChart";
import { TendersPublishedChart } from "./components/TendersPublishedChart";
import { TendersApplyChart } from "./components/TendersApplyChart";
import { TendersRegionChart } from "./components/TendersRegion";
import { ExpectedTendersChart } from "./components/ExpectedTendersChart";
import { CPVChart } from "./components/CPVChart";
import { Select } from "../../components/Select";
import { API, Auth, graphqlOperation } from "aws-amplify";
import moment from "moment";
import Lottie from "lottie-react";
import chartAnimation from "../../lottie/chart_main.json";
import useAnalyticsEventTracker from "../../tracker/EventTracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { RiBoxingLine } from "react-icons/ri";

const Competitor = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [filterLoading, setFilterLoading] = useState<boolean>(true);
    const [checkbox, setCheckbox] = useState<boolean>(false);
    const [userActive, setUserActive] = useState<boolean>(true);
    const [competitors, setCompetitors] = useState<number>(0);
    const [european, setEuropean] = useState<number>(0);
    const [national, setNational] = useState<number>(0);
    const [data, setData] = useState<any[]>([]);
    const [cleanData, setCleanData] = useState<any[]>([]);

    // Filter data
    const [competitorName, setCompetitorName] = useState<string>("");
    const [contractingAuthorityName, setContractingAuthorityName] =
        useState<string>("");
    const [years, setYears] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [procedure, setProcedure] = useState<string>("");
    const [nature, setNature] = useState<string>("");
    const [cpv, setCpv] = useState<string>("");
    const [cpvList, setCpvList] = useState<any[]>([]);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        ArcElement,
        Legend,
        PointElement,
        LineElement,
        LineController,
        TreemapController,
        TreemapElement
    );

    async function filters(newData: any, cpvs: any) {
        setFilterLoading(true);

        const phaseList: any[] = newData.length > 0 ? newData : cleanData;
        const cpvsList: any[] = cpvs.length > 0 ? cpvs : cpvList;

        console.log(phaseList);
        //prettier-ignore
        const filteredData = phaseList
            .filter((phases: any) => competitorName !== "" ? phases["Officiele benaming"]?.toLowerCase().includes(competitorName.toLowerCase()) : true)
            .filter((phases: any) => contractingAuthorityName !== "" ? phases["Naam Aanbestedende dienst"]?.toLowerCase().includes(contractingAuthorityName.toLowerCase()) : true)
            .filter((phases: any) => cpv !== "" ? phases["Hoofd cpv definitie code"]?.includes(cpv) || phases["Hoofd cpv definitie omschrijving"]?.toLowerCase().includes(cpv.toLowerCase()) : true)
            .filter((phases: any) => years !== "" ? phases["Publicatiedatum"].includes(years) : true)
            .filter((phases: any) => type !== "" ? phases["Type opdracht"].includes(type) : true)
            .filter((phases: any) => procedure !== "" ? phases["Procedure"]?.includes(procedure) : true)
            .filter((phases: any) => nature !== "" ? phases["Aard van de opdracht"].includes(nature) : true)
            .filter((phases: any) => checkbox ? cpvsList.some((cpv: any) => phases["Hoofd cpv definitie code"].substring(0,3) === cpv.cpv.code.substring(0,3)) : true)

        // Filter Europees of Nationaal
        const europeanData = await Promise.all(
            filteredData.filter((phase: any) => {
                return phase["Nationaal of Europees"].includes("Europees");
            })
        );
        setEuropean(europeanData.length);

        const nationalData = await Promise.all(
            filteredData.filter((phase: any) => {
                return phase["Nationaal of Europees"].includes("Nationaal");
            })
        );
        setNational(nationalData.length);

        setCompetitors(filteredData.length);

        setData(filteredData);

        setTimeout(() => {
            setFilterLoading(false);
        }, 1000);
    }

    useEffect(() => {
        document.title = "Concurrentie Analyse | TenderTracer";
        handleSql();
        EventTracker();
    }, []);

    function EventTracker() {
        useAnalyticsEventTracker(
            "Concurrentieanalyse",
            "Bekijkt Concurrentieanalyse",
            "Concurrentieanalyse"
        );
    }

    useEffect(() => {
        if (!loading) {
            filters([], []);
        }
    }, [years, type, procedure, nature, checkbox]);

    useEffect(() => {
        if (!loading) {
            setFilterLoading(true);
            setTimeout(() => {
                filters([], []);
            }, 3000);
        }
    }, [contractingAuthorityName, competitorName, cpv]);

    async function handleSql() {
        // Import data, CSV filter naar gunningen, verwijder de overige en zet CSV om naar JSON.
        const data = await require("./data/data.json");

        // Extra filter om 100% te weten dat het alleen gegunde zijn (Excel filter werkt niet optimaal)
        const filteredData = await data.Blad1.filter((phase: any) => {
            return phase["Publicatie type"].includes("gegunde");
        });

        // Filter Europees of Nationaal
        const europeanData = await filteredData.filter((phase: any) => {
            return phase["Nationaal of Europees"].includes("Europees");
        });
        setEuropean(europeanData.length);

        const nationalData = await filteredData.filter((phase: any) => {
            return phase["Nationaal of Europees"].includes("Nationaal");
        });
        setNational(nationalData.length);

        // Sorteer met meest recente publicaties eerst
        await filteredData
            .sort(function (a: any, b: any) {
                var a: any = new Date(a.Publicatiedatum).valueOf();
                var b: any = new Date(b.Publicatiedatum).valueOf();

                if (!Number.isNaN(a) || !Number.isNaN(a)) {
                    return a - b;
                }
            })
            .reverse();
        setCompetitors(filteredData.length);
        setCleanData(filteredData);

        //Haal CPV codes user op
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });

        const query = `query MyQuery { getUser(id: "${attributes.sub}") { active companies { items { company { active_subscription cpv(limit: 99999) { items { cpv { code description } } } } } } } }`;

        try {
            const cpvData: any = await API.graphql(graphqlOperation(query));
            setCpvList(
                cpvData.data.getUser.companies.items[0].company.cpv.items
            );
            setUserActive(
                cpvData.data.getUser.companies.items[0].company
                    .active_subscription
            );
            await filters(
                filteredData,
                cpvData.data.getUser.companies.items[0].company.cpv.items
            );
            setLoading(false);
        } catch (err: any) {
            console.log(err);
            const contractors = err.data.listContractors.items;
            let filterContractors = contractors.filter(
                (item: any | null): item is any => item !== null
            );

            var contractors2: any = [];

            await filterContractors.forEach((contract: any) => {
                if (contract !== null) {
                    contractors2.push(contract);
                }
            });

            setCompetitors(contractors2);
            setLoading(false);
        }
    }

    const colorPalette = [
        "#00aaaa",
        "#FF4D72",
        "#284F82",
        "#F2C80F",
        "#FD625E",
        "#5F6B6D",
        "#FE9666",
        "#00aaaa",
        "#FF4D72",
        "#284F82",
        "#F2C80F",
        "#FD625E",
        "#5F6B6D",
        "#FE9666",
        "#00aaaa",
        "#FF4D72",
        "#284F82",
        "#F2C80F",
        "#FD625E",
        "#5F6B6D",
        "#FE9666",
    ];

    // Lijn voor Doughnut chart
    const doughnutLabelsLine: any = {
        id: "doughnutLabelsLine",
        afterDraw(chart: any, args: any, options: any) {
            //prettier-ignore
            const { ctx, data, chartArea: { top, bottom, left, right, width, height } } = chart;

            data.datasets[0].data.forEach((datapoint: any, index: any) => {
                //prettier-ignore
                const { x, y } = chart.getDatasetMeta(0).data[index].tooltipPosition();

                const halfWidth = width / 2;
                const halfHeight = height / 2;

                const xLine = x >= halfWidth ? x + 25 : x - 25;
                const yLine = y >= halfHeight ? y + 25 : y - 25;
                const extraLine = x >= halfWidth ? 15 : -15;

                if (index < 3) {
                    ctx.beginPath();
                    ctx.moveTo(x, y);
                    ctx.lineTo(xLine, yLine);
                    ctx.lineTo(xLine + extraLine, yLine);
                    ctx.strokeStyle = "black";
                    ctx.stroke();

                    const textWidth = ctx.measureText(
                        chart.data.labels[index].width
                    );
                    const textXPosition = x >= halfWidth ? "left" : "right";
                    const plusFivePx = x >= halfWidth ? 5 : -5;
                    ctx.textAlign = textXPosition;
                    ctx.font = "10px";
                    ctx.textBaseline = "middle";
                    ctx.fillText(
                        chart.data.labels[index].length > 14
                            ? chart.data.labels[index].substring(0, 11) + "..."
                            : chart.data.labels[index],
                        xLine + extraLine + plusFivePx,
                        yLine
                    );
                }
            });
        },
    };

    return (
        <>
            {loading && data.length === 0 ? (
                <div className="w-full h-full flex justify-center items-center">
                    <Spinner infiniteLoop={false} />
                </div>
            ) : (
                <section className="h-full w-full overflow-y-auto pb-10">
                    <div className="bg-white shadow border border shadow rounded-lg mx-5 mt-5 mb-3 flex items-center p-2">
                        <RiBoxingLine className="text-2xl text-primary-blue" />
                        <p className="font-bold pl-2 text-lg">
                            Concurrentie Analyse
                        </p>
                    </div>
                    <div className="grid grid-cols-3 gap-3 mx-5 mb-5">
                        <div className="p-2 bg-white shadow border rounded-lg">
                            <p className="font-bold text-sm">
                                Naam concurrerend bedrijf
                            </p>
                            <InputSearch
                                placeholder="Voer hier de naam van je concurrent in..."
                                searchString={(string) =>
                                    setCompetitorName(string)
                                }
                                string={competitorName}
                            />
                        </div>
                        <div className="p-2 bg-white shadow border rounded-lg">
                            <p className="font-bold text-sm">
                                Naam aanbestedende dienst
                            </p>
                            <InputSearch
                                placeholder="Voer hier de naam van de aanbestedende dienst in..."
                                searchString={(string) =>
                                    setContractingAuthorityName(string)
                                }
                                string={contractingAuthorityName}
                            />
                        </div>
                        <div className="p-2 bg-white shadow border rounded-lg row-span-2">
                            <div className="flex">
                                <p className="font-bold w-full text-sm">
                                    Hoofd CPV definitie
                                </p>
                                <div className="group relative flex w-full justify-end">
                                    <p className="text-xs opacity-50 text-right cursor-pointer hover:text-primary-pink">
                                        Meer informatie*
                                    </p>
                                    <span className="absolute top-5 z-20 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                                        ✨ Wij hebben aan de hand van jouw
                                        deelselectie de voor jou relevante CPV
                                        codes al automatisch aangevinkt. Filter
                                        op alle concurrenten door het vinkje weg
                                        te halen.
                                    </span>
                                </div>
                            </div>
                            <div className="z-10">
                                <InputSearch
                                    placeholder="Voer hier een CPV code in..."
                                    searchString={(string) => setCpv(string)}
                                    string={cpv}
                                />
                            </div>
                            <div
                                className={clsx(
                                    "group flex relative items-center pt-5 pl-1 cursor-pointer",
                                    !userActive &&
                                        "opacity-50 cursor-not-allowed"
                                )}
                                onClick={() =>
                                    userActive ? setCheckbox(!checkbox) : ""
                                }
                            >
                                <input
                                    style={{
                                        width: "1.5vh",
                                        height: "1.5vh",
                                    }}
                                    type="checkbox"
                                    checked={checkbox}
                                    className={
                                        !userActive ? "cursor-not-allowed" : ""
                                    }
                                    onChange={() => ""}
                                />
                                <p className={clsx("pl-2")}>
                                    Filteren binnen mijn deelselectie
                                </p>
                                {!userActive && (
                                    <span className="absolute top-30 left-0 w-full justify-center items-center scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 flex">
                                        <FontAwesomeIcon
                                            className="mr-3 drop-shadow-xl animate-bounce"
                                            color="#ffd700"
                                            icon="crown"
                                        />
                                        <p className="font-bold">Premium</p>
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="h-10 w-full grid grid-cols-4 gap-4 col-span-2">
                            <div className="w-full h-full bg-white shadow border rounded-lg">
                                <Select
                                    onChange={(event) => {
                                        setYears(
                                            event.currentTarget.value.toString()
                                        );
                                    }}
                                >
                                    <option value="">Alle jaren</option>
                                    <option value="2019">2019</option>
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                </Select>
                            </div>
                            <div className="w-full h-full bg-white shadow border rounded-lg">
                                <Select
                                    onChange={(event) => {
                                        setType(
                                            event.currentTarget.value.toString()
                                        );
                                    }}
                                >
                                    <option value="">
                                        Alle type opdrachten
                                    </option>
                                    <option value="Diensten">Diensten</option>
                                    <option value="Leveringen">
                                        Leveringen
                                    </option>
                                    <option value="Werken">Werken</option>
                                </Select>
                            </div>
                            <div className="w-full h-full bg-white shadow border rounded-lg">
                                <Select
                                    onChange={(event) => {
                                        setProcedure(
                                            event.currentTarget.value.toString()
                                        );
                                    }}
                                >
                                    <option value="">Alle procedures</option>
                                    <option value="Openbaar">Openbaar</option>
                                    <option value="Niet-openbaar">
                                        Niet-openbaar
                                    </option>
                                    <option value="Onderhandeling zonder bekendmaking">
                                        Onderhandeling zonder bekendmaking
                                    </option>
                                    <option value="Mededelingsprocedure met onderhandeling">
                                        Mededelingsprocedure met onderhandeling
                                    </option>
                                    <option value="Onderhandse procedure">
                                        Onderhandse procedure
                                    </option>
                                    <option value="Concurrentiegerichte dialoog">
                                        Concurrentiegerichte dialoog
                                    </option>
                                    <option value="Innovatiepartnerschap">
                                        Innovatiepartnerschap
                                    </option>
                                </Select>
                            </div>
                            <div className="w-full h-full bg-white shadow border rounded-lg">
                                <Select
                                    onChange={(event) => {
                                        setNature(
                                            event.currentTarget.value.toString()
                                        );
                                    }}
                                >
                                    <option value="">
                                        Alle aard van opdrachten
                                    </option>
                                    <option value="Overheidsopdracht">
                                        Overheidsopdracht
                                    </option>
                                    <option value="Raamovereenkomst">
                                        Raamovereenkomst
                                    </option>
                                    <option value="Instellen van dynamisch aankoopsysteem">
                                        Instellen van dynamisch aankoopsysteem
                                        (DAS)
                                    </option>
                                    <option value="Opdracht">Opdracht</option>
                                </Select>
                            </div>
                        </div>
                    </div>

                    {filterLoading ? (
                        <div className="w-full flex justify-center">
                            <Lottie
                                animationData={chartAnimation}
                                className="drop-shadow-2xl"
                            />
                        </div>
                    ) : (
                        <div className="grid grid-cols-4 gap-3 px-5">
                            <div className="grid grid-cols-3 gap-3">
                                <div className="bg-white shadow border pl-3 py-3 row-span-1 rounded-lg flex flex-col">
                                    <p className="opacity-50 text-sm">
                                        Aantal Gunningen
                                    </p>
                                    <p className="text-[1.7vw] pt-3">
                                        {competitors}
                                    </p>
                                </div>
                                <div className="bg-white shadow border pl-3 py-3 row-span-1 rounded-lg flex flex-col">
                                    <p className="opacity-50 text-sm pr-2">
                                        Aantal Nationaal
                                    </p>
                                    <p className="text-[1.7vw] pt-3">
                                        {national}
                                    </p>
                                </div>
                                <div className="bg-white shadow border pl-3 py-3 row-span-1 rounded-lg flex flex-col">
                                    <p className="opacity-50 text-sm pr-2">
                                        Aantal Europees
                                    </p>
                                    <p className="text-[1.7vw] pt-3">
                                        {european}
                                    </p>
                                </div>
                            </div>
                            <div className="bg-white shadow border rounded-lg row-span-2 flex col-span-1 flex-col py-3">
                                <p className="opacity-50 text-sm pb-3 pl-3">
                                    Aard van opdrachten
                                </p>
                                <div className="relative w-full flex items-center justify-center">
                                    <ContractorChart
                                        data={data}
                                        colorPalette={colorPalette}
                                        doughnutLabelsLine={doughnutLabelsLine}
                                    />
                                </div>
                            </div>
                            <div className="bg-white shadow border rounded-lg row-span-2 flex col-span-1 flex-col py-3">
                                <p className="opacity-50 text-sm pb-3 pl-3">
                                    Aantal procedures
                                </p>
                                <div className="relative w-full flex items-center justify-center">
                                    <ProcedureChart
                                        data={data}
                                        colorPalette={colorPalette}
                                        doughnutLabelsLine={doughnutLabelsLine}
                                    />
                                </div>
                            </div>
                            <div className="bg-white shadow border rounded-lg row-span-2 flex col-span-1 flex-col py-3">
                                <p className="opacity-50 text-sm pb-3 pl-5">
                                    Type opdrachten
                                </p>
                                <div className="relative w-full flex items-center justify-center">
                                    <TypeChart
                                        data={data}
                                        colorPalette={colorPalette}
                                        doughnutLabelsLine={doughnutLabelsLine}
                                    />
                                </div>
                            </div>
                            <div className="bg-white shadow border row-span-5 relative rounded-lg flex items-center flex-col overflow-y-auto">
                                <p className="opacity-50 pt-5 pb-3">
                                    Top 10 aflopende contracten {competitorName}
                                </p>
                                <div
                                    className={clsx(
                                        "w-full h-full",
                                        !userActive && "blur"
                                    )}
                                >
                                    {data.length > 10
                                        ? data
                                              .filter((phase: any) => {
                                                  var date: any = moment(
                                                      phase[
                                                          "Voltooiing opdracht"
                                                      ],
                                                      "DD-MM-YYYY"
                                                  );
                                                  var dateNow: any = moment();
                                                  if (
                                                      phase[
                                                          "Voltooiing opdracht"
                                                      ] &&
                                                      date >= dateNow
                                                  ) {
                                                      return true;
                                                  } else {
                                                      return false;
                                                  }
                                              })
                                              .sort(function (a: any, b: any) {
                                                  var aDate: any = moment(
                                                      a["Voltooiing opdracht"],
                                                      "DD-MM-YYYY"
                                                  );
                                                  var bDate: any = moment(
                                                      b["Voltooiing opdracht"],
                                                      "DD-MM-YYYY"
                                                  );
                                                  return aDate - bDate;
                                              })
                                              .splice(0, 10)
                                              .map(
                                                  (
                                                      contract: any,
                                                      index: any
                                                  ) => (
                                                      <Link
                                                          key={index}
                                                          className="border-t w-full hover:underline cursor-pointer"
                                                          to={
                                                              userActive
                                                                  ? `/tender/821868/${contract["Id publicatie"]}`
                                                                  : ""
                                                          }
                                                      >
                                                          <p
                                                              className="p-3 text-sm font-bold truncate"
                                                              data-te-toggle="tooltip"
                                                              title={
                                                                  contract[
                                                                      "Naam aanbesteding"
                                                                  ]
                                                              }
                                                          >
                                                              {
                                                                  contract[
                                                                      "Naam aanbesteding"
                                                                  ]
                                                              }
                                                          </p>
                                                          <p
                                                              className="px-3 pb-3 text-xs truncate"
                                                              data-te-toggle="tooltip"
                                                              title={
                                                                  contract[
                                                                      "Officiele benaming"
                                                                  ]
                                                              }
                                                          >
                                                              Winnaar:{" "}
                                                              {contract[
                                                                  "Officiele benaming"
                                                              ]
                                                                  ? contract[
                                                                        "Officiele benaming"
                                                                    ]
                                                                  : "Onbekend"}
                                                          </p>
                                                          <p className="px-3 pb-3 text-xs">
                                                              Voltooiingsdatum:{" "}
                                                              {contract[
                                                                  "Voltooiing opdracht"
                                                              ]
                                                                  ? contract[
                                                                        "Voltooiing opdracht"
                                                                    ]
                                                                  : "Onbekend"}
                                                          </p>
                                                      </Link>
                                                  )
                                              )
                                        : data.map(
                                              (contract: any, index: any) => (
                                                  <Link
                                                      key={index}
                                                      className="border-t w-full hover:underline cursor-pointer"
                                                      to={
                                                          userActive
                                                              ? `/tender/821868/${contract["Id publicatie"]}`
                                                              : ""
                                                      }
                                                  >
                                                      <p
                                                          className="p-3 text-sm font-bold truncate"
                                                          data-te-toggle="tooltip"
                                                          title={
                                                              contract[
                                                                  "Naam aanbesteding"
                                                              ]
                                                          }
                                                      >
                                                          {
                                                              contract[
                                                                  "Naam aanbesteding"
                                                              ]
                                                          }
                                                      </p>
                                                      <p
                                                          className="px-3 pb-3 text-xs truncate"
                                                          data-te-toggle="tooltip"
                                                          title={
                                                              contract[
                                                                  "Officiele benaming"
                                                              ]
                                                          }
                                                      >
                                                          Winnaar:{" "}
                                                          {contract[
                                                              "Officiele benaming"
                                                          ]
                                                              ? contract[
                                                                    "Officiele benaming"
                                                                ]
                                                              : "Onbekend"}
                                                      </p>
                                                      <p className="px-3 pb-3 text-xs">
                                                          Voltooiingsdatum:{" "}
                                                          {contract[
                                                              "Voltooiing opdracht"
                                                          ]
                                                              ? contract[
                                                                    "Voltooiing opdracht"
                                                                ]
                                                              : "Onbekend"}
                                                      </p>
                                                  </Link>
                                              )
                                          )}
                                </div>
                                {!userActive && (
                                    <div className="absolute w-full h-full flex items-center justify-center">
                                        <FontAwesomeIcon
                                            className="mr-3 drop-shadow-xl animate-bounce"
                                            color="#ffd700"
                                            icon="crown"
                                        />
                                        <p className="text-primary-pink drop-shadow-2xl">
                                            Premium
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="bg-white shadow border rounded-lg col-span-2 row-span-2 flex flex-col py-5">
                                <p className="opacity-50 text-sm pb-3 pl-3">
                                    Aantal aanbestedingen en aantal
                                    inschrijvingen per jaar
                                </p>
                                <div className="relative w-full pt-2 flex items-center justify-center">
                                    <TendersApplyChart data={data} />
                                </div>
                            </div>
                            <div className="bg-white shadow border rounded-lg col-span-1 row-span-1 flex flex-col py-5">
                                <p className="opacity-50 pl-3 pb-3 text-sm w-full">
                                    Top 20 aantal gewonnen aanbestedingen
                                </p>
                                <div className="relative w-full flex items-center justify-center pt-30">
                                    <WonTendersChart
                                        data={data}
                                        colorPalette={colorPalette}
                                    />
                                </div>
                            </div>
                            <div className="bg-white shadow border rounded-lg col-span-1 row-span-1 flex flex-col py-5">
                                <p className="opacity-50 pl-3 pr-1 pb-3 text-sm w-full">
                                    Top 20 aanbestedingen per aanbestedende
                                    dienst
                                </p>
                                <div className="relative w-full flex items-center justify-center pt-30">
                                    <TendersPublishedChart
                                        data={data}
                                        colorPalette={colorPalette}
                                    />
                                </div>
                            </div>
                            <div className="bg-white shadow border relative rounded-lg row-span-2 flex col-span-1 flex-col py-3">
                                <p className="opacity-50 text-sm pb-3 pl-3">
                                    Top 5 regio's per aanbesteding
                                </p>
                                <div
                                    className={clsx(
                                        "relative w-full flex items-center justify-center",
                                        !userActive && "blur"
                                    )}
                                >
                                    <TendersRegionChart data={data} />
                                </div>
                                {!userActive && (
                                    <div className="absolute w-full h-full flex items-center justify-center">
                                        <FontAwesomeIcon
                                            className="mr-3 drop-shadow-xl animate-bounce"
                                            color="#ffd700"
                                            icon="crown"
                                        />
                                        <p className="text-primary-pink drop-shadow-2xl">
                                            Premium
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="bg-white shadow border relative rounded-lg row-span-2 flex col-span-1 flex-col py-3">
                                <p className="opacity-50 text-sm pb-3 pl-3">
                                    Verwachte datum nieuwe uitvraag
                                </p>
                                <div
                                    className={clsx(
                                        "relative w-full flex items-center justify-center",
                                        !userActive && "blur"
                                    )}
                                >
                                    <ExpectedTendersChart data={data} />
                                </div>
                                {!userActive && (
                                    <div className="absolute w-full h-full flex items-center justify-center">
                                        <FontAwesomeIcon
                                            className="mr-3 drop-shadow-xl animate-bounce"
                                            color="#ffd700"
                                            icon="crown"
                                        />
                                        <p className="text-primary-pink drop-shadow-2xl">
                                            Premium
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="bg-white shadow border relative rounded-lg row-span-2 flex col-span-1 flex-col py-3">
                                <p className="opacity-50 text-sm pb-3 pl-5">
                                    Top 5 aanbestedingen per CPV
                                </p>
                                <div
                                    className={clsx(
                                        "relative w-full flex items-center justify-center",
                                        !userActive && "blur"
                                    )}
                                >
                                    <CPVChart
                                        data={data}
                                        colorPalette={colorPalette}
                                    />
                                </div>
                                {!userActive && (
                                    <div className="absolute w-full h-full flex items-center justify-center">
                                        <FontAwesomeIcon
                                            className="mr-3 drop-shadow-xl animate-bounce"
                                            color="#ffd700"
                                            icon="crown"
                                        />
                                        <p className="text-primary-pink drop-shadow-2xl">
                                            Premium
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </section>
            )}
        </>
    );
};

export default Competitor;
