import { FormEvent, useState } from "react";
import Sidebar from "./sidebar";
import "../fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outlet } from "react-router-dom";
import { Button } from "../components/Button";
import { Auth } from "aws-amplify";
import emailjs from "@emailjs/browser";
import Topbar from "./topbar";
import { Spinner } from "../components/Spinner";
import Payment from "../pages/profile/phone";
import { OnboardingModal } from "../components/onboarding/Modal";
import clsx from "clsx";

interface layoutProps {
    active: boolean;
    subscription: boolean;
    onboarding: boolean;
}

const Layout = ({ active, subscription, onboarding }: layoutProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    function getErrorMailParams(sub: string) {
        const params = {
            name: sub,
            route: window.location.href,
            message: message,
        };

        return params;
    }

    async function sendInfo() {
        setLoading(true);
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });
        await emailjs.send(
            "service_6lkdt7y",
            "template_c2l4l6x",
            getErrorMailParams(attributes.sub),
            "3hb2pqFcSXP9kcagF"
        );
        setShowModal(false);
        setLoading(false);
    }

    return (
        <div className="h-screen flex overflow-hidden relative z-0">
            {active && (
                <div className="min-w-[250px] hidden lg:flex lg:flex-col">
                    <Sidebar
                        showPayment={() => (window.location.href = "/payment")}
                        active={active}
                        subscription={subscription}
                    />
                </div>
            )}
            <div className="w-full lg:pt-0">
                {active && (
                    <div className="flex lg:hidden w-screen">
                        <Topbar />
                    </div>
                )}
                {active ? (
                    <div className="lg:h-screen h-[calc(100vh-100px)]">
                        <Outlet />
                    </div>
                ) : (
                    <Payment />
                )}
                <div
                    className="absolute hidden lg:flex items-center bottom-8 bg-primary-pink text-white right-6 shadow-lg cursor-pointer rounded-lg px-5 py-3 hover:opacity-80"
                    onClick={() => setShowModal(!showModal)}
                >
                    {!showModal && (
                        <p className="opacity-80 pr-2">Geef ons feedback</p>
                    )}
                    <div
                        className={clsx(
                            !showModal && "border-l pl-2",
                            "opacity-80"
                        )}
                    >
                        <FontAwesomeIcon
                            className="text-xl text-white"
                            icon="star"
                        />
                    </div>
                </div>
            </div>
            {!onboarding && subscription && (
                <OnboardingModal
                    onClose={() =>
                        (window.location.href = "/recommended-tenders")
                    }
                />
            )}
            {showModal && (
                <div className="absolute right-8 bottom-24 w-[450px] p-2 bg-black shadow-2xl bg-opacity-5 rounded-lg">
                    <div className="p-3 mb-2 bg-white border shadow rounded-lg">
                        <b>Tendertracer assistent:</b>
                        <p>
                            Laat een bericht achter en wij nemen zo snel
                            mogelijk contact met je op.
                        </p>
                    </div>
                    <div className="p-5 bg-white border shadow rounded-lg">
                        <b>Feedback:</b>
                        <textarea
                            className="w-full border border-primary-blue focus:ring-primary-pink rounded-lg p-2 h-[200px] mt-1"
                            placeholder="Beschrijf hier zo duidelijk mogelijk je feedback."
                            onChange={(e: FormEvent<HTMLTextAreaElement>) =>
                                setMessage(e.currentTarget.value.toString())
                            }
                        />
                        <div className="flex justify-end">
                            <Button
                                type={
                                    message === ""
                                        ? "hidden"
                                        : loading
                                        ? "hidden"
                                        : "secondary"
                                }
                                className="flex items-center justify-center mt-2 border shadow"
                                onClick={message === "" ? () => "" : sendInfo}
                            >
                                {!loading ? (
                                    "Feedback opsturen"
                                ) : (
                                    <div className="flex items-center justify-end">
                                        <div className="pr-3">
                                            <p>Versturen...</p>
                                        </div>
                                        <div>
                                            <Spinner />
                                        </div>
                                    </div>
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Layout;
