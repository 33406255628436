import { Auth } from "aws-amplify";
import appRoutes from "../../routes/appRoutes";
import "../../fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopbarItem from "./TopbarItem";
import { useState } from "react";

interface topbarProps {
    style?: "none" | "hidden";
}

export default function Topbar({ style = "none" }: topbarProps) {
    const [showMenu, setShowMenu] = useState<boolean>();

    async function signOut() {
        try {
            await Auth.signOut();
            window.location.href = "/";
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="w-full bg-white rounded-r-lg drop-shadow-lg">
            <div
                onClick={() => setShowMenu(!showMenu)}
                className="w-full h-20 bg-white rounded-tr-lg border-b grid grid-cols-3 items-center justify-center cursor-pointer"
            >
                <div></div>
                <img className="w-60" src="/images/TenderTracer_logo.png" />
                <div className="flex items-center justify-end mr-10 mt-1">
                    <FontAwesomeIcon
                        style={{ fontSize: 20 }}
                        color="#284F82"
                        icon="bars"
                    />
                </div>
            </div>
            {showMenu && (
                <div
                    className="w-full z-30 rounded-lg shadow-xl"
                    onClick={() => setShowMenu(!showMenu)}
                >
                    {appRoutes.map((route: any, index: any) =>
                        route.sidebarProps ? (
                            <TopbarItem item={route} key={index} />
                        ) : null
                    )}
                    <div
                        className="flex p-5 w-full cursor-pointer justify-center items-center"
                        onClick={signOut}
                    >
                        <span className="text-primary-pink">Uitloggen</span>
                    </div>
                </div>
            )}
        </div>
    );
}
