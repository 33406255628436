import { API, Auth, graphqlOperation } from "aws-amplify";
import { FormEvent, useEffect, useState } from "react";
import { Button } from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { InputPhone } from "../../../../components/InputPhone";
import { Spinner } from "../../../../components/Spinner";

export const CompanySettings = (params: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [companyChamberOfCommerce, setCompanyChamberOfCommerce] =
        useState<string>(params.chamberOfCommerce);
    const [companyName, setCompanyName] = useState<string>(params.name);
    const [companyAddress, setCompanyAddress] = useState<string>(
        params.address
    );
    const [companyPostalCode, setCompanyPostalCode] = useState<string>(
        params.postalCode
    );
    const [companyCity, setCompanyCity] = useState<string>(params.city);
    const [companyCountry, setCompanyCountry] = useState<string>(
        params.country
    );
    const [companyId, setCompanyId] = useState<string | null>(null);
    useEffect(() => {
        getCompanyId();
    }, []);
    const getCompanyIdQuery = (companyUserId: string) =>
        `query MyQuery { getCompanyUser(id: "${companyUserId}") { companyId } }`;

    async function getCompanyId() {
        try {
            const { attributes } = await Auth.currentAuthenticatedUser();
            const userData: any = await API.graphql(
                graphqlOperation(`query MyQuery { getUser(id: "${attributes.sub}") { companies { items { id } } } }`)
            );
            const companyUserId = userData.data.getUser.companies.items[0].id;
            const companyData: any = await API.graphql(
                graphqlOperation(getCompanyIdQuery(companyUserId))
            );
            const companyId = companyData.data.getCompanyUser.companyId;
            setCompanyId(companyId);
        } catch (error) {
            console.log("Error getting company ID:", error);
        }
    }

    async function update() {
        setLoading(true);
        if (!companyId) {
            console.log("Fout: Bedrijfs-ID niet gevonden.");
            setLoading(false);
            return;
        }
        const updateCompany = `mutation MyQuery { updateCompany(input: {id: "${companyId}", name: "${companyName}", address: "${companyAddress}", postalcode: "${companyPostalCode}", city: "${companyCity}", country: "${companyCountry}"}) { id }}`;
        try {
            console.log(await API.graphql(graphqlOperation(updateCompany)));
            setMessage("Je gegevens zijn succesvol bijgewerkt!");
            setLoading(false);
        } catch (err) {
            console.log(err);
            setMessage("Mislukt! Er is wat fout gegaan.");
            setLoading(false);
        }
    }

    return (
        <>
            <div className="bg-white shadow border rounded-lg ml-5 mr-5 p-10">
                <Input
                    label="KvK nummer"
                    value={companyChamberOfCommerce}
                    placeholder="vb. 345543224"
                    readonly
                    onChange={(e: FormEvent<HTMLInputElement>) => null}
                />
                <p className="text-xs pl-1">
                    Je account is bedrijfsgebonden, daarom is het dus niet
                    mogelijk je KVK nummer te veranderen. Wil je toch gebruik
                    maken van een ander bedrijf? Maak dan opnieuw een account
                    aan.
                </p>
                <div className="pt-5">
                    <Input
                        label="Bedrijfsnaam"
                        value={companyName}
                        placeholder="vb. TenderTracer"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            setCompanyName(e.currentTarget.value.toString())
                        }
                    />
                </div>
                <div className="grid grid-cols-2 gap-5 pt-5">
                    <Input
                        label="Adres"
                        value={companyAddress}
                        placeholder="vb. Margietlaan"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            setCompanyAddress(e.currentTarget.value.toString())
                        }
                    />
                    <Input
                        label="Postcode"
                        value={companyPostalCode}
                        placeholder="vb. 1234AB"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            setCompanyPostalCode(
                                e.currentTarget.value.toString()
                            )
                        }
                    />
                </div>
                <div className="grid grid-cols-2 gap-5 pt-5 pb-10">
                    <Input
                        label="Plaats"
                        value={companyCity}
                        placeholder="vb. Amsterdam"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            setCompanyCity(e.currentTarget.value.toString())
                        }
                    />
                    <Input
                        label="Land"
                        value={companyCountry}
                        placeholder="vb. Nederland"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            setCompanyCountry(e.currentTarget.value.toString())
                        }
                    />
                </div>
            </div>
            <div className="p-5 float-right items-center">
                <div>
                    {message !== "" && (
                        <p style={{ color: "#ff4d72" }}>{message}</p>
                    )}
                </div>
                <Button
                    type={loading === false ? "secondary" : "hidden"}
                    onClick={() => (loading === false ? update() : undefined)}
                >
                    {loading === false ? (
                        "OPSLAAN"
                    ) : (
                        <div className="flex items-center">
                            <div className="pr-[15%]">
                                <p>Laden...</p>
                            </div>
                            <div>
                                <Spinner />
                            </div>
                        </div>
                    )}
                </Button>
            </div>
        </>
    );
};
