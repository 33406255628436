import { useEffect, useState } from "react";
import { Spinner } from "../../components/Spinner";
import { Button } from "../../components/Button";
import useAnalyticsEventTracker from "../../tracker/EventTracker";
import { IoMedicalOutline } from "react-icons/io5";

const Settings = () => {
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        document.title = "Hulp bij inschrijven | TenderTracer";
        EventTracker();
    }, []);

    function EventTracker() {
        useAnalyticsEventTracker(
            "Hulp bij inschrijven",
            "Bekijkt Hulp bij inschrijven",
            "Hulp bij inschrijven"
        );
    }

    function Event(label: string) {
        {
            useAnalyticsEventTracker(
                "Hulp bij inschrijven",
                label,
                "Hulp bij inschrijven"
            );

            window.location.href = "mailto:info@tendertracer.nl";
        }
    }

    return (
        <>
            {loading ? (
                <div className="w-full h-full flex justify-center items-center">
                    <Spinner />
                </div>
            ) : (
                <section className="h-full w-full overflow-y-auto">
                    <div className="bg-white shadow border rounded-lg p-2 ml-5 mr-5 mb-5 mt-5">
                        <div className="flex items-center">
                            <IoMedicalOutline className="text-2xl text-primary-blue" />
                            <p className="pl-2 font-bold text-lg">
                                Hulp bij inschrijven
                            </p>
                        </div>
                        <p className="text-sm">
                            TenderTracer heeft zichzelf tot doel gesteld om de
                            meest toegankelijke tool te bouwen die organisaties
                            wijst op relevante aanbestedingen. Inschrijven op
                            een aanbesteding is echter een vak apart. Daarom
                            heeft TenderTracer een geselecteerd netwerk van
                            Partners en Interimmers, gespecialiseerd in het
                            beantwoorden van Aanbestedingen. Wij brengen je
                            vraag, als service, bij deze groep onder de
                            aandacht, je gaat rechtstreeks het cont(r)act met
                            deze partijen aan.
                        </p>
                    </div>
                    <div className="grid grid-cols-2 gap-5 mx-5 mb-5">
                        <div className="p-5 bg-white shadow border rounded-lg">
                            <h1 className="font-bold text-xl">Coach</h1>
                            <p>
                                Als bedrijf weet je dat het winnen van Europese
                                aanbestedingen een ingewikkeld proces is dat
                                veel tijd, kennis en ervaring vereist. Door het
                                inzetten van een Bidmanager kunt u uw kansen op
                                succes aanzienlijk vergroten.
                            </p>
                            <p className="pt-5">
                                De bidmanager kan je helpen bij het begrijpen
                                van de procedures, criteria en
                                beoordelingsprocessen die worden gebruikt bij
                                het selecteren van winnaars. Of kan je adviseren
                                over hoe je jouw bedrijf het beste kunt
                                presenteren en hoe je zich kunt onderscheiden
                                van je concurrenten. je doet zelf het
                                schrijfwerk maar de coach zet de puntjes op de
                                i.
                            </p>
                            <p className="pt-5">
                                <i>
                                    <b>Interesse?</b> Mail ons het
                                    aanbestedingsnummer en een korte
                                    beschrijving van jouw verzoek. Dan delen we
                                    je vraag, als service, binnen ons
                                    Partnernetwerk.
                                </i>
                            </p>
                            <div className="pt-5">
                                <Button
                                    type="pink"
                                    onClick={() => {
                                        Event("Gekozen voor Coach");

                                        window.location.href =
                                            "mailto:info@tendertracer.nl";
                                    }}
                                >
                                    Mail ons
                                </Button>
                            </div>
                        </div>
                        <div className="p-5 bg-white shadow border rounded-lg">
                            <h1 className="font-bold text-xl">
                                A-Z begeleiding
                            </h1>
                            <p>
                                Als bedrijf weet je dat het winnen van Europese
                                aanbestedingen een complex proces is dat veel
                                tijd, inspanning en expertise vereist. Door het
                                uitbesteden van uw inschrijving, aan een
                                specialist, kunt je jouw kans op succes
                                aanzienlijk vergroten.
                            </p>
                            <p className="pt-5">
                                Met uitgebreide kennis en ervaring op het gebied
                                van Europese aanbestedingen, kan de Bidmanager
                                jouw bedrijf helpen bij het samenstellen van een
                                winnende inschrijving. Natuurlijk wordt je
                                geadviseerd over alle aspecten van het
                                inschrijvingsproces, van het begrijpen van de
                                aanbestedingsdocumenten tot het ontwikkelen van
                                een winnende strategie en het schrijven van je
                                offerte.
                            </p>
                            <p className="pt-5">
                                De Bidmanager begrijpt de procedures, criteria
                                en beoordelingsprocessen die worden gebruikt bij
                                het selecteren van winnaars.
                            </p>
                            <p className="pt-5">
                                Investeer in de toekomst van jouw bedrijf en
                                profiteer van professionele ondersteuning bij
                                het inschrijven op een Europese aanbesteding.
                            </p>
                            <p className="pt-5">
                                <i>
                                    <b>Interesse?</b> Mail ons het
                                    aanbestedingsnummer en een korte
                                    beschrijving van jouw verzoek. Dan delen we
                                    je vraag, als service, binnen ons
                                    Partnernetwerk.
                                </i>
                            </p>
                            <div className="pt-5">
                                <Button
                                    type="pink"
                                    onClick={() => {
                                        Event("Gekozen voor A tot Z");

                                        window.location.href =
                                            "mailto:info@tendertracer.nl";
                                    }}
                                >
                                    Mail ons
                                </Button>
                            </div>
                        </div>
                        <div className="col-span-2 p-5 bg-white shadow border rounded-lg">
                            <h1 className="font-bold text-xl">
                                Eerstelijns Tenderspecialist
                            </h1>
                            <p>
                                Een medewerker van TenderTracer helpt je met het
                                inrichten van je account, zoekt voor jouw
                                bedrijf de juiste CPV codes en is je eerste
                                aanspreekpunt. Krijg je bijvoorbeeld te veel of
                                te weinig aanbestedingen in je mailbox of kan je
                                de juiste aanbesteding niet vinden? Mail het
                                naar je Premium Tenderspecialist.
                            </p>
                            <div className="pt-5">
                                <Button
                                    type="pink"
                                    onClick={() => {
                                        Event("Gekozen voor A tot Z");

                                        window.location.href =
                                            "mailto:info@tendertracer.nl";
                                    }}
                                >
                                    Mail je Premium Tenderspecialist
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default Settings;
