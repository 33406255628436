import React from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface TabsProps {
    country: string;
    setCountry: (country: string) => Promise<void>; // Changed to Promise<void>
    userActive: boolean;
    setPublicationIdFilter: (filter: string) => void;
    resetFilters: () => void; // Changed to not take a parameter
}

const countries = ["NL", "BEL", "DEU", "FRA", "ESP"];

const Tabs: React.FC<TabsProps> = ({
    country,
    setCountry,
    userActive,
    setPublicationIdFilter,
    resetFilters,
}) => {
    const handleCountryChange = async (land: string) => {
        if (userActive || land === "NL") {
            handlePublicationIdFilter(land);
            await setCountry(land); // This now calls the handleCountryChange function in TenderOverview
            // resetFilters is now called within handleCountryChange in TenderOverview
        }
    };

    const handlePublicationIdFilter = (land: string) => {
        if (land === "NL" || land === "") {
            setPublicationIdFilter("-2024");
        } else {
            setPublicationIdFilter("");
        }
    };

    return (
        <div className="flex justify-around mx-6 p-1 space-x-16">
            {countries.map((land) => {
                const isPremium = land !== "NL";
                return (
                    <div
                        key={land}
                        className={clsx(
                            "group cursor-pointer relative flex justify-center",
                            country === land
                                ? "text-primary-pink font-bold"
                                : "",
                            isPremium &&
                                !userActive &&
                                "opacity-50 cursor-not-allowed"
                        )}
                        onClick={() => {
                            if (!isPremium || userActive) {
                                handleCountryChange(land);
                            }
                        }}
                        aria-label={isPremium ? `${land} (Premium)` : land}
                        role="tab"
                        tabIndex={0}
                    >
                        {land}
                        {/* Premium-indicator */}
                        {isPremium && !userActive && (
                            <span
                                className="absolute top-30 left-0 w-full p-2 justify-center items-center scale-0 rounded bg-gray-800 text-xs text-white group-hover:scale-100 group-hover:animate-bounce flex"
                                style={{ width: "6rem" }}
                            >
                                <FontAwesomeIcon
                                    className="mr-3 drop-shadow-xl animate-bounce"
                                    color="#ffd700"
                                    icon="crown"
                                />
                                <p className="font-bold">Premium</p>
                            </span>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default Tabs;