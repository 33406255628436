import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Amplify, API, graphqlOperation, Auth } from "aws-amplify";
import "./styles.css";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./layout/Layout";
import { routes } from "./routes";
import { Onboarding } from "./pages/auth/Onboarding";
import Login from "./pages/auth/Login";
import { PaymentSuccess } from "./pages/auth/PaymentSuccess";
import { PaymentFailed } from "./pages/auth/PaymentFailed";
import ForgotPassword from "./pages/auth/ForgotPassword";
import { TrialExpirationChecker } from "./components/TrialExpirationChecker";
import GoogleAnalytics from "./Analytics/GoogleAnalytics";
import { sendGAEvent } from "./utils/analytics";

Amplify.configure({
    ...awsExports,
    Auth: {
        region: "eu-central-1",
        userPoolId: "eu-central-1_1IbkiA4fq",
        userPoolWebClientId: "5t2a9r0bm75016q1joepsaslab",
    },
});

function App() {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState<any>([]);
    const [isUserLoggedIn, setUserLoggedIn] = useState("initializing");

    const fetchUser = async () => {
        const { attributes } = await Auth.currentAuthenticatedUser();
        try {
            const fetchUserQuery = `query MyQuery { getUser(id: "${attributes.sub}") { active trial companies { items { company { active active_subscription } } } } }`;
            const userData: any = await API.graphql(
                graphqlOperation(fetchUserQuery)
            );
            const userD = userData.data.getUser;
            console.log(userD);
            setUserData(userD);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUser();
        checkAuthState();
    }, []);

    async function checkAuthState() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            setUserLoggedIn("loggedIn");

            if (window.gtag){
                window.gtag('set', 'user_properties', {
                    user_type: user.attributes['custom:subscription_status'] || 'free_trial',
                    user_id: user.attributes.sub
                });
            }
            sendGAEvent('user_login');
        } catch (err) {
            setUserLoggedIn("loggedOut");
            setLoading(false);
        }
    }

    function display(loading: boolean, userData: any) {
        return (
            <>
                <GoogleAnalytics />
                <Toaster position="top-right" reverseOrder={false} />
                {loading === false && isUserLoggedIn !== "initializing" ? (
                    <BrowserRouter>
                        {isUserLoggedIn === "loggedIn" && (
                            <TrialExpirationChecker />
                        )}
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    isUserLoggedIn === "loggedIn" ? (
                                        <Layout
                                            active={userData.active}
                                            subscription={
                                                userData.companies.items[0]
                                                    .company.active_subscription
                                            }
                                            onboarding={userData.trial}
                                        />
                                    ) : (
                                        <Navigate
                                            to={`/login?from=${window.location.pathname}`}
                                        />
                                    )
                                }
                            >
                                {routes}
                            </Route>
                            <Route
                                path="/login/"
                                element={
                                    isUserLoggedIn === "loggedIn" ? (
                                        <Navigate to="/" />
                                    ) : (
                                        <Login />
                                    )
                                }
                            />
                            <Route
                                path="/register"
                                element={
                                    isUserLoggedIn === "loggedIn" ? (
                                        <Navigate to="/" />
                                    ) : (
                                        <Onboarding />
                                    )
                                }
                            />
                            <Route
                                path="/forgot_password"
                                element={
                                    isUserLoggedIn === "loggedIn" ? (
                                        <Navigate to="/" />
                                    ) : (
                                        <ForgotPassword />
                                    )
                                }
                            />
                            <Route
                                path="/payment_success"
                                element={<PaymentSuccess />}
                            />
                            <Route
                                path="/payment_failed"
                                element={<PaymentFailed />}
                            />
                        </Routes>
                    </BrowserRouter>
                ) : (
                    <div className="flex w-screen h-screen justify-center items-center">
                        <p className="lg:text-7xl text-4xl flex text-white font-bold drop-shadow-2xl animate-bounce contrast-200">
                            TenderTracer
                        </p>
                    </div>
                )}
            </>
        );
    }

    return <>{display(loading, userData)}</>;
}

export default App;
