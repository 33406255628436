import { ChartOptions } from "chart.js";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";

interface ProcedureChartProps {
    colorPalette: any;
    data: any;
    doughnutLabelsLine: any;
}

export function ContractorChart({
    colorPalette,
    data,
    doughnutLabelsLine,
}: ProcedureChartProps) {
    const [typeLabels, setTypeLabels] = useState<any[]>([]);
    const [typeData, setTypeData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const typeChart = {
        labels: typeLabels,
        datasets: [
            {
                data: typeData,
                backgroundColor: colorPalette,
                borderColor: colorPalette,
                borderWidth: 1,
                cutout: "60%",
            },
        ],
    };

    const donOptions: ChartOptions<"doughnut"> = {
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 20,
                left: 0,
                right: 0,
                bottom: 20,
            },
        },
    };

    useEffect(() => {
        handleData();
    }, []);

    async function handleData() {
        const output = Object.entries(
            data.reduce((prev: any, label: any) => {
                prev[label["Aard van de opdracht"]] = prev[
                    label["Aard van de opdracht"]
                ]
                    ? prev[label["Aard van de opdracht"]] + 1
                    : 1;
                return prev;
            }, {})
        )
            .map(([label, count]) => ({ label, count }))
            .sort((a: any, b: any) => b.count - a.count);

        var typeLabels = output.map(function (e) {
            return e.label;
        });
        var typeData = output.map(function (e) {
            return e.count;
        });

        setTypeLabels(typeLabels);
        setTypeData(typeData);

        setLoading(false);
    }

    return (
        <Doughnut
            className="w-full"
            data={typeChart}
            options={donOptions}
            plugins={[doughnutLabelsLine]}
        />
    );
}
