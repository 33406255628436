import { useState } from "react";
import ReactSelect from "react-select";

interface Props {
  data: any[];
  placeholder: string;
  multi?: boolean;
  advancedLabel?: boolean;
  defaultValue: string;
  defaultLabel: string;
  value?: any;
  disabled?: boolean;
  onChange: (value: any) => void;
}

export default function Select({
  data,
  placeholder,
  multi = false,
  onChange,
  disabled = false,
  defaultValue,
  value,
  defaultLabel,
  advancedLabel = false,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const CustomOption = ({ innerProps, data }: any) => {
    return (
      <div
        className="p-3 hover:bg-primary-pink hover:bg-opacity-20 hover:cursor-pointer z-50"
        {...innerProps}
      >
        <div className="text-sm">
          {advancedLabel && `${data?.value} - `}
          {data?.label}
        </div>
      </div>
    );
  };

  const LoadingMessage = (props: any) => {
    return (
      <div className="p-3 flex items-center justify-center" {...props}>
        Aan het laden...
      </div>
    );
  };

  const NoOptionsMessage = (props: any) => {
    return (
      <div className="p-3 flex items-center justify-center" {...props}>
        Geen resultaten gevonden
      </div>
    );
  };

  return (
    <ReactSelect
      className="text-sm drop-shadow"
      isMulti={multi}
      defaultValue={{ label: defaultLabel, value: defaultValue }}
      options={data}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      value={value}
      isDisabled={disabled}
      menuPlacement={"auto"}
      isLoading={loading}
      placeholder={placeholder}
      components={{
        Option: CustomOption,
        LoadingMessage: LoadingMessage,
        NoOptionsMessage: NoOptionsMessage,
      }}
      onInputChange={(value, actionMeta) => {
        if (actionMeta.action === "input-change") {
        }
      }}
      onChange={onChange}
      styles={{
        control: (provided: any, state: any) => ({
          ...provided,
          borderRadius: "8px",
          borderColor: state.isFocused ? "#00aaaa" : "#e5e7eb",
          boxShadow: state.isFocused ? "0 0 0 1px #00aaaa" : "blue",
          "&:hover": {
            borderColor: state.isFocused ? "#00aaaa" : "#00aaaa",
          },
        }),
      }}
    />
  );
}
