import { ChartOptions } from "chart.js";
import { useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import { Spinner } from "../../../components/Spinner";
import moment from "moment";

interface ProcedureChartProps {
    data: any;
}

export function TendersApplyChart({ data }: ProcedureChartProps) {
    const [typeData, setTypeData] = useState<any[]>([]);
    const [typeDataLine, setTypeDataLine] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const averageChart = {
        datasets: [
            {
                data: typeData,
                label: "Aantal aanbestedingen",
                backgroundColor: "#00aaaa",
                order: 2,
            },
            {
                data: typeDataLine,
                label: "Aantal inschrijvingen",
                backgroundColor: "#ff4d72",
                borderColor: "#ff4d72",
                type: "line" as const,
                order: 1,
            },
        ],
    };

    const mixedOptions: ChartOptions = {
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: false,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 0,
                left: 10,
                right: 10,
                bottom: 0,
            },
        },
    };

    useEffect(() => {
        handleData();
    }, []);

    async function handleData() {
        const output = Object.entries(
            data.reduce((prev: any, label: any) => {
                var test = moment(label["Publicatiedatum"], "DD-MM-YYYY")
                    .year()
                    .toString();
                prev[test] = prev[test] ? prev[test] + 1 : 1;
                return prev;
            }, {})
        )
            .map(([x, y]) => ({ x, y }))
            .sort((a: any, b: any) => b.count - a.count);

        const filteredOutput = output.filter((element) => element.x !== "NaN");

        setTypeData(filteredOutput.slice(0, 20));

        const outputLine = Object.entries(
            data.reduce((prev: any, label: any) => {
                prev[new Date(label["Publicatiedatum"]).getFullYear()] = prev[
                    new Date(label["Publicatiedatum"]).getFullYear()
                ]
                    ? label["Aantal inschrijvingen"] !== undefined
                        ? prev[
                              new Date(label["Publicatiedatum"]).getFullYear()
                          ] + Number(label["Aantal inschrijvingen"])
                        : prev[
                              new Date(label["Publicatiedatum"]).getFullYear()
                          ] + 1
                    : 1;
                return prev;
            }, {})
        )
            .map(([x, y]) => ({ x, y }))
            .sort((a: any, b: any) => b.count - a.count);

        const filteredOutputLine = outputLine.filter(
            (element) => element.x !== "NaN"
        );

        let lineData: any = [];

        filteredOutputLine.map((line: any) => {
            const foundObject: any = filteredOutput.find(
                (out: any) => out.x === line.x
            );
            if (foundObject) {
                let data: {} = {
                    x: line.x,
                    y: Math.ceil(line.y / foundObject.y),
                };
                lineData.push(data);
            }
        });

        setTypeDataLine(filteredOutputLine);
        setLoading(false);
    }

    return loading ? (
        <Spinner />
    ) : (
        <Chart
            type="bar"
            className="w-full"
            data={averageChart}
            options={mixedOptions}
        />
    );
}
