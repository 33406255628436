import { useEffect } from "react";

import { ForgotPasswordComp } from "./components/ForgotPassword";

const Login = () => {
    useEffect(() => {
        document.title = "Wachtwoord vergeten | TenderTracer";
    }, []);
    return (
        <section className="h-screen w-screen flex justify-center pt-[10%] relative bg-[#284F82] bg-center bg-cover bg-[url('/public/images/onboarding_bg.webp')]">
            <ForgotPasswordComp />
        </section>
    );
};

export default Login;
