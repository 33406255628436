/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_appsync_graphqlEndpoint": "https://wtc5yoxzangmtcon3bpmkh4qqe.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-nlxuj57isreozjddbtnugil3uu",
    "aws_cognito_identity_pool_id": "eu-west-3:449b0e26-add8-4ca5-8b87-5e00c1992f76",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_xGZbrDgO3",
    "aws_user_pools_web_client_id": "772h4c3pjlce33mqrju6hl847m",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
