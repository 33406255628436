import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import BlogPosts from "../../components/Blogsposts";
import { API, Auth, graphqlOperation } from "aws-amplify";
import Updates from "./components/Updates";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import DoughnutChart from "../../components/charts/DoughnutChart";
import BarChart from "../../components/charts/BarChart";
import useAnalyticsEventTracker from "../../tracker/EventTracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PiHandWaving } from "react-icons/pi";
import { TiChartPieOutline } from "react-icons/ti";
import axios from "axios";
import LoadingSpinner, { LoadingDocument } from "../../components/LoadingDocument";
import { SlFire } from "react-icons/sl";
import { GrBlog } from "react-icons/gr";
import { BiBook, BiCrown } from "react-icons/bi";
import { FaCrow, FaCrown } from "react-icons/fa6";
import { API_DEFAULT_HEADERS, API_DOMAIN } from "../../APIconfig";
import { plans } from "../../pages/profile/phone/data";
import Premium from "../profile/premium";
import CountdownClock from "../../components/CountDownClock";
import { Modal } from "../../components/Modal";
import Lottie from "lottie-react";
import crown from "../../lottie/crown.json";
import { Button } from "../../components/Button";
import { has, set, split } from "lodash";
import appRoutes from "../../routes/appRoutes";
import { Link } from "react-router-dom";
import clsx from "clsx";
// import {PremiumModal} from "../../components/pricing/PremiumModal";
import { PricingCard } from "../../components/pricing/PricingCard";
import { json } from "stream/consumers";
import { time } from "console";
import { TIMEOUT } from "dns";
import { TrialExpirationChecker } from "../../components/TrialExpirationChecker";
import { Spinner } from "../../components/Spinner";

interface Post {
    id: number;
    title: { rendered: string };
    excerpt: { rendered: string };
    content: { rendered: string };
    featured_media: number;
    guid: any;
    _embedded: { "wp:featuredmedia": FeaturedMedia[] };
    yoast_head_json: YoastHead;
}

interface FeaturedMedia {
    source_url: string;
}

interface YoastHead {
    keyword: string;
}

export const Dashboard = () => {
    const [fullName, setFullName] = useState<string>("");
    const [stripeID, setStripeID] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingTrial, setLoadingTrial] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [leveringenData, setLeveringenData] = useState<any[]>([]);
    const [werkenData, setWerkenData] = useState<any[]>([]);
    const [dienstenData, setDienstenData] = useState<any[]>([]);
    const [posts, setPosts] = useState<Post[]>([]);
    const [updates, setUpdates] = useState<any[]>([]);
    const [tenderTypeData, setTenderTypeData] = useState<any[]>([]);
    const [totalTenders, setTotalTenders] = useState<number>(0);
    const [totalAwards, setTotalAwards] = useState<number>(0);
    const [isPremium, setIsPremium] = useState<boolean>(false);
    const [trialDateEnd, setTrialEndDate] = useState<any>("");
    const [companyUserId, setCompanyUserId] = useState<string | null>(null);
    const [companyId, setCompanyId] = useState<string | null>(null);
    const [path, setPath] = useState("");
    const [subscription, setSubscription] = useState<boolean>(false);
    const [stripeStatus, setStripeStatus] = useState<string>("");

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        ArcElement,
        Legend
    );

    useEffect(() => {
        document.title = "Dashboard | TenderTracer";
        checkPremium();
        EventTracker();
    }, []);

    function EventTracker() {
        useAnalyticsEventTracker("Dashboard", "Bekijkt Dashboard", "Dashboard");
    }

    function formatDate(dateString: string): string {
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const checkPremiumQuery = (userID: string) =>
        `query MyQuery { getUser(id: "${userID}") { firstname stripe_id lastname trial stripeStatus trialDateEnd companies { items { id company { active_subscription } } } } } `;

    const getCompanyIdQuery = (companyUserId: string) =>
        `query MyQuery { getCompanyUser(id: "${companyUserId}") { companyId } }`;

    const dashboardQuery = `
        query DashboardData($userId: ID!, $companyUserId: ID!) {
        user: getUser(id: $userId) {
            firstname
            lastname
            stripe_id
            trial
            stripeStatus
            trialDateEnd
            companies {
            items {
                id
                company {
                id
                active_subscription
                }
            }
            }
        }
        companyUser: getCompanyUser(id: $companyUserId) {
            companyId
        }
        dashboardFeatures: searchDashboardFeatures(sort: {field: createdAt, direction: asc}, limit: 3) {
            items {
            title
            description
            coming_soon
            active
            createdAt
            }
        }
        generalStatistics: getGeneralStatistics(id: "1") {
            totalAwards
            totalTenders
        }
        dashboardStatistics: searchDashboardStatistics(sort: {field: createdAt, direction: asc}) {
            items {
            type
            name
            value
            }
        }
        }
        `;
    
    
    const [hasUsedTrial, setHasUsedTrial] = useState<boolean>(false);
    
    async function checkPremium() {
        try {
            const { attributes } = await Auth.currentAuthenticatedUser();
            const response: any = await API.graphql(
                graphqlOperation(dashboardQuery, { 
                    userId: attributes.sub,
                    companyUserId: attributes.sub
                })
            );
    
            const data = response.data;
    
            // Verwerk user data
            const userData = data.user;
            setFullName(userData.firstname);
            setStripeID(userData.stripe_id);
            setStripeStatus(userData.stripeStatus);
    
            const isUserActive = userData.companies.items[0]?.company.active_subscription || false;
            setIsPremium(isUserActive);
            setSubscription(isUserActive);
    
            const companyUserId = userData.companies.items[0]?.id;
            setCompanyUserId(companyUserId);
    
            // Verwerk company data
            const companyId = data.companyUser?.companyId;
            setCompanyId(companyId);
    
            // Verwerk trial data
            const trialDateEnd = userData.trialDateEnd;
            if (trialDateEnd) {
                setHasUsedTrial(true);
                const trialEndDate = new Date(trialDateEnd);
                if (!isNaN(trialEndDate.getTime())) {
                    setTrialEndDate(trialEndDate);
                }
            } else {
                setHasUsedTrial(false);
            }
    
            // Verwerk dashboard features
            setUpdates(data.dashboardFeatures?.items || []);
    
            // Verwerk algemene statistieken
            setTotalAwards(data.generalStatistics?.totalAwards || 0);
            setTotalTenders(data.generalStatistics?.totalTenders || 0);
    
            // Verwerk dashboard statistieken
            const dashboardStats = data.dashboardStatistics?.items || [];
            const leveringen = dashboardStats.filter((item: any) => item.type === "Aantal leveringen");
            const werken = dashboardStats.filter((item: any) => item.type === "Aantal werken");
            const diensten = dashboardStats.filter((item: any) => item.type === "Aantal diensten");
            const tenderType = dashboardStats.filter((item: any) => item.type === "Type aanbesteding");
    
            setLeveringenData(leveringen.map((item: any) => ({ x: item.name, y: parseFloat(item.value) || 0 })));
            setWerkenData(werken.map((item: any) => ({ x: item.name, y: parseFloat(item.value) || 0 })));
            setDienstenData(diensten.map((item: any) => ({ x: item.name, y: parseFloat(item.value) || 0 })));
            setTenderTypeData(tenderType.map((item: any) => ({ name: item.name, value: parseFloat(item.value) || 0 })));
    
            // Haal blogposts op
            const blogResponse: any = await axios.get<Post[]>(
                "https://tendertracer.nl/index.php/wp-json/wp/v2/posts?_embed"
            );
            setPosts(blogResponse.data);
    
            setLoading(false);
        } catch (error) {
            console.error("Error in checkPremium:", error);
            setLoading(false);
        }
    }

    async function initiateMonthlyPayment() {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getIdToken();
            const jwt = accessToken.getJwtToken();
            const monthlyPlan = plans.find(
                (plan) => plan.title === "Maandelijks"
            );
            if (!monthlyPlan) {
                console.error("Maandelijkse abonnement niet gevonden");
                return;
            }
            const raw = JSON.stringify({
                customerID: stripeID,
                priceID: monthlyPlan.id,
                locale: "nl",
                success_url: `${window.location.origin}/?payment_completed=true`,
                cancel_url: `${window.location.origin}/?payment_cancelled=true`,
            });
            const requestOptions: RequestInit = {
                method: "POST",
                headers: new Headers(API_DEFAULT_HEADERS(jwt)),
                body: raw,
            };
            const response = await fetch(
                `${API_DOMAIN}/stripe/pay`,
                requestOptions
            );
            const jsonResponse = await response.json();
            window.location.replace(jsonResponse.body);
        } catch (error) {
            console.log(error);
        }
    }
    async function checkSubscriptionStatus() {
        try {
            const { attributes } = await Auth.currentAuthenticatedUser();
            const userData: any = await API.graphql(
                graphqlOperation(checkPremiumQuery(attributes.sub))
            );
            const companyId =
                userData.data.getUser.companies.items[0].company.id;

            await API.graphql(
                graphqlOperation(
                    `mutation UpdateCompany{
                updateCompany(input: {id: "${companyId}", active_subscription: true}) {
                    id
                    active_subscription
                    }
          }`
                )
            );
            setSubscription(true);
            setIsPremium(true);
        } catch (error) {
            console.log("Error checking subscription status:", error);
        }
    }

    async function fetchTrial() {
        setLoadingTrial(true);
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getIdToken();
            const jwt = accessToken.getJwtToken();

            // Haal de huidige user ID op

            var raw = JSON.stringify({
                customerID: stripeID,
                priceID: plans[0].id,
                locale: "nl",
                success_url: `${window.location.origin}/?trial_activated=true`,
                cancel_url: `${window.location.origin}/?trial_activated=false`,
            });

            var requestOptions: any = {
                method: "POST",
                headers: new Headers(API_DEFAULT_HEADERS(jwt)),
                body: raw,
            };

            const response = await fetch(
                `${API_DOMAIN}/stripe/trial`,
                requestOptions
            );
            const jsonResponse = await response.json();
            console.log("Stripe url", jsonResponse.url);
            window.location.replace(jsonResponse.body);
        } catch (error) {
            console.log("Error in fetchTrial: ", error);
            setLoadingTrial(false);
        }
    }
    async function activateTrial() {
        console.log("activateTrial function called");

        try {
            const { attributes } = await Auth.currentAuthenticatedUser();
            const userData: any = await API.graphql(
                graphqlOperation(checkPremiumQuery(attributes.sub))
            );

            const companyUserId = userData.data.getUser.companies.items[0].id;
            setCompanyUserId(companyUserId);
            const companyData: any = await API.graphql(
                graphqlOperation(getCompanyIdQuery(companyUserId))
            );
            console.log("Company data: ", companyData);
            //console.log("Company ID: ", companyData.data.getCompanyUser.companyId);
            const companyId = companyData.data.getCompanyUser.companyId;
            setCompanyId(companyId);

            const userId = attributes.sub;

            console.log("User ID:", userId);
            console.log("company ID: ", companyId);

            const trialEndDate = new Date();
            trialEndDate.setDate(trialEndDate.getDate() + 45);
            const formattedDate = formatDate(trialEndDate.toISOString());

            await API.graphql(
                graphqlOperation(
                    `mutation UpdateUser($input: UpdateUserInput!) {
                        updateUser(input: $input) {
                            id
                            trialDateEnd
                        }
                    }`,
                    {
                        input: {
                            id: userId,
                            trialDateEnd: formattedDate,
                        },
                    }
                )
            );

            if (companyId) {
                await API.graphql(
                    graphqlOperation(
                        `mutation MyMutation { updateCompany(input: {id: "${companyId}", active_subscription: true}) { id active_subscription } }`
                    )
                );
            }
            setIsPremium(true);
            setTrialEndDate(trialEndDate);
            setHasUsedTrial(true);
            return Promise.resolve();
        } catch (error) {
            console.log("Error in activateTrial:", error);
            return Promise.reject();
        }
    }
    useEffect(() => {
        console.log("Dashboard component mounted");
        const urlParams = new URLSearchParams(window.location.search);
        console.log("URL params:", urlParams);

        const trialActivated = urlParams.get("trial_activated");
        console.log("Trial activated parameter:", trialActivated);
        const paymentCompleted = urlParams.get("payment_completed");
        console.log("Payment completed parameter:", paymentCompleted);

        if (trialActivated === "true") {
            console.log("Activating trial...");
            activateTrial().then(() => {
                //window.location.replace("/");
                window.history.pushState(
                    {},
                    document.title,
                    window.location.pathname
                );
                window.location.reload();
            });
        } else if (paymentCompleted === "true") {
            console.log("Payment completed");
            checkSubscriptionStatus().then(() => {
                window.history.pushState(
                    {},
                    document.title,
                    window.location.pathname
                );
                window.location.reload();
            });
        }
    }, []);

    return (
        <>
            <TrialExpirationChecker />

            {loading ? (
                <div className="w-full h-full flex items-center justify-center">
                    {/* <LoadingDocument infiniteLoop={true} /> */}
                    <LoadingSpinner />
                </div>
            ) : (
                <section className="w-full h-full overflow-y-scroll">
                    <div className="flex justify-between p-2 items-center bg-white border shadow rounded-lg ml-5 mr-5 mt-2">
                        <div className="flex items-center">
                            <PiHandWaving className="text-4xl text-primary-pink" />
                            <h2 className="font-bold pl-2 text-xl">
                                Welkom terug, {fullName}!
                            </h2>
                        </div>
                        <div className="flex items-center">
                            <p className="text-xs opacity-30 pr-2">
                                Versie 3.2 |{" "}
                            </p>
                            <FontAwesomeIcon
                                className="text-[20px] text-primary-blue mr-2 hover:cursor-pointer hover:text-primary-pink"
                                icon={["fab", "youtube"]}
                                onClick={() =>
                                    window.open(
                                        "https://www.youtube.com/@TenderTracer"
                                    )
                                }
                            />
                            <FontAwesomeIcon
                                className="text-[20px] text-primary-blue mr-3 hover:cursor-pointer hover:text-primary-pink"
                                icon={["fab", "linkedin"]}
                                onClick={() =>
                                    window.open(
                                        "https://www.linkedin.com/company/tendertracer/mycompany/"
                                    )
                                }
                            />
                        </div>
                    </div>
                    {!hasUsedTrial && !subscription && (
                        <div
                            onClick={() => setShowModal(true)}
                            className="flex justify-between bg-gradient-to-br from-amber-50 to-purple-200 border shadow p-2 items-center bg-white rounded-lg ml-5 mr-5 mt-4 mb-2 hover:underline cursor-pointer"
                        >
                            <div className="flex items-center">
                                <FaCrown className="text-2xl text-primary-pink" />
                                <h3 className="font-bold pl-2 text-lg pr-1">
                                    Gratis proefperiode van TenderTracer Premium
                                    voor 45 dagen? |
                                </h3>
                                <p>Klik dan hier!</p>
                            </div>
                        </div>
                    )}
                    {new Date() < new Date(trialDateEnd) &&
                        isPremium &&
                        stripeStatus === "trialing" && (
                            <div className="flex justify-between items-center bg-gradient-to-br from-amber-50 to-purple-200 border shadow p-4 rounded-lg mx-5 mt-4 mb-2">
                                <div className="flex items-center space-x-3">
                                    <FontAwesomeIcon
                                        icon="crown"
                                        className="text-3xl text-primary-pink"
                                    />
                                    <div>
                                        <h2 className="font-bold text-lg text-gray-800">
                                            Premium proeftijd
                                        </h2>
                                        <p className="text-sm text-gray-600">
                                            Uw proefperiode verloopt over:
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-4">
                                    <CountdownClock
                                        targetDate={trialDateEnd}
                                        onExpire={() =>
                                            window.location.reload()
                                        }
                                    />
                            
                                    <button
                                        onClick={initiateMonthlyPayment}
                                        className="bg-primary-pink text-white px-4 py-2 rounded-full hover:bg-pink-600 transition duration-300 ease-in-out flex items-center space-x-2"
                                    >
                                        <span className="text-sm font-semibold">
                                            Premium verlengen
                                        </span>
                                        <FontAwesomeIcon
                                            icon="arrow-right"
                                            className="text-sm"
                                        />
                                    </button>
                                </div>
                            </div>
                        )}

                    <div className="lg:grid grid-cols-6 mx-5">
                        <div className="col-span-4">
                            <div className="p-2 bg-white mt-2 w-full border shadow rounded-lg hidden items-center lg:flex">
                                <TiChartPieOutline className="text-2xl text-primary-blue" />
                                <h2 className="pl-2 font-bold text-lg">
                                    Laatste cijfers
                                </h2>
                                <p className="pl-1 opacity-50 text-sm">
                                    | Afgelopen maand
                                </p>
                            </div>
                            <div className="grid-cols-3 gap-4 hidden lg:grid">
                                <div className="mt-2 bg-white border shadow rounded-lg p-3">
                                    <p className="opacity-50 text-sm pb-3">
                                        Totaal aantal aanbestedingen
                                    </p>
                                    <div className="flex">
                                        <p className="text-5xl">
                                            {totalTenders?.toLocaleString(
                                                undefined,
                                                {
                                                    maximumFractionDigits: 2,
                                                }
                                            )}
                                        </p>
                                        <FontAwesomeIcon
                                            className="text-[25px] text-primary-green"
                                            icon="arrow-trend-up"
                                        />
                                    </div>
                                </div>
                                <div className="mt-2 bg-white border shadow rounded-lg p-3">
                                    <p className="opacity-50 text-sm pb-3">
                                        Totaal aantal gunningen
                                    </p>
                                    <div className="flex">
                                        <p className="text-5xl">
                                            {totalAwards?.toLocaleString(
                                                undefined,
                                                {
                                                    maximumFractionDigits: 2,
                                                }
                                            )}
                                        </p>
                                        <FontAwesomeIcon
                                            className="text-[25px] text-primary-green"
                                            icon="arrow-trend-up"
                                        />
                                    </div>
                                </div>
                                <div className="mt-2 bg-white border shadow rounded-lg p-3">
                                    <p className="opacity-50 text-sm pb-3">
                                        Type aanbestedingen
                                    </p>
                                    <DoughnutChart data={tenderTypeData} />
                                </div>
                            </div>
                            <div className="grid-cols-3 gap-4 hidden lg:grid">
                                <div className="my-2 bg-white border shadow rounded-lg p-2">
                                    <p className="opacity-50 text-sm pb-3">
                                        Aantal leveringen
                                    </p>
                                    <BarChart data={leveringenData} />
                                </div>
                                <div className="my-2 bg-white border shadow rounded-lg p-2">
                                    <p className="opacity-50 text-sm pb-3">
                                        Aantal diensten
                                    </p>
                                    <BarChart data={dienstenData} />
                                </div>
                                <div className="my-2 bg-white border shadow rounded-lg p-2">
                                    <p className="opacity-50 text-sm pb-3">
                                        Aantal werken
                                    </p>
                                    <BarChart data={werkenData} />
                                </div>
                            </div>
                        </div>
                        <div className="lg:pl-3 w-full col-span-2">
                            <div>
                                <div className="p-2 mt-2 flex items-center bg-white border shadow rounded-lg">
                                    <SlFire className="text-2xl text-primary-blue" />
                                    <h2 className="pl-2 font-bold text-lg">
                                        Serviceberichten
                                    </h2>
                                </div>
                                <div className="mt-2">
                                    <Updates updates={updates} />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-4 mt-5">
                            <div className="flex items-center p-2 bg-white border shadow rounded-lg">
                                <GrBlog className="text-2xl text-primary-blue" />
                                <h2 className="pl-2 font-bold text-lg">
                                    Onze Blogs
                                </h2>
                            </div>
                            <div className="mt-1">
                                <BlogPosts posts={posts} />
                            </div>
                        </div>
                        <div className="col-span-2 mt-5 lg:pl-3">
                            <div className="flex items-center p-2 bg-white border shadow w-full rounded-lg h-auto">
                                <BiBook className="text-2xl text-primary-blue" />
                                <h2 className="pl-2 font-bold text-lg">
                                    Kennisbank
                                </h2>
                            </div>
                            <div
                                className="rounded-lg bg-white p-2 mt-2 hover:cursor-pointer hover:underline hover:shadow"
                                onClick={() => window.open("/help")}
                            >
                                <p className="text-sm">Hulp bij inschrijven</p>
                            </div>
                            <div
                                className="rounded-lg bg-white p-2 mt-1 hover:cursor-pointer hover:underline hover:shadow"
                                onClick={() =>
                                    window.open(
                                        "https://tendertracer.nl/aanbesteden"
                                    )
                                }
                            >
                                <p className="text-sm">Wat is aanbesteden</p>
                            </div>
                            <div
                                className="rounded-lg bg-white p-2 mt-1 hover:cursor-pointer hover:underline hover:shadow"
                                onClick={() =>
                                    window.open(
                                        "https://tendertracer.nl/mkbers-en-aanbestedingen-zo-maak-je-kans-op-succes/"
                                    )
                                }
                            >
                                <p className="text-sm">Hoe behaal ik succes</p>
                            </div>
                            <div className="bg-white mt-2 rounded-lg p-2">
                                <p>Uitleg video's</p>
                                <div className="grid grid-cols-2 gap-3 w-full pt-2">
                                    <div className="rounded-lg h-auto">
                                        <YoutubeEmbed embedId="yKTeclkP5-c" />
                                    </div>
                                    <div className="rounded-lg h-auto">
                                        <YoutubeEmbed embedId="ROsNhxSmJAs" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showModal && (
                        <Modal
                            title={
                                "Proefperiode 45 Dagen Gratis - TenderTracer Premium"
                            }
                            onClick={() => setShowModal(false)}
                        >
                            <div className="px-10 pt-10 relative">
                                <div className="absolute inset-0 flex items-center justify-center pointer-events-none opacity-25">
                                    <Lottie
                                        loop={false}
                                        animationData={crown}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </div>
                                <div className="lg:grid grid-cols-4">
                                    <div className="relative z-10 col-span-3">
                                        <p className="font-bold">
                                            Bedankt voor je interesse in
                                            TenderTracer!
                                        </p>
                                        <p className="pt-3 font-bold font-sans">
                                            Instructies voor Activering van de
                                            45 Dagen Gratis Premiumperiode van
                                            TenderTracer
                                        </p>
                                        <ul className="list-disc pl-5 pt-2">
                                            <li>
                                                <span className="font-sans font-bold">
                                                    Klik op de knop voor de 45
                                                    dagen gratis Premium:
                                                </span>{" "}
                                                <span className="font-normal">
                                                    Hiermee krijg je 45 dagen
                                                    toegang tot TenderTracer
                                                    Premium, geheel kosteloos.
                                                </span>
                                            </li>
                                            <li>
                                                <span className="font-sans font-bold">
                                                    Registratie via Stripe:
                                                </span>{" "}
                                                <span className="font-normal">
                                                    Je wordt doorgestuurd naar
                                                    Stripe, om de volledig
                                                    gratis Premiumperiode
                                                    definitief te maken. Stopt
                                                    automatisch, geen verborgen
                                                    kosten!
                                                </span>
                                            </li>
                                            <li>
                                                <span className="font-sans font-bold">
                                                    Onboarding met AI:
                                                </span>{" "}
                                                <span className="font-normal">
                                                    Na het activeren van je
                                                    Premium proefperiode, helpt
                                                    onze AI-assistent je snel en
                                                    eenvoudig, binnen 5 minuten,
                                                    met het inregelen van
                                                    TenderTracer Premium.
                                                </span>
                                            </li>
                                        </ul>
                                        <p className="pt-3 font-bold font-sans">
                                            Na de 45 Dagen Proefperiode
                                        </p>
                                        <ul className="list-disc pl-5 pt-2">
                                            <li>
                                                <span className="font-sans font-bold">
                                                    Gratis Versie:
                                                </span>{" "}
                                                <span className="font-normal">
                                                    Kies je na 45 dagen niet
                                                    voor de premium versie, dan
                                                    stopt de proefperiode
                                                    automatisch en kun je verder
                                                    met de gratis lightversie
                                                    van TenderTracer.
                                                </span>
                                            </li>
                                            <li>
                                                <span className="font-sans font-bold">
                                                    Premium Versie:
                                                </span>{" "}
                                                <span className="font-normal">
                                                    Aan het einde van de
                                                    proefperiode kun je ook
                                                    kiezen om door te gaan met
                                                    onze premium versie, tegen
                                                    een betaling van € 35,- per
                                                    maand (exclusief btw)
                                                </span>
                                            </li>
                                        </ul>
                                        <p className="pt-3">
                                            Veel succes en plezier met het
                                            ontdekken van TenderTracer Premium!
                                        </p>
                                    </div>
                                    <div className="col-span-1 ml-5 pl-3 border-l border-gray-300">
                                        <p className="font-bold font-sans mb-2">
                                            Voordelen van TenderTracer Premium:
                                        </p>
                                        <ul className="space-y-2 text-sm">
                                            <li>
                                                <span className="font-semibold font-sans">
                                                    🔍 Voor u geselecteerde
                                                    aanbestedingen
                                                </span>
                                            </li>
                                            <li>
                                                <span className="font-semibold font-sans">
                                                    🌍 Internationale
                                                    aanbestedingen
                                                </span>
                                            </li>
                                            <li>
                                                <span className="font-semibold font-sans">
                                                    📊 Uitgebreide concurrentie
                                                    analyse
                                                </span>
                                            </li>
                                            <li>
                                                <span className="font-semibold font-sans">
                                                    🆘 Hulp bij inschrijven
                                                </span>
                                            </li>
                                            <li>
                                                <span className="font-semibold font-sans">
                                                    🤖 Door AI gestuurde
                                                    CPV-codes*
                                                </span>
                                            </li>
                                        </ul>
                                        <br></br>
                                        <br></br>
                                        <p className="text-xs italic">
                                            *CPV-codes (Common Procurement
                                            Vocabulary) categoriseren
                                            onderwerpen van aanbestedingen
                                            binnen de EU.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {loadingTrial ? (
                                <Button
                                    type="hidden"
                                    className="m-5 flex space-x-2 items-center justify-center"
                                >
                                    <Spinner />
                                    Laden...
                                </Button>
                            ) : (
                                <Button
                                    type="secondary"
                                    onClick={fetchTrial}
                                    className="animate-bounce m-5"
                                >
                                    Start gratis proefperiode
                                </Button>
                            )}
                        </Modal>
                    )}
                </section>
            )}
        </>
    );
};
{
    /* <p className="pt-3">
                                    <span className="font-bold">Klik op de knop voor de 90 dagen gratis Premium</span>: Hiermee krijg je 90 dagen toegang tot TenderTracer Premium, geheel kosteloos.
                                    </p>
                                    <p className="pt-3 font-bold">
                                        Wat gebeurt er na de 90 dagen?
                                    </p>
                                    <p>
                                        Aan het einde van de proefperiode krijg
                                        je de optie om door te gaan met onze
                                        premium versie. Als je ervoor kiest om
                                        geen premium versie te nemen, stopt de
                                        proefperiode automatisch en kun je
                                        verder met onze gratis versie. Er zijn
                                        geen verborgen kosten of verrassingen;
                                        je hebt altijd de controle.
                                    </p>
                                    <p className="pt-3 font-bold">
                                        Onboarding met AI
                                    </p>
                                    <p>
                                        Na het activeren van je proefperiode,
                                        zullen we je begeleiden door een
                                        onboarding proces dat wordt ondersteund
                                        door AI. Deze slimme assistent helpt je
                                        om snel en eenvoudig aan de slag te gaan
                                        met TenderTracer.
                                    </p>
                                    <p className="pt-3">
                                        Veel succes en plezier met het ontdekken
                                        van TenderTracer!
                                    </p>
                                </div>
                                <div> */
}
