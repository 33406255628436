import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faChevronRight,
    faChevronLeft,
    faChevronDown,
    faSquareCheck,
    faSquareMinus,
    faBriefcase,
    faBuilding,
    faChartLine,
    faRightFromBracket,
    faUser,
    faHeart,
    faDownLong,
    faTrashCan,
    faCheck,
    faPlus,
    faEye,
    faEyeSlash,
    faSuitcaseMedical,
    faHandBackFist,
    faStore,
    faXmark,
    faPencil,
    faHeartCrack,
    faBug,
    faRobot,
    faMagnifyingGlassDollar,
    faCrown,
    faBars,
    faCommentDots,
    faStar,
    faFilter,
    faFilePdf,
    faFileExcel,
    faFileWord,
    faFileZipper,
    faFile,
    faFileLines,
    faFileCsv,
    faRetweet,
    faInfo,
    faBullhorn,
    faHourglassHalf,
    faKey,
    faLink,
    faEuroSign,
    faCircleCheck,
    faExclamation,
    faChartSimple,
    faFire,
    faBlog,
    faHeadphones,
    faBook,
    faArrowTrendUp,
    faCircle,
    faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
    faSquare as farSquare,
    faHeart as farHeart,
    faClock as farClock,
    faBell as farBell,
    faFlag as farFlag,
    faBuilding as farBuilding,
    faEnvelope as farEnvelope,
    faCircleQuestion as farCircleQuestion,
    faLightbulb as farLightbulb,
} from "@fortawesome/free-regular-svg-icons";
import { faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons";

library.add(
    faHeadphones,
    faLocationDot,
    faCircle,
    faLinkedin,
    faYoutube,
    farLightbulb,
    faBook,
    faArrowTrendUp,
    faBlog,
    faChartSimple,
    faChevronRight,
    faFire,
    faChevronLeft,
    faChevronDown,
    farSquare,
    faSquareCheck,
    faSquareMinus,
    faBriefcase,
    faBuilding,
    faChartLine,
    faRightFromBracket,
    faUser,
    faHeart,
    faDownLong,
    faTrashCan,
    faCheck,
    faPlus,
    faEye,
    faEyeSlash,
    faSuitcaseMedical,
    faHandBackFist,
    faStore,
    faXmark,
    faPencil,
    faHeartCrack,
    faRobot,
    faBug,
    faMagnifyingGlassDollar,
    faCheck,
    faXmark,
    faCrown,
    faBars,
    faCommentDots,
    faStar,
    faHeartCrack,
    faFilter,
    faFilePdf,
    faFileExcel,
    faFileWord,
    faFileZipper,
    faFile,
    faFileLines,
    faFileCsv,
    farHeart,
    faRetweet,
    faInfo,
    farClock,
    faBullhorn,
    faHourglassHalf,
    faEuroSign,
    faKey,
    farBell,
    farFlag,
    farEnvelope,
    farBuilding,
    faLink,
    faEuroSign,
    faTrashCan,
    farCircleQuestion,
    faCircleCheck,
    faExclamation
);
