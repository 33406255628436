import React, { FormEvent, useEffect, useState } from "react";
import { Button } from "../../Button";
import { Input } from "../../Input";

interface Props {
    onClose: () => void;
    onNext: () => void;
    setKeywords: (keywords: string[]) => void;
    keywords: string[];
}

interface KeywordsObject {
    [key: string]: string;
}

export default function BrancheOnboarding({
    onClose,
    onNext,
    setKeywords,
    keywords,
}: Props) {
    const initialKeywordsState: KeywordsObject = {
        keyword1: "",
        keyword2: "",
        keyword3: "",
        keyword4: "",
        keyword5: "",
        keyword6: "",
        keyword7: "",
        keyword8: "",
        keyword9: "",
        keyword10: "",
    };

    const [valid, setValid] = useState<boolean>(false);
    const [keywordsArray, setKeywordsArray] =
        useState<KeywordsObject>(initialKeywordsState);

    const handleKeywords = (
        event: FormEvent<HTMLInputElement>,
        key: string
    ) => {
        setKeywordsArray({
            ...keywordsArray,
            [key]: event.currentTarget.value,
        });
    };

    useEffect(() => {
        const filteredKeywords = Object.values(keywordsArray).filter(
            (keyword) => keyword !== ""
        );
        setValid(filteredKeywords.length >= 5);
        setKeywords(filteredKeywords);
    }, [keywordsArray]);

    const renderInputs = () => {
        return Object.keys(initialKeywordsState).map((key, index) => (
            <Input
                key={key}
                label=""
                value={keywordsArray[key]}
                required={false}
                type="text"
                placeholder={`Trefwoord ${index + 1}`}
                onChange={(e: FormEvent<HTMLInputElement>) =>
                    handleKeywords(e, key)
                }
            />
        ));
    };

    return (
        <div className="flex pl-16 py-20">
            <div className="h-full flex flex-col items-">
                <div className="pr-20">
                    <div className="group relative flex h-full mt-5">
                        <p className="text-xs opacity-50 pb-1 text-begin cursor-pointer hover:text-primary-pink">
                            Bedrijfsidentificatie
                        </p>
                    </div>
                    <p className="col-span-2 text-begin font-bold">
                        Beschrijf je bedrijf in minimaal 5 en maximaal 10
                        trefwoorden
                    </p>
                    <div className="grid grid-cols-2 gap-3 mt-3">
                        {renderInputs()}
                    </div>
                    <div className="mt-20 flex w-full justify-between">
                        <Button onClick={onClose} type="white">
                            Terug
                        </Button>
                        {!valid ? (
                            <Button type="hidden">Verder gaan</Button>
                        ) : (
                            <Button onClick={onNext} type="pink">
                                Verder gaan
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
