import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { FormEvent, useState } from "react";
import { Button } from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { InputPhone } from "../../../../components/InputPhone";
import { Modal } from "../../../../components/Modal";
import { Spinner } from "../../../../components/Spinner";

interface UserInfoProps {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    stripeID: string;
    companyInfo: any;
    cpvs: any;
}

interface UserInfo {
    UserInfo: UserInfoProps;
    reloadData: () => void;
}

export const PersonalSettings = ({ UserInfo, reloadData }: UserInfo) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [firstname, setFirstname] = useState<string>(UserInfo.firstname);
    const [lastname, setLastname] = useState<string>(UserInfo.lastname);
    const [phone, setPhone] = useState<string>(UserInfo.phone);
    const [email, setEmail] = useState<string>(UserInfo.email);
    const [message, setMessage] = useState<string>("");
    const [verificationCode, setVerificationCode] = useState<string>("");

    async function update() {
        setLoading(true);
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });
        const updateUser = `mutation MyQuery { updateUser(input: {id: "${attributes.sub}", firstname: "${firstname}", lastname: "${lastname}", email: "${email}", phone: "+31${phone}"}) { firstname }}`;
        try {
            if (attributes.email !== email) {
                await Auth.verifyCurrentUserAttributeSubmit(
                    "email",
                    verificationCode
                );
                setShowModal(false);
            }
            await API.graphql(graphqlOperation(updateUser));
            await reloadData();
            setMessage("Je gegevens zijn succesvol bijgewerkt!");
            setLoading(false);
        } catch (err) {
            console.log(err);
            setMessage("Mislukt! Er is wat fout gegaan.");
            setLoading(false);
        }
    }

    async function validator() {
        setLoading(true);
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });
        const user = await Auth.currentAuthenticatedUser();

        if (email.includes("@") && email.includes(".")) {
            try {
                if (attributes.email !== email) {
                    await Auth.updateUserAttributes(user, {
                        email: email,
                    });
                    setShowModal(true);
                    setLoading(false);
                } else {
                    await update();
                }
            } catch (err) {
                console.log(err);
                setMessage("Mislukt! Er is wat fout gegaan.");
                setLoading(false);
            }
        } else {
            setMessage("Fout! Voer een geldig emailadres in.");
        }
    }

    return (
        <div className="">
            <div className="bg-white shadow border rounded-lg ml-5 mr-5 p-10">
                <div className="grid grid-cols-2 gap-5">
                    <Input
                        label="Voornaam"
                        value={firstname}
                        placeholder="vb. John"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            setFirstname(e.currentTarget.value.toString())
                        }
                    />
                    <Input
                        label="Achternaam"
                        value={lastname}
                        placeholder="vb. Doe"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            setLastname(e.currentTarget.value.toString())
                        }
                    />
                </div>
                <div className="pt-5">
                    <Input
                        label="Emailadres"
                        value={email}
                        type="email"
                        placeholder="vb. john@tendertracer.nl"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            setEmail(e.currentTarget.value.toString())
                        }
                    />
                </div>
                {/* <div className="pt-5">
                    <InputPhone
                        label="Telefoonnummer"
                        value={phone}
                        type="tel"
                        placeholder="611627384"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            setPhone(e.currentTarget.value.toString())
                        }
                    />
                </div> */}
            </div>
            <div className="p-5 float-right grid grid-cols-2 gap-5 items-center">
                <div>
                    {message !== "" && (
                        <p style={{ color: "#ff4d72" }}>{message}</p>
                    )}
                </div>
                <Button
                    type={loading === false ? "secondary" : "hidden"}
                    onClick={() =>
                        loading === false ? validator() : undefined
                    }
                >
                    {loading === false ? (
                        "OPSLAAN"
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <div style={{ paddingRight: "15%" }}>
                                <p>Laden...</p>
                            </div>
                            <div>
                                <Spinner />
                            </div>
                        </div>
                    )}
                </Button>
            </div>
            {showModal ? (
                <Modal
                    title="Email verifiëren"
                    onClick={() => setShowModal(false)}
                >
                    <div className="p-5">
                        <p>
                            Het lijkt erop dat je, je emailadres wilt wijzigen.
                            We hebben daarom naar je nieuwe emailadres een
                            verificatiecode verstuurd. Vul deze hieronder in:
                        </p>
                        <div className="pt-5">
                            <Input
                                label="Voer je verificatiecode in:"
                                value={verificationCode}
                                type="number"
                                placeholder="000000"
                                onChange={(e: FormEvent<HTMLInputElement>) =>
                                    setVerificationCode(
                                        e.currentTarget.value.toString()
                                    )
                                }
                            />
                        </div>
                        <p className="pt-5">
                            <b>Let op!</b> Het kan even duren tot je een email
                            ontvangt.
                        </p>
                        <div className="pt-5 float-right items-center">
                            <Button
                                type={
                                    loading === false ? "secondary" : "hidden"
                                }
                                onClick={() =>
                                    loading === false ? update() : undefined
                                }
                            >
                                {loading === false ? (
                                    "AANPASSEN"
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div style={{ paddingRight: "15%" }}>
                                            <p>Laden...</p>
                                        </div>
                                        <div>
                                            <Spinner />
                                        </div>
                                    </div>
                                )}
                            </Button>
                        </div>
                    </div>
                </Modal>
            ) : null}
        </div>
    );
};
