import { Auth } from "aws-amplify";
import { plans } from "../../pages/profile/phone/data";
import { Modal } from "../Modal";
import { PricingCard } from "./PricingCard";
import { useState } from "react";
import { Spinner } from "../Spinner";
import { API_DEFAULT_HEADERS, API_DOMAIN } from "../../APIconfig";

interface Props {
    stripeId: string;
    closeModal: () => void;
}

export default function PremiumModal({ stripeId, closeModal }: Props) {
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSelect(
        id: string,
        title: string,
        price: string,
        type: string
    ) {
        setLoading(true);
        console.log("Creating payment link");

        Auth.currentSession().then((res) => {
            let accessToken = res.getIdToken();
            let jwt = accessToken.getJwtToken();

            var raw = JSON.stringify({
                customerID: stripeId,
                priceID: id,
                locale: "nl",
            });

            var requestOptions: any = {
                method: "POST",
                headers: new Headers(API_DEFAULT_HEADERS(jwt)),
                body: raw,
            };

            fetch(`${API_DOMAIN}/stripe/pay`, requestOptions)
                .then(async (response) => {
                    const jsonResponse = await response.json();
                    window.location.replace(jsonResponse.body);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        });
    }

    return (
        <Modal
            title="Tendertracer Premium"
            subtitle="Lijkt erop dat je een functionaliteit probeert te gebruiken wat enkel beschikbaar is voor gebruikers met een Tendertracer Premium abonnement. Sluit hieronder een betaald abonnement af om gebruik te kunnen maken van deze functionaliteit."
            icon={"crown"}
            onClick={closeModal}
        >
            <div className="relative grid grid-cols-2 p-5">
                {loading && (
                    <div className="absolute w-full h-full bg-black bg-opacity-30 flex flex-col items-center justify-center">
                        <Spinner />
                        <p className="pt-3">Betaling aanmaken...</p>
                    </div>
                )}
                {plans.map((plan) => (
                    <div key={plan.id}>
                        <PricingCard
                            id={plan.id}
                            title={plan.title}
                            subtitle={plan.subtitle}
                            discount={plan.discount}
                            period={plan.period}
                            price={plan.price}
                            features={plan.features}
                            buttonText="Direct betalen"
                            button={handleSelect}
                            invoiceId={plan.invoiceId}
                            loading={loading}
                        />
                    </div>
                ))}
            </div>
        </Modal>
    );
}
