import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

interface SelectProps {
    children: React.ReactNode;
    className?: string;
    defaultValue?: string;
    disabled?: boolean;
    onChange?: (event: any) => void;
}

export const Select = ({
    children,
    defaultValue = "",
    className,
    disabled = false,
    onChange,
}: SelectProps) => {
    return (
        <div
            className={clsx(
                "relative w-full h-full ring-primary-pink",
                className
            )}
        >
            <div className="absolute right-2 lg:right-3 lg:top-2">
                <FontAwesomeIcon className={""} icon="chevron-down" />
            </div>
            <select
                id="dropdown"
                className="w-full h-full rounded-lg appearance-none truncate pl-3 pr-5"
                defaultValue={defaultValue}
                onChange={onChange}
                disabled={disabled}
            >
                {children}
            </select>
        </div>
    );
};
