import { API, Auth, graphqlOperation } from "aws-amplify";
import { FormEvent, useState } from "react";
import { Button } from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { Spinner } from "../../../../components/Spinner";

export const AccountSettings = (params: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordValid, setNewPasswordValid] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    async function update() {
        setLoading(true);
        var checkup = Validator();
        const user = await Auth.currentAuthenticatedUser();
        if (checkup === true) {
            try {
                await Auth.changePassword(user, password, newPassword);
                setMessage("Je gegevens zijn succesvol bijgewerkt!");
                setLoading(false);
            } catch (err: any) {
                if (err.toString().includes("NotAuthorizedException")) {
                    setMessage("Je hebt een foutief wachtwoord ingevuld.");
                } else if (err.toString().includes("LimitExceededException")) {
                    setMessage(
                        "Je hebt het te vaak geprobeerd. Probeer het later nogmaals.."
                    );
                } else {
                    setMessage(
                        "Fout! Er is wat mis gegaan. Probeer het later nogmaals."
                    );
                }
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }

    function Validator() {
        var errors = [];
        if (password === "" || newPassword === "" || newPasswordValid === "") {
            errors.push("Vul alle velden in.");
        } else if (newPassword !== newPasswordValid) {
            errors.push("Wachtwoorden komen niet met elkaar overeen.");
        } else if (newPassword.length < 6 || newPassword.length > 99) {
            errors.push(
                "Wachtwoord moet bestaan uit minimaal 6 en maximaal 99 karakters."
            );
        } else if (!/[A-Z]/.test(newPassword)) {
            errors.push("Wachtwoord moet minimaal 1 hoofdletter bevatten.");
        } else if (!/[a-z]/.test(newPassword)) {
            errors.push("Wachtwoord moet minimaal 1 kleine letter bevatten.");
        } else if (!/[0-9]/.test(newPassword)) {
            errors.push("Wachtwoord moet minimaal 1 cijfer bevatten.");
        } else if (!/[!@#$%^&*]/.test(newPassword)) {
            errors.push("Wachtwoord moet minimaal 1 symbool bevatten.");
        }

        if (errors.length > 0) {
            setMessage(errors[0]);
            setLoading(false);
            return false;
        } else {
            setMessage("");
            return true;
        }
    }

    return (
        <div className="">
            <div className="bg-white shadow border rounded-lg ml-5 mr-5 p-10">
                <div className="pt-5">
                    <Input
                        label="Huidige wachtwoord"
                        value={password}
                        type="password"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            setPassword(e.currentTarget.value.toString())
                        }
                    />
                </div>
                <div className="pt-14">
                    <Input
                        label="Nieuwe wachtwoord"
                        value={newPassword}
                        type="password"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            setNewPassword(e.currentTarget.value.toString())
                        }
                    />
                </div>
                <div className="pt-5">
                    <Input
                        label="Nogmaals nieuwe wachtwoord"
                        value={newPasswordValid}
                        type="password"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            setNewPasswordValid(
                                e.currentTarget.value.toString()
                            )
                        }
                    />
                </div>
            </div>
            <div className="p-5 float-right grid grid-cols-2 gap-5 items-center">
                <div>
                    {message !== "" && (
                        <p style={{ color: "#ff4d72" }}>{message}</p>
                    )}
                </div>
                <Button
                    type={loading === false ? "secondary" : "hidden"}
                    onClick={() => (loading === false ? update() : undefined)}
                >
                    {loading === false ? (
                        "WIJZIGEN"
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <div style={{ paddingRight: "15%" }}>
                                <p>Laden...</p>
                            </div>
                            <div>
                                <Spinner />
                            </div>
                        </div>
                    )}
                </Button>
            </div>
        </div>
    );
};
