import "../fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface SelectionItemProps {
  name?: String;
  onClick?: () => void;
}

export const SelectedSelectionSelectorItem = ({
  name,
  onClick,
}: SelectionItemProps) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: "100%",
        minHeight: 50,
        display: "flex",
        alignItems: "center",
        paddingLeft: "5%",
        borderBottomWidth: 1,
        borderColor: "#284F82",
        cursor: "pointer",
      }}
    >
      <div style={{ paddingRight: "7%" }}>
        <FontAwesomeIcon color="#284F82" icon="chevron-left" />
      </div>
      <div
        style={{ paddingTop: "3%", paddingBottom: "3%", paddingRight: "3%" }}
      >
        <p style={{ color: "#284F82" }}>{name}</p>
      </div>
    </div>
  );
};
