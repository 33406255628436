import clsx from "clsx";

interface ButtonProps {
    type?: "primary" | "secondary" | "pink" | "white" | "ghost" | "hidden";
    buttonType?: "button" | "submit";
    size?: "sm" | "md" | "lg" | "xl";
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
}

export const Button = ({
    type = "primary",
    buttonType = "button",
    children,
    size = "md",
    className,
    onClick,
    disabled = false,
}: ButtonProps) => {
    const typeClasses =
        type === "primary"
            ? "bg-primary-blue text-white hover:bg-primary-green"
            : type === "secondary"
            ? "bg-primary-green text-white hover:bg-primary-pink"
            : type === "pink"
            ? "bg-primary-pink text-white hover:bg-[#9B3047]"
            : type === "white"
            ? "bg-white text-black hover:bg-[#B7B7B7]"
            : type === "hidden"
            ? "bg-transparent outline outline-primary-green outline-1 text-primary-green cursor-not-allowed rounded-xl hover:text-primary-pink hover:outline-primary-pink"
            : "bg-transparent outline outline-primary-green outline-1 text-primary-green hover:text-primary-pink rounded-xl hover:outline-primary-pink";
    const sizeClasses =
        size === "sm"
            ? "text-sm p0"
            : size === "lg"
            ? "text-lg px-5 py-2 font-medium"
            : size === "xl"
            ? "text-xl px-10 py-4 font-medium"
            : "px-5 py-2 font-medium";
    return (
        <button
            onClick={onClick}
            type={buttonType}
            className={clsx(`rounded-xl `, typeClasses, sizeClasses, className)}
            disabled={disabled}
        >
            {children}
        </button>
    );
};
