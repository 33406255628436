import { Button } from "../../Button";

interface Props {
    onClose: () => void;
    onNext: () => void;
}

export default function WelcomeOnboarding({ onClose, onNext }: Props) {
    return (
        <div className="h-full flex flex-col w-full px-10">
            <div className="w-[100%] h-[620px] bg-top p-10 flex items-end justify-end bg-cover bg-[url('/public/images/onboarding-roadmap-1.gif')]">
                <Button onClick={onNext} type="pink">
                    Verder gaan
                </Button>
            </div>
        </div>
    );
}
