import { useEffect, useState } from "react";
import "../fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import emailjs from "@emailjs/browser";
import { Button } from "./Button";
import { Auth } from "aws-amplify";
import Lottie from "lottie-react";
import loadingAnimation from "../lottie/Loading-2.json";
import { Loader2 } from "lucide-react";

interface SpinnerProps {
    infiniteLoop?: boolean;
}

export const LoadingDocument = ({ infiniteLoop = true }: SpinnerProps) => {
    const [showError, setShowError] = useState<boolean>(false);
    const [showErrorButton, setErrorButton] = useState<boolean>(true);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    function getErrorMailParams(sub: string) {
        const params = {
            name: sub,
            route: window.location.href,
            message: "Mislukt om pagina in te laden.",
            logs: getConsoleLog(),
        };

        return params;
    }

    function getConsoleLog() {
        var logMessages: any = [];
        console.log = function () {
            logMessages.push.apply(logMessages, arguments);
        };

        return logMessages;
    }

    async function sendInfo() {
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });
        await emailjs.send(
            "service_6lkdt7y",
            "template_c2l4l6x",
            getErrorMailParams(attributes.sub),
            "3hb2pqFcSXP9kcagF"
        );
        setErrorButton(false);
    }

    useEffect(() => {
        if (infiniteLoop === false) {
            setTimeout(() => {
                setShowErrorMessage(true);
                setErrorMessage(
                    "Duurt iets langer als verwacht.. Excuses voor het ongemak!"
                );
            }, 5000);
            setTimeout(() => {
                setShowError(true);
                setErrorMessage("");
            }, 15000);
        }
    }, []);

    return (
        <div role="status" className="flex flex-col items-center">
            {showError ? (
                <div
                    className="flex flex-col items-center"
                    style={{ marginTop: "-50%" }}
                >
                    <FontAwesomeIcon
                        className="text-[200px]"
                        color="#ff4d72"
                        icon="heart-crack"
                    />
                    <div className="pt-2 flex flex-col items-center">
                        <p>
                            Wegens een technische storing is het niet gelukt om
                            de pagina in te laden.
                        </p>
                        <p>
                            Stel ons op de hoogte van dit probleem door op de
                            knop hieronder te drukken.
                        </p>
                        {showErrorButton ? (
                            <Button
                                type="ghost"
                                className="flex items-center justify-center mt-5"
                                onClick={() => sendInfo()}
                            >
                                <FontAwesomeIcon
                                    className="pr-2 text-xl"
                                    color="#ff4d72"
                                    icon="robot"
                                />
                                Melding maken
                            </Button>
                        ) : (
                            <p className="pt-5 text-primary-pink">
                                We gaan ermee aan de slag! Bedankt voor het
                                doorgeven. Nogmaals excuses voor het ongemak.
                            </p>
                        )}
                    </div>
                </div>
            ) : (
                <Lottie
                    loop={false}
                    animationData={loadingAnimation}
                    className="w-40"
                />
            )}
            {showErrorMessage && <span className="pt-2">{errorMessage}</span>}
        </div>
    );
};

const LoadingSpinner = () => {
    return (
      <div className="flex items-center justify-center space-x-2">
        <Loader2 className="h-6 w-6 animate-spin text-primary-blue" />
        <span className="text-primary-blue">Bezig met laden...</span>
      </div>
    );
  };
  
  export default LoadingSpinner;