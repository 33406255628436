import Moment from "moment";
import "moment/locale/nl";
import { Link } from "react-router-dom";
import { translatePhase, translateProcedure, translateType } from "../tenders/TenderOverview";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { sendGAEvent } from "../../utils/analytics";

Moment.locale("nl");

interface TenderItemProps {
    item: any;
    index: string;
    companyID: string;
    favorite: boolean;
}

export const FavoriteTenderItem = ({ item, index, companyID, favorite}: TenderItemProps) => {
    const [phaseData, setPhaseData] = useState<any>(null);

    useEffect(() => {
        const fetchPhaseData = async () => {
            const lastPhaseItem = item.tender.phases.items[item.tender.phases.items.length - 1];
            if (lastPhaseItem) {
                const getPhaseQuery = `query MyQuery {
                    getPhase(id: "${lastPhaseItem.id}") {
                        id
                        title
                        phase
                        country
                        end_date
                        date_receipt
                        time_receipt
                        tender_type
                        procedure
                        dispatch_notice_date
                        contracting_authority
                    }
                }`;

                try {
                    const phaseResult: any = await API.graphql(graphqlOperation(getPhaseQuery));
                    setPhaseData(phaseResult.data.getPhase);
                } catch (error) {
                    console.error("Error fetching phase data:", error);
                }
            }
        };
        fetchPhaseData();
    }, [item]);

    const dispatch_notice_date = Moment(
        item?.tender?.dispatch_notice_date?.toString()
    ).format("LL");

    const lastPhaseItem = item.tender.phases.items[item.tender.phases.items.length - 1];
    console.log(item);
    const end_date = (() => {
        if (phaseData?.end_date) {
            return Moment(phaseData.end_date.toString()).format("LL");
        } else if (phaseData?.date_receipt) {
            return phaseData.time_receipt
                ? `${Moment(phaseData.date_receipt).format("LL")}, ${phaseData.time_receipt}`
                : Moment(phaseData.date_receipt).format("LL");
        } else if (lastPhaseItem?.end_date) {
            return Moment(lastPhaseItem.end_date.toString()).format("LL");
        }
        return "Onbekend";
    })();

    return (
        <Link
            to={`/tender/${item.tender.id}/${
                lastPhaseItem ? lastPhaseItem.id : item.tender.id
            }`}
        >
            <div className="flex lg:flex-row flex-col bg-white rounded-lg ml-5 mr-5 mb-3 p-5 border shadow hover:shadow-xl cursor-pointer">
                <div className="lg:w-[60%] flex flex-col">
                    <div className="text-primary-pink">
                        {item.tender.current_phase ? (
                            <p>
                                Meest actuele publicatie:{" "}
                                {translatePhase(item.tender.current_phase)}
                            </p>
                        ) : (
                            <p>Onbekend</p>
                        )}
                    </div>
                    <span className="font-bold lg:text-xl pt-3 lg:pt-0">
                        {item.tender.title}
                    </span>
                    <span className="pt-3 text-sm lg:pt-0 pb-3">
                        {item.tender.contracting_authority}
                    </span>
                </div>
                <div className="flex flex-col pt-3 lg:w-[40%] lg:border-t-0 border-t lg:mt-0 mt-5 lg:pl-10">
                    <div className="flex justify-between">
                        <span className="font-bold">Publicatiedatum: </span>
                        <div className="text-right opacity-60">
                            {item.tender.dispatch_notice_date ? (
                                <span>{dispatch_notice_date}</span>
                            ) : (
                                <span>Onbekend</span>
                            )}
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-bold">Sluitingsdatum: </span>
                        <div className="text-right opacity-60">
                            <span>{end_date}</span>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-bold">Procedure: </span>
                        <div className="text-right opacity-60">
                        {(() => {
                            const procedure = phaseData?.procedure || item.tender.procedure;
                            if (!procedure || procedure === "-" || procedure === "null") {
                                return <span>Onbekend</span>;
                            } else {
                                return <span>{translateProcedure(procedure)}</span>;
                            }
                        })()}
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-bold">Type opdracht:</span>
                        <div className="text-right opacity-60">
                            {item.tender.tender_type ? (
                                <span>{translateType(item.tender.tender_type, item.tender.country)}</span>
                            ) : (
                                <span>Onbekend</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};
