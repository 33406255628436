import { RouteType } from "../../routes/config";
import "../../fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";

type Props = {
  item: RouteType;
};

const TopbarItem = ({ item }: Props) => {
  return item.sidebarProps && item.mobile ? (
    <Link
      className="flex z-30 p-5 w-full cursor-pointer border-b justify-center items-center"
      to={item.path}
    >
      <span className="hover:text-primary-pink">
        {item.sidebarProps.displayText}
      </span>
    </Link>
  ) : null;
};

export default TopbarItem;
