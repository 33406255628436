import { Plan } from "AppTypes";

export const plans: Plan[] = [
    {
        title: "Maandelijks",
        subtitle: "",
        id: "price_1OvHItBsOwaHH4Y25J9BvHnM",
        invoiceId: "price_1OvHItBsOwaHH4Y25J9BvHnM",
        price: "35,-",
        discount: "",
        period: "/maand",
        button: "Direct betalen",
        features: [
            {
                name: "Maandelijkse betaling",
                adventage: true,
            },
            {
                name: "Volledige toegang tot Tendertracer",
                adventage: true,
            },
            {
                name: "Betaling per Factuur, Ideal, Creditcard of Automatisch incasso",
                adventage: true,
            },
        ],
    },
    {
        title: "Jaarlijks",
        subtitle: "",
        id: "price_1OvHJdBsOwaHH4Y2mEu67Ojk",
        invoiceId: "price_1OvHJdBsOwaHH4Y2mEu67Ojk",
        price: "350,-",
        discount: "€420",
        period: "/jaar",
        button: "Direct betalen",
        features: [
            {
                name: "Jaarlijkse betaling",
                adventage: true,
            },
            {
                name: "Volledige toegang tot Tendertracer",
                adventage: true,
            },
            {
                name: "Betaling per Factuur, Ideal, Creditcard of Automatisch incasso",
                adventage: true,
            },
        ],
    },
];
