// import React, { useEffect, useState } from 'react';

// interface CountdownProps {
//     targetDate: Date | string;
// }

// const CountdownClock: React.FC<CountdownProps> = ({ targetDate }) => {
//     const [countdown, setCountdown] = useState({
//         days: 0,
//         hours: 0,
//         minutes: 0,
//         seconds: 0,
//     });

//     useEffect(() => {
//         const getValidDate = (date: Date | string) => {
//             if (date instanceof Date) {
//                 return date;
//             }
//             return new Date(date);
//         };

//         const validTargetDate = getValidDate(targetDate);

//         if (isNaN(validTargetDate.getTime())) {
//             console.error('Invalid date provided to CountdownClock');
//             return;
//         }

//         const interval = setInterval(() => {
//             const now = new Date().getTime();
//             const distance = validTargetDate.getTime() - now;

//             if (distance < 0) {
//                 clearInterval(interval);
//                 setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
//                 alert("Je premium periode is afgelopen. De pagina zal over enkele seconden verversen.");

//                 setTimeout(() => {
//                     window.location.reload();
//                 }, 8000);
//                 return;
//             }

//             const days = Math.floor(distance / (1000 * 60 * 60 * 24));
//             const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//             const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//             const seconds = Math.floor((distance % (1000 * 60)) / 1000);

//             setCountdown({ days, hours, minutes, seconds });
//         }, 1000);

//         return () => {
//             clearInterval(interval);
//         };
//     }, [targetDate]);

//     return (
//         <div className="flex space-x-4 text-center items-center justify-center bg-gray-100 p-4 rounded-lg shadow-lg">
//             <div className="flex flex-col">
//                 <span className="text-2xl font-bold">{countdown.days}</span>
//                 <span className="text-sm">Dagen</span>
//             </div>
//             <div className="flex flex-col">
//                 <span className="text-2xl font-bold">{countdown.hours}</span>
//                 <span className="text-sm">Uren</span>
//             </div>
//             <div className="flex flex-col">
//                 <span className="text-2xl font-bold">{countdown.minutes}</span>
//                 <span className="text-sm">Minuten</span>
//             </div>
//             <div className="flex flex-col">
//                 <span className="text-2xl font-bold">{countdown.seconds}</span>
//                 <span className="text-sm">Seconden</span>
//             </div>
//         </div>
//     );
// };

// export default CountdownClock;
import React, { useEffect, useState } from 'react';

interface CountdownClockProps {
  targetDate: Date;
  onExpire?: () => void;
}

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  total: number;
}

const CountdownClock: React.FC<CountdownClockProps> = ({ targetDate, onExpire }) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    total: 0
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +new Date(targetDate) - +new Date();
      let timeLeft: TimeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        total: 0
      };

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
          total: difference
        };
      }

      return timeLeft;
    };

    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      if (newTimeLeft.total <= 0) {
        clearInterval(timer);
        if (onExpire) {
          onExpire();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate, onExpire]);

  return (
    <div>
      {timeLeft.total > 0 ? (
        <div className="flex space-x-4 text-center items-center justify-center bg-gray-100 p-4 rounded-lg shadow-lg">
             <div className="flex flex-col">
                 <span className="text-2xl font-bold">{timeLeft.days}</span>
                 <span className="text-sm">Dagen</span>
             </div>
             <div className="flex flex-col">
                 <span className="text-2xl font-bold">{timeLeft.hours}</span>
                 <span className="text-sm">Uren</span>
             </div>
             <div className="flex flex-col">
                 <span className="text-2xl font-bold">{timeLeft.minutes}</span>
                 <span className="text-sm">Minuten</span>
             </div>
             <div className="flex flex-col">
                 <span className="text-2xl font-bold">{timeLeft.seconds}</span>
                 <span className="text-sm">Seconden</span>
             </div>
         </div>
      ) : (
        <span>Expired</span>
      )}
    </div>
  );
};

export default CountdownClock;